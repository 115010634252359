import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'

import {
  vuexMapDefaultsMutations
} from 'src/utils.store'

import {
  definirPrevisaoPadraoSemDiaDeTrabalho,
  definirPrevisaoPadraoParaFerias,
  definirPrevisaoPadraoParaAfastamento,
  verificarSeDataEstaEntreOuEhIgual,
  formatarInformacoesDaJornada,
  definirPrevisaoPadraoEmDiaDeTrabalho,
  definirPrevisaoPadraoProximaJornada
} from './utils'

import { definirDataParaConsultarJornadasComProblemas } from '@/components/Home/utils'

import { API, retryRequest } from 'src/services'
import moment from 'moment'
import { FiltradorDeErros } from 'src/erros'

function defaultState () {
  return {
    dataInicioMes: null,
    dataFimMes: null,
    mesReferencia: null,
    dataHoje: moment().startOf('day'),
    dataOntem: moment().startOf('day').subtract(1, 'days'),
    dataAmanha: moment().startOf('day').add(1, 'days'),
    pontosDeHoje: {
      loading: false,
      error: false,
      pontos: []
    },
    pontosDeOntem: {
      loading: false,
      error: false,
      pontos: []
    },
    jornadaAnterior: {
      loading: false,
      error: false,
      jornada: null
    },
    jornadaAtual: {
      loading: false,
      error: false,
      jornada: null
    },
    proximaJornada: {
      loading: false,
      error: false,
      jornada: null
    },
    bancoDeHoras: {
      loading: false,
      error: false,
      quantidade: null
    },
    jornadasComProblemas: {
      loading: false,
      error: false,
      quantidade: null
    },
    acompanhamento: {
      loading: false,
      error: false,
      previsao: {}
    }
  }
}

const getters = { }

const mutations = {
  ...vuexMapDefaultsMutations(defaultState()),
  resetState (state) {
    resetarParaEstadoInicial(state, defaultState())
  }
}

const actions = {
  async pegarPontos ({rootState, state, commit, dispatch}) {
    const empregado = rootState.userInfo.compemployee
    const params = {
      employees: [empregado.id],
      infoToRefetch: null,
      startDate: state.dataOntem,
      endDate: state.dataHoje
    }
    commit('pontosDeOntem', { loading: true })
    commit('pontosDeHoje', { loading: true })
    try {
      const res = await retryRequest(API.timesheet.save, {load: 'pts'}, params)
      const { lines } = res.body.success

      const pontosDeOntem =
        lines.find(l => moment(l.data).isSame(state.dataOntem, 'day'))
      const pontosDeHoje =
        lines.find(l => moment(l.data).isSame(state.dataHoje, 'day'))

      commit('pontosDeOntem', {loading: false, pontos: pontosDeOntem?.timelogs || [], error: false})
      commit('pontosDeHoje', {loading: false, pontos: pontosDeHoje?.timelogs || [], error: false})
      dispatch('pegarAcompanhamento')
      return lines
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
      commit('pontosDeOntem', {loading: false, error: true})
      commit('pontosDeHoje', {loading: false, error: true})
    }
  },
  async pegarJornadas ({rootState, state, commit, dispatch}) {
    const empregado = rootState.userInfo.compemployee
    const params = {
      employees: [empregado.id],
      infoToRefetch: null,
      startDate: state.dataOntem,
      endDate: state.dataAmanha
    }
    commit('jornadaAnterior', { loading: true })
    commit('jornadaAtual', { loading: true })
    commit('proximaJornada', { loading: true })
    try {
      const res = await retryRequest(API.timesheet.save, {load: 'djs'}, params)
      const { lines } = res.body.success
      const jornadaAnterior =
        lines.find(l => moment(l.data).isSame(state.dataOntem, 'day'))
      const jornadaAtual =
        lines.find(l => moment(l.data).isSame(state.dataHoje, 'day'))
      const proximaJornada =
        lines.find(l => moment(l.data).isSame(state.dataAmanha, 'day'))

      const infomacoesJornadaAnterior = {
        nome: 'jornadaAnterior',
        dataInicio: state.dataOntem,
        dataFim: state.dataOntem,
        ...jornadaAnterior
      }
      const infomacoesJornadaAtual = {
        nome: 'jornadaAtual',
        dataInicio: state.dataHoje,
        dataFim: state.dataHoje,
        ...jornadaAtual
      }
      const infomacoesProximaJornada = {
        nome: 'proximaJornada',
        dataInicio: state.dataAmanha,
        dataFim: state.dataAmanha,
        ...proximaJornada
      }
      dispatch('tratarJornada', infomacoesJornadaAnterior)
      dispatch('tratarJornada', infomacoesJornadaAtual)
      dispatch('tratarJornada', infomacoesProximaJornada)
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
      commit('jornadaAnterior', {loading: false, error: true})
      commit('jornadaAtual', {loading: false, error: true})
      commit('proximaJornada', {loading: false, error: true})
    }
  },
  async tratarJornada ({commit, dispatch}, jornada) {
    const dataInicio = jornada.dataInicio
    const dataFim = jornada.dataFim
    const jornadaPendente =
        jornada?.dayJourney.journey.name === 'Aguarde, atualizando.'

    const possuiJustificativa = jornada?.dayJourney.journey.justify

    if (jornadaPendente) {
      const { infoToRefetch } = jornada

      await dispatch('gerarJornadasComProblemas', { dataInicio, dataFim })
      const jornadaRecalculada = await dispatch('pegarJornadaRecalculadas', infoToRefetch)
      commit(`${jornada.nome}`, { loading: false, jornada: jornadaRecalculada, error: false })
    } else {
      commit(`${jornada.nome}`, { loading: false, jornada: jornada?.dayJourney, error: false })
    }

    if (possuiJustificativa) {
      const motivo = await dispatch('pegarJustificativaDaJornada', { dataInicio, dataFim })
      commit(`${jornada.nome}`, { jornada: { ...jornada?.dayJourney, motivo } })
    }
  },
  async gerarJornadasComProblemas ({rootState}, { dataInicio, dataFim }) {
    const empregado = rootState.userInfo.compemployee
    try {
      await API.genEmpsDjs.save({
        employees: [empregado.id],
        startDate: dataInicio,
        endDate: dataFim
      })
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
    }
  },
  async pegarJornadaRecalculadas (_, parametros) {
    const params = {
      djs: [],
      infoToRefetchList: [parametros],
      recalculate: true,
      toLoad: ['djs', 'tms']
    }

    try {
      const res = await retryRequest(API.timesheetSync.save, undefined, params)
      const { lines } = res.body.success
      /* Aqui pegamos o primeiro e unico item do array, pois a função
      somente é chamada quando é recalculado apenas uma jornada
      */
      const { dayJourney } = lines[0]

      return dayJourney
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
    }
  },
  async pegarJustificativaDaJornada ({rootState}, { dataInicio, dataFim }) {
    const empregado = rootState.userInfo.compemployee
    const params = {
      employees: [empregado.id],
      startDate: dataInicio,
      endDate: dataFim
    }
    try {
      const res = await retryRequest(API.timesheet.save, {load: 'justifies'}, params)
      const { lines } = res.body.success
      const { justify: { reason } } = lines[0]

      return reason
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
    }
  },
  async pegarBancoDeHoras ({rootState, state, commit}) {
    const possuiModuloBancoDeHoras = rootState.userInfo.compMan.hasWhbank
    if (!possuiModuloBancoDeHoras) {
      return
    }

    const empregado = rootState.userInfo.compemployee
    const params = {
      kind: 'overall',
      employeeId: empregado.id,
      startDate: state.dataInicioMes.format('YYYY-MM-DD'),
      endDate: state.dataFimMes.format('YYYY-MM-DD')
    }

    commit('bancoDeHoras', {loading: true})
    try {
      const res = await API.whboManager.get(params)
      const info = res.body.success

      const quantidade = info?.window?.totalBalance || info.all_times

      commit('bancoDeHoras', {loading: false, quantidade: quantidade, error: false})
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
      commit('bancoDeHoras', {loading: false, error: true})
    }
  },
  async pegarJornadasComProblemas ({rootState, state, commit}) {
    const dataHoje = moment()
    const { dataInicio, dataFim } = definirDataParaConsultarJornadasComProblemas(dataHoje, state.dataInicioMes, state.dataFimMes)

    const empregado = rootState.userInfo.compemployee
    const inicioDoMes = dataInicio.format('YYYY-MM-DD')
    const fimDoMes = dataFim.format('YYYY-MM-DD')
    const params = {
      employees: [empregado.id],
      startDate: inicioDoMes,
      endDate: fimDoMes
    }
    commit('jornadasComProblemas', { loading: true })
    try {
      const res = await retryRequest(API.timesheet.save, {load: 'lines'}, params)
      const { lines } = res.body.success
      const diasComProblemas =
        lines?.filter(linha => {
          const observacao = linha?.timeMeasurements?.apontamentos?.obs?.value
          return observacao && !observacao.includes('Ausência justificada:')
        })

      commit('jornadasComProblemas', { loading: false, quantidade: diasComProblemas?.length, error: false })
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
      commit('jornadasComProblemas', { loading: false, error: true })
    }
  },
  async pegarAcompanhamento ({rootState, commit, state, dispatch}) {
    const empregado = rootState.userInfo.compemployee
    const diaCorrente = new Date()
    const pontosDoDia = state.pontosDeHoje.pontos
    const possuiPontoDeEntrada = Boolean(pontosDoDia[0].length)
    const possuiPontoDePausa = Boolean(pontosDoDia[1].length)
    const possuiPontoDeRetorno = Boolean(pontosDoDia[2].length)
    const possuiPontoDeSaida = Boolean(pontosDoDia[3].length)
    const mostrarInicioEFimDaJornada =
      rootState.userInfo.compMan.frontOptions.timesheetDjDisplayText === 'start_end'
    commit('acompanhamento', {loading: true})
    try {
      /*
       Primeiro, verificamos se existe Férias ou Afastamento no dia corrente,
       e, se já possuimos ponto de saida.
      */
      const { vacation, leave } = await dispatch('pegarFeriasEAfastamento')
      const possuiFerias = Boolean(vacation.length)
      const inicioUltimasFerias = moment(vacation[vacation.length - 1]?.start)
      const fimUltimasFerias = moment(vacation[vacation.length - 1]?.end)
      const possuiAfastamento = Boolean(leave.length)
      const hojeEstaNoPeriodoDasFerias = possuiFerias
        ? verificarSeDataEstaEntreOuEhIgual(diaCorrente, inicioUltimasFerias, fimUltimasFerias, 'day')
        : false
      const hojeEstaNoPeriodoDeAfastamento = possuiAfastamento
        ? verificarSeDataEstaEntreOuEhIgual(diaCorrente, moment(leave[0]?.start), 'day')
        : false

      if (possuiFerias && hojeEstaNoPeriodoDasFerias && !possuiPontoDeSaida) {
        const previsaoFerias = definirPrevisaoPadraoParaFerias(vacation[vacation.length - 1])
        commit('acompanhamento', {loading: false, previsao: previsaoFerias})
        return
      }

      if (possuiAfastamento && hojeEstaNoPeriodoDeAfastamento && !possuiPontoDeSaida) {
        const previsaoAcompanhamento = definirPrevisaoPadraoParaAfastamento(leave[0])
        commit('acompanhamento', {loading: false, previsao: previsaoAcompanhamento})
        return
      }
      /* Como sabemos que o dia corrente não é férias ou afastamento,
        vamos recalcular a jornada do dia para garantir consistência.
        Alem disso, agora verificamos se é dispensa, suspensão, folga ou sem jornada
      */
      const paramentrosDj = {
        employees: [empregado.id],
        infoToRefetch: null,
        startDate: state.dataHoje,
        endDate: state.dataHoje
      }

      const djsRes = await retryRequest(API.timesheet.save, {load: 'djs'}, paramentrosDj)
      const jornada = djsRes.body.success.lines[0]
      await dispatch('gerarJornadasComProblemas', {dataInicio: state.dataHoje, dataFim: state.dataHoje})
      const jornadaDoDiaCorrente = await dispatch('pegarJornadaRecalculadas', jornada.infoToRefetch)
      if (!jornadaDoDiaCorrente.isWorkDay) {
        const { isHoliday } = jornadaDoDiaCorrente
        const { justify, suspend } = jornadaDoDiaCorrente.journey

        if (jornadaDoDiaCorrente?.journey?.name === 'Sem jornada') {
          const previsao = definirPrevisaoPadraoSemDiaDeTrabalho('sem jornada')
          commit('acompanhamento', {loading: false, previsao: previsao})
          return
        }
        if (isHoliday) {
          const previsao = definirPrevisaoPadraoSemDiaDeTrabalho('feriado')
          commit('acompanhamento', {loading: false, previsao: previsao})
          return
        }
        if (suspend) {
          const previsao = definirPrevisaoPadraoSemDiaDeTrabalho('suspensao')
          commit('acompanhamento', {loading: false, previsao: previsao})
          return
        }
        if (justify) {
          const motivo =
            await dispatch('pegarJustificativaDaJornada', {dataInicio: state.dataHoje, dataFim: state.dataHoje})
          const previsao = definirPrevisaoPadraoSemDiaDeTrabalho('dispensa', motivo)
          commit('acompanhamento', {loading: false, previsao: previsao})
          return
        }
        const previsao = definirPrevisaoPadraoSemDiaDeTrabalho('folga')
        commit('acompanhamento', {loading: false, previsao: previsao})
        return
      }
      /*
        Nesta Parte do Fluxo, sabemos que o dia corrente é um dia de trabalho.
        Sendo assim, chamamos a API de acompanhamento
      */
      const res = await API.acompanhamento.get({id: empregado.id})
      const { horariosPrevistos } = res.body

      if (possuiPontoDeSaida) {
        /*
          Como Já temos o ponto de Saída, vamos verificar a próxima Jornada
        */
        const proximaJornada = state.proximaJornada.jornada
        const proximaJornadaEhDiaDeTrabalho = proximaJornada?.isWorkDay

        if (!proximaJornadaEhDiaDeTrabalho) {
          /*
            Caso a próxima jornada não seja jornada de trabalho,
            vamos verificar se existe jornada de trabalho pelos
            próximos 45 dias
          */
          const params = {
            employees: [empregado.id],
            infoToRefetch: null,
            startDate: state.dataAmanha,
            endDate: moment(state.dataAmanha).add(45, 'days')
          }
          let proximaJornadaDeTrabalho
          const djsRes = await retryRequest(API.timesheet.save, {load: 'djs'}, params)
          const jornadas = djsRes.body.success.lines
          const jornadasASeremRecalculadas = jornadas.filter(djs => djs.dayJourney.toBeRecalculated)

          if (jornadasASeremRecalculadas.length) {
            await dispatch('gerarJornadasComProblemas', {dataInicio: state.dataHoje, dataFim: params.endDate})
            const infoToRefetch = jornadas.map(dj => dj.infoToRefetch)
            const infoToRefetchParams = {
              djs: [],
              infoToRefetchList: infoToRefetch,
              recalculate: true,
              toLoad: ['djs', 'tms']
            }

            const res = await retryRequest(API.timesheetSync.save, undefined, infoToRefetchParams)
            const jornadasRecalculadas = res.body.success.lines

            proximaJornadaDeTrabalho = jornadasRecalculadas.find(l => l.dayJourney.isWorkDay)
          } else {
            proximaJornadaDeTrabalho = jornadas.find(l => l.dayJourney.isWorkDay)
          }
          if (proximaJornadaDeTrabalho) {
            const proximaJornadaFormatada = formatarInformacoesDaJornada(proximaJornadaDeTrabalho.dayJourney)
            const previsaoProximaJornada =
              definirPrevisaoPadraoProximaJornada('possui jornada', mostrarInicioEFimDaJornada, proximaJornadaFormatada)
            commit('acompanhamento', {loading: false, previsao: previsaoProximaJornada})
            return
          }
          const previsaoSemJornada = definirPrevisaoPadraoProximaJornada('sem jornada')
          commit('acompanhamento', {loading: false, previsao: previsaoSemJornada})
          return
        }
        const proximaJornadaFormatada = formatarInformacoesDaJornada(proximaJornada)
        const previsaoProximaJornada =
            definirPrevisaoPadraoProximaJornada('possui jornada', mostrarInicioEFimDaJornada, proximaJornadaFormatada)

        commit('acompanhamento', {loading: false, previsao: previsaoProximaJornada})
        return
      } else {
        const jornadaAtual = formatarInformacoesDaJornada(jornadaDoDiaCorrente)

        if (!possuiPontoDeEntrada) {
          const dataAgoraEmHoras = moment(diaCorrente).hours()
          const dataInicioJornadaEmHoras = jornadaAtual.inicio.hours()
          const excedeuHorarioPrevisto = moment(dataAgoraEmHoras).isAfter(dataInicioJornadaEmHoras)

          if (excedeuHorarioPrevisto) {
            const previsaoEntradaAtrasada =
              definirPrevisaoPadraoEmDiaDeTrabalho('entrada atrasada', mostrarInicioEFimDaJornada, jornadaAtual)
            commit('acompanhamento', {loading: false, previsao: previsaoEntradaAtrasada})
            return
          }
          const previsaoEntrada =
            definirPrevisaoPadraoEmDiaDeTrabalho('entrada', mostrarInicioEFimDaJornada, jornadaAtual)
          commit('acompanhamento', {loading: false, previsao: previsaoEntrada})
          return
        }

        if (possuiPontoDePausa && !possuiPontoDeRetorno) {
          const retorno = horariosPrevistos.find(h => h.evento === 'horario_retorno')
          const dataAgoraEmHoras = moment.utc(diaCorrente).toISOString()
          const dataRetornoJornadaEmHoras = moment.utc(retorno.horario_utc).toISOString()
          const excedeuHorarioPrevisto = moment(dataAgoraEmHoras).isAfter(dataRetornoJornadaEmHoras)

          if (excedeuHorarioPrevisto) {
            const previsaoRetornoAtrasado =
              definirPrevisaoPadraoEmDiaDeTrabalho('retorno atrasado', mostrarInicioEFimDaJornada, dataRetornoJornadaEmHoras)
            commit('acompanhamento', {loading: false, previsao: previsaoRetornoAtrasado})
            return
          }
          const previsaoRetorno =
            definirPrevisaoPadraoEmDiaDeTrabalho('retorno', mostrarInicioEFimDaJornada, dataRetornoJornadaEmHoras)
          commit('acompanhamento', {loading: false, previsao: previsaoRetorno})
          return
        } else {
          const saida = horariosPrevistos.find(h => h.evento === 'horario_saida')
          const dataAgoraEmHoras = moment.utc(diaCorrente).toISOString()
          const dataSaidaJornadaEmHoras = moment.utc(saida.horario_utc).toISOString()
          const excedeuHorarioPrevisto = moment(dataAgoraEmHoras).isAfter(dataSaidaJornadaEmHoras)

          if (excedeuHorarioPrevisto) {
            const previsaoSaidaAtrasada =
              definirPrevisaoPadraoEmDiaDeTrabalho('saida atrasada', mostrarInicioEFimDaJornada, dataSaidaJornadaEmHoras)
            commit('acompanhamento', {loading: false, previsao: previsaoSaidaAtrasada})
            return
          }
          const previsaoSaida =
              definirPrevisaoPadraoEmDiaDeTrabalho('saida', mostrarInicioEFimDaJornada, dataSaidaJornadaEmHoras)
          commit('acompanhamento', {loading: false, previsao: previsaoSaida})
          return
        }
      }
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
      commit('acompanhamento', {loading: false, error: true})
    }
  },
  async pegarFeriasEAfastamento ({rootState}) {
    const empregado = rootState.userInfo.compemployee
    try {
      const res = await API.afastamento.save({}, {
        id: empregado.id,
        getList: true,
        page: 0,
        serach: null
      })

      return res.body.success
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
    }
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  getters,
  mutations,
  actions
}
