import { obterComNotacaoPonto } from '@/utils/outro/outro'

export function handlerUseStateVmodel (mergeOldWithNew = false) {
  // General mixin that decides from a prop if use the useState prop or
  // the giver v-model.
  // If you want to set the state you can use this.state = obj that
  // this mixin will guess by the given props if should use the v-model or commit to vuex
  // You can use the callback too, it should receive a computed object like, eg: {set: func, get: func}
  // it is usefull when you need to dynamic generate a generic computed, you can se in use at calcDedailsRules
  return {
    props: {
      useState: { required: false, type: String },
      value: { 'default': undefined },
      customVmodel: { 'default': undefined, type: Object }
    },
    computed: {
      // This is useful to allow other mixins that implement this one to have their own computed state overriding this one
      state: {
        get () { return this.__state },
        set (value) { this.__state = value }
      },
      __state: {
        get () {
          if (this.value === undefined && !this.useState && !this.$listeners.change && !this.customVmodel) {
            throw new Error('Missing required params, this component need useState, v-model (should not be undefined), @change or customVmodel')
          }

          if (this.customVmodel) {
            return this.customVmodel.get()
          } else if (this.value === undefined) {
            return obterComNotacaoPonto(this.$store.state, this.useState, '/')
          } else {
            return this.value
          }
        },
        set (value) {
          if (this.customVmodel) {
            this.customVmodel.set(value)
          } else if (this.value === undefined) {
            this.$store.commit(this.useState, value)
          } else {
            const final = mergeOldWithNew ? { ...this.__state, ...value } : value

            this.$emit('input', final)
            if (this.$listeners.change) {
              this.$emit('change', final)
            }
          }
        }
      }
    },
    methods: {
      _setState (newState) {
        this.state = { ...newState }
      }
    }
  }
}
