import { API, retryRequest } from 'src/services'
import { congelarObjetosDaLista } from 'src/utils/formatadoras/formatadoras'

const minimalOnly = [
  'id', 'code', 'name', 'resignation'
]

const defaultOnly = [
  'closing_date', 'id', 'code', 'name',
  'resignation', 'company', 'work_hour_bank_rules',
  'phoneclock_password', 'funct', 'custom_rules',
  'sincronizar_com_repp', 'id_repp'
]

const PER_PAGE = 30

let timer

export default {
  namespaced: true,
  state: {
    items: [],
    loading: false,
    page: 0,
    totalPages: 0
  },
  getters: {
    onlyNotFired (state) {
      return state.items.filter(i => !i.resignation)
    },
    onlyFired (state) {
      return state.items.filter(i => i.resignation)
    },
    onlyWithWhbAndNotFired (state) {
      return state.items.filter(i => (i.workHourBankRules || i.customRules.calculoPassivo) && !i.resignation)
    },
    onlyWithWhb (state) {
      return state.items.filter(i => i.workHourBankRules || i.customRules.calculoPassivo)
    },
    aindaFaltaMostrarPaginas (state) {
      const {page, totalPages, items} = state
      const saoNumeros = [page, totalPages].every(v => typeof v === 'number')
      const possuiMenosQuePerPage = items.length < PER_PAGE
      const maisPaginasAlemDaAtual = totalPages > page
      return saoNumeros && !possuiMenosQuePerPage && maisPaginasAlemDaAtual
    }
  },
  mutations: {
    appendItems (state, payload) {
      state.items = [...state.items, ...payload]
      state.loading = false
    },
    saveItems (state, payload) {
      state.items = payload
      state.page = 0
      state.loading = false
    },
    resetItems (state) {
      state.items = []
    },
    loading (state, payload) {
      state.loading = Boolean(payload)
    },
    setTotalPages (state, payload) {
      state.totalPages = payload
    },
    setPage (state, payload) {
      state.page = payload
    }
  },
  actions: {
    async fetchItems ({ commit }, payload) {
      payload = payload || {}

      commit('loading', true)

      const {
        withFields = [],
        fullReload,
        withMinimalInformation,
        withParams
      } = payload

      if (fullReload) {
        commit('resetItems')
      }

      let requiredFields = defaultOnly
      if (withMinimalInformation) {
        requiredFields = minimalOnly
      }

      let params = {
        active: 'false',
        order_by: 'name',
        fired: 'all',
        noderef: 'bulk'
      }
      if (withParams) {
        params = {...params, ...withParams}
      }

      const only = Array.from(new Set([...requiredFields, ...withFields]))

      const result = await retryRequest(API.employee.get, {
        fields: JSON.stringify(only),
        ...params
      })
      commit('saveItems', congelarObjetosDaLista(result.body.success))
    },
    async fetchItemsComPaginacao ({ state, commit }, payload = {}) {
      commit('loading', true)

      clearTimeout(timer)
      timer = setTimeout(async () => {
        const {
          withFields = ['first_work_day'],
          fullReload,
          search,
          append = false,
          withMinimalInformation,
          withParams
        } = payload
        const {page} = state

        if (fullReload) {
          commit('resetItems')
        }

        let requiredFields = defaultOnly
        if (withMinimalInformation) {
          requiredFields = minimalOnly
        }
        const only = Array.from(new Set([...requiredFields, ...withFields]))

        // append define se iremos adicionar no final (mais itens carregados)
        const paginaParaRequisicao = append ? (page + 1) : 0

        let searchParams = {}
        if (search) {
          searchParams['search'] = JSON.stringify({name_code_phoneclock_password: search})
        }

        let params = {
          ...searchParams,
          active: 'false',
          order_by: 'name',
          fired: 'all',
          noderef: 'bulk',
          page: paginaParaRequisicao,
          per_page: PER_PAGE
        }
        if (withParams) {
          params = {...params, ...withParams}
        }

        const result = await retryRequest(API.employee.get, {
          fields: JSON.stringify(only),
          ...params
        })

        if (result.status === 204) {
          result.body = {
            success: [],
            total: 0,
            totalPages: 0
          }
        }

        const {totalPages, success} = result.body

        commit('setTotalPages', totalPages)
        commit('setPage', paginaParaRequisicao)

        if (append) {
          commit('appendItems', congelarObjetosDaLista(success))
        } else {
          commit('saveItems', congelarObjetosDaLista(success))
        }
      }, 500)
    }
  }
}
