import moment from 'moment'

export const definirDataParaConsultarJornadasComProblemas = (dataHoje, dataInicialComponente, dataFinalComponente) => {
  const copiaDataInicioComponente = moment(dataInicialComponente).clone()
  const copiaDataFimComponente = moment(dataFinalComponente).clone()
  const copiaDataHoje = moment(dataHoje).clone()
  const diaDataHojeEhIgualADiaDataInicialDoComponente = moment(copiaDataHoje).isSame(copiaDataInicioComponente, 'days')
  const mesDataHojeEhIgualAoMesDataFinalDoComponente = moment(copiaDataHoje).isSame(dataFinalComponente, 'month')
  const dataHojeEhDiaUm = copiaDataHoje.date() === 1

  let dataInicio
  let dataFim

  if (diaDataHojeEhIgualADiaDataInicialDoComponente) {
    dataInicio = copiaDataInicioComponente.subtract(1, 'month')
    if (dataHojeEhDiaUm) {
      const fimDoMesAnteriorDeAcordoComADataDeInicio = moment(dataInicio).endOf('month')
      dataFim = fimDoMesAnteriorDeAcordoComADataDeInicio
    } else {
      const fimDoMesAnteriorDeAcordoComADataFinalDoComponente = copiaDataFimComponente.subtract(1, 'month')
      dataFim = fimDoMesAnteriorDeAcordoComADataFinalDoComponente
    }
  } else {
    dataInicio = copiaDataInicioComponente

    if (mesDataHojeEhIgualAoMesDataFinalDoComponente) {
      const diaOntem = dataHoje.subtract(1, 'day')
      dataFim = diaOntem
    } else {
      dataFim = copiaDataFimComponente
    }
  }
  return {dataInicio, dataFim}
}
