<template lang="pug">
  .column-header-wrapper(:style="{'grid-column': index + 1}")
    template(v-if="pesquisavel")
      .group
        template(v-if="!options")
          template(v-if="mask")
            input(
              v-mask="mask"
              type='text'
              required=''
              v-model="searchValue"
              :placeholder="placeholder"
              autocomplete="off"
              ref="input"
              @keyup.down="() => $emit('focusFirstRow')"
            )
          template(v-else)
            input(
              type='text'
              required=''
              v-model="searchValue"
              :placeholder="placeholder"
              v-uiv-popover.bottom.hover="{content: mensagemAoPassarCursor}"
              autocomplete="off"
              ref="input"
              @keyup.down="() => $emit('focusFirstRow')"
            )

          span.highlight
          label {{ column }}

        template(v-else)
          .option-column-name.to-click.noselect(@click="switchShowOptions") {{ column }}

          transition(name="bounce2")
            .options-container(v-if="showOptions", v-click-outside="closeOptions")
              .noselect(v-for="op in options" @click="setOptionsSearch(op)")
                span(:class="op.iconClass" @click="setOptionsSearch(op)")
                span {{ op.text }}

      .sort-icon.icon-ptt-icons-dropdown.to-click(
        v-if="!options && ordenavel"
        :class="{active: currentFieldSort.field === field}"
        :style="currentFieldSort.field === field && currentFieldSort.order === 'desc' ? 'transform: rotate(180deg)' : ''",
        @click="switchOrder"
      )

      .filter.icon-bv-icones-filtro-contorno.to-click(
        :style="searchValue ? 'opacity: 1; color: var(--color-success)' : ''"
        @click="() => $refs.input.focus()"
      )
    template(v-else)
      .coluna(:class="[classeDeCentralizado]" v-uiv-popover.bottom.hover="{content: mensagemAoPassarCursor}")
        label {{ column }}

</template>

<script>
export default {
  name: 'ColumnHeader',
  props: {
    column: {type: String, required: true},
    field: {type: String, required: true},
    index: {type: Number, required: true},
    options: {type: Array, required: false, 'default': null},
    search: {type: String, required: true},
    mask: {type: String, required: false, 'default': null},
    placeholder: {type: String, required: false, 'default': ''},
    currentFieldSort: {type: Object, required: true},
    pesquisavel: {type: Boolean, 'default': true},
    ordenavel: {type: Boolean, 'default': true},
    tituloCentralizado: {type: Boolean, 'default': false},
    mensagemAoPassarCursor: {type: String, required: false, 'default': null}
  },
  data () {
    return {
      showOptions: false
    }
  },
  computed: {
    searchValue: {
      get () {
        return this.search
      },
      set (value) {
        this.$emit('search', this.field, value)
      }
    },
    classeDeCentralizado () {
      return this.tituloCentralizado
        ? 'centralizado'
        : ''
    }
  },
  methods: {
    switchOrder () {
      this.$emit(
        'order',
        this.field,
        this.currentFieldSort.field === this.field && this.currentFieldSort.order === 'asc' ? 'desc' : 'asc'
      )
    },
    focusSearch (text = '', merge = true) {
      // used for parent be able to focus my input search
      if (this.$refs.input) this.$refs.input.focus()
      if (text) {
        this.searchValue = merge ? this.searchValue + text : text
      }
    },
    switchShowOptions () {
      this.showOptions = !this.showOptions
    },
    closeOptions () {
      this.showOptions = false
    },
    setOptionsSearch (item) {
      this.searchValue = item.text
      this.closeOptions()
    }
  }
}
</script>

<style scoped lang="scss">
.column-header-wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto auto;
  position: relative;
  padding-right: 10px;

  .centralizado {
    text-align: center;
  }

  .sort-icon {
    transition: transform 0.2s ease-out;
    margin-right: 10px;

    &:not(.active) {
      color: lightgray;
    }
  }

  .filter {
    margin-right: 10px;
    grid-column: 3 / 4;
    opacity: 0;
    color: var(--color-primary);
    transition: opacity 0.2s linear, color 0.2s linear;
  }

  &:hover, &:focus-within {
    .filter {
      opacity: 1;
    }
  }
  .coluna {
    position:relative;
    grid-column: 1 / 4;
    padding-top: 1px;
    /* Label Design */
    label {
      color: var(--color-primary);
      font-size: 15px;
      font-weight: bold;
      pointer-events:none;
      align-self: center;
    }
  }
  .group {
    position:relative;
    align-self: end;
    grid-column: 1 / 2;

    input {
      padding: 10px 5px 10px 0;
      display:block;
      width:100%;
      border:none;
      z-index: -10;

      &::selection {
        background-color: var(--color-primary);
        color: var(--color-primary-contrast);
      }

      &:not(:focus) {
        &::placeholder {
          color: transparent !important;
        }
      }
    }
    input:focus {
      outline:none;
    }
    /* Label Design */
    label {
      color: var(--color-primary);
      font-size: 15px;
      font-weight: bold;
      position:absolute;
      pointer-events:none;
      top: 8px;
      transition:0.2s ease all;
      display: grid;
      grid-template-columns: max-content min-content;
      grid-column-gap: 3px;
      z-index: 100;

      .icon-ptt-icons-dropdown {
        margin-top: 2px;
        z-index: 100;
      }

    }

    .option-column-name {
      color: var(--color-primary);
      font-size: 15px;
      font-weight: bold;
      padding-bottom: 5px;
    }
    /* Active state */
    input:focus ~ label, input:valid ~ label {
      top: -8px;
      font-size: 12px;
      color: var(--color-primary);
      background-color: var(--front-background-color);
      margin-left: -4px;
      padding: 0 7px 0 4px;
    }

    /* active state */
    input:focus ~ .bar:before, input:focus ~ .bar:after {
      width: 50%;
    }
    /* Highlighter */
    .highlight {
      position:absolute;
      height:60%;
      width:100px;
      top:25%;
      left:0;
      pointer-events:none;
      opacity:0.5;
    }
    /* active state */
    input:focus ~ .highlight {
      animation:inputHighlighter 0.3s ease;
    }

    .options-container {
      display: grid;
      grid-template-columns: minmax(0, 0.2fr) minmax(0, 1fr);
      position: absolute;
      width: 100%;
      background-color: var(--front-background-color);
      box-shadow: 5px 5px 0px -2px rgba(41,41,41,0.11);
      padding: 3px;
      border-bottom: 2px solid var(--color-primary);
      border-left: 2px solid var(--color-primary);
      border-right: 2px solid var(--color-primary);
      overflow: hidden;

      div {
        display: contents;
        cursor: pointer;

        &:hover {
          span {
            background-color: whitesmoke;
          }
        }

        span:first-child {
          text-align: center;
        }

        span {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@keyframes complete-down {
  0% {
    height: 0%
  }
  100% {
    height: 100%
  }
}

.bounce2-enter-active {
  animation: complete-down  .2s;
}
.bounce2-leave-active {
  animation: complete-down  .2s reverse;
}

</style>
