<template lang="pug">
  .main-header
    template(v-if="!onlySheetView")
      router-link.main-header__logo(to="/")
        img.to-click(:src="logo", alt="Logo Pontotel. Letra P com 2 pontos em amarelo parecendo uma carinha de sorrindo. Ao lado escrito Pontotel em branco")
    template(v-else)
      a.main-header__logo
        img(:src="logo", alt="Logo Pontotel. Letra P com 2 pontos em amarelo parecendo uma carinha de sorrindo. Ao lado escrito Pontotel em branco")

    ul.main-header__links

      template(v-if="!onlySheetView")
        Notification

        uiv-dropdown.category-dropdown(v-for="group in groups", :key="group.category",
          v-if="group.show && group.values.find(i => i.show)" :ref="`dropdown-${group.category}`"
        )
          a.to-click.main-header__button--category.noselect.lowercase(type="primary" class="dropdown-toggle" tabindex="0" @keydown.enter="() => $refs[`dropdown-${group.category}`][0].toggle()" aria-haspopup="true")
            span {{ group.category }}
            .category-icon(:class="`icon-ptt-icons-${group.icon}`")
            .arrow-icon.icon-ptt-icons-dropdown

          template(slot="dropdown")
            li
              router-link.to-click.noselect.lowercase(v-for="(item, index) in group.values" tag="a", v-if="item.show" :to="item.link" :key="index")
                template(v-if="item.adapted")
                  | {{ $ws(item.adapted) }}
                template(v-else)
                  | {{ item.name }}

      template(v-else)
        Notification(v-if="possuiAlgumaPermissaoDeRelatorio")
        label(style="grid-column: 2/-2") {{ $store.state.userInfo.name }}

      uiv-popover(trigger="hover" title="Acessando o sistema como")
        ul#botao-sair.to-click(
          style="padding: 0;",
          v-if="showLogoutButton"
        )
          a.to-click.main-header__button--category(@click="sair", @keydown.enter="sair", tabindex="0")
            span sair
            .category-icon.icon-ptt-icons-sair(style="font-size: 1.4em")
        template(slot="popover")
          .info-do-usuario(v-if="infoDoUsuario")
            div(v-for="(item, key) in infoDoUsuario", :key="key", :class="{[`info-do-usuario__${key}`]: true}")
              template(v-if="item.conteudo")
                .legenda
                  | {{item.legenda}}
                .conteudo
                  | {{item.conteudo}}

</template>

<script>
import { mapActions } from 'vuex'
import Notification from './Notification.vue'
// ------------------------------------------------
// NÃO MOVER OU REMOVER ESSE IMPORT.
// Por algum motivo, ao retirar ou mover esse import
// acontecem alguns problemas de execução que não
// deixam a renderização das telas.

// eslint-disable-next-line no-unused-vars
import { LOGIN_PATH } from 'src/router/auth'
// ------------------------------------------------
import moment from 'moment'
import Slider from 'src/common/inputs/Slider'

// gambiarra feita para o card https://taiga.pontotel.com.br/project/mayraminae-prod/us/3184
const Daycoval = '61fd1484726c4dacae2ed299'

export default {
  components: {
    Notification, Slider
  },
  created () {
    if (!this.$store.state.userInfo.compMan.frontOptions.showHeaderRoleMine && this.$store.state.userInfo.roleGroup.kind === 'mine') {
      this.onlySheetView = true
      this.$router.goToTimesheet(new Date(), this.$store.state.userInfo.compemployee)
    }
    // Don't show helpscout button for BV
    if (this.$store.state.userInfo.compMan.id !== '5cd49411d28021000c09e013') window.Beacon('init', 'eb8125d5-b976-4cf7-969e-7aa2d2ce5513')
  },
  beforeDestroy () {
    window.Beacon('destroy')
  },
  computed: {
    infoDoUsuario () {
      try {
        const {name, email, roleGroup} = this.$store.state.userInfo

        return {
          nome: {
            legenda: 'Nome',
            conteudo: (name !== email && name) || '-'
          },
          email: {
            legenda: 'E-mail',
            conteudo: email || '-'
          },
          permissaoDoSistema: {
            legenda: 'Permissão no sistema',
            conteudo: (roleGroup && roleGroup.name) || '-'
          }
        }
      } catch (erro) {
        return null
      }
    },
    logo () {
      return require('../../../static/images/logo-pontotel-branco.png')
    },
    currentRouteName () {
      return this.$route.name
    },
    showOnlyNew () {
      return this.$store.state.userInfo.compMan.frontOptions.useTimesheet
    },
    showLogoutButton () {
      const value = this.$store.state.visualConfigs?.visual?.frontButtonLogout
      return value !== undefined && !this.$store.state.userInfo.email.includes('lar21@') ? value : true
    },
    possuiAlgumaPermissaoDeRelatorio () {
      // Permissão geral "exportar relatórios/gerar registros"
      return this.$hasPermission({module: 'exportfiles', action: 'post'})
    }
  },
  data () {
    const today = moment().format('YYYY-MM-DD')
    const sp = {
      isLar21: this.$store.state.userInfo.email.includes('lar21@'),
      canUseActivities: this.$store.state.userInfo.compMan.hasActivity,
      showEventlog: this.$store.state.userInfo.compMan.createEventlog,
      hasRecognizeFace: this.$store.state.userInfo.compMan.hasRecognizeFace,
      usePositions: this.$store.state.userInfo.compMan.usePositions,
      canUseLocker: this.$store.state.userInfo.compMan.canLockFolha,
      canUseWHB: this.$store.state.userInfo.compMan.hasWhbank,
      hasApprovals: this.$store.state.userInfo.compMan.hasApprovals,
      hasREP: this.$store.state.userInfo.compMan.has_rep,
      hasOnCall: this.$store.state.userInfo.compMan.hasOnCall,
      hasAprovacaoDeArquivos: this.$store.state.userInfo.compMan.hasAprovacaoDeArquivo,
      hasAcompanhamento: this.$store.state.userInfo.compMan?.hasAcompanhamento,
      has671: this.$store.state.userInfo.compMan?.portaria671,
      novoModuloDeAprovacoes: this.$store.state.userInfo.compMan?.novoModuloDeAprovacoes,
      gestaoDeFerias: this.$store.state.userInfo.compMan?.gestaoDeFerias
    }

    const p = {
      cadastros: {
        self: this.$hasPermission({module: 'cadastros', action: 'get'}),
        compemployee: this.$hasPermission({module: 'compemployee', action: 'get'}),
        company: this.$hasPermission({module: 'company', action: 'get'}),
        employer: this.$hasPermission({module: 'employer', action: 'get'}),
        activitytype: sp.canUseActivities && this.$hasPermission({module: 'activitytype', action: 'get'}),
        activitycostcenter: sp.canUseActivities && this.$hasPermission({module: 'activitycostcenter', action: 'get'}),
        activityobject: sp.canUseActivities && this.$hasPermission({module: 'activityobject', action: 'get'}),
        usergroup: this.$hasPermission({module: 'usergroup', action: 'get'}),
        supervisor: this.$hasPermission({module: 'supervisor', action: 'get'}),
        routine: this.$hasPermission({module: 'routine', action: 'get'}),
        holiday: this.$hasPermission({module: 'holidaysCalendar', action: 'get'}),
        oncall: sp.hasOnCall && this.$hasPermission({module: 'oncall', action: 'get'}),
        reason: this.$hasPermission({module: 'reason', action: 'get'}),
        dayjourney: this.$hasPermission({module: 'dayjourney', action: 'get'})
      },
      folhas: {
        self: this.$hasPermission({module: 'timelog', action: 'get'}),
        verify: this.$hasPermission({module: 'problems', action: 'get'}),
        autocorrect: this.$hasPermission({module: 'fastregister', action: 'get'}) && this.$hasPermission({
          any: ['justify_post_all', 'justify_post_group', 'suspension_post_all', 'suspension_post_group', 'journey_post_all', 'journey_post_group', 'faketimelog_post_all', 'faketimelog_post_group', 'faketimelog_delete_all', 'faketimelog_delete_group', 'timelog_post_all', 'timelog_post_group']
        }),
        locker: sp.canUseLocker && this.$hasPermission({module: 'locker', action: 'get'})
      },
      planejamento: {
        self: this.$hasPermission({
          any: [
            'timemeasurements_get_all', 'timemeasurements_get_group',
            'realtime_get_all', 'realtime_get_group',
            'eventlog_get_all', 'eventlog_get_group',
            'dayjourney_get_all', 'dayjourney_get_group'
          ]
        }),
        timemeasurements: this.$hasPermission({module: 'timemeasurements', action: 'get'}),
        realtime: this.$hasPermission({module: 'realtime', action: 'get'}),
        eventlog: sp.showEventlog && this.$hasPermission({module: 'eventlog', action: 'get'}),
        dayjourney: this.$hasPermission({module: 'dayjourney', action: 'get'}),
        acompanhamentoTempoReal: this.$store.state.userInfo.compMan.possuiLimiteHoraExtra && this.$hasPermission({module: 'acompanhamentodiario', action: 'get'})
      },
      gestao: {
        self: this.$hasPermission({
          any: [
            'exportfiles_post_all', 'exportfiles_post_group', 'exportfiles_post_mine',
            'workhourbankobjects_get_all', 'workhourbankobjects_get_group', 'workhourbankobjects_get_mine',
            'objectapprovalrules_get_all', 'objectapprovalrules_get_group', 'objectapprovalrules_get_mine',
            'activityline_get_all', 'activityline_get_group', 'activityline_get_mine',
            'rep_get_all', 'rep_get_group', 'rep_get_mine',
            'biometric_get_all', 'biometric_get_group', 'biometric_get_mine',
            'appkey_get_all', 'appkey_get_group', 'appkey_get_mine',
            'notificationrules_get_all', 'notificationrules_get_group', 'notificationrules_get_mine',
            'aprovacaoarquivo_get_all', 'aprovacaoarquivo_get_group', 'aprovacaoarquivo_get_mine',
            'manage_get_all', 'manage_get_group', 'manage_get_mine'
          ]
        }),
        activityline: sp.canUseActivities && this.$hasPermission({module: 'activityline', action: 'get'}),
        workhourbankobjects: sp.canUseWHB && this.$hasPermission({module: 'workhourbankobjects', action: 'get'}),
        approvals: sp.hasApprovals && this.$hasPermission({module: 'objectapprovalrules', action: 'get'}),
        exportfiles: this.$hasPermission({module: 'exportfiles', action: 'post'}),
        rep: sp.hasREP && this.$hasPermission({module: 'company', action: 'get'}) && this.$hasPermission({module: 'rep', action: 'get'}),
        biometric: sp.hasRecognizeFace && this.$hasPermission({module: 'biometric', action: 'get'}),
        appkey: this.$hasPermission({module: 'appkey', action: 'get'}),
        coletores: this.$hasPermission({module: 'manage', action: 'get'}) && sp.has671,
        notificationrules: sp.hasAcompanhamento && this.$hasPermission({module: 'notificationrules', action: 'get'}),
        aprovacaoarquivo: sp.hasAprovacaoDeArquivos && this.$hasPermission({module: 'aprovacaoarquivo', action: 'get'}),
        gerenciamentoDeFerias: sp.gestaoDeFerias
      },
      admin: {
        self: this.$hasPermission({
          any: [
            'customrules_post_all',
            'workhourbankrules_post_all',
            'lineapprovalsrules_post_all',
            'disciplinerules_post_all',
            'role_post_all',
            'user_get_all',
            'user_get_group',
            'user_get_mine',
            'appkey_get_all',
            'customrules_post_all'
          ]
        }),
        customrules: this.$hasPermission({module: 'customrules', action: 'post'}),
        workhourbankrules: sp.canUseWHB && this.$hasPermission({module: 'workhourbankrules', action: 'get'}),
        approvalsrules: sp.hasApprovals && this.$hasPermission({module: 'objectapprovalrules', action: 'get'}),
        role: this.$hasPermission({module: 'role', action: 'get'}),
        user: this.$hasPermission({module: 'user', action: 'get'}),
        appkey: this.$hasPermission({module: 'appkey', action: 'get'}),
        employeemov: sp.usePositions && this.$hasPermission({module: 'employeemov', action: 'get'}),
        auditlog: this.$hasPermission({module: 'appkey', action: 'get'}) && this.$store.state.userInfo.compMan.id !== Daycoval,
        lockerRules: this.$hasPermission({module: 'appkey', action: 'get'}),
        parametrizadorE02: this.$hasPermission({ module: 'appkey', action: 'get' }) && this.$store.state.userInfo.email.includes('lar21@'),
        auditInteg: this.$hasPermission({module: 'appkey', action: 'get'}) && this.$store.state.userInfo.email.includes('lar21@')
      }
    }

    const routsWithDynamicParams = {
      folhas: {name: 'Folhas de ponto', link: this.buildFolhaUrl(), show: p.folhas.self},
      gestaoAtividades: {
        name: 'Gestão de atividades',
        link: `/activity-manager/work-place/${today}/${today}/${this.$store.state.userInfo.firstCompany}`,
        show: p.gestao.activityline
      }
    }

    const mostrarMenuBancoDeHoras = () => {
      const ocultarMenuBancoDeHoras = this.$store.state.userInfo.compMan.frontOptions?.ocultarMenuBancoDeHoras
      const ehFuncionario = this.$store.state.userInfo.roleGroup.kind === 'mine'
      const ehGestor = this.$store.state.userInfo.roleGroup.kind === 'group'

      if (ehGestor && ocultarMenuBancoDeHoras) {
        return false
      }
      if (ehFuncionario) {
        return this.$store.state.userInfo.compMan.frontOptions.mostrarMenuBancoDeHorasParaFuncionario
      }
      return true
    }

    return {
      onlySheetView: false,
      groups: [
        {
          category: 'Cadastros',
          show: p.cadastros.self,
          icon: 'pessoas',
          values: [
            { adapted: 'employee', link: '/employee/list', show: p.cadastros.compemployee },
            { adapted: 'company', link: '/company/list', show: p.cadastros.company },
            { adapted: 'employer', link: '/employer/list', show: p.cadastros.employer },
            { name: 'Grupos', link: '/user-group', show: p.cadastros.usergroup },
            { adapted: 'journey', link: '/workjourney', show: p.cadastros.dayjourney },
            { adapted: 'routine', link: '/routine/list', show: p.cadastros.routine },
            { name: 'Feriados', link: '/holidays', show: p.cadastros.holiday },
            { name: 'Motivos padrão', link: '/reason/list', show: p.cadastros.reason },
            { name: 'Tipo de Atividade', link: '/activity-type', show: p.cadastros.activitytype },
            { name: 'Centro de custo', link: '/activity-cost-center', show: p.cadastros.activitycostcenter },
            { name: 'Atividade', link: '/activity', show: p.cadastros.activityobject }
            // { adapted: 'supervisor', link: '/supervisor/list', show: p.cadastros.supervisor }
          ]
        },
        {
          category: 'folha',
          show: p.folhas.self,
          icon: 'folha',
          values: [
            routsWithDynamicParams.folhas,
            { name: 'Verificar folhas', link: '/verify-timesheet', show: p.folhas.verify },
            { name: 'fechamento de folha', link: '/fechar-folha-em-conjunto', show: p.folhas.locker },
            { name: 'Alterações rápidas', link: '/fast', show: p.folhas.autocorrect }
          ]
        },
        {
          category: 'planejamento',
          show: p.planejamento.self,
          icon: 'calendario',
          values: [
            { name: 'Painel de acompanhamento', link: '/extra-time-dashboard', show: p.planejamento.timemeasurements },
            { name: 'Escalas de trabalho', link: '/work-schedule', show: p.planejamento.dayjourney },
            { name: 'Acompanhamento de entrada', link: '/monitor-entrance/60', show: p.planejamento.realtime },
            { name: 'Acompanhamento por posto', link: '/monitor-workplace/180//', show: p.planejamento.realtime },
            { name: 'Painel de Pontos', link: '/painel-pontos', show: sp.isLar21 },
            { name: 'Painel de acompanhamento em tempo real', link: '/acompanhamento-tempo-real', show: p.planejamento.acompanhamentoTempoReal }
          ]
        },
        {
          category: 'gestão',
          show: p.gestao.self,
          icon: 'grafico',
          values: [
            { name: 'Relatórios e registros', link: '/fechamentos', show: p.gestao.exportfiles },
            { name: 'banco de horas', link: '/gerenciamento-banco', show: (p.gestao.workhourbankobjects && mostrarMenuBancoDeHoras()) },
            routsWithDynamicParams.gestaoAtividades,
            { name: sp.novoModuloDeAprovacoes ? 'solicitações' : 'aprovação', link: '/approvals', show: p.gestao.approvals },
            { name: 'dispositivos', link: '/deviceManage/list', show: p.gestao.appkey },
            { name: 'coletores', link: '/coletores/listar', show: p.gestao.coletores },
            { name: 'reps', link: '/rep', show: p.gestao.rep },
            { name: 'biometria facial', link: '/face-biometric', show: p.gestao.biometric },
            { name: 'notificação', link: '/regras-de-notificacao', show: p.gestao.notificationrules },
            { name: 'gerenciamento de arquivos', link: '/gerenciamento-de-arquivos/list', show: p.gestao.aprovacaoarquivo },
            { name: 'férias', link: 'gerenciamento-de-ferias', show: p.gestao.gerenciamentoDeFerias }
          ]
        },
        {
          category: 'Admin',
          icon: 'config',
          show: p.admin.self,
          values: [
            { name: 'Usuários', link: '/user', show: p.admin.user },
            { name: 'Permissões', link: '/permissions/list', show: p.admin.role },
            { name: 'Dados da empresa', link: '/dados', show: p.admin.appkey },
            { name: 'Customizações de Cálculo', link: '/customizacoes-de-calculo/listar', show: p.admin.customrules },
            { name: 'Regras de banco de horas', link: '/timeoff/list', show: p.admin.workhourbankrules },
            { name: 'Regras de aprovação', link: '/approvals-rules/list', show: p.admin.approvalsrules },
            { name: 'Regras de disciplinação', link: '/discipline-rules/list', show: p.admin.disciplinerules },
            { name: 'Regras de travamento', link: '/regras-de-travamento/list', show: p.admin.lockerRules },
            { name: 'Log de operações', link: '/log', show: p.admin.auditlog },
            { name: 'Parametrizador E02', link: 'parametrizador/listar', show: p.admin.parametrizadorE02 },
            { name: 'Auditorias de integrações', link: '/auditor', show: p.admin.auditInteg },
            { name: 'Configuração de Integrações', link: '/setup', show: p.admin.auditInteg }
          ]
        }
      ]
    }
  },
  methods: {
    ...mapActions([
      'setAuthorization',
      'setUserInfo'
    ]),
    sair () {
      this.$store.dispatch('logout')
    },
    buildFolhaUrl () {
      if (this.$store.getters.getUserInfo.roles[0].filter === 'mine') {
        const emp = this.$store.state.userInfo.compemployee
        const { closingDate } = emp
        let startDateMine
        let endDateMine

        if (closingDate) {
          if (closingDate === 1) {
            startDateMine = moment().startOf('month').format('YYYY-MM-DD')
            endDateMine = moment().endOf('month').format('YYYY-MM-DD')
          } else {
            startDateMine = moment().date(closingDate).format('YYYY-MM-DD')
            endDateMine = moment().date(closingDate - 1).add(1, 'month').format('YYYY-MM-DD')
          }
        } else {
          startDateMine = moment().startOf('month').format('YYYY-MM-DD')
          endDateMine = moment().endOf('month').format('YYYY-MM-DD')
        }

        return `/timesheet/employee/${startDateMine}/${endDateMine}/${emp.id}`
      }
      const { firstCompany } = this.$store.state.userInfo
      const today = moment().format('YYYY-MM-DD')

      return `/timesheet/work-place/${today}/${today}/${firstCompany}`
    }
  }
}
</script>

<style scoped lang="scss">
.main-header {
  height: 50px;
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.5fr;
  grid-template-rows: 100%;
  background-color: var(--front-color-header);
}

@media screen and (min-width: 1920px){
  .main-header {
    grid-template-columns: 0.5fr 0.6fr 0.5fr;
  }
}

.main-header > * {
  color: var(--front-color-font-header);
}

.main-header__links {
  display: grid;
  margin: 0;
  height: 100%;
  align-items: center;
  font-size: 14px;
  justify-self: start;
  grid-template-columns: repeat(7, 2fr);
  width: 100%;
  padding-left: 0;
  justify-items: center;
}
.main-header__logo {
  height: 100%;
  display: grid;
}
.main-header__logo > img {
  max-height: 50px;
  align-self: center;
  justify-self: right;
  width: 88px;
}
.category-icon {
  position: relative;
  left: 5px;
  font-size: 16px;
}
.arrow-icon {
  /*height: 10px;*/
  position: relative;
  left: 9px;
}
.main-header__button--category {
  color: var(--front-color-font-header);
  text-overflow: ellipsis;
  text-shadow: none;
  white-space: nowrap;
  font-weight: 300;
  font-size: 1.1em;
  display: flex;
  justify-self: center;
  margin: 0 1rem;
  align-items: center;
}

.category-dropdown {
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  &.open {
    border-bottom: 2px solid var(--color-warning);
    & > a {
      color: var(--color-warning);
    }
  }
}
.category-dropdown:hover > a {
  font-weight: bold;
}

.info-do-usuario {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  & > *:not(:first-child) {
    margin-top: 1rem;
  }
  .legenda {
    font-weight: normal;
    font-size: small
  }
  .conteudo {
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__permissaoDoSistema .conteudo {
    text-transform: uppercase
  }
}

/*@media screen and (max-width: 1281px) {*/
/*.main-header {*/
/*grid-template-columns: 0.5fr 1fr 0.5fr;*/
/*}*/
/*}*/

/*@media screen and (max-width: 1025px) {*/
/*.main-header {*/
/*grid-template-columns: 0.6fr 1fr 0.6fr;*/
/*}*/
/*}*/

</style>
