import moment from 'moment'
import {mapValues} from 'lodash'

import ApontamentoDiario from 'src/components/Sheets/Dominio/ApontamentoDiario'
import {Rubrica} from 'src/components/CustomizacoesDeCalculo/Dominio/Agregados/Rubricas'
import {NomeRubrica} from 'src/components/CustomizacoesDeCalculo/Dominio/ObjetosDeValor/Rubricas'

class InfoDeApontamentos {
  constructor ({apontamentos, bancoDeHoras, toBeRecalculated, observacao, empregado, data}) {
    const formatarApontamentoDiario = apontamento => {
      return new ApontamentoDiario({
        rubrica: new Rubrica({
          nome: new NomeRubrica({
            completo: apontamento['nome_completo'],
            abreviado: apontamento['nome_abreviado']
          })
        }),
        valor: apontamento['valor'],
        dataDeVencimento: apontamento['data_de_vencimento']
      })
    }

    this.apontamentos = apontamentos.map(formatarApontamentoDiario)
    this.bancoDeHoras = bancoDeHoras.map(formatarApontamentoDiario)
    this.toBeRecalculated = toBeRecalculated
    this.observacao = observacao
    this.empregado = empregado
    this.data = data
  }
}

class ResultadoApontamentoDiarioApi {
  constructor (infoApontamentosIndexadoPorJornadaEsperada) {
    this.apontamentos = mapValues(infoApontamentosIndexadoPorJornadaEsperada, infoApontamentos => {
      return new InfoDeApontamentos({
        apontamentos: infoApontamentos['apontamentos'],
        bancoDeHoras: infoApontamentos['banco_de_horas'],
        toBeRecalculated: infoApontamentos['to_be_recalculated'],
        observacao: infoApontamentos['observacao'],
        empregado: infoApontamentos['empregado'],
        data: infoApontamentos['data']
      })
    })
  }
}

export class ApontamentoDiarioRepoConsulta {
  constructor ({api}) {
    this.api = api
  }
  async consultarApontamentosDiariosDeEmpregadoPorPeriodo ({empregados, linhas, dataInicio, dataFim}) {
    const { body, status } = await this.api.save(
      {
        inicio: moment(dataInicio).format('YYYY-MM-DD'),
        fim: moment(dataFim).format('YYYY-MM-DD')
      },
      {
        empregados: empregados,
        'jornadas_realizadas': linhas
      }
    )

    if (status === 204) {
      return null
    }

    return new ResultadoApontamentoDiarioApi(body['apontamentos_diarios'])
  }
}

class InfoApontamentoVerificarFolha {
  constructor ({campo, nome, rubricaId}) {
    this.campo = campo
    this.nome = nome
    this.rubricaId = rubricaId
  }
}

class ResultadoApontamentoApi {
  constructor (infoApontamentos) {
    this.apontamentos = infoApontamentos.map(infoApontamento => new InfoApontamentoVerificarFolha({
      campo: infoApontamento['campo'],
      nome: infoApontamento['nome'],
      rubricaId: infoApontamento['rubrica_id']
    }))
  }
  obterApontamentosParaSeletorDeVerificarFolhas () {
    return this.apontamentos.map(apontamento => {
      return {
        field: apontamento.rubricaId || apontamento.campo,
        text: apontamento.nome,
        ehRubrica: Boolean(apontamento.rubricaId)
      }
    })
  }
  obterApontamentosParaSeletorDePainelDeAcompanhamento () {
    return this.apontamentos.map(apontamento => {
      return {
        id: apontamento.rubricaId || apontamento.campo,
        name: apontamento.nome,
        ehRubrica: Boolean(apontamento.rubricaId)
      }
    })
  }
  obterApontamentosIndexadoPeloCodigo () {
    const apontamentoEhStacked = apontamento => {
      return ['allTimes', 'generalExtraTime', 'generalDelay'].includes(apontamento)
    }

    return this.apontamentos.reduce((resultado, apontamento) => {
      return {
        ...resultado,
        [apontamento.campo]: {
          name: apontamento.nome,
          stacked: apontamentoEhStacked(apontamento.campo)
        }
      }
    }, {})
  }
  obterCorParaApontamentosIndexadoPeloCodigo () {
    return this.apontamentos.reduce((resultado, apontamento) => {
      return {
        ...resultado,
        [apontamento.campo]: `#${Math.floor(Math.random() * 16777215).toString(16)}`
      }
    }, {})
  }
}

export class ApontamentoRepoConsulta {
  constructor ({api}) {
    this.api = api
  }
  async consultarTodosApontamentosDoCliente ({tipo = 'horaria'}) {
    const { body } = await this.api.get({tipo: tipo})

    return new ResultadoApontamentoApi(body['apontamentos'])
  }
}
