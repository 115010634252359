import moment from 'moment'
import {
  obterValorLimiteHorasTrabalhadas,
  obterApontamentosSelecionadosLimiteHorasTrabalhadas,
  obterJornadaBaseLimiteHorasTrabalhadas,
  OPCOES_DE_APONTAMENTOS
} from './limiteDeAcumuloPorHorasTrabalhadas'

function converteStringParaDate (string) {
  if (!string) return null
  return moment(string).toDate()
}

function formataTipoDoCicloDeAutoCompensacao (tipoDoCicloDeAutoCompensacao) {
  const opcoes = [{id: 'month', name: 'mensal'}, {id: 'week', name: 'semanal'}, {id: 'days', name: 'diária'}]
  return {
    loaded: opcoes,
    selected: opcoes.find(v => v.id === tipoDoCicloDeAutoCompensacao)
  }
}

function formataCamposDesconsideradosGatilhoMensal (camposDesconsideradosGatilhoMensal) {
  if (!camposDesconsideradosGatilhoMensal) return []

  const campos = [
    {id: 'extra_time_50_tot', name: 'H. Extra'},
    {id: 'extra_time_100_tot', name: 'H.E. Extraord'},
    {id: 'extra_time_special_tot', name: 'H.E. Especial'},
    {id: 'delay_time', name: 'Atraso'},
    {id: 'early_exit', name: 'Saída Antecipada'},
    {id: 'lunch_penalty', name: 'Atraso de Pausa'},
    {id: 'absence_hours', name: 'H. Faltas'}
  ]

  return camposDesconsideradosGatilhoMensal.map(id => campos.find(campo => id === campo.id))
}

function converteValorMinimo (valor, indice) {
  const indicesIgnorados = [3, 8, 9]
  if (indicesIgnorados.includes(indice)) return -1
  if (valor === -1) return 0
  return valor
}

function converteValorMaximo (valor) {
  if (valor === -1) return null
  return valor
}

function formataModalidadeDoBanco (autoCompensate) {
  const opcoes = [
    {id: 'geral', name: 'compensação geral'},
    {id: 'janela', name: 'compensação por janela'}
  ]
  const REPRESENTACAO_NO_SELECT_PARA_ESTADO = {
    'true': {id: 'janela', name: 'compensação por janela'},
    'false': {id: 'geral', name: 'compensação geral'}
  }

  return {
    selected: REPRESENTACAO_NO_SELECT_PARA_ESTADO[autoCompensate],
    loaded: opcoes
  }
}

function formataTipoDeContagemDeTempo (rollingWorkHoursBank) {
  const opcoes = [
    {id: 'fixo', name: 'a partir da data de início do banco ou da última baixa (fixo)'},
    {id: 'movel', name: 'a partir do primeiro evento de banco do empregado (móvel)'}
  ]
  return {
    loaded: opcoes,
    selected: rollingWorkHoursBank
      ? {id: 'movel', name: 'a partir do primeiro evento de banco do empregado (móvel)'}
      : {id: 'fixo', name: 'a partir da data de início do banco ou da última baixa (fixo)'}
  }
}

function temLimite (valor) {
  return valor !== -1
}

function temLimiteAcimaDeZero (valor) {
  return temLimite(valor) && valor !== 0
}

function checaSeUsaLimiteDiario (
  dailyLimitPerHourKind,
  limiteDiarioInferiorPorTipoDeHora,
  apontLimiteDeAcumuloHorasTrabDiaria
) {
  return dailyLimitPerHourKind.some(temLimite) ||
    limiteDiarioInferiorPorTipoDeHora.some(temLimiteAcimaDeZero) ||
    apontLimiteDeAcumuloHorasTrabDiaria?.length > 0
}

export function converteObjetoDoBackParaVuex (regraDeBancoDeHoras) {
  const {
    id,
    // Cabeçalho
    name,
    code,
    dateOfBancoStart,
    numberOfDaysToCompensation,
    rollingWorkHoursBank,
    autoCompensate,
    windowType,
    windowExpirationCycle,
    permitirDescontoEmSaldosVencidos,
    // FatorDeTransformacaoDeHoras
    compensationPerHourKind,
    // RazaoDeAcumuloECompensacao
    splitPerKindOfHour,
    // LimitesDiarios
    dailyLimitPerHourKind,
    limiteDiarioInferiorPorTipoDeHora,
    apontLimiteDeAcumuloHorasTrabDiaria,
    valorLimiteDeAcumuloHorasTrabDiaria,
    jornadaBaseLimiteDeAcumuloHorasTrabDiaria,
    // LimitesMensais
    monthlyLimitPerKindOfHora,
    limiteMensalInferiorPorTipoDeHora,
    // LimitesGerais
    generalLimitPerKindOfHora,
    limiteGeralInferiorPorTipoDeHora,
    // Aprovação automática
    isApproved,
    separarCompensacaoEAcumulo,
    // OutrasConfiguracoes
    autoAccTm,
    autoCompStm,
    negativeBalance,
    partialAcc,
    considerarIsTmsAsCompensation,
    compensateOnLock,
    completarVisualizacaoDoAcumuladoComJanelasFalsas,
    manterSaldoMensal,
    mudaNomenclaturaParaCompensacaoNaFolha,
    // ConfiguracoesAvancadas
    configBaixaAutomatica
  } = regraDeBancoDeHoras

  return {
    id: id,
    // Cabeçalho
    nome: name,
    codigo: code,
    dataInicioDoBanco: converteStringParaDate(dateOfBancoStart),
    limiteDeAcumuloDoBanco: numberOfDaysToCompensation,
    tipoDeContagemDeTempo: formataTipoDeContagemDeTempo(rollingWorkHoursBank),
    modalidadeDoBanco: formataModalidadeDoBanco(autoCompensate),
    tipoDoCicloDeAutoCompensacao: formataTipoDoCicloDeAutoCompensacao(windowType),
    quantosCiclosParaVencimento: windowExpirationCycle,
    permitirDescontoEmSaldosVencidos: permitirDescontoEmSaldosVencidos,
    // FatorDeTransformacaoDeHoras
    fatorDeTransformacaoDeHoras: compensationPerHourKind,
    // RazaoDeAcumuloECompensacao
    usaRazaoDeAcumuloECompensacao: splitPerKindOfHour.some(value => value !== 0),
    razaoDeAcumuloECompensacao: splitPerKindOfHour.map(x => (1 - x) * 100),
    // LimitesDiarios
    usaLimiteDiario: checaSeUsaLimiteDiario(
      dailyLimitPerHourKind,
      limiteDiarioInferiorPorTipoDeHora,
      apontLimiteDeAcumuloHorasTrabDiaria
    ),
    limiteDeAcumuloECompensacaoDiario: dailyLimitPerHourKind.map(converteValorMaximo),
    limiteBaseDeAcumuloECompensacaoDiario: limiteDiarioInferiorPorTipoDeHora.map(converteValorMinimo),
    limiteDeAcumuloPorHorasTrabalhadas: {
      seletor: {
        loaded: OPCOES_DE_APONTAMENTOS,
        selected: obterApontamentosSelecionadosLimiteHorasTrabalhadas(apontLimiteDeAcumuloHorasTrabDiaria)
      },
      valor: obterValorLimiteHorasTrabalhadas(valorLimiteDeAcumuloHorasTrabDiaria),
      jornadaBase: obterJornadaBaseLimiteHorasTrabalhadas(jornadaBaseLimiteDeAcumuloHorasTrabDiaria)
    },
    // LimitesMensais
    usaLimiteMensal: monthlyLimitPerKindOfHora.some(temLimite) || limiteMensalInferiorPorTipoDeHora.some(
      temLimiteAcimaDeZero
    ),
    limiteDeAcumuloECompensacaoMensal: monthlyLimitPerKindOfHora.map(converteValorMaximo),
    limiteBaseDeAcumuloECompensacaoMensal: limiteMensalInferiorPorTipoDeHora.map(converteValorMinimo),
    // LimitesGerais
    usaLimiteGeral: generalLimitPerKindOfHora.some(temLimite),
    limiteDeAcumuloECompensacaoGeral: generalLimitPerKindOfHora.map(converteValorMaximo),
    limiteBaseDeAcumuloECompensacaoGeral: limiteGeralInferiorPorTipoDeHora.map(converteValorMinimo),
    // Aprovação automática
    aprovacaoAutomatica: isApproved,
    separarCompensacaoEAcumulo: separarCompensacaoEAcumulo,
    // OutrasConfiguracoes
    acumularHorasAutomaticamente: autoAccTm,
    compensarHorasAutomaticamente: autoCompStm,
    permitirBalancoNegativo: negativeBalance,
    considerarAcumuloParcialDeHoras: partialAcc,
    considerarBaixaComTmsComoCompensacao: considerarIsTmsAsCompensation,
    compensateOnLock: compensateOnLock,
    completarVisualizacaoDoAcumuladoComJanelasFalsas: completarVisualizacaoDoAcumuladoComJanelasFalsas,
    mostrarHistoricoDosSaldosNoAcumuladoDeBancoDeHoras: manterSaldoMensal,
    mudaNomenclaturaParaCompensacaoNaFolha: mudaNomenclaturaParaCompensacaoNaFolha,
    // ConfiguracoesAvancadas
    usarGatilhoDeSaldoGeral: Boolean(configBaixaAutomatica['gatilho_limite_geral']),
    gatilhoLimiteGeral: configBaixaAutomatica['gatilho_limite_geral'] !== undefined
      ? configBaixaAutomatica['gatilho_limite_geral']
      : 100,
    usarGatilhoDeSaldoMensal: Boolean(configBaixaAutomatica['gatilho_base_mensal']),
    gatilhoBaseMensal: configBaixaAutomatica['gatilho_base_mensal'] !== undefined
      ? configBaixaAutomatica['gatilho_base_mensal']
      : 50,
    camposDesconsideradosDoGatilhoMensal: formataCamposDesconsideradosGatilhoMensal(
      configBaixaAutomatica['apontamentos_desconsiderados_gatilho_mensal']
    )
  }
}
