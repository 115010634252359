import { API, retryRequest } from 'src/services'
// import { API } from 'src/services'
import { congelarObjetosDaLista } from 'src/utils/formatadoras/formatadoras'

export default {
  namespaced: true,
  state: {
    items: [],
    loading: false
  },
  getters: {
    onlyActive (state) {
      return state.items.filter(i => i.active)
    },
    notActive (state) {
      return state.items.filter(i => !i.active)
    }
  },
  mutations: {
    saveItems (state, payload) {
      state.items = payload
      state.loading = false
    },
    loading (state, payload) {
      state.loading = Boolean(payload)
    }
  },
  actions: {
    async fetchItems ({ commit }) {
      commit('loading', true)
      const result = await retryRequest(API.activity.get, {
        fields: JSON.stringify(['id', 'name', 'activity_cost_center', 'activity_type', 'duration', 'active']),
        force: true})
      // const result = await API.activity.get({page: 1, force: true})
      commit('saveItems', congelarObjetosDaLista(result.body.success))
    }
  }
}
