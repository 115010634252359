
const removerAvisoNavegacaoDuplicada = (erro) => {
  if (erro.name !== 'NavigationDuplicated') {
    throw erro
  }
}

/**
 * Configura o prototype push da instância de um router para evitar a exibição de erros de navegação duplicada.
 *
 * @param {Object} Router - O objeto do router a ser configurado.
 * @returns {void}
 *
 * @example
 * configurarPrototypePushParaNaoMostrarErroNavegacaoDuplicada(Router);
 */

export const configurarPrototypePushParaNaoMostrarErroNavegacaoDuplicada = (router) => {
  const metodoOriginalPush = router.prototype.push

  /**
   * Substitui o método `push` original do router para tratar erros de navegação duplicada.
   *
   * @param {Object} location - O objeto de localização a ser navegado.
   * @param {Function} [onResolve] - A função a ser executada quando a navegação for resolvida com sucesso.
   * @param {Function} [onReject] - A função a ser executada quando ocorrer um erro na navegação.
   * @returns {Promise} - Uma promise que resolve quando a navegação for concluída ou rejeitada quando ocorrer um erro.
   *
   */

  router.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) return metodoOriginalPush.call(this, location, onResolve, onReject)
    return metodoOriginalPush.call(this, location).catch(erro => removerAvisoNavegacaoDuplicada(erro))
  }
}

/**
 * Configura o prototype replace da instância de um router para evitar a exibição de erros de navegação duplicada.
 *
 * @param {Object} Router - O objeto do router a ser configurado.
 * @returns {void}
 *
 * @example
 * configurarPrototypeReplaceParaNaoMostrarErroNavegacaoDuplicada(Router);
 */

export const configurarPrototypeReplaceParaNaoMostrarErroNavegacaoDuplicada = (router) => {
  const metodoOriginaReplace = router.prototype.replace

  /**
   * Substitui o método `replace` original do router para tratar erros de navegação duplicada.
   *
   * @param {Object} location - O objeto de localização a ser navegado.
   * @param {Function} [onResolve] - A função a ser executada quando a navegação for resolvida com sucesso.
   * @param {Function} [onReject] - A função a ser executada quando ocorrer um erro na navegação.
   * @returns {Promise} - Uma promise que resolve quando a navegação for concluída ou rejeitada quando ocorrer um erro.
   *
   */

  router.prototype.replace = function replace (location, onResolve, onReject) {
    if (onResolve || onReject) return metodoOriginaReplace.call(this, location, onResolve, onReject)
    return metodoOriginaReplace.call(this, location).catch(erro => removerAvisoNavegacaoDuplicada(erro))
  }
}
