let baseUrl
const repUrl = `${process.env.VUE_APP_REP_URL}`
let gerenciadorDeColetoresUrl
let reppUrl
let integracaoUrl
let segredoIntegracao
let auditorUrl
let integradorSankhyaUrl
let keycloakBaseUrl = `${process.env.VUE_APP_KEYCLOAK_BASE_URL}`

if (process.env.VUE_APP_K8s) {
  let namespace = window.location.pathname
  namespace = namespace[namespace.length - 1] === '/' ? namespace.slice(0, namespace.length - 1) : namespace
  namespace = namespace.split('/new-front')[0]
  baseUrl = `${window.location.origin}${namespace}` + '/back'
  gerenciadorDeColetoresUrl = `${window.location.origin}${namespace}` + '/gerenciador-de-coletores'
  reppUrl = `${window.location.origin}${namespace}` + '/rep-p'
  integracaoUrl = `${process.env.VUE_APP_INTEGRACAO_URL}`
  segredoIntegracao = `${process.env.VUE_APP_SEGREDO_INTEGRACAO}`
  auditorUrl = `${window.location.origin}${namespace}` + '/auditor-log'
  integradorSankhyaUrl = `${window.location.origin}${namespace}` + '/integrador-sankhya'
} else {
  baseUrl = `${process.env.VUE_APP_API_URL}`
  integracaoUrl = `${process.env.VUE_APP_INTEGRACAO_URL}`
  segredoIntegracao = `${process.env.VUE_APP_SEGREDO_INTEGRACAO}`
  gerenciadorDeColetoresUrl = `${process.env.VUE_APP_GERENCIADOR_DE_COLETOR_URL}`
  reppUrl = `${process.env.VUE_APP_REPP_URL}`
  auditorUrl = `${process.env.VUE_APP_AUDITOR_URL}`
  integradorSankhyaUrl = `${process.env.VUE_APP_INTEGRADOR_SANKHYA_URL}`
}

const config = {
  baseUrl,
  integracaoUrl,
  auditorUrl,
  integradorSankhyaUrl,
  segredoIntegracao,
  repUrl,
  gerenciadorDeColetoresUrl,
  reppUrl,
  version: '5.0.0',
  keycloakBaseUrl
}

export default config
