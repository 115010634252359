<template>
  <span
    :style="{ margin, height, width: computedWidth }"
    class="skeleton-box"
  />
</template>

<script>
export default {
  name: 'SkeletonBox',
  props: {
    maxWidth: {type: Number, default: 100},
    minWidth: {type: Number, default: 80},
    height: {type: String, default: '1em'},
    width: {type: String, default: null},
    margin: {type: String, default: '0'}
  },
  computed: {
    computedWidth () {
      // Either use the given fixed width or
      // a random width between the given min
      // and max values.
      return this.width || `${Math.floor((Math.random() * (this.maxWidth - this.minWidth)) + this.minWidth)}%`
    }
  }
}
</script>

<style lang="scss" scoped>
.skeleton-box {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  background-color: #DDDBDD;
  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 5s infinite;
    content: '';
  }
  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
