import { construirUrlDeRedirecionamentoKeycloak, construirUrlDeRenovacaoToken, obterParametrosAutenticacaoKeycloak, possuiParametrosAutenticacaoKeycloak } from './utils'
import { ApiKeycloakAutenticacaoCorpo } from './keycloak.interface'
import { FiltradorDeErros } from 'src/erros'
import { API } from 'src/services'
import store from 'src/store'

export const keycloakSSOLogin = async (): Promise<void> => {
  try {
    const { realm, code, sessionState } = obterParametrosAutenticacaoKeycloak()

    if (!possuiParametrosAutenticacaoKeycloak()) {
      throw new Error('Parâmetros do Keycloak não encontrados na URL.')
    }

    store.commit('cognitoTokens', {
      authorization: null,
      accessToken: null,
      refreshToken: null,
      userPoolId: null,
      userPoolWebClientId: null
    })

    const urlDeRedirecionamento = construirUrlDeRedirecionamentoKeycloak(realm as string)

    const resposta = await API.obterTokenDoKeycloakAPartirDaSessao(realm, code, sessionState, urlDeRedirecionamento)

    const { access_token: accessToken, refresh_token: refreshToken } = resposta.data as ApiKeycloakAutenticacaoCorpo

    store.commit('cognitoTokens', {
      authorization: accessToken ? 'Bearer ' + accessToken : null,
      accessToken: null,
      refreshToken: refreshToken,
      definirDataCriacaoToken: true
    })
    store.commit('setAPIRenovacaoTokenKeycloak', construirUrlDeRenovacaoToken(realm as string))
  } catch (erro) {
    FiltradorDeErros.capturarErro(erro as Error)
  }
}
