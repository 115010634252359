import store from '../store/index'
import { hasPermission } from '../utils'

export default {
  bind (el, binding) {
    validarPermissoes(el, binding)
  },
  update (el, binding) {
    validarPermissoes(el, binding)
  },
  componentUpdated (el, binding) {
    validarPermissoes(el, binding)
  }
}

function validarPermissoes (el, binding) {
  if (!store.getters.getUserInfo) {
    return
  }

  const userRoles = store.getters.getUserInfo.roles
  const userRolesNames = userRoles.map(r => r.name)

  if (userRolesNames.includes('admin_master')) {
    return true
  }

  const isValid = hasPermission(binding.value, userRoles)

  if (!isValid) {
    if (binding.value.hide === true) {
      el.style.display = 'none'
    } else {
      desabilitarTodosComponentes(el)
    }
  } else {
    el.style.display = ''
    habilitarTodosComponentes(el)
  }
}

export const desabilitarTodosComponentes = (element) => {
  disableElements(element.getElementsByTagName('input'))
  disableElements(element.getElementsByTagName('button'))
  disableElements(element.getElementsByTagName('textarea'))
  disableElements(element.getElementsByTagName('select'))
  element.addEventListener('click', preventDefault, true)

  if (element.disabled === false) {
    element.disabled = true
    element.disabledIf = true
  }
}

const habilitarTodosComponentes = (element) => {
  enableElements(element.getElementsByTagName('input'))
  enableElements(element.getElementsByTagName('button'))
  enableElements(element.getElementsByTagName('textarea'))
  enableElements(element.getElementsByTagName('select'))
  element.removeEventListener('click', preventDefault, true)

  if (element.disabledIf) {
    element.disabled = false
    element.disabledIf = false
  }
}

const preventDefault = (event) => {
  event.stopPropagation()
  event.preventDefault()
  return false
}

const disableElements = (elements) => {
  const len = elements.length
  for (let i = 0; i < len; i++) {
    if (elements[i].attributes.ignoreHasPermission) {
      continue
    }
    if (elements[i].disabled === false) {
      elements[i].disabled = true
      elements[i].disabledIf = true
    }
  }
}

const enableElements = (elements) => {
  const len = elements.length
  for (let i = 0; i < len; i++) {
    if (elements[i].attributes.ignoreHasPermission) {
      continue
    }
    if (elements[i].disabledIf) {
      elements[i].disabled = false
      elements[i].disabledIf = false
    }
  }
}
