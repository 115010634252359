<template lang="pug">
  label.p-checkbox.p-checkbox__label(v-on="localListeners")
    input.p-checkbox__input(
      type="checkbox",
      v-model="state"
      v-bind="localAttributes",
      :disabled="disabled || loading || readonly",
      :class="classInput"
      @keyup.enter="state = !state"
      readonly
    )
    .p-checkbox__indicator
    .icone(v-if="$slots.icone")
      slot(name="icone")
    span(:class="classLabel") {{ label }}
      .icon-info.icon-ptt-icons-info.color-info(
        v-if="infoIcon"
        :title="infoIconTitle"
        :style="`right: ${infoIconPosition}`"
      )
</template>

<script>
import { handlerUseStateVmodel } from '../mixins/handlerUseStateVmodel'

export default {
  name: 'PCheckbox',
  mixins: [handlerUseStateVmodel()],
  data () {
    return {
      localListeners: undefined,
      localAttributes: undefined
    }
  },
  props: {
    label: { type: String, required: true },
    infoIcon: { type: Boolean, required: false, default: false },
    infoIconTitle: { type: String, required: false, default: '' },
    infoIconPosition: { type: String, require: false, default: '-30px' },
    disabled: Boolean,
    loading: Boolean,
    readonly: Boolean
  },
  created () {
    this.localListeners = Object.keys(this.$listeners)
      .filter(key => key !== 'input')
      // eslint-disable-next-line no-sequences
      .reduce((res, key) => (res[key] = this.$listeners[key], res), {}) // eslint-disable-line no-return-assign

    this.localAttributes = Object.keys(this.$attrs)
      .filter(key => key !== 'type' && key !== 'disabled')
      // eslint-disable-next-line no-sequences
      .reduce((res, key) => (res[key] = this.$attrs[key], res), {}) // eslint-disable-line no-return-assign
  },
  computed: {
    classLabel () {
      return {
        'p-checkbox__label--loading': this.loading,
        'p-checkbox__label--disabled': this.disabled,
        'p-checkbox__label--readonly': this.readonly
      }
    },
    classInput () {
      return {
        'p-checkbox__input--loading': this.loading,
        'p-checkbox__input--disabled': this.disabled
      }
    }
  }
}
</script>

<style scoped>

.p-checkbox {
  margin-bottom: 5px;
  padding-top: 2px;
  font-size: 14px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: grid;
  align-items: center;
  grid-template-columns: 22px auto 1fr;
  grid-template-rows: auto;
  align-items: center;
  grid-template-areas:
    "indicator icone label";
}

.p-checkbox span {
  grid-area: label;
  position: relative;
}
.icon-info.icon-ptt-icons-info.color-info {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}
.p-checkbox .p-checkbox__input {
  position: fixed;
  z-index: -1;
  opacity: 0;
}

.p-checkbox:hover .p-checkbox__input:not([disabled]) ~ .p-checkbox__indicator {
  background: #fff;
  opacity: .8;
}
.p-checkbox:hover .p-checkbox__input:not([disabled]) ~ span,
.p-checkbox__input:focus ~ span  {
  color: var(--color-primary);
  font-weight: bold;
}

.p-checkbox:hover .p-checkbox__input ~ .p-checkbox__indicator,
.p-checkbox .p-checkbox__input:focus ~ .p-checkbox__indicator {
  background: #fff;
}

.p-checkbox .p-checkbox__input:checked ~ .p-checkbox__indicator {
  background: #fff;
}

.p-checkbox:hover .p-checkbox__input:not([disabled]):checked ~ .p-checkbox__indicator,
.p-checkbox .p-checkbox__input:checked:focus ~ .p-checkbox__indicator {
  background: #fff;
}

.p-checkbox .p-checkbox__input:disabled ~ .p-checkbox__indicator {
  background: #fff;
  opacity: .4;
  pointer-events: none;
}

.p-checkbox__label .p-checkbox__input:disabled ~ .p-checkbox__indicator:after {
  border-color: var(--color-default-disabled);
}

.p-checkbox__label .p-checkbox__input:disabled ~ .p-checkbox__indicator {
  border-color: var(--color-default-disabled);
}

.p-checkbox__label--disabled {
  cursor: auto;
  color: #6a6a6a;
}

.p-checkbox__label--readonly {
  cursor: auto;
  color: var(--color-default);
}

.p-checkbox__label--loading {
  color: transparent;
  background-color: var(--color-default-disabled);
}

.p-checkbox__indicator {
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 11px;
  height: 11px;
  display: flex;
  position: relative;
  cursor: pointer;
  margin-right: 5px;
}
.p-checkbox__indicator:after, .p-checkbox__indicator:before  {
  position: absolute;
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  left: 1px;
  top: 5px;
  -webkit-transform: rotate(225deg) rotateY(180deg);
          transform: rotate(225deg) rotateY(180deg);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}
.p-checkbox__label .p-checkbox__indicator:before {
  width: 5px;
  box-shadow: inset -2px 0 0 0 var(--color-primary);
  transition: height .15s ease;
}
.p-checkbox__label .p-checkbox__indicator:after {
  height: 8px;
  box-shadow: inset 0 2px 0 0 var(--color-primary);
  transition: width .15s ease .15s;
}
.p-checkbox .p-checkbox__input:checked + .p-checkbox__indicator:before {
  height: 17px;
  transition: height .15s ease .15s;
}
.p-checkbox .p-checkbox__input:checked + .p-checkbox__indicator:after {
  width: 5px;
  transition: width .15s ease;
}

</style>
