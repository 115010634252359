import { IRegraDeNotificacaoRepositorio } from './regraNotificacaoRepositorio.interface'
import { IRegraDeNotificacaoServico } from './regraNotificacaoServico.interface'
import { IRegraDeNotificacao, IResposeRegraDeNotificacao, UsuarioNotificado } from './regraNotificacao.interface'
import { RegraDeNotificacao } from './regraNotificacao'

export class RegraDeNotificacaoServico implements IRegraDeNotificacaoServico {
    regraDeNotificacaoRepositorio: IRegraDeNotificacaoRepositorio
    constructor (regraDeNotificacaoRepositorio: IRegraDeNotificacaoRepositorio) {
      this.regraDeNotificacaoRepositorio = regraDeNotificacaoRepositorio
    }
    async obterRegrasDeNotificacoes (parametrosDeBusca: unknown): Promise<IResposeRegraDeNotificacao> {
      return this.regraDeNotificacaoRepositorio.obterRegrasDeNotificacoes(parametrosDeBusca)
    }
    async removerRegraDeNotificacao (idRegraNotificacao: Pick<IRegraDeNotificacao, 'id'>): Promise<unknown> {
      return this.regraDeNotificacaoRepositorio.removerRegraDeNotificacao(idRegraNotificacao)
    }
    async obterRegraDeNotificacao (idRegraNotificacao: Pick<IRegraDeNotificacao, 'id'>): Promise<IResposeRegraDeNotificacao> {
      return this.regraDeNotificacaoRepositorio.obterRegraDeNotificacao(idRegraNotificacao)
    }
    async criarRegraDeNotificacao (regrasDeNotificacoes: IRegraDeNotificacao[]): Promise<unknown> {
      return this.regraDeNotificacaoRepositorio.criarRegraDeNotificacao(regrasDeNotificacoes)
    }
    async atualizarRegraDeNotificacao (idRegraNotificacao: Pick<IRegraDeNotificacao, 'id'>, camposAlterados: Partial<IRegraDeNotificacao>): Promise<unknown> {
      return this.regraDeNotificacaoRepositorio.atualizarRegraDeNotificacao(idRegraNotificacao, camposAlterados)
    }
    formatarRegrasParaCriacao (regrasDeNotificacao: IRegraDeNotificacao): IRegraDeNotificacao[] {
      const tipoNoticado = regrasDeNotificacao.tipoNotificado
      const regraParaEnvio = new RegraDeNotificacao(regrasDeNotificacao)
      if (tipoNoticado === 'perfil') return [regraParaEnvio]
      const usuariosNotificados = regrasDeNotificacao.usuariosNotificados
      if (usuariosNotificados.length === 1) {
        return [ {
          ...regrasDeNotificacao,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          usuariosNotificados: [usuariosNotificados[0].id as any]
        }]
      }

      const regrasDeNotificacaoComNomesFormatados = usuariosNotificados.map((usuario: UsuarioNotificado) => {
        const nome = `${regrasDeNotificacao.nome} ${usuario.nome}`
        const regraParaEnvio = new RegraDeNotificacao({
          ...regrasDeNotificacao,
          nome,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          usuariosNotificados: [usuario.id] as any
        })
        return regraParaEnvio
      })
      return regrasDeNotificacaoComNomesFormatados
    }
    obterCamposAlteradosNaRegraDeNotificacao (regraDeNoticacaoAlterada: IRegraDeNotificacao, copiaRegraOriginal: IRegraDeNotificacao): Partial<IRegraDeNotificacao> {
        type tiposDosCamposDaRegra = string | string[] | Date | unknown | boolean
        const valoresAlterados: Record<string, tiposDosCamposDaRegra> = {}

        Object.keys(regraDeNoticacaoAlterada).forEach((chave: string) => {
          const copiaDaRegraOriginal: Record<string, tiposDosCamposDaRegra> = copiaRegraOriginal as unknown as Record<string, tiposDosCamposDaRegra>
          const regraAlterada: Record<string, tiposDosCamposDaRegra> = regraDeNoticacaoAlterada as unknown as Record<string, tiposDosCamposDaRegra>

          if (JSON.stringify(regraAlterada[chave]) !== JSON.stringify(copiaDaRegraOriginal[chave])) {
            valoresAlterados[chave] = regraAlterada[chave]
          }
        })
        return valoresAlterados
    }
}
