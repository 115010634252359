import moment from 'moment'
import { omit } from 'lodash'

import { obterHorasMinutosParaDecimal, obterHorasEMinutosAPartirDeUmFloat } from 'src/utils/formatadoras/formatadoras'

export function formatarEscritaRegraCustomizada (estado) {
  function converteMinutosEmHoras (minutos) {
    return obterHorasMinutosParaDecimal(0, minutos)
  }

  function formatarLimitesDeHorasExtras (horaExtra) {
    return {
      boundary: parseFloat(horaExtra.boundary),
      overtime_name: horaExtra.overtime_name
    }
  }

  function formatarLimitesDeHorasExtrasNoturnas (horaExtra) {
    return {
      boundary: parseFloat(horaExtra.boundary),
      overtime_name: horaExtra.overtime_name
    }
  }

  function formatarLimitesDeHorasExtrasMensais (horaExtra) {
    return {
      boundary: parseFloat(horaExtra.boundary),
      overtime_name: horaExtra.overtime_name
    }
  }
  function formatarLimitesDeHorasExtrasMensaisNoturnas (horaExtra) {
    return {
      boundary: parseFloat(horaExtra.boundary),
      overtime_name: horaExtra.overtime_name
    }
  }

  function formatPausePairs (pausePairs) {
    return {
      dur_hr: parseFloat(pausePairs.dur_hr),
      exp_min: converteMinutosEmHoras(pausePairs.exp_min)
    }
  }

  function formatNightTimeDuration (nightTimeDuration) {
    const minutes = nightTimeDuration.minutes
    const seconds = nightTimeDuration.seconds
    return ((minutes / 60) + ((seconds / 60) / 60))
  }

  function formataConfigNonaHora (estado) {
    const horarioInicio = moment(estado.horarioInicioDaNonaHora)
    const horarioFim = moment(estado.horarioFimDaNonaHora)
    const duracao = {
      minutos: estado.duracaoDaNonaHora.minutes,
      segundos: estado.duracaoDaNonaHora.seconds
    }

    return {
      'horario_inicio': obterHorasMinutosParaDecimal(horarioInicio.hour(), horarioInicio.minutes()),
      'horario_fim': obterHorasMinutosParaDecimal(horarioFim.hour(), horarioFim.minutes()),
      'duracao': ((duracao.minutos / 60) + ((duracao.segundos / 60) / 60)),
      'tolerancia': converteMinutosEmHoras(estado.toleranciaDaNonaHora)
    }
  }

  function formataConsiderandoCalculoPassivo (valor, calculoPassivo) {
    if (calculoPassivo) {
      return 'total_length'
    }

    return valor
  }

  function formatarDuracaoMinimaDePausaParaDuplaPegada (estado) {
    if (!estado.configuracaoDePausaMinimaEstaHabilitada) {
      return 0.0
    }
    return estado.duracaoDePausaMinimaParaRealizarCalculoDeDuplaPegada.float
  }

  function converterDataFormatadaEmDecimal (dataFormatada) {
    const data = moment(dataFormatada)
    return obterHorasMinutosParaDecimal(data.hour(), data.minutes())
  }

  const params = {
    ...estado,
    periodoHoraExtraLimites: estado.periodoHoraExtraLimites.map(formatarLimitesDeHorasExtrasMensais),
    periodoHoraExtraLimitesNoturna: estado.periodoHoraExtraLimitesNoturna.map(formatarLimitesDeHorasExtrasMensaisNoturnas),
    delayType: formataConsiderandoCalculoPassivo(estado.delayType, estado.calculoPassivo),
    extraHourType: formataConsiderandoCalculoPassivo(estado.extraHourType, estado.calculoPassivo),
    delayTolerance: converteMinutosEmHoras(estado.delayTolerance),
    extraTimeTolerance: converteMinutosEmHoras(estado.extraTimeTolerance),
    arriveTolerance: converteMinutosEmHoras(estado.arriveTolerance),
    earlyArriveTolerance: converteMinutosEmHoras(estado.earlyArriveTolerance),
    earlyExitTolerance: converteMinutosEmHoras(estado.earlyExitTolerance),
    exitTolerance: converteMinutosEmHoras(estado.exitTolerance),
    payKindOverride: estado.payKindOverride.selected.id,
    overtimeBounds: estado.overtimeBounds.map(formatarLimitesDeHorasExtras),
    limiteHoraExtraNoturna: estado.limiteHoraExtraNoturna.map(formatarLimitesDeHorasExtrasNoturnas),
    pausePairs: estado.pausePairs.map(formatPausePairs),
    lunchTolerance: converteMinutosEmHoras(estado.lunchTolerance),
    excessLunchTol: converteMinutosEmHoras(estado.excessLunchTol),
    shiftPauseTol: converteMinutosEmHoras(estado.shiftPauseTol),
    nightTimeStart: estado.nightTimeStart,
    nightTimeEnd: estado.nightTimeEnd,
    nightTimeDuration: formatNightTimeDuration(estado.nightTimeDuration),
    nightTolerance: converteMinutosEmHoras(estado.nightTolerance),
    configuracoesCalculoNonaHora: formataConfigNonaHora(estado),
    interjourneyDuration: estado.interjourneyDuration.float,
    defaultDsr: estado.defaultDsr.float,
    limiteSuperiorDaJanelaDeAtraso: converteMinutosEmHoras(estado.limiteSuperiorDaJanelaDeAtraso),
    limiteInferiorDaJanelaDeAtraso: converteMinutosEmHoras(estado.limiteInferiorDaJanelaDeAtraso),
    toleranciaAtrasoDePausaDescanso: converteMinutosEmHoras(estado.toleranciaAtrasoDePausaDescanso),
    toleranciaHoraExtraDePausaDescanso: converteMinutosEmHoras(estado.toleranciaHoraExtraDePausaDescanso),
    duracaoDePausaMinimaParaRealizarCalculoDeDuplaPegada: formatarDuracaoMinimaDePausaParaDuplaPegada(estado),
    duracaoEsperadaDaJornadaComDuplaPegada: estado.duracaoEsperadaDaJornadaComDuplaPegada.float,
    grupoDeRubricasId: estado.grupoDeRubricas.selected?.id,
    tempoCorrecaoAutomaticaPontoEntrada: Number(estado.tempoCorrecaoAutomaticaPontoEntrada),
    tempoCorrecaoAutomaticaPontoPausa: Number(estado.tempoCorrecaoAutomaticaPontoPausa),
    tempoCorrecaoAutomaticaPontoRetorno: Number(estado.tempoCorrecaoAutomaticaPontoRetorno),
    tempoCorrecaoAutomaticaPontoSaida: Number(estado.tempoCorrecaoAutomaticaPontoSaida),
    inicioDoHorarioDePlantaoHmv: converterDataFormatadaEmDecimal(estado.inicioDoHorarioDePlantaoHmv),
    fimDoHorarioDePlantaoHmv: converterDataFormatadaEmDecimal(estado.fimDoHorarioDePlantaoHmv),
    separarHoraExtraNoturnaDoAdicionalNoturnoEmFeriados: estado.separarHoraExtraNoturnaDoAdicionalNoturnoEmFeriados,
    utilizarDuracaoRealDaJornadaNoCalculoDeAtraso: estado.utilizarDuracaoRealDaJornadaNoCalculoDeAtraso,
    onCallIgnoreWorkedOverlap: estado.onCallIgnoreWorkedOverlap,
    aplicarReducaoNoturnaEmHorasDeFalta: estado.aplicarReducaoNoturnaEmHorasDeFalta,
    calcularInterjornadaSemConsiderarFolgaComReferenciaNoUltimoDiaDeTrabalho: estado.calcularInterjornadaSemConsiderarFolgaComReferenciaNoUltimoDiaDeTrabalho
  }

  const parametrosNonaHora = [
    'horarioInicioDaNonaHora', 'horarioFimDaNonaHora',
    'duracaoDaNonaHora', 'toleranciaDaNonaHora'
  ]
  const parametrosParaOmitir = [
    'loading',
    'showExtraHourAndDelayTypeTogether',
    ...parametrosNonaHora,
    'grupoDeRubricas', // Mantém apenas o grupoDeRubricasId
    'configuracaoDePausaMinimaEstaHabilitada'
  ]

  if (estado.id) {
    parametrosParaOmitir.push('id')
  }

  return omit(params, parametrosParaOmitir)
}

export function formatarLeituraRegraCustomizada (regraCustomizada, { gruposDeRubricas }) {
  // returns a object that we can use in Object.assign to update all estado once

  const estadoInicial = obterEstadoPadrao()

  function formatHourMinsFloat (float, minutes = null) {
    return { hours: null, minutes: minutes, float: float }
  }

  function converteHorasEmMinutos (horas) {
    const { hours, minutes } = obterHorasEMinutosAPartirDeUmFloat(horas)
    return minutes + (hours * 60)
  }

  function formatToSelect (selectedId, options) {
    return {
      selected: options.find(option => option.id === selectedId),
      loaded: options
    }
  }

  function formatarLimitesDeHorasExtras (horasExtras) {
    return {
      boundary: parseFloat(horasExtras.boundary),
      overtime_name: horasExtras.overtime_name
    }
  }

  function formatarLimitesDeHorasExtrasMensais (horaExtra) {
    return {
      boundary: parseFloat(horaExtra.boundary),
      overtime_name: horaExtra.overtime_name
    }
  }

  function formatPausePairs (pausePairs) {
    return {
      dur_hr: pausePairs.dur_hr,
      exp_min: converteHorasEmMinutos(pausePairs.exp_min)
    }
  }

  function formatNightTimeDuration (nightTimeDuration) {
    const minutos = nightTimeDuration * 60
    const segundos = Math.round((minutos % Math.floor(minutos)) * 60)

    return {
      minutes: Math.floor(minutos),
      seconds: Math.floor(segundos)
    }
  }

  function extraHourAndDelayTypeTogetherIsEqual (extraHourType, delayType, calculoPassivo) {
    if (calculoPassivo) {
      return true
    }

    if (extraHourType === 'total_length' && delayType === 'total_length') {
      return true
    } else if (extraHourType === 'entry_exit_extra_time_interval' && delayType === 'atraso_early_exit') {
      return true
    } else if (
      extraHourType === 'jornada_flexivel_com_janela_de_entrada' &&
      delayType === 'jornada_flexivel_com_janela_de_entrada'
    ) {
      return true
    }
    return false
  }

  function obterHorarioNonaHora (horario, valorPadrao) {
    if (!horario) return valorPadrao
    return moment().hours(horario).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss')
  }

  function obterDuracaoNonaHora (regraDeCalculo) {
    if (!regraDeCalculo?.configuracoesCalculoNonaHora?.duracao) {
      return estadoInicial.duracaoDaNonaHora
    }

    const minutos = regraDeCalculo.configuracoesCalculoNonaHora['duracao'] * 60
    const segundos = Math.round((minutos % Math.floor(minutos)) * 60)

    return {
      minutes: Math.floor(minutos),
      seconds: Math.floor(segundos)
    }
  }

  const payKindOverrideOptions = [
    { id: '', name: 'Não' },
    { id: 'normal', name: 'Desconsiderar Feriados e Folgas' },
    { id: 'extra', name: 'Hora Extra' },
    { id: '100', name: 'Extraordinária' },
    { id: 'special', name: 'Especial' }
  ]

  function formataConsiderandoCalculoPassivo (valor, calculoPassivo) {
    if (calculoPassivo) {
      return 'passivo'
    }

    return valor
  }

  function formataGrupoDeRubricas (idGrupoDeRubrica) {
    if (!idGrupoDeRubrica) {
      return { selected: null, loaded: gruposDeRubricas }
    }

    return {
      selected: gruposDeRubricas.find(grupoDeRubrica => grupoDeRubrica.id === idGrupoDeRubrica),
      loaded: gruposDeRubricas
    }
  }

  return {
    ...regraCustomizada,
    delayType: formataConsiderandoCalculoPassivo(regraCustomizada.delayType, regraCustomizada.calculoPassivo),
    extraHourType: formataConsiderandoCalculoPassivo(regraCustomizada.extraHourType, regraCustomizada.calculoPassivo),
    delayTolerance: converteHorasEmMinutos(regraCustomizada.delayTolerance),
    extraTimeTolerance: converteHorasEmMinutos(regraCustomizada.extraTimeTolerance),
    arriveTolerance: converteHorasEmMinutos(regraCustomizada.arriveTolerance),
    earlyArriveTolerance: converteHorasEmMinutos(regraCustomizada.earlyArriveTolerance),
    earlyExitTolerance: converteHorasEmMinutos(regraCustomizada.earlyExitTolerance),
    exitTolerance: converteHorasEmMinutos(regraCustomizada.exitTolerance),
    payKindOverride: formatToSelect(regraCustomizada.payKindOverride, payKindOverrideOptions),
    overtimeBounds: regraCustomizada.overtimeBounds.map(formatarLimitesDeHorasExtras),
    limiteHoraExtraNoturna: regraCustomizada?.limiteHoraExtraNoturna?.map(formatarLimitesDeHorasExtras) || estadoInicial.limiteHoraExtraNoturna,
    pausePairs: regraCustomizada.pausePairs.map(formatPausePairs),
    lunchTolerance: converteHorasEmMinutos(regraCustomizada.lunchTolerance),
    excessLunchTol: converteHorasEmMinutos(regraCustomizada.excessLunchTol),
    shiftPauseTol: converteHorasEmMinutos(regraCustomizada.shiftPauseTol),
    nightTimeStart: regraCustomizada.nightTimeStart,
    nightTimeEnd: regraCustomizada.nightTimeEnd,
    nightTimeDuration: formatNightTimeDuration(regraCustomizada.nightTimeDuration),
    nightTolerance: converteHorasEmMinutos(regraCustomizada.nightTolerance),
    horarioInicioDaNonaHora: obterHorarioNonaHora(
      regraCustomizada?.configuracoesCalculoNonaHora?.inicio,
      estadoInicial.horarioInicioDaNonaHora
    ),
    horarioFimDaNonaHora: obterHorarioNonaHora(
      regraCustomizada?.configuracoesCalculoNonaHora?.fim,
      estadoInicial.horarioFimDaNonaHora
    ),
    duracaoDaNonaHora: obterDuracaoNonaHora(regraCustomizada),
    toleranciaDaNonaHora: regraCustomizada.configuracoesCalculoNonaHora
      ? converteHorasEmMinutos(regraCustomizada?.configuracoesCalculoNonaHora?.tolerancia)
      : estadoInicial.toleranciaDaNonaHora,
    interjourneyDuration: formatHourMinsFloat(regraCustomizada.interjourneyDuration),
    defaultDsr: formatHourMinsFloat(regraCustomizada.defaultDsr),
    showExtraHourAndDelayTypeTogether: extraHourAndDelayTypeTogetherIsEqual(
      regraCustomizada.extraHourType, regraCustomizada.delayType, regraCustomizada.calculoPassivo
    ),
    limiteSuperiorDaJanelaDeAtraso: converteHorasEmMinutos(regraCustomizada.limiteSuperiorDaJanelaDeAtraso),
    limiteInferiorDaJanelaDeAtraso: converteHorasEmMinutos(regraCustomizada.limiteInferiorDaJanelaDeAtraso),
    toleranciaAtrasoDePausaDescanso: converteHorasEmMinutos(regraCustomizada.toleranciaAtrasoDePausaDescanso),
    toleranciaHoraExtraDePausaDescanso: converteHorasEmMinutos(regraCustomizada.toleranciaHoraExtraDePausaDescanso),
    grupoDeRubricas: formataGrupoDeRubricas(regraCustomizada.grupoDeRubricasId, regraCustomizada.calculoPassivo),
    duracaoDePausaMinimaParaRealizarCalculoDeDuplaPegada: formatHourMinsFloat(regraCustomizada.duracaoDePausaMinimaParaRealizarCalculoDeDuplaPegada),
    duracaoEsperadaDaJornadaComDuplaPegada: formatHourMinsFloat(regraCustomizada.duracaoEsperadaDaJornadaComDuplaPegada),
    configuracaoDePausaMinimaEstaHabilitada: Boolean(regraCustomizada.duracaoDePausaMinimaParaRealizarCalculoDeDuplaPegada),
    periodoHoraExtraLimites: regraCustomizada.periodoHoraExtraLimites.map(formatarLimitesDeHorasExtrasMensais),
    periodoHoraExtraLimitesNoturna: regraCustomizada?.periodoHoraExtraLimitesNoturna?.map(formatarLimitesDeHorasExtrasMensais) || estadoInicial.periodoHoraExtraLimitesNoturna,
    tempoCorrecaoAutomaticaPontoEntrada: regraCustomizada.tempoCorrecaoAutomaticaPontoEntrada || estadoInicial.tempoCorrecaoAutomaticaPontoEntrada,
    tempoCorrecaoAutomaticaPontoPausa: regraCustomizada.tempoCorrecaoAutomaticaPontoPausa || estadoInicial.tempoCorrecaoAutomaticaPontoPausa,
    tempoCorrecaoAutomaticaPontoRetorno: regraCustomizada.tempoCorrecaoAutomaticaPontoRetorno || estadoInicial.tempoCorrecaoAutomaticaPontoRetorno,
    tempoCorrecaoAutomaticaPontoSaida: regraCustomizada.tempoCorrecaoAutomaticaPontoSaida || estadoInicial.tempoCorrecaoAutomaticaPontoSaida,
    inicioDoHorarioDePlantaoHmv: moment().hours(regraCustomizada.inicioDoHorarioDePlantaoHmv).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss'),
    fimDoHorarioDePlantaoHmv: moment().hours(regraCustomizada.fimDoHorarioDePlantaoHmv).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss'),
    separarHoraExtraNoturnaDoAdicionalNoturnoEmFeriados: Boolean(regraCustomizada.separarHoraExtraNoturnaDoAdicionalNoturnoEmFeriados),
    utilizarDuracaoRealDaJornadaNoCalculoDeAtraso: Boolean(regraCustomizada.utilizarDuracaoRealDaJornadaNoCalculoDeAtraso),
    onCallIgnoreWorkedOverlap: Boolean(regraCustomizada.onCallIgnoreWorkedOverlap),
    aplicarReducaoNoturnaEmHorasDeFalta: Boolean(regraCustomizada.aplicarReducaoNoturnaEmHorasDeFalta),
    calcularInterjornadaSemConsiderarFolgaComReferenciaNoUltimoDiaDeTrabalho: Boolean(regraCustomizada.calcularInterjornadaSemConsiderarFolgaComReferenciaNoUltimoDiaDeTrabalho)
  }
}

export function obterEstadoPadrao () {
  return {
    id: null,
    loading: false,
    showExtraHourAndDelayTypeTogether: true,
    name: '',
    code: '',
    dayCases: [],
    // FolhaEspelhoFields
    addSumtimemeasToTimelogsheet: false,
    abbreviateObsInTimelogsheet: true,
    mostrarLocaisDeTrabalhoDosPontos: false,
    mostrarImagemDoEmpregado: false,
    ignoreZeroValueSumtimes: false,
    allowIntermediaryPts: false,
    showRoutine: true,
    showRoutineName: true,
    showPause: true,
    showTot: false,
    showEmployeeRoutine: false,
    signedDate: true,
    assinaturaNormal: true,
    travamentoVirtual: false,
    vistoSuperior: true,
    vistoRh: true,
    hashTravamentoNaFolha: false,
    restVsHolidayWorked: false,
    showApont: false,
    splitWorkedDays: false,
    tempoCorrecaoAutomaticaPontoEntrada: 0,
    tempoCorrecaoAutomaticaPontoPausa: 0,
    tempoCorrecaoAutomaticaPontoRetorno: 0,
    tempoCorrecaoAutomaticaPontoSaida: 0,
    // Apontamentos
    titles: {},
    folhaShows: {},
    pdfShows: {},
    disableShows: {},
    // Calculos - Categorizar horas extras
    calculoPassivo: false,
    grupoDeRubricas: { selected: null, loaded: [] },
    delayType: 'total_length',
    extraHourType: 'total_length',
    separateEntryExitTol: false,
    delayTolerance: 5,
    extraTimeTolerance: 5,
    arriveTolerance: 5,
    earlyArriveTolerance: 5,
    earlyExitTolerance: 5,
    exitTolerance: 5,
    payKindOverride: {
      selected: { id: '', name: 'não' },
      loaded: [
        { id: '', name: 'não' },
        { id: 'normal', name: 'desc. feriados/folgas' },
        { id: 'extra', name: 'hora extra' },
        { id: '100', name: 'extraordinária' },
        { id: 'special', name: 'especial' }
      ]
    },
    filterOvertime: false,
    categorizarHorasExtrasNoturnas: false,
    overtimeBounds: [
      { boundary: 2, overtime_name: 'overtime_a' },
      { boundary: 4, overtime_name: 'overtime_b' },
      { boundary: 99, overtime_name: 'overtime_c' }
    ],
    limiteHoraExtraNoturna: [
      { boundary: 2, overtime_name: 'overtime_a' },
      { boundary: 4, overtime_name: 'overtime_b' },
      { boundary: 99, overtime_name: 'overtime_c' }
    ],
    filterFromBottom: false,
    limiteSuperiorDaJanelaDeAtraso: 30,
    limiteInferiorDaJanelaDeAtraso: 30,
    // Limites Horas Extras Mensal
    categorizarHorasExtrasPorPeriodo: false,
    categorizarHorasExtrasNoturnasPorPeriodo: false,
    periodoHoraExtraLimites: [
      { boundary: 32, overtime_name: 'overtime_a' },
      { boundary: 50, overtime_name: 'overtime_b' },
      { boundary: 999, overtime_name: 'overtime_c' }
    ],
    periodoHoraExtraLimitesNoturna: [
      { boundary: 32, overtime_name: 'overtime_a' },
      { boundary: 50, overtime_name: 'overtime_b' },
      { boundary: 999, overtime_name: 'overtime_c' }
    ],
    // Calculos - Pausas intrajornadas (refeições)
    pausePairs: [
      { dur_hr: 6, exp_min: 60 },
      { dur_hr: 4, exp_min: 15 }
    ],
    lunchTolerance: 5,
    excessLunchTol: 5,
    shiftPauseTol: 5,
    usePause100: true,
    breakTimeDouble: false,
    breakTimeDoubleAlsoNonduration: false,
    dblBrkOnly50: true,
    ignorePauseWeekends: false,
    payThroughPauseWeekends: false,
    payPauseMissing: false,
    useJourneyLunch: false,
    useJourneyLunchPause: false,
    useJourneyLunchPenalty: false,
    earlyExitMinusLunch: false,
    delayMinusLunch: false,
    // Calculos - Horário noturno
    nightTimeStart: moment().hours(22).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss'),
    nightTimeEnd: moment().hours(5).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss'),
    nightTimeDuration: {
      minutes: 52,
      seconds: 30
    },
    nightTolerance: 5,
    nightTimeThroughPause: true,
    extendedNightshift: true,
    extensaoDeHorarioNoturnoAoFimDoIntervaloTrabalhado: false,
    separaApontamentoProrrogacaoAdicionalNoturno: false,
    reduceNightLunch: false,
    extendedNightshiftReduce: true,
    nightReductionAllExtras: true,
    separateHnr: false,
    separateNightExtras: false,
    reduceOnlyNightAdded: false,
    aplicarCalculoDeNonaHora: false,
    horarioInicioDaNonaHora: moment().hours(22).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss'),
    horarioFimDaNonaHora: moment().hours(5).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss'),
    duracaoDaNonaHora: {
      minutes: 52,
      seconds: 30
    },
    toleranciaDaNonaHora: 5,
    separarHoraExtraNoturnaDoAdicionalNoturnoEmFeriados: false,
    utilizarDuracaoRealDaJornadaNoCalculoDeAtraso: false,
    // Calculos - Outros
    interjourneyDuration: { hours: 11, minutes: 0, float: 11 },
    onCallIgnoreWorkedOverlap: false,
    partialIsAtraso: false,
    forcarPagamentoNormalEmDispensas: true,
    cutHolidays: false,
    cutHolidayLglDur: false,
    extendFolga: true,
    dsrDateOnFalta: false,
    dsrDoubleHoliday: false,
    calcDsr: true,
    interjornExcludesFolgas: false,
    allowInterjourneyOnFolgas: false,
    dispensaCombine: false,
    ignoreHolidayTypes: true,
    combHolfolgaHol: false,
    combine100Special: false,
    combineNormalAnd100: false,
    combExtrasOvertime: true,
    mandatoryOverride: false,
    delayWithLglDur: false,
    procPtForward: false,
    ignoreHolidaysGeneral: false,
    allowFaltaOnFeriado: false,
    trustKind: false,
    defaultDsr: { hours: 7, minutes: 20, float: 7.333333333333333333 },
    suspensionCombine: false,
    multiplePauses: false,
    pauseAddToInterjourney: false,
    onCallTlThrowbackwards: false,
    pagarPausaComoTrabalhadoDiasUteis: false,
    pausaAutomaticaPelaJornadaExecutada: false,
    allowMultipleEntrance: false,
    considerarMultiplasEntradasComoPausa: false,
    naoConsiderarInterjornadaEmDiasComSobreaviso: true,
    ignorarToleranciaHoraExtraNoSobreaviso: false,
    calcularInterjornadaSemConsiderarFolgaComReferenciaNoUltimoDiaDeTrabalho: false,
    // Calculos - Pausas descanso
    toleranciaAtrasoDePausaDescanso: 5,
    toleranciaHoraExtraDePausaDescanso: 5,
    realizarPausaDescansoSegundoNr17: false,
    gerarAtrasoDePausaDescanso: false,
    gerarHoraExtraDePausaDescanso: false,
    gerarHoraExtraNaoRealizacaoPausaDescanso: false,
    // Calculos - Dupla Pegada
    habilitarVerificaoDeOcorrenciaDeDuplaPegada: false,
    duracaoDePausaMinimaParaRealizarCalculoDeDuplaPegada: { hours: 2, minutes: 0, float: 2 },
    duracaoEsperadaDaJornadaComDuplaPegada: { hours: 6, minutes: 0, float: 6 },
    configuracaoDePausaMinimaEstaHabilitada: true,
    // Calculos - Plantão HMV
    habilitarApontamentosPlantaoHmv: false,
    inicioDoHorarioDePlantaoHmv: moment().hours(20).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss'),
    fimDoHorarioDePlantaoHmv: moment().hours(6).minutes(0).seconds(0).format('YYYY-MM-DDTHH:mm:ss'),
    // Calculos - Horas Normais
    habilitarCalculoDeHorasNormais: false,
    // Não sei onde usa
    nullifyNightlyHour1236: false,
    hasEarlyExit: false,
    strictPunch: false,
    showDsr: false,
    aplicarReducaoNoturnaEmHorasDeFalta: false
  }
}
