export default function fetchItems () {
  return {
    props: {
      // the component will load data automaticly if this function is given
      // it must return a list. Return the $API promise will not work
      loadDataFunction: { type: Function, required: false }
    },
    async created () {
      if (this.loadDataFunction) {
        this.state.loading = true
        const items = await this.loadDataFunction()
        this.state = {...this.state, loading: false, loaded: items || []}
      }
    }
  }
}
