import { API, retryRequest } from 'src/services'
import { congelarObjetosDaLista } from 'src/utils/formatadoras/formatadoras'

const defaultOnly = [
  'name', 'id', 'kind', 'description'
]

export default {
  namespaced: true,
  state: {
    items: [],
    loading: false
  },
  getters: {
  },
  mutations: {
    saveItems (state, payload) {
      state.items = payload
      state.loading = false
    },
    resetItems (state) {
      state.items = []
    },
    loading (state, payload) {
      state.loading = Boolean(payload)
    }
  },
  actions: {
    async fetchItems ({ commit }, payload) {
      payload = payload || {}

      commit('loading', true)

      const { withFields = [], fullReload } = payload

      if (fullReload) {
        commit('resetItems')
      }

      const only = Array.from(new Set([...defaultOnly, ...withFields]))

      const result = await retryRequest(API.roleGroup.get, {
        fields: JSON.stringify(only),
        order_by: 'name'
      })
      commit('saveItems', congelarObjetosDaLista(result.body.success))
    }
  }
}
