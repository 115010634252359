import { API, retryRequest } from 'src/services'
import { congelarObjetosDaLista } from 'src/utils/formatadoras/formatadoras'

const PER_PAGE = 30

export default {
  namespaced: true,
  state: {
    items: [],
    loading: false
  },
  getters: {
    todosGrupos (state) {
      return state.items
    }
  },
  mutations: {
    saveItems (state, payload) {
      state.items = payload
      state.loading = false
    },
    resetItems (state) {
      state.items = []
    },
    loading (state, payload) {
      state.loading = Boolean(payload)
    }
  },
  actions: {
    async fetchItems ({ commit }, payload) {
      payload = payload || {}

      commit('loading', true)

      const { fullReload } = payload

      if (fullReload) {
        commit('resetItems')
      }

      const result = await retryRequest(
        API.userGroup.get,
        {order_by: 'name'}
      )

      commit('saveItems', congelarObjetosDaLista(result.body.success))
    },
    async fetchItemsComPaginacao ({ state, commit }, payload = {}) {
      commit('loading', true)

      const { fullReload, append = false } = payload
      const {page} = state

      if (fullReload) {
        commit('resetItems')
      }

      const paginaParaRequisicao = append ? (page + 1) : 0

      const result = await retryRequest(API.userGroup.get, {
        order_by: 'name',
        page: paginaParaRequisicao,
        per_page: PER_PAGE
      })

      if (result.status === 204) {
        result.body = {
          success: [],
          total: 0,
          totalPages: 0
        }
      }

      const {totalPages, success} = result.body

      commit('setTotalPages', totalPages)
      commit('setPage', paginaParaRequisicao)

      if (append) {
        commit('appendItems', congelarObjetosDaLista(success))
      } else {
        commit('saveItems', congelarObjetosDaLista(success))
      }
    }
  }
}
