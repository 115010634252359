<template lang="pug">
  .p-time-count-field
    .campos(v-if="decimal")
      PTextField.input-decimal(
        :label="label",
        type="text",
        v-model="float",
        :loading="loading",
        :disabled="disabled",
        v-on="localListeners",
        v-bind="localAttributes",
        :error="error",
        placeholder="00.00"
        v-mask="['#.##', '##.##', '###.##']"
      )

    .campos(v-else)
      PTextField.input-horas(
        v-if="!justMinutes"
        :label="`${notShowLabel ? '' : label}`",
        type="text",
        v-model="hours",
        v-mask="['#', '##', '###']",
        :loading="loading",
        :disabled="disabled",
        v-on="localListeners",
        v-bind="localAttributes"
        :error="error"
      )

      PTextField.input-minutos(
        :label="`${notShowLabel ? '' : labelMinutos}`",
        type="text",
        v-model="minutes",
        v-mask="withoutLimitMinutes ? ['#', '##', '###'] : mascaraDoInput",
        :loading="loading",
        :disabled="disabled",
        v-on="localListeners",
        v-bind="localAttributes"
        :error="error"
      )
</template>

<script>
import { mask } from 'vue-the-mask'

import { obterHorasMinutosParaDecimal, obterHorasEMinutosAPartirDeUmFloat } from 'src/utils/formatadoras/formatadoras'
import { handlerUseStateVmodel } from 'src/common/mixins/handlerUseStateVmodel'
import { localListenersAndAttributes } from 'src/common/mixins/localListenersAndAttributes'

function valoresIniciais (estadoInicial) {
  if (!estadoInicial) {
    return {
      hours: null,
      minutes: null,
      float: null
    }
  }

  if (estadoInicial.float) {
    return obterHorasEMinutosAPartirDeUmFloat(estadoInicial.float)
  } else if (estadoInicial.hours && estadoInicial.minutes) {
    return {
      hours: estadoInicial.hours,
      minutes: estadoInicial.minutes,
      float: obterHorasMinutosParaDecimal(estadoInicial.hours, estadoInicial.minutes)
    }
  }
}

export default {
  name: 'PTimeCountField',
  props: {
    loading: {type: Boolean},
    disabled: {type: Boolean},
    decimal: {type: Boolean},
    error: {type: String},
    label: {type: String, default: 'horas'},
    labelMinutos: {type: String, default: 'minutos'},
    justMinutes: {type: Boolean, default: false},
    notShowLabel: {type: Boolean, default: false},
    withoutLimitMinutes: {type: Boolean, default: false}
  },
  created () {
    if (this.state.float) {
      this.float = this.state.float
    }
  },
  data () {
    return {
      mascaraDoInput: {
        mask: 'm#',
        tokens: {
          '#': { pattern: /\d/ },
          'm': { pattern: /[0-5]/ }
        }
      },
      ...valoresIniciais(this.value)
    }
  },
  computed: {
    time: {
      get () {
        return {
          hours: this.hours,
          minutes: this.minutes,
          float: this.float
        }
      }
    }
  },
  mounted () {
    if (this.state.float !== undefined && this.state.float !== null) {
      this.float = this.state.float
      this.updateValueWithFloat(this.float)
    }
  },
  methods: {
    updateValueWithFloat (float) {
      let newState
      if (this.justMinutes) {
        newState = {
          hours: 0,
          minutes: Math.floor(float * 60),
          float: float
        }
      } else {
        newState = obterHorasEMinutosAPartirDeUmFloat(float)
      }
      this.float = float.toFixed(2)
      this.hours = newState.hours
      this.minutes = newState.minutes
    }
  },
  watch: {
    time: function (newTime) {
      if (this.decimal) {
        this.state = obterHorasEMinutosAPartirDeUmFloat(parseFloat(newTime.float))
      } else {
        this.state = {
          hours: parseInt(newTime.hours),
          minutes: parseInt(newTime.minutes),
          float: obterHorasMinutosParaDecimal(newTime.hours, newTime.minutes)
        }
      }
    }
  },
  mixins: [handlerUseStateVmodel(), localListenersAndAttributes()],
  directives: { mask }
}
</script>

<style lang="scss" scoped>
.p-time-count-field {
  .campos {
    display: flex;
    align-items: center;

    // TODO: consertar esse valores fixos de input
    .input-horas {
      width: 70px;
      margin-right: 10px;
    }

    .input-minutos {
      width: 70px;
    }

    .input-decimal {
      width: 70px;
    }
  }
}
</style>
