import store from '../store/index'

export default {
  // When the bound element is inserted into the DOM...
  inserted: function (el, binding) {
    // dont apply this directive if the element starts disabled
    if (el.disabled) { return }

    store.watch(() => store.getters[binding.value], (value) => {
      if (value === true) {
        el.disabled = 'true'
        if (getLoadingChild(el)) {
          return
        }
        el.innerHTML = el.innerHTML + '<span class="cssloader-remove" style="display:inline-block; vertical-align: middle; margin-left: 5px;"><div class="cssload-container"><div class="cssload-speeding-wheel"></div></div></span>'
      } else {
        el.disabled = ''
        if (getLoadingChild(el)) {
          getLoadingChild(el).remove()
        }
      }
    })
  }
}

function getLoadingChild (doc) {
  let notes = null
  for (let i = 0; i < doc.childNodes.length; i++) {
    if (doc.childNodes[i].className === 'cssloader-remove') {
      notes = doc.childNodes[i]
      break
    }
  }
  return notes
}
