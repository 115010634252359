const multiSelect = {
  mapStates: function (statesNames) {
    const result = {}
    statesNames.forEach(i => {
      result[i] = { selected: [], loaded: [] }
    })
    return result
  },
  mapMutations: function (statesNames) {
    const result = {}
    statesNames.forEach(i => {
      result[i] = function (state, payload) {
        state[i] = { ...state[i], ...payload }
        if (!state[i].selected) {
          state[i].selected = []
        }
      }
    })
    return result
  }
}

const uniqueSelect = {
  mapStates: function (statesNames) {
    const result = {}
    statesNames.forEach(i => {
      result[i] = { selected: null, loaded: [] }
    })
    return result
  },
  mapMutations: function (statesNames) {
    const result = {}
    statesNames.forEach(i => {
      result[i] = function (state, payload) {
        state[i] = { ...state[i], ...payload }
        if (!state[i].selected) {
          state[i].selected = null
        }
      }
    })
    return result
  }
}

export {
  multiSelect,
  uniqueSelect
}
