import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'

import {
  vuexMapDefaultsMutations
} from 'src/utils.store'

function defaultState () {
  return {
    limits: [],
    titles: []
  }
}

const mutations = {
  ...vuexMapDefaultsMutations(defaultState()),
  resetState (state) {
    resetarParaEstadoInicial(state, defaultState())
  },
  titles (state, payload) {
    state.titles = payload
  }
}

const actions = {}

export default {
  namespaced: true,
  state: defaultState(),
  mutations,
  actions
}
