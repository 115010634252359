<template lang="pug">
  .wrapper-smart-table
    .grid(
      :style="estilosDinamicos",
      :class="{'details-box-open': showingDetailsOrConfigBox, 'modo-estado-inicial': mostrarEstadoInicial}")

      // HEADER AND BODY
      .header(v-if="!mostrarEstadoInicial")
        ColumnHeader.header-column(v-for="(column, index) in header",
        v-bind="buildBinds('header', column, index)", :key="index", v-on="buildHeaderEvents(column)")

      EstadoInicial(v-if="mostrarEstadoInicial")
        slot(name="estadoInicial" v-if="$slots.estadoInicial")
      EstadoErro(v-else-if="mostrarEstadoErro" :mensagemDeErro="serverSidePagination.ocorreuErro")
        slot(name="estadoErro"  v-bind:mensagemDeErro="serverSidePagination.ocorreuErro")
      EstadoVazio(v-else-if="mostrarEstadoVazio")
        slot(name="estadoVazio" v-if="$slots.estadoVazio")
      .row(
        v-for="(item, index) in state.loading ? mergedConfig.perPage : toDisplay"
        v-else
        :key="`${name}-row-${index}`" :id="`${name}-row-${index}`"
        :class="{selected: state.selected && state.selected.index !== undefined ? state.selected.index === item.realIndex : false}"
        @focus="focusRow"
        tabindex="-1"
      )
        slot(v-bind="buildBinds('body', item, index)" :possuiColunaDeConfiguracao="mergedConfig.possuiColunaDeConfiguracao" v-if="!state.loading")
          // use this TableRow if the user of this component didnt inplemented this slot
          TableRow(v-bind="buildBinds('body', item, index)")

        TableRow(v-bind="buildBinds('body', item, index)" :possuiColunaDeConfiguracao="mergedConfig.possuiColunaDeConfiguracao" :loading="true" v-else="" :key="'loading-' + index")

      // DETAILS BOX
      transition(name="details-box")
        .details-box(v-if="toDisplay && detailsBox.show && !showConfigBox" :style="configAndDetailsBoxPosition.gridStyle")
          .icon-ptt-icons-X.to-click(@click="closeDetailsBox")

          slot(name="detailsBox", :item="detailsBoxItem")

        .details-box(v-else-if="showConfigBox" :style="configAndDetailsBoxPosition.gridStyle")
          .icon-ptt-icons-X.to-click(@click="toggleConfig")

          Configs(:name="name", :headerItems="headerItems", :config="mergedConfig",
          @applyConfigs="loadConfigs", @close="toggleConfig")

      // CONFIG ICON
      .table-config.to-click(v-if="mergedConfig.possuiColunaDeConfiguracao && !mostrarEstadoInicial" @click="toggleConfig")
        span.icon-ptt-icons-config

    .footer
      Paginate(:currentPage="currentPage", :numberItemsDisplay="mergedConfig.perPage",
      :totalPages="totalPages", @goToPage="changePage" v-if="showPaginate")

      PageInfo(:totalItems="totalItems", :perPage="mergedConfig.perPage", :currentPage="currentPage")

</template>

<script>
import ColumnHeader from './ColumnHeader'
import TableRow from './TableRow'
import layout from './layout'
import data from './data'
import keyboard from './keyboard'
import loadBulkData from './loadBulkData'
import loadPages from './loadPages'
import Configs from './Configs'
import EstadoVazio from './EstadoVazio'
import EstadoInicial from './EstadoInicial'
import EstadoErro from './EstadoErro'
import { Paginate, PageInfo } from 'src/common/pagination'

export default {
  name: 'SmartTable',
  mixins: [layout, data, loadBulkData, loadPages, keyboard],
  components: {ColumnHeader, TableRow, Configs, Paginate, PageInfo, EstadoVazio, EstadoInicial, EstadoErro},
  props: {
    name: {type: String, required: true},
    headerItems: {type: Array, required: true},
    // headerItems obj eg: {
    //    column: String              nome que vai aparecer na header
    //    field:  String              field dos objetos passados na prop items que essa coluna vai exibir
    //    filterFunction: Function    função que que será passada para o .filter desse field
    //                                recebe: {search, item}
    //    width: String               Vc pode usar qualquer notação do css grid, como por exemplo: 1fr.
    //                                Se esse field não for fornecido, 1fr será o valor default
    //    sortFunction: Function      função que será passada para a func .sort  -  Lembre-se que ela deve retornar: 0|1|-1
    //                                ela recebe um obj: {field, order: 'asc' | 'desc', itemA, itemB})
    //    options: Array[Object]      Use esse parametro quando o field tiver tipos unicos (como na tela de dispositivos
    //                                que temos android, ios e pc). Os objetos devem ter o formato: {iconClass: '', text: ''}
    //                                use a filterFunction para em conjunto com esse campo, vc vai receber esse text como search
    //    mask: String                aplica a diretiva v-mask com esse valor, útil para criar header com formato próprio
    //                                como de tempo: 99:99
    //    userCustom: Object          configura oque o usuário pode customizar na tela de cofigurações da tabela
    //      {
    //        movable: bool, true     o usuário pode mover essa coluna para outra posição,
    //        hiddable: bool true     o usuário pode esconder esse campo
    //      }
    //
    //    pesquisavel: bool, true     habilita ou desabilita o input de pesquisa da coluna, por padrão é true
    //    ordenavel:   bool, true     habilita ou desabilita botão de ordenação, por padrão é true
    //    mensagemAoPassarCursor: String, null    mostra balão abaixo do item com a mensagem passada
    // }
    config: {type: Object, 'default': function () { return {} }},
    // config eg: {
    //    startFocusedSearch: String [header.field] ao criar o componente, foca no input de busca da header dessa coluna
    //    detailsBox: {
    //      openOnClickRow: Boolean               abre automaticamente o detailsBox ao clicar em uma linha
    //      onlyOpenWithDoubleClick: Boolean      se for true, openOnClickRow == true e for um evento de duplo click, abre o details
    //      numberColumnsKeep: int                número de colunas que vai deixar a esquerda quando abrir, o default é 2
    //      minHeight: int                        min height css (útil para quando você sabe que terá height alto)
    //    perPage: int                            número de items por página
    //                                            todo se passar -1 o component vai ver o espaço disponível e
    //                                            todo ver quantos items por linha ele pode mostrar
    //    loadBulkDataFunctionChunkSize: int      quando o loadBulkDataFunction é passado vamos carregar a primeira página
    //                                            e fazer as restantes em requests paralelos para o backend
    //                                            por default vamos pedir 50 itens, essa prop sobrescreve esse comportamento
    //    startPage:                              número inícial da página ao carregar o component.
    //                                            Se for maior q o número de páginas existente, irá usar a primeira página
    //    defaultColumnSearch: String [field]     Se for fornecido, quando o usuário estiver focado em uma linha e digitar,
    //                                            o foco será alterado para o input de pesquisa dessa coluna. O mesmo acontece
    //                                            a primeira linha estiver focada e o usuário apertar arrowUp.
    //                                            Esse só será o default caso o user não tenha feito nenhuma busca,
    //                                            se não será o ultimo field buscado
    //    defaultSortField: String                Só da para ordernar um field por vez. Use essa prop para indicar
    //                                            que a table deve iniciar ordenada pelo field.
    //    defaultFilterText: Object               String que já será utilizada como busca quando o camponent for criado
    //                                            ex: {field: String, text: 'search Value'}
    //    possuiColunaDeConfiguracao: Boolean     mostra coluna de configuração da tabela (padrão true)
    // }
    loadPageFunction: {type: [Function, Boolean], required: false},
    // loadPageFunction: receives {perPage, page, orderBy, search, searchedField, reverse} and must return {items, totalItems, totalPages}
    loadBulkDataFunction: {type: Function, required: false},
    // loadBulkDataFunction: receives {perPage, page} and must return {items, totalItems}
    //
    //
    // ------------------------- EVENTS THAT THIS COMPONENT EMITS -------------------------
    // rowClick (rowItem, columnValue, index)
    //
    // ------------------------- USEFUL METHODS -------------------------
    // limpaCamposDePesquisa ()                 limpa campos de pesquisa do header
    // resetPagesAndReload ()                   quando usando paginação server side e precisar descartar todas as páginas carregadas no cache
    // closeDetailsBox ()                       fecha a tela de detalhes
    // focusSearch (search: string = '')        foca na última coluna pesquisada com o texto fornecido
    //
    // -------------------------------------------------------------------
    definirChaveDoItem: {type: Function, required: false},
    /**
    * Essa prop previne que o `loadPageFunction`
    * seja executado na montagem do componente
    * */
    previnirCarregamentoNaMontagem: {type: Boolean, default: false}
  },
  computed: {
    mergedConfig () {
      return {
        perPage: 10,
        loadBulkDataFunctionChunkSize: 50,
        startPage: 0,
        startFocusedSearch: null,
        defaultColumnSearch: null,
        defaultSortField: null,
        possuiColunaDeConfiguracao: true,
        ...this.config,
        detailsBox: {
          openOnClickRow: true,
          minHeight: 0,
          onlyOpenWithDoubleClick: false,
          numberColumnsKeep: 2,
          ...(this.config.detailsBox || {})
        }
      }
    },
    showPaginate () {
      return this.isServerSidePaginated
        ? this.serverSidePagination.totalPages > 1
        : this.state.loaded.length > this.mergedConfig.perPage
    },
    totalItems () {
      return this.isServerSidePaginated
        ? this.serverSidePagination.totalItems
        : this.state.loaded.length
    },
    detailsBoxItem () {
      if (this.isServerSidePaginated) {
        const definirChave = this.obterFuncaoParaDefinirChaveDoItem()
        const items = this.serverSidePagination.loadedPages[this.currentPage]
        return items.find(item => definirChave(item) === this.detailsBox.itemId)
      }
      return this.state.loaded[this.detailsBox.index]
    },
    /**
     * @description O Estado de inicial da SmartTable funciona somente para server side
     * esse estado é visível quando a primeira busca ainda não foi feita (estado alterado na loadPageDataUsingGivenFunc())
     * e também quando a tabela é server side e não tem nada para mostrar e não está carregando
     */
    mostrarEstadoInicial () {
      return !this.efetuouPrimeiraBusca && this.isServerSidePaginated && !this.toDisplay && !this.state.loading
    },
    /**
     * @description O Estado de erro da SmartTable funciona somente para server side
     * esse estado é visível quando o loadPageDataUsingGivenFunc() retorna um erro e
     * preenche a propriedade reativa serverSidePagination.ocorreuErro
     */
    mostrarEstadoErro () {
      return this.serverSidePagination.ocorreuErro !== null
    },
    /**
     * @description O Estado de vazio da SmartTable funciona tanto para server side
     * quanto para o antigo carregamento de todos os dados de uma vez.
     * ele é visível quando não há dados para mostrar e não está carregando.
     */
    mostrarEstadoVazio () {
      return !this.toDisplay && !this.state.loading
    }
  },
  mounted () {
    if (this.mergedConfig.startFocusedSearch) {
      this.$nextTick(() => {
        const headetToFocus = this.$children.find(i => i.field === this.mergedConfig.startFocusedSearch)
        if (headetToFocus) {
          headetToFocus.focusSearch()
        }
      })
    }

    if (this.mergedConfig.defaultFilterText) {
      const {field, text} = this.mergedConfig.defaultFilterText
      this.search[field] = text
    }
  },
  methods: {
    isClickToCloseDetails (rowItem, realIndex) {
      return this.isServerSidePaginated
        ? rowItem.id === this.detailsBox.itemId
        : realIndex === this.detailsBox.index
    },
    setDetailsBoxInfoItem (rowItem, realIndex) {
      if (this.isServerSidePaginated) {
        const definirChave = this.obterFuncaoParaDefinirChaveDoItem()
        this.detailsBox.itemId = definirChave(rowItem)
      } else {
        this.detailsBox.index = realIndex
      }
    },
    rowClick (rowItem, columnValue, realIndex, isDoubleClick) {
      // close details if clicked in same index

      const {openOnClickRow, onlyOpenWithDoubleClick} = this.mergedConfig.detailsBox

      if (this.isClickToCloseDetails(rowItem, realIndex)) {
        this.closeDetailsBox()
        return
      }

      this.state = {selected: {item: rowItem, index: realIndex}}

      if (openOnClickRow && !this.detailsBox.show) {
        if ((onlyOpenWithDoubleClick && isDoubleClick) || !onlyOpenWithDoubleClick) {
          this.detailsBox.show = !this.detailsBox.show
        }
      }

      if (this.detailsBox.show) this.setDetailsBoxInfoItem(rowItem, realIndex)

      this.$emit('rowClick', rowItem, columnValue, realIndex)
    },
    focusSearch (search = '') {
      this.executeSearchInHeader(search)
    },
    limpaCamposDePesquisa () {
      for (const campo in this.search) {
        this.$set(this.search, campo, '')
      }
    },
    obterFuncaoParaDefinirChaveDoItem () {
      let definirChave = this.definirChaveDoItem
      if (!definirChave) {
        definirChave = (item) => item.id
      }
      return definirChave
    }
  }
}
</script>

<style scoped lang="scss">
@mixin padding-first-row-item {
  padding-left: 20px;
}
@mixin padding-last-row-item {
  padding-right: 20px;
}

@mixin margin-top-and-border {
  margin-top: 5px;
  border-top: 2px solid var(--color-primary);
}

/* IF YOU NEED TO CHANGE THIS VALUES, CHANGE IN columnsDisposition COMPUTED TOO */
$header-row-height: 45px;
$body-row-height: 35px;

.wrapper-smart-table {
  width: 100%;
  height: 100%;

  .grid {
    display: grid;
    width: 100%;
    height: 100%;
    min-height: var(--alturaMinimaDinamicaDaTabela);
    overflow: hidden;
    position: relative;
    transition: all 0.6s linear, height 0s;
    border-bottom: 2px solid var(--color-primary);
    z-index: 0;

    &.modo-estado-inicial {
      border-bottom: unset;
    }

    .header {
      display: contents;
      .header-column {
        height: $body-row-height;
        @include margin-top-and-border;
        border-bottom: 2px solid var(--color-primary);
        grid-row: 1;
      }
      .header-column:first-child { @include padding-first-row-item }
      .header-column:last-child { @include padding-last-row-item }
    }

    .details-box {
      box-sizing: border-box;
      transition: all 0.4s;
      @include margin-top-and-border;
      background-color: whitesmoke;
      border-left: 1px solid lightgray;
      padding: 10px 30px 10px 10px;
      animation: slide-left-full 0.2s ease-out;
      z-index: 100;
      height: calc(100% - 5px);
      overflow-y: auto;
      overflow-x: hidden;

      .icon-ptt-icons-X {
        position: absolute;
        font-size: 17px;
        right: 10px;
      }
    }

    // styles of row
    .row {
      display: contents;
      border-bottom: 1px solid lightgray;
      animation: fade-in 0.2s linear;

      &.selected {
        .body-column {
          background-color: rgba(209, 209, 209, 0.5);
        }
      }

      .body-column {
        border-bottom: 1px solid lightgray;
        align-items: center;
        display: grid;
        // height: $body-row-height;
        min-height: $body-row-height;
        padding-right: 10px;

        &:first-child { @include padding-first-row-item }
        &:last-child { @include padding-last-row-item }

        &::selection {
          background-color: var(--color-primary);
          color: var(--color-primary-contrast);
        }
      }

      &:focus-within .body-column {
        background-color: rgba(209, 209, 209, 0.3);
        outline: none;
      }

      &:hover:not(:focus-within) .body-column {
        background-color: whitesmoke;
      }
    }

    .table-config {
      grid-column: -2 / -1;
      grid-row: 1 / 2;
      display: grid;
      height: 35px;
      margin-top: 5px;
      position: relative;
      border-bottom: 2px solid var(--color-primary);
      border-top: 2px solid var(--color-primary);
      span { align-self: center; }
    }
  }

  .footer {
    display: grid;
    grid-template-columns: 1fr 0.5fr;

    &> span {
      display: grid;
      align-items: center;
      justify-self: right;
    }
  }
}

.details-box-enter-active {
  transform: translateX(0%);
}

.details-box-enter, .details-box-leave-to {
  transform: translateX(100%);
}
</style>
