import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'

import {
  vuexMapDefaultsMutations,
  vuexMapDefaultsActions,
  vuexMapDefaultsGetters
} from 'src/utils.store'

import { API } from 'src/services'

const moduleGroups = {
  cadastro: [
    'cadastros',
    'company',
    'compemployee',
    'employer',
    'journey',
    'reason',
    'region',
    'role',
    'rolegroup',
    'routine',
    'supervisor',
    'user',
    'usergroup',
    'dadoscadastraisdoempregado',
    'escaladoempregado',
    'configuracoesdepontoebeneficiosdoempregado',
    'camposopcionaisdoempregado',
    'camposcustomizaveisdoempregado',
    'camposcustomizaveisdafolha',
    'dadosdetrabalhodoempregado'
  ],
  lancamentos: [
    'feriaseafastamento',
    'abstractjustify',
    'arbitrarytms',
    'holidaysCalendar',
    'justify',
    'sumtimemeasurements',
    'suspension',
    'workhourbankauth'
  ],
  bancoDeHoras: [
    'workhourbankcloser',
    'workhourbankobjects',
    'workhourbankrules',
    'workhourbankwindow',
    'transferenciadeapontamentos'
  ],
  travamento: [
    'locker',
    'lockconfirmation',
    'unilateralcloses',
    'unlocker',
    'unlockerask',
    'regradetravamento'
  ],
  administracao: [
    'appkey',
    'arbitrarytmsrules',
    'customevent',
    'customrules',
    'dayjourney',
    'deviceregister',
    'edittimelogkind',
    'employeemov',
    'employeemovover',
    'exportfiles',
    'faketimelog',
    'groupscomment',
    'ignoretimelog',
    'importafd',
    'importarfuncionarios',
    'manage',
    'nextel',
    'notification',
    'notificationrules',
    'positionrule',
    'recalculatekind',
    'showtimelogs',
    'userblock',
    'aprovacaoarquivo',
    'controledeacesso',
    'trabalhoremoto'
  ],
  acompanhamento: [
    'alteracoesmultiplas',
    'absence',
    'autocomplete',
    'autocorrect',
    'biometric',
    'eventlog',
    'fastregister',
    'movementlog',
    'oncall',
    'problems',
    'realtime',
    'timelog',
    'timemeasurements',
    'acompanhamentodiario'
  ],
  atividades: [
    'activityapprove',
    'activitycostcenter',
    'activityline',
    'activityobject',
    'activitytype'
  ],
  aprovacoes: [
    'objectapprovalrules',
    'objectapprovalsrequest',
    'aprovacaoemlotesolicitacoes'
  ],
  relatorios: [
    'r01', 'r02', 'r03', 'r04', 'r05', 'r06', 'r07', 'r08', 'r09', 'r10',
    'r11', 'r12', 'r13', 'r14', 'r15', 'r16', 'r17', 'r18', 'r19', 'r20',
    'r21', 'r22', 'r23', 'r24', 'r25', 'r26', 'r27', 'r28', 'r29', 'r30',
    'r31', 'r35', 'r36', 'r37', 'r38', 'relatoriodeauditoria'
  ],
  exportadores: [
    'e01', 'e02', 'e03', 'e04', 'e05', 'e06', 'e07', 'e08', 'e09', 'e10', 'e11', 'e12'
  ],
  importadores: [
    'i01', 'i02', 'i03', 'i04', 'importadoresgestaoweb'
  ]
}

// const subModuleGroups = {
//   compemployee: [
//     'camposcustomizaveisdoempregado',
//     'camposopcionaisdoempregado',
//     'configuracoesdepontoebeneficiosdoempregado',
//     'dadoscadastraisdoempregado',
//     'dadosdetrabalhodoempregado',
//     'escaladoempregado'
//   ]
// }

function fromBackend (roleGroup) {
  return {
    id: roleGroup.id,
    name: roleGroup.name,
    description: roleGroup.description,
    kind: roleGroup.kind,
    roles: roleGroup.roles
  }
}

function toBackEnd (state) {
  function formatRoles (s) {
    const extractRoles = (acc, m) => {
      if (m.actions.get) acc.push({module: m.module, filter: s.kind, action: 'get'})
      if (m.actions.post) acc.push({module: m.module, filter: s.kind, action: 'post'})
      if (m.actions.delete) acc.push({module: m.module, filter: s.kind, action: 'delete'})
      if (m.subModules.length > 0) {
        m.subModules.forEach(subModule => {
          if (subModule.actions.get) acc.push({module: subModule.module, filter: s.kind, action: 'get'})
          if (subModule.actions.post) acc.push({module: subModule.module, filter: s.kind, action: 'post'})
          if (subModule.actions.delete) acc.push({module: subModule.module, filter: s.kind, action: 'delete'})
        })
      }
      return acc
    }
    const cadastro = s.cadastro.reduce(extractRoles, [])
    const lancamentos = s.lancamentos.reduce(extractRoles, [])
    const bancoDeHoras = s.bancoDeHoras.reduce(extractRoles, [])
    const administracao = s.administracao.reduce(extractRoles, [])
    const acompanhamento = s.acompanhamento.reduce(extractRoles, [])
    const atividades = s.atividades.reduce(extractRoles, [])
    const travamento = s.travamento.reduce(extractRoles, [])
    const aprovacoes = s.aprovacoes.reduce(extractRoles, [])
    const relatorios = s.relatorios.reduce(extractRoles, [])
    const exportadores = s.exportadores.reduce(extractRoles, [])
    const importadores = s.importadores.reduce(extractRoles, [])

    return [
      ...cadastro,
      ...lancamentos,
      ...bancoDeHoras,
      ...administracao,
      ...acompanhamento,
      ...atividades,
      ...travamento,
      ...aprovacoes,
      ...relatorios,
      ...exportadores,
      ...importadores
    ]
  }

  const request = {
    name: state.name,
    description: state.description,
    kind: state.kind,
    roles: formatRoles(state)
  }

  if (state.id) request.id = state.id

  return request
}

function defaultState () {
  return {
    loading: false,
    roleDetails: null,
    options: null,
    cadastro: null,
    lancamentos: null,
    bancoDeHoras: null,
    administracao: null,
    acompanhamento: null,
    atividades: null,
    travamento: null,
    aprovacoes: null,

    // Relatórios
    relatorios: null,
    exportadores: null,
    importadores: null,

    emEdicaoPossuiRelatoriosERegistrosAtivo: false,

    // object to back end
    id: null,
    name: '',
    description: '',
    kind: 'all'
  }
}

const getters = {
  ...vuexMapDefaultsGetters(defaultState()),
  permissaoGeralRelatorios (state) {
    const moduloDeRelatorios = state.administracao?.find(modulo => modulo.module === 'exportfiles')
    return moduloDeRelatorios
  },
  possuiOpcaoGerarRelatorioAtiva (_, getters) {
    return getters.permissaoGeralRelatorios?.actions.post
  }
}

const mutations = {
  ...vuexMapDefaultsMutations(defaultState()),
  resetState (state) {
    resetarParaEstadoInicial(state, defaultState())
  },
  setFromState (state, {...roleGroup}) {
    const moduloDeRelatoriosERegistros = roleGroup.roles.some(permissao => permissao.name.includes('exportfiles_post'))
    state.emEdicaoPossuiRelatoriosERegistrosAtivo = moduloDeRelatoriosERegistros

    roleGroup.roles.forEach(r => {
      if (r?.subpermissao) {
        const moduloPai = r.permissaoPai.split('_')[0]

        const group = Object.keys(moduleGroups)
          .filter(m => moduleGroups[m].includes(moduloPai))
        if (group.length === 1) {
          const groupName = group[0]
          const m = state[groupName].find(m => m.module === moduloPai)
          const submodulo = m.subModules.find(e => e.module === r.module)
          if (submodulo) {
            submodulo.actions[r.action] = true
          }
        }
      } else {
        const group = Object.keys(moduleGroups)
          .filter(m => moduleGroups[m].includes(r.module))
        if (group?.length === 1) {
          const groupName = group[0]
          const m = state[groupName].find(m => m?.module === r?.module)
          if (m) {
            m.actions[r.action] = true
          }
        }
      }
    })

    delete roleGroup.roles
    Object.assign(state, roleGroup)
  },
  formatPermissions (state, payload) {
    const dadosApi = payload

    const objectFormat = m => {
      return {
        module: m,
        moduleName: dadosApi.modulesPrettifier[m],
        actions: {
          get: false,
          post: false,
          delete: false
        },
        kind: 'mine',
        subModules: dadosApi.submodulos.filter(submodule => submodule[0] === m).map(submodule => ({
          actions: {
            get: false,
            post: false,
            delete: false
          },
          module: submodule[1],
          kind: 'mine',
          moduleName: dadosApi.submodulosPrettifier[submodule[1]]
        }))
      }
    }

    const filterByModule = g => formatedModules.filter(m => moduleGroups[g].includes(m.module))

    const formatedModules = dadosApi.modules.map(objectFormat)
    state.cadastro = filterByModule('cadastro')
    state.lancamentos = filterByModule('lancamentos')
    state.bancoDeHoras = filterByModule('bancoDeHoras')
    state.administracao = filterByModule('administracao')
    state.acompanhamento = filterByModule('acompanhamento')
    state.atividades = filterByModule('atividades')
    state.travamento = filterByModule('travamento')
    state.aprovacoes = filterByModule('aprovacoes')
    state.relatorios = filterByModule('relatorios')
    state.exportadores = filterByModule('exportadores')
    state.importadores = filterByModule('importadores')
  },
  loading (state, payload) {
    state.loading = payload
  }
}

const actions = {
  ...vuexMapDefaultsActions(defaultState()),
  async loadRoleGroups ({ commit }, roleGroupId) {
    commit('loading', true)

    const api = await API.roleGroup.get({id: roleGroupId})

    commit('setFromState', {
      ...fromBackend(api.data.success[0])
    })
    commit('loading', false)
  },
  async loadRoleDetails ({ commit }) {
    const api = await API.roleDetails.get()

    commit('roleDetails', api.data.success)
    commit('formatPermissions', api.data.success)
  },
  async salvarGrupoDePermissoes ({ state, getters, dispatch }) {
    if (!getters.possuiOpcaoGerarRelatorioAtiva) {
      dispatch('definirPermissaoDeTodosOsRelatoriosERegistrosDeAcordoComPermissaoGeral', false)
    }

    const grupoDePermissoes = toBackEnd(state)

    if (state.id) {
      await API.roleGroup.save({id: state.id}, grupoDePermissoes)
    } else {
      await API.roleGroup.save(grupoDePermissoes)
    }
  },
  definirPermissaoDeTodosOsRelatoriosERegistrosDeAcordoComPermissaoGeral ({ state, commit }, permissaoGeral) {
    const relatorios = state.relatorios
    const exportadores = state.exportadores
    const importadores = state.importadores
    const definirPermissoDeAcordoComPermissaoGeral = (relatorio) => {
      relatorio.actions.get = permissaoGeral
      relatorio.actions.post = permissaoGeral
      relatorio.actions.delete = permissaoGeral
    }

    relatorios.forEach(definirPermissoDeAcordoComPermissaoGeral)
    exportadores.forEach(definirPermissoDeAcordoComPermissaoGeral)
    importadores.forEach(definirPermissoDeAcordoComPermissaoGeral)

    commit('relatorios', relatorios)
    commit('exportadores', exportadores)
    commit('importadores', importadores)
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  getters,
  mutations,
  actions
}
