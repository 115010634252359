import Vue from 'vue'
import header from './Header/state'
import scheduler from './Scheduler/state'
import sums from './Sums/state'
import { API } from 'src/services'
import { range } from 'src/utils'
import { copiarObjetoProfundamente, congelarObjetoProfundamente, formatarDataParaEnvio } from 'src/utils/formatadoras/formatadoras'
import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'
import { formatDayInfo, genDayInfoToPreview } from './utils'
import store from 'src/store'
import merge from 'deepmerge'
import { FiltradorDeErros } from 'src/erros'

const defaultState = () => ({
  commonJourneys: null,
  journeys: null,
  routines: null,
  journeysMode: 'generated', // generated or code , when  is code we will gen a refNumber for journeys missing code
  changesHistory: [],
  baseInfoUsedToPopulate: null,
  populatingScheduler: false,
  populatingSums: false,
  // Object with multiple previews of applyRoutinePreviewAndData. E.g:  we will reset the
  // applyRoutineConfig state when request to change starts, so we need to keep the info here while dont finishes
  previewApplying: {},
  applyRoutineConfig: {
    rowNumber: null,
    colNumber: null,
    routine: null,
    phaseShift: 0,
    overManualChanges: false,
    active: false
  },
  requests: {
    scheduler: [],
    sums: []
  }
})

const __state = defaultState()

Vue.http.interceptors.push(function (request, next) {
  if (request.url.includes('work-scheduler')) {
    if (request.params.type === 'base') {
      store.commit('workSchedule/setPopulateRequest', {request, type: 'scheduler'})
    } else if (request.params.type === 'sums') {
      store.commit('workSchedule/setPopulateRequest', {request, type: 'sums'})
    }
  }
  next()
})

const getters = {
  journeysPerId (state) {
    return state.journeys ? new Map(state.journeys.map(jrn => [jrn.id, jrn])) : new Map()
  },
  previewJourneysDays (state, getters) {
    const info = getters['applyRoutinePreviewAndData']

    if (info) {
      return merge.all([info.dayInfosToPreview, state.previewApplying])
    }

    return state.previewApplying
  },
  applyRoutinePreviewAndData (state, getters) {
    const { periodData } = state.scheduler
    const { applyRoutineConfig } = state

    if ((!periodData || !periodData.length) || !applyRoutineConfig.active || !applyRoutineConfig.routine) {
      return null
    }

    const { colNumber, rowNumber, routine, phaseShift, overManualChanges } = applyRoutineConfig

    const group = getters['scheduler/selectionGroup'](rowNumber, colNumber)
      .filter(i => i.rowNumber === rowNumber)
      .map(i => i.colNumber)
      .sort((a, b) => a - b)

    const startDate = periodData[rowNumber].period[group[0]].day
    const endDate = group.length !== 1 ? periodData[rowNumber].period[group[group.length - 1]].day : null

    let colsList

    // if group length is one it means that the new routine is endless
    if (group.length === 1) {
      colsList = range(group[0], periodData[0].period.length - 1)
    } else {
      colsList = [...group]
    }

    const result = {}

    colsList.forEach((col, index) => {
      const journey = routine.journeys[(index + phaseShift) % routine.journeys.length]
      result[col] = genDayInfoToPreview(
        periodData[rowNumber].period[col],
        overManualChanges,
        journey,
        getters['journeysPerId']
      )
    })

    return {
      dayInfosToPreview: {[rowNumber]: result},
      rowNumber,
      routine,
      phaseShift,
      employee: periodData[rowNumber].employee,
      overManualChanges,
      affectedCols: colsList,
      startDate,
      endDate
    }
  }
}

const mutations = {
  commonJourneys (state, {commonJourneys, journeys}) {
    state.commonJourneys = commonJourneys
    state.journeys = journeys
  },
  previewApplying (state, payload) {
    state.previewApplying = merge.all([state.previewApplying, payload])
  },
  resetPreviewApplying (state) {
    state.previewApplying = defaultState().previewApplying
  },
  abortRequests (state, type) {
    if (state.requests[type].length) {
      state.requests[type].forEach(i => i.abort())
      state.requests[type] = []
    }
  },
  applyRoutineConfig (state, payload) {
    state.applyRoutineConfig = {...state.applyRoutineConfig, ...payload}

    if (state.applyRoutineConfig.active &&
      (state.applyRoutineConfig.rowNumber === null || state.applyRoutineConfig.colNumber == null)) {
      state.applyRoutineConfig.active = false
      state.applyRoutineConfig.rowNumber = null
      state.applyRoutineConfig.colNumber = null
      state.applyRoutineConfig.overManualChanges = false
    }
  },
  setPopulateRequest (state, {request, type}) {
    state.requests[type].push(request)
  },
  resetState (state) {
    resetarParaEstadoInicial(state, defaultState())
  },
  routines (state, routines) {
    state.routines = routines
  },
  journeysMode (state, mode) {
    state.journeysMode = mode
  },
  removeLastItemChangeHistory (state) {
    state.changesHistory = state.changesHistory.slice(1, state.changesHistory.length)
  },
  baseInfoUsedToPopulate (state, info) {
    state.baseInfoUsedToPopulate = copiarObjetoProfundamente(congelarObjetoProfundamente(info))
  },
  populatingScheduler (state, payload) {
    state.populatingScheduler = Boolean(payload)
  },
  populatingSums (state, payload) {
    state.populatingSums = payload
  },
  resetChangeHistory (state) {
    state.changesHistory = []
  },
  appendChangeToHistory (state, {listRowsCols, undoType}) {
    const infoPerEmp = new Map()

    listRowsCols.forEach(({rowNumber, colNumber}) => {
      const empId = state.scheduler.periodData[rowNumber].employee.id

      if (infoPerEmp.get(empId) === undefined) {
        infoPerEmp.set(empId, [])
      }

      infoPerEmp.get(empId).push({...state.scheduler.periodData[rowNumber].period[colNumber]})
    })

    const changes = []
    infoPerEmp.forEach((value, key) => {
      changes.push({
        employee: key,
        affectedRowsCols: listRowsCols,
        [undoType]: Array.from(new Set(value.map(i => undoType === 'dates' ? i.day : i.djCreator.id)))
      })
    })
    state.changesHistory = [changes, ...state.changesHistory]
  }
}

const actions = {
  async fetchRoutines ({ commit }) {
    const result = await API.routine.get()
    commit('routines', result.data.success)
  },
  async fetchSomas ({ commit, state }, page = null) {
    commit('abortRequests', 'sums')

    const pegarPeriodosDeDatas = () => {
      if (state && state.header && state.header.rangeDates) {
        const periodo = state.header.rangeDates
        const possuiInicio = 'start' in periodo
        const possuiFim = 'end' in periodo
        if (possuiInicio && possuiFim) {
          return [periodo.start, periodo.end]
        }
      }
      return null
    }

    const pegarPeriodoDeDatasFormatado = periodo => {
      if (periodo && periodo.length && periodo.length === 2) {
        const [inicio, fim] = periodo
        return {
          startDate: formatarDataParaEnvio(inicio),
          endDate: formatarDataParaEnvio(fim)
        }
      }
    }

    const periodoDeDatas = pegarPeriodosDeDatas()
    const periodoDeDatasFormatado = pegarPeriodoDeDatasFormatado(periodoDeDatas)

    if (!periodoDeDatasFormatado) {
      this.modalDeErro(`Por favor, selecione um periodo de datas válido!`)
      return
    }

    let params = {
      companies: state.header.companies.selected.map(i => i.id),
      ...periodoDeDatasFormatado
    }

    const savedPaginationInfo = state.sums.paginationInfo

    if (page !== null && !savedPaginationInfo) {
      throw new Error(`fetchSomas action dispatched to page ${page} but theres no paginationInfo in state`)
    } else if (page !== null) {
      params = savedPaginationInfo.reqDataPerPage[page]
    } else {
      params = {
        ...params,
        paginationInfo: {perPage: 10, page: 0}
      }
    }

    commit('populatingSums', true)

    const result = await API.workSchedule.save({type: 'sums'}, params)

    const { companiesSums, paginationInfo } = result.data.success

    commit('sums/companiesSums', companiesSums)
    commit('sums/paginationInfo', paginationInfo)
    commit('populatingSums', false)
  },
  async fetchSchedulerData ({ commit, state, dispatch, getters }, {page = null, search = null}) {
    commit('abortRequests', 'scheduler')

    commit('populatingScheduler', true)
    commit('resetChangeHistory')
    let baseReqParams

    const savedPaginationInfo = state.scheduler.paginationInfo

    const params = {
      companies: state.header.companies.selected.map(i => i.id),
      startDate: formatarDataParaEnvio(state.header.rangeDates.start),
      endDate: formatarDataParaEnvio(state.header.rangeDates.end),
      configs: {
        genAllCodes: state.journeysMode === 'generated'
      },
      reportType: state.reportType
    }

    if (page !== null && !savedPaginationInfo) {
      throw new Error(`fetchSchedulerData action dispatched to page ${page} but theres no paginationInfo in state`)
    } else if (page !== null && savedPaginationInfo && savedPaginationInfo.reqDataPerPage.length) {
      if (!search && search !== '') {
        baseReqParams = savedPaginationInfo.reqDataPerPage[page]
      } else {
        baseReqParams = savedPaginationInfo.reqDataPerPage[page]
        baseReqParams['search'] = search
        baseReqParams['companies'] = state.header.companies.selected.map(i => i.id)
        baseReqParams['employees'] = []
      }
    } else {
      baseReqParams = {
        ...params,
        paginationInfo: {perPage: 12, page: 0}
      }
    }

    // Just to update paginate component without wait for finish the request
    commit('scheduler/paginationInfo', {...state.scheduler.paginationInfo, page: page || 0})

    commit('baseInfoUsedToPopulate', baseReqParams)
    const baseReq = API.workSchedule.save({type: 'base'}, baseReqParams)
    const journeysReq = API.workSchedule.save({type: 'journeys'}, baseReqParams)

    const result = await Promise.all([baseReq, journeysReq])

    const { periodData, paginationInfo } = result[0].data.success
    const journeysData = result[1].data.success

    commit('scheduler/paginationInfo', paginationInfo)

    commit('commonJourneys', journeysData)

    const periodDataFormated = periodData.map(empInfo => ({
      ...empInfo,
      period: empInfo.period.map((i) => formatDayInfo(i, getters.journeysPerId))
    }))

    commit('scheduler/scheduleData', periodDataFormated)
    commit('scheduler/resetSelectedDays')
    commit('populatingScheduler', false)

    if (state.routines === null) {
      dispatch('fetchRoutines')
    }
  },
  async applyNewRoutine ({ commit, dispatch, getters }) {
    const info = {...getters['applyRoutinePreviewAndData']}

    if (!info) { return }

    commit('previewApplying', copiarObjetoProfundamente(info.dayInfosToPreview))

    commit('applyRoutineConfig', {
      active: false,
      overManualChanges: false,
      routine: null,
      phaseShift: 0
    })

    try {
      await dispatch('scheduler/applyNewRoutine', info)
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
    }
    commit('resetPreviewApplying', null)
  }
}

export default {
  namespaced: true,
  modules: {header, scheduler, sums},
  state: __state,
  getters,
  mutations,
  actions
}
