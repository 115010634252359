<script type="text/jsx">
import { obterComNotacaoPonto } from 'src/utils/outro/outro'
import { isString } from 'lodash'

export default {
  name: 'TableRow',
  functional: true,
  render (h, context) {
    const { props, scopedSlots } = context
    const classesConteudo = props?.classesConteudo !== undefined ? props.classesConteudo : []

    function styleFromColIndex (colIndex) {
      return {
        // plus 2 because 1 is for header and index starts at 0 and css grid starts at 1
        'grid-row': `${props.index + 2} / ${props.index + 2}`,
        'grid-column': `${colIndex + 1} / ${colIndex + 2}`
      }
    }

    let columns

    if (props.loading) {
      columns = columns = props.header
        .map((i, colIndex) => {
          return <div class="body-column" {...{style: {...styleFromColIndex(colIndex)}}}>
            <div style="width: 30%; height: 50%; background-color: lightgray;"></div>
          </div>
        })
    } else {
      columns = props.header
        .map((i, colIndex) => {
          let content

          if (scopedSlots[i.field]) {
            content = scopedSlots[i.field]({
              columnValue: obterComNotacaoPonto(props.item, i.field),
              rowItem: props.item,
              index: props.index
            })
          } else {
            content = obterComNotacaoPonto(props.item, i.field)
          }

          function onClickMe (isDoubleClick) {
            props.onClick(props.item, props.item[i.field], props.item.realIndex, isDoubleClick)
          }

          return (
            // this component classes are in SmartTable.vue
            <div class="body-column" {...{
              'class': {
                'table-row-active-details': props.activeDetails
              },
              attrs: {
                tabindex: '-1',
                title: isString(props.item[i.field]) ? props.item[i.field] : false
              },
              style: styleFromColIndex(colIndex),
              on: {
                click: (e) => {
                  onClickMe(false)
                },
                dblclick: (e) => {
                  onClickMe(true)
                },
                keydown: (event) => {
                  if (event.key === 'Enter') {
                    onClickMe(true)
                  } else {
                    props.onKeydown({event, rowNumber: props.index})
                  }
                }
              }
            }}>
              <span {...{
                'class': [`no-wrap`, ...classesConteudo]
              }}>{content}</span>
            </div>
          )
        })
    }

    if (!props.possuiColunaDeConfiguracao) {
      return [
        ...columns
      ]
    }

    return [
      ...columns,
      <div class="body-column" {...{style: {
        'grid-row': `${props.index + 2} / ${props.index + 2}`,
        'grid-column': '-2 / -1'
      }}}></div>
    ]
  }
}
</script>

<style lang="scss">
.table-row-active-details {
  background-color: rgba(209, 209, 209, 0.5) !important;
}
</style>
