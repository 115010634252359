import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'

import {
  vuexMapDefaultsMutations,
  vuexMapDefaultsActions
} from 'src/utils.store'

import { multiSelect } from 'src/common/selectors'

function defaultState () {
  return {
    ...multiSelect.mapStates(['companies', 'employees', 'userGroups']),
    startDate: null,
    endDate: null,
    type: 'employee',
    acionadoPelaHome: false
  }
}

const state = defaultState()

const mutations = {
  ...multiSelect.mapMutations(['companies', 'employees', 'userGroups']),
  ...vuexMapDefaultsMutations(defaultState()),
  resetState: state => {
    const toBe = defaultState()
    delete toBe['companies']
    delete toBe['employees']
    delete toBe['userGroups']
    resetarParaEstadoInicial(state, toBe)
    state.companies.selected = []
    state.employees.selected = []
    state.userGroups.selected = []
  }
}

const actions = {
  ...vuexMapDefaultsActions(defaultState())
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
