import Vue from 'vue'
import VueI18n from 'vue-i18n'

// hack to reload view after load from back
// ref => https://stackoverflow.com/questions/40657569/vue-i18n-dynamic-locale-with-promise-doesnt-update
Vue.config.lang = ''

Vue.use(VueI18n)

const messages = {
  pt: {
    // words: {
    //   employee: '__FUNCIONÁRIO__ | __FUNCIONÁRIOS__',
    //   company: '__LOCAL DE TRABALHO__ | __LOCAIS DE TRABALHO__',
    //   companyShortName: '__LOCAL__ | __LOCAIS__',
    //   employer: '__EMPREGADOR__ | __EMPREGADORES__',
    //   supervisor: '__RESPONSÁVEL__ | __RESPONSÁVEIS__',
    //   routine: '__ESCALA__ | __ESCALAS__',
    //   journey: '__JORNADA__ | __JORNADAS__',
    //   customRules: '__REGRA DE CÁLCULO__ | __REGRAS DE CÁLCULO__',
    //   locker: '__TRAVAMENTO__ | __TRAVAMENTOS__'
    // }
    words: {
      user: 'usuário | usuários',
      employee: 'empregado | empregados',
      company: 'local de trabalho | locais de trabalho',
      companyShortName: 'local | locais',
      employer: 'empregador | empregadores',
      supervisor: 'responsável | responsáveis',
      routine: 'escala | escalas',
      journey: 'jornada | jornadas',
      customRules: 'regra de cálculo | regras de cálculo',
      locker: 'travamento | travamentos',
      group: 'grupo | grupos',
      all: 'aplicar a todos'
    }
  }
}

const i18n = new VueI18n({
  locale: 'pt',
  messages
})

export function configureI18n (messages) {
  // i18n.setLocaleMessage('pt', messages)
}

export default i18n
