import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'

import {
  vuexMapDefaultsMutations,
  vuexMapDefaultsActions,
  vuexMapDefaultsGetters
} from 'src/utils.store'

import { API } from 'src/services'

const ddds = [
  '11', '12', '13', '14',
  '15', '16', '17', '18',
  '19', '21', '22', '24',
  '27', '28', '31', '32',
  '33', '34', '35', '37',
  '38', '41', '42', '43',
  '44', '45', '46', '47',
  '48', '49', '51', '53',
  '54', '55', '61', '62',
  '63', '64', '65', '66',
  '67', '68', '69', '71',
  '73', '74', '75', '77',
  '79', '81', '82', '83',
  '84', '85', '86', '87',
  '88', '89', '91', '92',
  '93', '94', '95', '96',
  '97', '98', '99', '00',
  '+244', '+48', '+1604',
  '+598', '+52', '+57', '+250',
  'CA:AT', 'CA:CT', 'CA:ET',
  'CA:MT', 'CA:NT', 'CA:PT',
  'CA:AA', 'US:AK', 'US:CT',
  'US:ET', 'US:HI', 'US:MT',
  'US:PT'
]

function fromBackend (company) {
  function formatadorCampoCustomizado ({key, value}) {
    return {
      field: key,
      text: value
    }
  }

  const formatPhone = number => {
    const isVoip = !(/55[0-9]{10,11}/.test(number))
    return {
      number: isVoip ? number : number.slice(2),
      voip: isVoip,
      editing: false
    }
  }
  return {
    id: company.id,
    code: company.code,
    name: company.name,
    visivel: company.visivel,
    // dateModified: company.dateModified,
    // phonePrefix: company.phonePrefix,
    phone: company.phone.map(formatPhone),
    appearOnRealtime: company.appearOnRealtime,
    ddd: company.ddd,
    empregadores: company.empregadores,
    sincronizarRepp: company.sincronizarComRepp,
    descricaoAFD: company.enderecoQueApareceNoAfd,
    timezoneDoColetor: company.aceitarTimezoneDoColetor,
    active: company.active,
    region: company.region,
    fullRegion: company.fullRegion,
    // routine: company.routine,
    priority: company.priority,
    color: company.color,
    customRules: company.customRules,
    location: company.location,
    // repList: company.repList,
    hasOnCall: company.hasOnCall,
    supervisor: company.supervisor,
    positions: company.positions,
    reserve: company.reserve,
    // approveOverridePermission: company.approveOverridePermission
    logradouro: company.logradouro,
    numero: company.numero,
    complemento: company.complemento,
    bairro: company.bairro,
    cep: company.cep,
    enderecoRetornado: company.logradouro || company.numero || company.complemento || company.bairro || company.cep,
    camposCustomizados: company.camposCustomizados.map(formatadorCampoCustomizado)
  }
}

function toBackEnd (state) {
  function formatadorCampoCustomizado ({field, text}) {
    return {
      key: field,
      value: text
    }
  }

  const formatPhone = ({number, voip}) => {
    const numWithoutMask = number
      .replace('(', '')
      .replace(')', '')
      .replace('-', '')

    if (voip) {
      return numWithoutMask
    }
    return `55${numWithoutMask}`
  }

  return {
    id: state.id,
    code: state.code,
    name: state.name,
    visivel: state.visivel,
    // dateModified: state.dateModified,
    phonePrefix: state.phonePrefix,
    phone: state.phone.map(formatPhone),
    appearOnRealtime: state.appearOnRealtime,
    ddd: state.ddd.selected.id,
    employers: state.empregadores.selected ? state.empregadores.selected.map(i => i.id) : [],
    sincronizarComRepp: state.sincronizarRepp,
    enderecoQueApareceNoAfd: state.descricaoAFD,
    aceitarTimezoneDoColetor: state.timezoneDoColetor,
    active: state.active,
    region: state.fullRegion[state.fullRegion.length - 1].id,
    routine: state.routine,
    priority: state.priority,
    color: state.color,
    customRules: state.customRules.selected?.id,
    location: state.location,
    repList: state.repList,
    hasOnCall: state.hasOnCall,
    supervisor: state.supervisor.selected?.id,
    positions: state.positions,
    reserve: state.reserve,
    approveOverridePermission: state.approveOverridePermission,
    logradouro: state.logradouro,
    numero: state.numero,
    complemento: state.complemento,
    bairro: state.bairro,
    cep: state.cep,
    camposCustomizados: state.camposCustomizados.map(formatadorCampoCustomizado)
  }
}

function defaultState () {
  return {
    id: null,
    loading: false,
    code: null,
    name: '',
    visivel: true,
    // dateModified: null,
    // phonePrefix: null,
    phone: [],
    appearOnRealtime: true,
    ddd: {
      selected: {id: '11', name: '11'},
      loaded: ddds.map(ddd => { return {id: ddd, name: ddd} })
    },
    empregadores: { selected: null, loaded: [] },
    sincronizarRepp: false,
    descricaoAFD: '',
    timezoneDoColetor: false,
    active: true,
    region: null,
    fullRegion: [],
    // routine: null,
    priority: 1,
    color: '#f6',
    customRules: { selected: null },
    location: [],
    // repList: null,
    hasOnCall: false,
    supervisor: { selected: null },
    positions: [],
    reserve: false,
    // approveOverridePermission: null,
    logradouro: '',
    numero: '',
    complemento: '',
    bairro: '',
    cep: '',
    infoLogradouroRetornado: true,
    infoNumeroRetornado: true,
    infoComplementoRetornado: true,
    infoBairroRetornado: true,
    infoCepRetornado: true,
    enderecoRetornado: false,
    camposCustomizados: []
  }
}

const getters = {
  ...vuexMapDefaultsGetters(defaultState())
}

const mutations = {
  ...vuexMapDefaultsMutations(defaultState()),
  resetState (state) {
    resetarParaEstadoInicial(state, defaultState())
  },
  setFromState (state, {commonSupervisors, commonCustomRules, commonEmployers, ...company}) {
    state.ddd.selected = {id: company.ddd, name: company.ddd}
    state.customRules.selected = company.customRules
    state.supervisor.selected = commonSupervisors.find(s => s.id === company.supervisor)
    state.empregadores.selected = commonEmployers.filter(empregador => {
      const ehArray = Array.isArray(company.empregadores)
      if (ehArray) {
        return company.empregadores.includes(empregador.id)
      } else {
        return false
      }
    })

    delete company.ddd
    delete company.customRules
    delete company.supervisor
    delete company.empregadores
    Object.assign(state, company)
  },
  loading (state, payload) {
    state.loading = payload
  },
  enderecoVazio (state) {
    state.logradouro = ''
    state.numero = ''
    state.complemento = ''
    state.bairro = ''
    state.cep = ''
    state.infoLogradouroRetornado = true
    state.infoNumeroRetornado = true
    state.infoComplementoRetornado = true
    state.infoBairroRetornado = true
    state.infoCepRetornado = true
    state.enderecoRetornado = false
  },
  lugarParaEndereco (state, payload) {
    state.logradouro = ''
    state.numero = ''
    state.complemento = ''
    state.bairro = ''
    state.cep = ''
    state.infoLogradouroRetornado = false
    state.infoNumeroRetornado = false
    state.infoComplementoRetornado = false
    state.infoBairroRetornado = false
    state.infoCepRetornado = false
    state.enderecoRetornado = false

    payload.forEach(componenteEndereco => {
      componenteEndereco.types.forEach(tipo => {
        switch (tipo) {
          case 'route':
            state.logradouro = componenteEndereco['long_name']
            state.infoLogradouroRetornado = true
            state.enderecoRetornado = true
            break
          case 'street_number':
            state.numero = componenteEndereco['long_name']
            state.infoNumeroRetornado = true
            state.enderecoRetornado = true
            break
          case 'sublocality':
            state.bairro = componenteEndereco['long_name']
            state.infoBairroRetornado = true
            state.enderecoRetornado = true
            break
          case 'postal_code':
            state.cep = componenteEndereco['long_name']
            state.infoCepRetornado = true
            state.enderecoRetornado = true
            break
        }
      })
    })
  },
  salvarSincronizarComRepp (state, payload) {
    state.sincronizarRepp = payload
  },
  mudarVisibilidadeDoLocalDeTrabalho (state, status) {
    state.visivel = status
  },
  sobreAvisoSempreAtivo (state, valor) {
    state.hasOnCall = valor
  }
}

const actions = {
  ...vuexMapDefaultsActions(defaultState()),
  async loadCompany ({ commit, rootState, state, rootGetters }, companyId) {
    commit('loading', true)

    await API.employer.get({ fields: JSON.stringify(['id', 'id_repp']) })
    const api = await API.company.get({id: companyId})
    const infoCompleta = api.data.success[0]
    let empregadoresCommon = rootState.common.employers.items

    if (rootState.userInfo.compMan.portaria671 && infoCompleta.sincronizarComRepp) {
      try {
        empregadoresCommon = rootGetters['common/employers/visiveisComIdRepp']
      } catch (erro) {
        state.empregadores = { selected: null, loaded: [] }
      }
    }

    commit('setFromState', {
      id: companyId,
      commonSupervisors: rootState.common.supervisors.items,
      commonEmployers: empregadoresCommon,
      ...fromBackend(infoCompleta)
    })

    commit('loading', false)
  },
  async save ({ state }) {
    const info = toBackEnd(state)

    if (state.id) {
      await API.company.save({id: state.id}, info)
    } else {
      await API.company.save(info)
    }
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  getters,
  mutations,
  actions
}
