var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { class: `wrapper-radio-slider-${_vm.theme}` },
    [
      _c("span", { style: _vm.positionBackground }),
      _vm._l(_vm.items, function (tab, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "to-click",
            class: { "is-active": index === _vm.current },
            attrs: { tabindex: "0" },
            on: {
              click: function ($event) {
                return _vm.selecionarAba(tab, index)
              },
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.selecionarAba(tab, index)
              },
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "noselect",
                class: {
                  disabled: _vm.disabledModes.includes(tab.id),
                  required: !!tab.required,
                },
              },
              [
                _vm._t(
                  "default",
                  function () {
                    return [_vm._v(_vm._s(tab.name))]
                  },
                  { tab: tab }
                ),
                _vm.observacao[tab.id]
                  ? _c("div", {
                      directives: [
                        {
                          name: "uiv-popover",
                          rawName: "v-uiv-popover.down.hover",
                          value: { content: _vm.observacao[tab.id] },
                          expression: "{content: observacao[tab.id]}",
                          modifiers: { down: true, hover: true },
                        },
                      ],
                      staticClass: "icone-chapado-info color-info",
                    })
                  : _vm._e(),
              ],
              2
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }