/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { IServicoHttp } from './http.interfaces'

/**
* Classe abstrata BaseService que serve como base para todos os serviços HTTP.
* Define a estrutura para adicionar interceptadores de requisições e respostas,
* além de fornecer métodos genéricos para operações HTTP.
*/
abstract class ServicoHttpBase implements IServicoHttp {
    protected httpClient: AxiosInstance;

    constructor (baseURL: string) {
      this.httpClient = axios.create({ baseURL })
      this.inicializarInterceptadores()
    }

    protected inicializarInterceptadores (): void {
      this.adicionarInterceptadorDeRequisicao()
      this.adicionarInterceptadorDeResposta()
    }

    /**
    * Método abstrato para adicionar interceptadores de requisições. As classes derivadas
    * devem implementar este método para configurar quaisquer interceptadores de requisição
    * específicos necessários, como adicionar tokens de autenticação aos cabeçalhos.
    */
    protected abstract adicionarInterceptadorDeRequisicao (): void;
    /**
    * Método abstrato para adicionar interceptadores de respostas. As classes derivadas
    * devem implementar este método para configurar quaisquer interceptadores de resposta
    * específicos necessários, como tratar erros globais ou extrair dados específicos das respostas.
    */
    protected abstract adicionarInterceptadorDeResposta (): void;
    /**
    * Trata a resposta de uma requisição HTTP, extraindo informações úteis como dados e status.
    * @param {AxiosResponse} resposta - A resposta recebida de uma requisição HTTP.
    * @returns {Promise<{data: any, status: number}>} Um objeto contendo os dados da resposta e o status HTTP.
    */
    private async tratarResposta (resposta: AxiosResponse): Promise<any> {
      return {
        data: resposta.data,
        status: resposta.status
      }
    }

    public async get (url: string, config?: AxiosRequestConfig): Promise<any> {
      const response = await this.httpClient.get(url, config)
      return this.tratarResposta(response)
    }

    public async post (url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
      const response = await this.httpClient.post(url, data, config)
      return this.tratarResposta(response)
    }

    public async put (url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
      const response = await this.httpClient.put(url, data, config)
      return this.tratarResposta(response)
    }

    public async patch (url: string, data?: any, config?: AxiosRequestConfig): Promise<any> {
      const response = await this.httpClient.patch(url, data, config)
      return this.tratarResposta(response)
    }

    public async delete (url: string, config?: AxiosRequestConfig): Promise<any> {
      const response = await this.httpClient.delete(url, config)
      return this.tratarResposta(response)
    }
}

export default ServicoHttpBase
