<template functional lang="pug">
  .details-box-label
    label
      slot(name="title")
        | {{ props.title }}
      span.icone-chapado-info(v-if="props.tooltipComDica" v-uiv-popover.bottom.hover="{content: props.tooltipComDica}")
    b(v-if="!props.loading && !slots.cleanBody")
      slot(name="body")
        | {{ props.body }}

    .loading(v-else)

    // use esse slot para nao ter a tag b
    slot(name="cleanBody" v-if="!props.loading")

    img.copyicon(src="../../../static/images/copy_content.svg" v-if="props.copyicon"
    @click="$options.methods.copy(props.body)")

</template>

<script>
export default {
  name: 'DBLabel',
  functional: true,
  props: {
    // if this props arent given we will use the slots
    title: {type: String, required: false},
    body: {required: false},
    loading: {type: Boolean, required: false},
    copyicon: {type: Boolean, 'default': false},
    tooltipComDica: {type: String, required: false, 'default': null}
  },
  methods: {
    copy (text) {
      navigator.clipboard.writeText(text)
    }
  }
}
</script>

<style lang="scss">
.details-box-label {
  label {
    display: block;
    font-size: 15px;

    .icone-chapado-info {
      padding-left: 2px;
    }
  }

  b {
    font-size: 19px;
    color: var(--color-primary);
  }

  .loading {
    width: 100%;
    max-width: 80px;
    height: 25px;
    background-color: lightgray;
  }

  img {
    visibility: hidden;
    margin-left: 10px;
    width: 15px;
    cursor: pointer;
    color: var(--color-primary);
  }

  &:hover {
    img {
      visibility: visible;
    }
  }
}
</style>
