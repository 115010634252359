<template lang="pug">
.estado-erro
  slot(v-if="$slots.default" :mensagemDeErro="mensagemDeErro")
  .estado-erro-padrao(v-else)
    img(src="../../../static/icones/emojiTriste.svg")
    .estado-erro-padrao__texto
      p Desculpe, algo deu errado!
      span {{ mensagemDeErro }}
</template>

<script>
export default {
  name: 'EstadoErro',
  props: {
    mensagemDeErro: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.estado-erro {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);

  &-padrao {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    img {
      width: 53px;
    }

    &__texto {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      color:  #2C3E50;

      p {
        margin: 0;
        font-size: 1rem;
        font-weight: 600;
      }

      span {
        font-size: 14px;
      }
    }
  }
}
</style>
