import { executeShortcut } from 'src/utils'

export default {
  methods: {
    focusRow (rowNumber) {
      const el = document.getElementById(`${this.name}-row-${rowNumber}`).firstChild
      el.focus()
    },
    keyboardEventRow ({event, rowNumber}) {
      const actions = {
        'up': () => {
          const to = rowNumber - 1

          if (to < 0) return this.executeSearchInHeader()
          this.focusRow(to)
        },
        'down': () => {
          const to = rowNumber + 1

          if (to >= this.toDisplay.length) {
            // todo make go to next page
            return
          }
          this.focusRow(to)
        },
        'esc': () => this.closeDetailsBox(),
        'left': () => {
          if (this.currentPage - 1 >= 0) this.changePage(this.currentPage - 1)
        },
        'right': () => {
          if (this.currentPage + 1 < this.totalPages) this.changePage(this.currentPage + 1)
        },
        'left+ctrl': () => {
          this.changePage(0)
          this.$nextTick(() => this.focusRow(0))
        },
        'right+ctrl': () => {
          this.changePage(this.totalPages - 1)
          this.$nextTick(() => this.focusRow(0))
        }
      }

      event.preventDefault()
      event.returnValue = false
      const {executedShortcut} = executeShortcut(event, actions)

      if (!executedShortcut && !event.shiftKey && !event.ctrlKey) {
        // assume that user is trying to search, lets focus in header
        // only use key as search if is a number or letter
        const kc = event.keyCode
        this.executeSearchInHeader(
          (kc > 47 && kc < 58) || (kc === 32) || (kc > 64 && kc < 91) || (kc > 95 && kc < 112)
            ? event.key : ''
        )
      }
    },
    executeSearchInHeader (text) {
      let headerComponent

      if (this.lastSearchedField) {
        headerComponent = this.$children
          .find(i => i.$options.name === 'ColumnHeader' && i.field === this.lastSearchedField)
      } else if (this.mergedConfig.defaultColumnSearch) {
        headerComponent = this.$children
          .find(i => i.$options.name === 'ColumnHeader' && i.field === this.mergedConfig.defaultColumnSearch)
      } else {
        headerComponent = this.$children
          .find(i => i.$options.name === 'ColumnHeader')
      }

      headerComponent.focusSearch(text)
    }
  }
}
