import { formatarDataComPadraoFornecido } from '@/utils/formatadoras/formatadoras.js'
import { multiSelect } from '@/common/selectors/index.js'
import { dadosIniciaisAcompanhamentoEmTempoReal, somarQuantidadesSelecionadas, calcularPorcentagem, converterChavesDeObjeto } from './utils.js'
import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'
import { API } from '@/services.js'
import servicoLocalStorage from 'src/typescript/servicos/localStorage/localStorage.servico'

const defaultState = () => {
  return {
    ...multiSelect.mapStates(['filtroEmpregadores', 'filtroGrupos', 'filtroLocaisDeTrabalho']),
    filtroLideranca: [],
    filtroHierarquia: null,
    carregando: false,
    dadosAcompanhamentoEmTempoReal: dadosIniciaisAcompanhamentoEmTempoReal,
    horarioUltimaAtualizacao: '',
    totalEmpregados: 0
  }
}

const getters = {
  possuiFiltrosSelecionados (state) {
    const possuiEmpregadoresFiltrados = state.filtroEmpregadores.selected.length > 0
    const possuiGruposFiltrados = state.filtroGrupos.selected.length > 0
    const possuiLocaisDeTrabalhoFiltrados = state.filtroLocaisDeTrabalho.selected.length > 0
    const possuiLiderancasFiltradas = state.filtroLideranca.length > 0
    return [possuiEmpregadoresFiltrados, possuiGruposFiltrados, possuiLocaisDeTrabalhoFiltrados, possuiLiderancasFiltradas].some(filtro => Boolean(filtro))
  },
  eventos (state) {
    const eventos = state.dadosAcompanhamentoEmTempoReal.eventos
    const chavesReferentesAosEventos = Object.keys(eventos)

    const dadosEventos = {}

    chavesReferentesAosEventos.forEach(evento => {
      dadosEventos[evento] = {
        total: eventos[evento].total,
        porcentagem: state.totalEmpregados === 0 ? 0 : (eventos[evento].total / state.totalEmpregados) * 100
      }
    })

    return dadosEventos
  },
  dadosPainelTrabalhando (state) {
    const { estados, eventos } = state.dadosAcompanhamentoEmTempoReal
    const totalEmpregados = state.totalEmpregados
    const chavesReferentesAosEstadoTrabalhando = ['trabalhando', 'emPausa', 'aRetornarEmBreve', 'retornoPausaEmAtraso', 'aSairEmBreve', 'emHoraExtra', 'aEstourarLimiteDeHoraExtraEmBreve', 'realizandoHoraExtraAcimaDoLimiteDefinido']
    const chavesReferentesAHoraExtra = ['emHoraExtra', 'aEstourarLimiteDeHoraExtraEmBreve', 'realizandoHoraExtraAcimaDoLimiteDefinido']

    const quantidadeEmpregadosTrabalhando = somarQuantidadesSelecionadas(estados, chavesReferentesAosEstadoTrabalhando)
    const quantidadeEmpregadosEmHoraExtra = somarQuantidadesSelecionadas(estados, chavesReferentesAHoraExtra)

    return {
      entradaAtrasada: {
        total: eventos.entradaAtrasada.total,
        porcentagem: calcularPorcentagem(eventos.entradaAtrasada.total, totalEmpregados)
      },
      aEstourarLimiteDeHoraExtraEmBreve: {
        total: estados.aEstourarLimiteDeHoraExtraEmBreve.total,
        porcentagem: calcularPorcentagem(estados.aEstourarLimiteDeHoraExtraEmBreve.total, totalEmpregados)
      },
      totalHoraExtra: {
        total: quantidadeEmpregadosEmHoraExtra,
        porcentagem: calcularPorcentagem(quantidadeEmpregadosEmHoraExtra, totalEmpregados)
      },
      totalTrabalhando: {
        total: quantidadeEmpregadosTrabalhando,
        porcentagem: calcularPorcentagem(quantidadeEmpregadosTrabalhando, totalEmpregados)
      }
    }
  },
  dadosPainelFaltas (state) {
    const { estados } = state.dadosAcompanhamentoEmTempoReal
    const totalAIniciar = estados.aEntrarEmBreve.total + estados.antesDeTrabalhar.total

    return {
      totalEmpregados: state.totalEmpregados,
      emAtraso: {
        total: estados.emAtraso.total,
        porcentagem: calcularPorcentagem(estados.emAtraso.total, state.totalEmpregados)
      },
      aIniciar: {
        total: totalAIniciar,
        porcentagem: calcularPorcentagem(totalAIniciar, state.totalEmpregados)
      }
    }
  },
  dadosPainelAusentes (state) {
    const { estados, eventos: { ausencias } } = state.dadosAcompanhamentoEmTempoReal
    const totalJornadaEncerrada = estados.trabalhou.total + estados.ausenciaTrabalhada.total
    const totalEmpregados = state.totalEmpregados
    return {
      afastamento: {
        total: ausencias.afastamento.total,
        porcentagem: calcularPorcentagem(ausencias.afastamento.total, totalEmpregados)
      },
      ferias: {
        total: ausencias.ferias.total,
        porcentagem: calcularPorcentagem(ausencias.ferias.total, totalEmpregados)
      },
      dispensa: {
        total: ausencias.dispensa.total,
        porcentagem: calcularPorcentagem(ausencias.dispensa.total, totalEmpregados)
      },
      suspensao: {
        total: ausencias.suspensao.total,
        porcentagem: calcularPorcentagem(ausencias.suspensao.total, totalEmpregados)
      },
      folga: {
        total: ausencias.folga.total,
        porcentagem: calcularPorcentagem(ausencias.folga.total, totalEmpregados)
      },
      faltou: {
        total: ausencias.falta.total,
        porcentagem: calcularPorcentagem(ausencias.falta.total, totalEmpregados)
      },
      trabalhou: {
        total: totalJornadaEncerrada,
        porcentagem: calcularPorcentagem(totalJornadaEncerrada, totalEmpregados)
      }
    }
  }
}

const mutations = {
  ...multiSelect.mapMutations(['filtroEmpregadores', 'filtroGrupos', 'filtroLocaisDeTrabalho']),
  definirFiltroLideranca (state, lideranca) {
    state.filtroLideranca = lideranca
  },
  definirFiltroHierarquia (state, hierarquia) {
    state.filtroHierarquia = hierarquia
  },
  resetState (state) {
    resetarParaEstadoInicial(state, defaultState())
  },
  definirCarregando (state, carregando) {
    state.carregando = carregando
  },
  definirDadosAcompanhamentoEmTempoReal (state, dados) {
    state.dadosAcompanhamentoEmTempoReal = dados
  },
  definirHorarioUltimaAtualizacao (state, data) {
    state.horarioUltimaAtualizacao = formatarDataComPadraoFornecido(data, 'HH:mm:ss')
  },
  definirTotalEmpregados (state, total) {
    state.totalEmpregados = total
  }
}

const actions = {
  async iniciarAcompanhamentoEmTempoReal ({ commit, dispatch }) {
    try {
      commit('definirCarregando', true)

      const filtro = await dispatch('comporFiltroParaPesquisa')
      await dispatch('adicionarFiltroLocalStorage')
      const response = await API.acompanhamentoDiarioGeral.save(filtro)
      commit('definirTotalEmpregados', response.data.total_de_empregados)
      commit('definirHorarioUltimaAtualizacao', new Date())
      commit('definirDadosAcompanhamentoEmTempoReal', converterChavesDeObjeto(response.body))
    } catch (erro) {
      throw erro
    } finally {
      commit('definirCarregando', false)
    }
  },
  comporFiltroParaPesquisa ({ rootState, state }) {
    const idsEmregadores = state.filtroEmpregadores.selected.map(empregador => empregador.id)
    const idsGrupos = state.filtroGrupos.selected.map(grupo => grupo.id)
    const idsLocaisDeTrabalho = state.filtroLocaisDeTrabalho.selected.map(local => local.id)
    const possuiFeatureFlagAcessoPorLideranca = rootState.userInfo.compMan.hasAcessoPorLideranca
    const ehGestor = rootState.userInfo.roleGroup.kind === 'group'
    const estaVinculadoALideranca = rootState.userInfo.acessoPorLideranca

    let filtro = {
      dia: formatarDataComPadraoFornecido(new Date(), 'YYYY-MM-DD')
    }

    if (idsEmregadores.length > 0) {
      filtro['empregadores'] = idsEmregadores
    }

    if (idsGrupos.length > 0) {
      filtro['grupos'] = idsGrupos
    }

    if (idsLocaisDeTrabalho.length > 0) {
      filtro['locaisDeTrabalho'] = idsLocaisDeTrabalho
    }

    if (possuiFeatureFlagAcessoPorLideranca) {
      if (ehGestor && !estaVinculadoALideranca) return filtro
      const filtroHierarquiaTratado = state.filtroHierarquia === 'grupos_associados'
        ? null
        : state.filtroHierarquia
      filtro['usuariosLideres'] = state.filtroLideranca?.map(usuario => usuario.id)
      filtro['modalidadeDeAcesso'] = filtroHierarquiaTratado
    }

    return filtro
  },
  obterChavesLocalStorage () {
    return {
      empregador: 'acompanhamento-filtro-empregadores',
      grupo: 'acompanhamento-filtro-grupos',
      localDeTrabalho: 'acompanhamento-filtro-locais-de-trabalho',
      lideres: 'acompanhamento-filtro-lideres',
      hierarquia: 'acompanhamento-filtro-hierarquia'
    }
  },
  async adicionarFiltroLocalStorage ({ state, dispatch }) {
    const chavesLocalStorage = await dispatch('obterChavesLocalStorage')
    await dispatch('removerFiltroLocalStorage')
    const empregadoresFiltrados = state.filtroEmpregadores.selected
    const gruposFiltrados = state.filtroGrupos.selected
    const locaisDeTrabalhoFiltrados = state.filtroLocaisDeTrabalho.selected
    const lideresFiltrados = state.filtroLideranca
    const hierarquiaFiltrada = state.filtroHierarquia

    if (empregadoresFiltrados.length > 0) {
      const empregadosParaLocalStorage = empregadoresFiltrados.map(empregador => {
        return {
          id: empregador.id,
          name: empregador.name
        }
      })
      servicoLocalStorage.salvarItem(chavesLocalStorage.empregador, empregadosParaLocalStorage)
    }
    if (gruposFiltrados.length > 0) {
      servicoLocalStorage.salvarItem(chavesLocalStorage.grupo, gruposFiltrados)
    }
    if (locaisDeTrabalhoFiltrados.length > 0) {
      const locaisDeTrabalhoParaLocalStorage = locaisDeTrabalhoFiltrados.map(local => {
        return {
          id: local.id,
          name: local.name,
          empregadores: local.empregadores
        }
      }
      )
      servicoLocalStorage.salvarItem(chavesLocalStorage.localDeTrabalho, locaisDeTrabalhoParaLocalStorage)
    }
    if (lideresFiltrados.length > 0) {
      const lideresParaLocalStorage = lideresFiltrados.map(lider => {
        return {
          id: lider.id,
          name: lider.name
        }
      })
      servicoLocalStorage.salvarItem(chavesLocalStorage.lideres, lideresParaLocalStorage)
    }

    if (hierarquiaFiltrada) {
      servicoLocalStorage.salvarItem(chavesLocalStorage.hierarquia, hierarquiaFiltrada)
    }
  },
  async definirFiltroAPartirDoLocalStorage ({ dispatch, commit }) {
    const chavesLocalStorage = await dispatch('obterChavesLocalStorage')
    const filtroEmpregadores = servicoLocalStorage.obterItem(chavesLocalStorage.empregador)
    const filtroGrupos = servicoLocalStorage.obterItem(chavesLocalStorage.grupo)
    const filtroLocaisDeTrabalho = servicoLocalStorage.obterItem(chavesLocalStorage.localDeTrabalho)
    const filtroLideres = servicoLocalStorage.obterItem(chavesLocalStorage.lideres)
    const filtroHierarquia = servicoLocalStorage.obterItem(chavesLocalStorage.hierarquia)

    if (filtroEmpregadores) {
      commit('filtroEmpregadores', { selected: filtroEmpregadores })
    }

    if (filtroGrupos) {
      commit('filtroGrupos', { selected: filtroGrupos })
    }

    if (filtroLocaisDeTrabalho) {
      commit('filtroLocaisDeTrabalho', { selected: filtroLocaisDeTrabalho })
    }

    if (filtroLideres) {
      commit('definirFiltroLideranca', filtroLideres)
    }

    if (filtroHierarquia) {
      commit('definirFiltroHierarquia', filtroHierarquia)
    }
  },
  async verificarFiltrosLocalStorage ({ dispatch }) {
    const chavesLocalStorage = await dispatch('obterChavesLocalStorage')
    const filtroEmpregadores = servicoLocalStorage.obterItem(chavesLocalStorage.empregador) || []
    const filtroGrupos = servicoLocalStorage.obterItem(chavesLocalStorage.grupo) || []
    const filtroLocaisDeTrabalho = servicoLocalStorage.obterItem(chavesLocalStorage.localDeTrabalho) || []
    const filtroLideres = servicoLocalStorage.obterItem(chavesLocalStorage.lideres) || []
    const filtroHierarquia = servicoLocalStorage.obterItem(chavesLocalStorage.hierarquia)

    const filtroParaAcessoPorLideranca = Boolean(filtroLideres.length && filtroHierarquia)

    return Boolean(filtroEmpregadores.length || filtroGrupos.length || filtroLocaisDeTrabalho.length || filtroParaAcessoPorLideranca)
  },
  async removerFiltroLocalStorage ({ dispatch }) {
    const chavesLocalStorage = await dispatch('obterChavesLocalStorage')
    servicoLocalStorage.removerItem(chavesLocalStorage.empregador)
    servicoLocalStorage.removerItem(chavesLocalStorage.grupo)
    servicoLocalStorage.removerItem(chavesLocalStorage.localDeTrabalho)
    servicoLocalStorage.removerItem(chavesLocalStorage.lideres)
    servicoLocalStorage.removerItem(chavesLocalStorage.hierarquia)
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  getters,
  mutations,
  actions
}
