import { groupBy } from 'src/utils'
import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'
import { formatarDataParaEnvio } from 'src/utils/formatadoras/formatadoras'
import {
  vuexMapDefaultsMutations,
  vuexMapDefaultsActions,
  vuexMapDefaultsGetters
} from 'src/utils.store'

import {
  StatusSolicitacao,
  TipoDeEntidade
} from 'src/components/Approvals/utils'

import { definirDataParaConsultarJornadasComProblemas } from '@/components/Home/utils'

import { API } from 'src/services'
import moment from 'moment'

import { FiltradorDeErros } from 'src/erros'

function defaultState () {
  return {
    carregando: false,
    type: 'month',
    startDate: null,
    endDate: null,
    mesReferencia: null,
    company: {
      loaded: [],
      selected: null
    },
    estatisticaJornadasComProblemas: {
      quantidade: 0,
      loading: false,
      error: false
    },
    estatisticaPedidosDeAprovacao: {
      quantidade: 0,
      loading: false,
      error: false
    },
    estatisticaTravamentosPendentes: {
      quantidade: 0,
      loading: false,
      error: false
    },
    registroBancoDeHorasEmpregado: {
      acumuladoPositivo: [],
      acumuladoNegativo: [],
      loading: false,
      error: false
    },
    eventosExcepcionais: {
      eventos: {},
      loading: false,
      error: false
    },
    eventosProgramados: {
      eventos: {},
      loading: false,
      error: false
    }
  }
}

const getters = {
  ...vuexMapDefaultsGetters(defaultState())
}

const mutations = {
  ...vuexMapDefaultsMutations(defaultState()),
  resetState (state) {
    resetarParaEstadoInicial(state, defaultState())
  },
  limparAcumulados (state) {
    state.registroBancoDeHorasEmpregado.acumuladoPositivo = []
    state.registroBancoDeHorasEmpregado.acumuladoNegativo = []
  }
}

const actions = {
  ...vuexMapDefaultsActions(defaultState()),
  async carregarDadosTravamentosPendentes ({commit, state}, parametros) {
    commit('estatisticaTravamentosPendentes', {
      quantidade: state.estatisticaTravamentosPendentes.quantidade,
      loading: true,
      error: false
    })

    try {
      const resultadoAPI = await API.estatisticaDeBloqueio.get({
        startDate: moment(state.startDate).format('YYYY-MM-DD'),
        endDate: moment(state.endDate).format('YYYY-MM-DD'),
        company: parametros.company,
        user: parametros.user,
        comp_man: parametros.comp_man
      })

      const travamentosNaoIniciados = resultadoAPI.body.success.notStarted
      commit('estatisticaTravamentosPendentes', {
        quantidade: travamentosNaoIniciados.length,
        loading: false,
        error: false
      })
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
      commit('estatisticaTravamentosPendentes', {
        quantidade: state.estatisticaTravamentosPendentes.quantidade,
        loading: false,
        error: true
      })
    }
  },
  async carregarDadosDeAprovacao ({commit, state}, parametros) {
    commit('estatisticaPedidosDeAprovacao', {
      quantidade: state.estatisticaPedidosDeAprovacao.quantidade,
      loading: true,
      error: false
    })
    try {
      const response = await API.approvals.save({
        queryKind: 'company',
        idList: [parametros.company],
        modelKind: 'all',
        approveKind: 'pending',
        startDate: moment(state.startDate).toISOString(),
        endDate: moment(state.endDate).toISOString()
      })
      const aprovacoesPendentes = response.body.success

      const resposta = await API.solicitacaoDeAprovacao.save({
        tipoDeEvento: null,
        status: StatusSolicitacao.pendente,
        entidades: {
          tipo: TipoDeEntidade.localDeTrabalho,
          ids: [parametros.company]
        },
        dataInicial: formatarDataParaEnvio(state.startDate),
        dataFinal: formatarDataParaEnvio(state.endDate)
      })

      const aprovacoesNovoFormatoPendentes = resposta.body

      commit('estatisticaPedidosDeAprovacao', {
        quantidade: aprovacoesPendentes.length + aprovacoesNovoFormatoPendentes.totalDeItens,
        loading: false,
        error: false
      })
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
      commit('estatisticaPedidosDeAprovacao', {
        quantidade: state.estatisticaPedidosDeAprovacao.quantidade,
        loading: false,
        error: true
      })
    }
  },
  async carregarDadosJornadasComProblemas ({commit, state}, parametros) {
    commit('estatisticaJornadasComProblemas', {
      quantidade: state.estatisticaJornadasComProblemas.quantidade,
      loading: true,
      error: false
    })

    try {
      const dataHoje = moment()
      const { dataInicio, dataFim } = definirDataParaConsultarJornadasComProblemas(dataHoje, state.startDate, state.endDate)
      const resultadoAPI = await API.timesheetVerify.save({
        companies: [parametros.company],
        onlyTotal: true,
        startDate: dataInicio.format('YYYY-MM-DD'),
        endDate: dataFim.format('YYYY-MM-DD')
      })

      const { totals } = resultadoAPI.body.success

      const jornadaComProblemas = totals?.doubleLines + totals?.oddPts
      commit('estatisticaJornadasComProblemas', {
        quantidade: jornadaComProblemas,
        loading: false,
        error: false
      })
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
      commit('estatisticaJornadasComProblemas', {
        quantidade: state.estatisticaJornadasComProblemas.quantidade,
        loading: false,
        error: true
      })
    }

    commit('carregando', false)
  },
  async carregarEventosProgramados ({commit, state}, parametros) {
    commit('eventosProgramados', {
      eventos: state.eventosProgramados.eventos,
      loading: true,
      error: false
    })
    try {
      const response = await API.estatisticaDiariaDeAusensiaEsperada.get({
        company: parametros.company,
        user: parametros.user,
        comp_man: parametros.comp_man,
        startDate: moment(state.startDate).format('YYYY-MM-DD'),
        endDate: moment(state.endDate).format('YYYY-MM-DD')
      })
      const { expectedAbsence } = response.body.success

      // Férias
      /*
          Será listado no componente a quantidade de Colaboradores de férias no período.
          Saliento que no painel de acompanhamento, é listado a somatória da quantidade
          de dias de férias dos colaboradores
        */
      const ferias = expectedAbsence.filter(item => item.info?.is_vacation === 'True')
      const feriasAgrupadasPorEmpregado = groupBy(ferias, 'employeeId')

      // Dispensas
      const dispensas = expectedAbsence.filter(item => item.info?.justify?.typeId === 'justify')

      // Suspensões
      const suspensoes = expectedAbsence.filter(item => item.info?.justify?.typeId === 'suspension')

      const eventosProgramados = {
        ferias: feriasAgrupadasPorEmpregado.length,
        dispensas: dispensas.length,
        suspensoes: suspensoes.length
      }

      commit('eventosProgramados', {
        eventos: eventosProgramados,
        loading: false,
        error: false
      })
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
      commit('eventosProgramados', {
        eventos: state.eventosProgramados,
        loading: false,
        error: true
      })
    }
  },
  async carregarEventosExcepcionais ({commit, state}, parametros) {
    commit('eventosExcepcionais', {
      eventos: state.eventosExcepcionais.eventos,
      loading: true,
      error: false
    })
    try {
      const response = await API.estatisticaDiariaInesperada.get({
        company: parametros.company,
        user: parametros.user,
        comp_man: parametros.comp_man,
        startDate: moment(state.startDate).format('YYYY-MM-DD'),
        endDate: moment(state.endDate).format('YYYY-MM-DD')
      })

      const { absence, holidayWorked, dayOffWorked } = response.body.success

      const eventosExpecionais = {
        faltas: absence?.length,
        feriadosTrabalhados: holidayWorked?.length,
        folgasTrabalhadas: dayOffWorked?.length
      }
      commit('eventosExcepcionais', {
        eventos: eventosExpecionais,
        loading: false,
        error: false
      })
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
      commit('eventosExcepcionais', {
        eventos: state.eventosExcepcionais.eventos,
        loading: false,
        error: true
      })
    }
  },
  async verificarECarregarBancoDeHoras ({dispatch, rootState}, parametros) {
    const possuiModuloBancoDeHoras = rootState.userInfo.compMan.hasWhbank
    if (!possuiModuloBancoDeHoras) {
      return
    }

    const ehBancoDeHorasNovo = await dispatch('verificarBancoDeHorasNovoOuAntigo')

    if (ehBancoDeHorasNovo === 'erro') return
    const parametrosDaRequisicao = {
      companies: [parametros.company],
      user: parametros.user,
      comp_man: parametros.comp_man,
      showResignated: false
    }

    if (ehBancoDeHorasNovo) {
      await dispatch('carregarDadosBancosDeHorasNovo', parametrosDaRequisicao)
    } else {
      await dispatch('carregandoDadosBancoDeHorasAntigo', parametrosDaRequisicao)
    }
  },
  async carregarDadosBancosDeHorasNovo ({state, commit}, parametrosDaRequisicao) {
    commit('registroBancoDeHorasEmpregado', {
      acumuladoPositivo: state.registroBancoDeHorasEmpregado.acumuladoPositivo,
      acumuladoNegativo: state.registroBancoDeHorasEmpregado.acumuladoNegativo,
      loading: true,
      error: false
    })
    const params = {
      ...parametrosDaRequisicao,
      startDate: moment(state.startDate).format('YYYY-MM-DD'),
      endDate: moment(state.endDate).format('YYYY-MM-DD')
    }
    try {
      const response = await API.whbRulesManagement.save(params)

      if (response.status === 200) {
        const bancoPorEmpregado = response.body.success.duePerEmployees
        const acumuladoTotal = bancoPorEmpregado.map(acTotal => {
          return {
            idEmpregado: acTotal.employee.id,
            empregado: acTotal.employee.name,
            horas: acTotal.accumulatedTotal
          }
        })

        const acumuladoNegativo = acumuladoTotal.filter(obj => obj.horas.charAt(0) === '-')
        const acumuladoPositivo = acumuladoTotal.filter(obj => obj.horas.charAt(0) !== '-')

        commit('registroBancoDeHorasEmpregado', {
          acumuladoPositivo: acumuladoPositivo,
          acumuladoNegativo: acumuladoNegativo,
          loading: false,
          error: false
        })
      }
    } catch (erro) {
      const erroRegraDeBancoDeHoras = erro.body.erro === 'Empregado não possui uma regra de banco de horas.'
      const erroRegraDeCompensacaoPorJanela = erro.body.error === 'Empregado não possui uma regra de compensação por janela.'
      if (erroRegraDeBancoDeHoras || erroRegraDeCompensacaoPorJanela) {
        commit('registroBancoDeHorasEmpregado', {
          acumuladoPositivo: state.registroBancoDeHorasEmpregado.acumuladoPositivo,
          acumuladoNegativo: state.registroBancoDeHorasEmpregado.acumuladoNegativo,
          loading: false,
          error: false
        })
        return
      }
      FiltradorDeErros.capturarErro(erro)
      commit('registroBancoDeHorasEmpregado', {
        acumuladoPositivo: state.registroBancoDeHorasEmpregado.acumuladoPositivo,
        acumuladoNegativo: state.registroBancoDeHorasEmpregado.acumuladoNegativo,
        loading: false,
        error: true
      })
    }
  },
  async carregandoDadosBancoDeHorasAntigo ({state, commit}, parametrosDaRequisicao) {
    commit('registroBancoDeHorasEmpregado', {
      acumuladoPositivo: state.registroBancoDeHorasEmpregado.acumuladoPositivo,
      acumuladoNegativo: state.registroBancoDeHorasEmpregado.acumuladoNegativo,
      loading: true,
      error: false
    })
    const params = {
      ...parametrosDaRequisicao,
      startDate: moment(state.startDate).format('YYYY-MM-DD')
    }
    try {
      const response = await API.whboManagerNew.save(params)

      if (response.status === 200) {
        const infoEmpregados = response.body.success
        const bancoDeHorasEmpregados = infoEmpregados.map(empregado => {
          return {
            idEmpregado: empregado.employee.id,
            empregado: empregado.employee.name,
            horas: empregado.hours_formatted_monthly.all_times
          }
        })

        const acumuladoNegativo = bancoDeHorasEmpregados.filter(obj => obj.horas.charAt(0) === '-')
        const acumuladoPositivo = bancoDeHorasEmpregados.filter(obj => obj.horas.charAt(0) !== '-')

        commit('registroBancoDeHorasEmpregado', {
          acumuladoPositivo: acumuladoPositivo,
          acumuladoNegativo: acumuladoNegativo,
          loading: false,
          error: false
        })
      }
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
      commit('registroBancoDeHorasEmpregado', {
        acumuladoPositivo: state.registroBancoDeHorasEmpregado.acumuladoPositivo,
        acumuladoNegativo: state.registroBancoDeHorasEmpregado.acumuladoNegativo,
        loading: false,
        error: true
      })
    }
  },
  async verificarBancoDeHorasNovoOuAntigo ({commit, state}) {
    commit('registroBancoDeHorasEmpregado', {
      acumuladoPositivo: state.registroBancoDeHorasEmpregado.acumuladoPositivo,
      acumuladoNegativo: state.registroBancoDeHorasEmpregado.acumuladoNegativo,
      loading: true,
      error: false
    })

    try {
      const chamadaAPI = await API.whbCount.get({})

      let verificaSeEhNovoBanco = false

      if (chamadaAPI.status === 200) {
        const resultado = chamadaAPI.body.success
        verificaSeEhNovoBanco = Boolean(resultado.new !== 0)
      }

      return verificaSeEhNovoBanco
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
      commit('registroBancoDeHorasEmpregado', {loading: false, error: true})
      return 'erro'
    }
  },
  async carregarTodasEstatisticasHome ({commit, dispatch}, parametros) {
    Promise.allSettled([
      dispatch('carregarDadosTravamentosPendentes', parametros),
      dispatch('carregarDadosJornadasComProblemas', parametros),
      dispatch('verificarECarregarBancoDeHoras', parametros),
      dispatch('carregarEventosExcepcionais', parametros),
      dispatch('carregarEventosProgramados', parametros),
      dispatch('carregarDadosDeAprovacao', parametros)
    ])
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  getters,
  mutations,
  actions
}
