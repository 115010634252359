import moment from 'moment'

export const verificarSeDataEstaEntreOuEhIgual = (dataReferencia, dataComparativaInicio, dataComparativaFim, paramentro) => {
  const ehIgualInicio = moment(dataReferencia).isSame(dataComparativaInicio, paramentro)
  const ehIgualFim = moment(dataReferencia).isSame(dataComparativaFim, paramentro)
  const estaEntre = moment(dataReferencia).isBetween(dataComparativaInicio, dataComparativaFim, [])

  return [ehIgualInicio, ehIgualFim, estaEntre].some(data => data)
}
export const definirPrevisaoPadraoParaFerias = (informacoesFerias) => {
  const { start, end, total } = informacoesFerias
  const dataInicio = moment(start).format('DD.MM.YYYY')
  const dataFim = moment(end).format('DD.MM.YYYY')
  const totalDias = `${total} dias`
  const previsaoFerias = {
    tipo: 'ferias',
    icone: 'ferias',
    texto: 'você está de <strong>férias</strong> entre os dias',
    detalhes: `<strong>${dataInicio} até ${dataFim} | ${totalDias}</strong>`
  }
  return previsaoFerias
}
export const definirPrevisaoPadraoParaAfastamento = (informacoesAfastamento) => {
  const { start, end, total } = informacoesAfastamento
  const dataInicio = moment(start).format('DD.MM.YYYY')
  const dataFim = end === 'indeterminado' ? end : moment(end).format('DD.MM.YYYY')
  const totalDias = total === '-' ? total : `${total} dias`
  const detalhes = end === 'indeterminado'
    ? `<strong>${dataInicio} até ${dataFim}</strong>`
    : `<strong>${dataInicio} até ${dataFim} | ${totalDias}</strong>`
  const previsaoAfastamento = {
    tipo: 'afastamento',
    icone: end === 'indeterminado' ? 'afastamento indeterminado' : 'afastamento',
    texto: 'você está de <strong>afastamento</strong> entre os dias',
    detalhes: detalhes
  }

  return previsaoAfastamento
}
export const definirPrevisaoPadraoSemDiaDeTrabalho = (tipoJornada, detalhesJornada) => {
  const jornadaFeriado = {
    tipo: 'feriado',
    icone: 'feriado',
    texto: 'você está de <strong>folga</strong> hoje',
    horario: '',
    horarioDecorrido: '',
    detalhes: ''
  }
  const jornadaSuspensao = {
    tipo: 'suspensao',
    icone: 'suspensao',
    texto: 'você está <strong>suspenso</strong> hoje',
    horario: '',
    horarioDecorrido: '',
    detalhes: ''
  }
  const jornadaDispensa = {
    tipo: 'dispensa',
    icone: 'dispensa',
    texto: 'você está <strong>dispensado</strong> hoje',
    horario: '',
    horarioDecorrido: '',
    detalhes: detalhesJornada?.description
  }
  const jornadaFolga = {
    tipo: 'folga',
    icone: 'folga',
    texto: 'você está de <strong>folga</strong> hoje',
    horario: '',
    horarioDecorrido: '',
    detalhes: ''
  }
  const semJornada = {
    tipo: 'sem-jornada',
    icone: 'sem-jornada',
    texto: 'você tem uma <strong>jornada sem trabalho</strong>',
    horario: '',
    horarioDecorrido: '',
    detalhes: ''
  }

  return {
    'feriado': jornadaFeriado,
    'suspensao': jornadaSuspensao,
    'dispensa': jornadaDispensa,
    'folga': jornadaFolga,
    'sem jornada': semJornada
  }[tipoJornada]
}
export const formatarInformacoesDaJornada = (jornada) => {
  const diaDaJornada = moment(jornada.day)
  const inicioJornada = moment(jornada.journey.start)
  const pausaJornada = moment(jornada.journey.stPause)
  const retornoJornada = moment(jornada.journey.endPause)
  const fimJornada = moment(jornada.journey.end)

  const duracaoJornada = fimJornada.diff(inicioJornada)
  const duracaoPausa = retornoJornada.diff(pausaJornada)

  const duracaoJornadaFinal = moment.duration(duracaoJornada - duracaoPausa)
  const duracaoJornadaFinalHoras = Math.floor(duracaoJornadaFinal.asHours())
  const duracaoJornadaFinalMinutos = duracaoJornadaFinal.minutes()

  const duracaoJornadaFinalFormatada = duracaoJornadaFinalMinutos
    ? `${duracaoJornadaFinalHoras}h${duracaoJornadaFinalMinutos}min`
    : `${duracaoJornadaFinalHoras}h`

  const duracaoPausaFinalHoras = Math.floor(moment.duration(duracaoPausa).asHours())
  const duracaoPausaFinalMinutos = moment.duration(duracaoPausa).minutes()

  const duracaoPausaFormatada = duracaoPausaFinalHoras
    ? duracaoJornadaFinalMinutos
      ? `${duracaoPausaFinalHoras}h${duracaoPausaFinalMinutos}min`
      : `${duracaoPausaFinalHoras}h`
    : `${duracaoPausaFinalMinutos}min`

  return {
    dia: diaDaJornada,
    inicio: inicioJornada,
    pausa: pausaJornada,
    retorno: retornoJornada,
    fim: fimJornada,
    duracaoJornada: duracaoJornadaFinalFormatada,
    duracaoPausa: duracaoPausaFormatada
  }
}
export const definirPrevisaoPadraoEmDiaDeTrabalho = (tipoPrevisao, mostrarInicioEFimJornada, informacoesDaJornada) => {
  const definirPrevisaoDeEntrada = () => {
    const horarioEntradaFormatado = informacoesDaJornada?.inicio?.format('HH:mm')
    const tipo = mostrarInicioEFimJornada ? 'entrada' : 'flexivel'
    const icone = mostrarInicioEFimJornada ? 'entrada' : 'relogio'
    const texto = mostrarInicioEFimJornada
      ? 'seu ponto de <strong>entrada</strong> está previsto para hoje às'
      : '<strong>duração</strong> da sua jornada de hoje'
    const horario = mostrarInicioEFimJornada
      ? horarioEntradaFormatado
      : informacoesDaJornada?.duracaoJornada
    const previsaoEntrada = {
      tipo: tipo,
      icone: icone,
      texto: texto,
      horario: horario
    }

    return previsaoEntrada
  }
  const definirPrevisaoDeEntradaAtrasada = () => {
    const diaCorrenteHoras = moment(new Date()).hours()
    const diaCorrenteMinutos = moment(new Date()).minutes()
    const horarioEntradaHoras = moment(informacoesDaJornada?.inicio).hours()
    const horarioEntradaMinutos = moment(informacoesDaJornada?.inicio).minutes()

    const atrasoEmHoras = diaCorrenteHoras - horarioEntradaHoras
    const atrasoEmMinutos = diaCorrenteMinutos - horarioEntradaMinutos
    const atrasoEmHorasFormatado =
        atrasoEmHoras.toString().length === 1 ? `0${atrasoEmHoras}` : atrasoEmHoras
    const atrasoEmMinutosFormatado =
        atrasoEmMinutos.toString().length === 1 ? `0${atrasoEmMinutos}` : atrasoEmMinutos
    const atrasoFormatado = `${atrasoEmHorasFormatado}:${atrasoEmMinutosFormatado}`
    const tipo = mostrarInicioEFimJornada
      ? 'atraso'
      : 'flexivel'
    const icone = mostrarInicioEFimJornada ? 'entrada' : 'relogio'
    const texto = mostrarInicioEFimJornada
      ? 'atraso em relação a sua <strong>entrada</strong>'
      : '<strong>duração</strong> da sua jornada de hoje'
    const horario = mostrarInicioEFimJornada
      ? atrasoFormatado
      : informacoesDaJornada?.duracaoJornada

    const previsaoEntradaAtrasada = {
      tipo: tipo,
      icone: icone,
      texto: texto,
      horario: horario
    }

    return previsaoEntradaAtrasada
  }
  const definirPrevisaoDeRetorno = () => {
    const previsaoDeRetorno = {
      tipo: 'retorno',
      icone: 'retorno',
      texto: 'quando devo bater o ponto de <strong>retorno</strong>?',
      horario: moment(informacoesDaJornada).format('HH:mm')
    }

    return previsaoDeRetorno
  }
  const definirPrevisaoDeRetornoAtrasado = () => {
    const previsaoRetornoAtrasado = {
      tipo: 'atraso',
      icone: 'retorno',
      texto: 'você excedeu seu <strong>horário de pausa</strong> às',
      horario: moment(informacoesDaJornada).format('HH:mm')
    }

    return previsaoRetornoAtrasado
  }
  const definirPrevisaoDeSaida = () => {
    const previsaoDeSaida = {
      tipo: 'saida',
      icone: 'saida',
      texto: 'quando devo bater o ponto de <strong>saída</strong>?',
      horario: moment(informacoesDaJornada).format('HH:mm')
    }

    return previsaoDeSaida
  }
  const definirPrevisaoDeSaidaAtrasada = () => {
    const previsaoDeSaidaAtrasada = {
      tipo: 'atraso',
      icone: 'saida',
      texto: 'você está fazendo <strong>horas extras</strong> desde',
      horario: moment(informacoesDaJornada).format('HH:mm')
    }

    return previsaoDeSaidaAtrasada
  }

  return {
    'entrada': definirPrevisaoDeEntrada(),
    'entrada atrasada': definirPrevisaoDeEntradaAtrasada(),
    'retorno': definirPrevisaoDeRetorno(),
    'retorno atrasado': definirPrevisaoDeRetornoAtrasado(),
    'saida': definirPrevisaoDeSaida(),
    'saida atrasada': definirPrevisaoDeSaidaAtrasada()
  }[tipoPrevisao]
}

export const definirPrevisaoPadraoProximaJornada = (tipoPrevisao, mostrarInicioEFimJornada, informacoesDaProximaJornada) => {
  const definirPrevisaoComJornada = () => {
    const tipo = mostrarInicioEFimJornada
      ? 'entrada'
      : 'flexivel'
    const icone = mostrarInicioEFimJornada
      ? 'entrada'
      : 'relogio'
    const texto = mostrarInicioEFimJornada
      ? 'sua próxima <strong>entrada</strong> está prevista para'
      : '<strong>duraçao</strong> da sua próxima jornada'
    const horario = mostrarInicioEFimJornada
      ? `${informacoesDaProximaJornada?.inicio?.format('HH:mm')} | ${informacoesDaProximaJornada?.dia?.format('DD.MM.YYYY')}`
      : informacoesDaProximaJornada?.duracaoJornada
    const previsaoComJornada = {
      tipo: tipo,
      icone: icone,
      texto: texto,
      horario: horario
    }

    return previsaoComJornada
  }

  const definirPrevisaoSemJornada = () => {
    const previsaoSemJornada = {
      tipo: 'erro',
      icone: 'suspensao',
      texto: 'sem jornada nos próximos 45 dias'
    }

    return previsaoSemJornada
  }
  return {
    'possui jornada': definirPrevisaoComJornada(),
    'sem jornada': definirPrevisaoSemJornada()
  }[tipoPrevisao]
}

export const definirDataDeInicioEFimDeFolha = (dataInicioStore, dataFimStore, dataComponente, callBackParaAlterarDatasNaStore) => {
  if (dataComponente) {
    const dataInicio = dataInicioStore.clone()
    const dataFim = dataFimStore.clone()
    const dataSelecionada = moment(dataComponente).set('date', dataFim.date())
    const dataSelecionadaEhDiaUm = moment(dataInicio).date() === 1
    let novaDataInicio
    let novaDataFim

    if (dataSelecionadaEhDiaUm) {
      const dataReferencia = moment(dataComponente)
      novaDataInicio = moment(dataReferencia).startOf('month')
      novaDataFim = moment(dataReferencia).endOf('month')
      callBackParaAlterarDatasNaStore(novaDataInicio, novaDataFim)
      return
    }

    const diferencaMeses = dataFim.diff(dataSelecionada, 'month')
    const diferencaEhNegativa = Math.sign(diferencaMeses) === -1
    const diferencaTratadaParaNumeroPositivo = Math.abs(diferencaMeses)

    if (diferencaEhNegativa) {
      novaDataInicio = dataInicio.add(diferencaTratadaParaNumeroPositivo, 'month')
      novaDataFim = dataFim.add(diferencaTratadaParaNumeroPositivo, 'month')
      callBackParaAlterarDatasNaStore(novaDataInicio, novaDataFim)
    } else {
      novaDataInicio = dataInicio.subtract(diferencaTratadaParaNumeroPositivo, 'month')
      novaDataFim = dataFim.subtract(diferencaTratadaParaNumeroPositivo, 'month')
      callBackParaAlterarDatasNaStore(novaDataInicio, novaDataFim)
    }
  }
}
