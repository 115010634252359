var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", {
    staticClass: "skeleton-box",
    style: { margin: _vm.margin, height: _vm.height, width: _vm.computedWidth },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }