<template lang="pug">
  div
    ul
      li(@click="goTo(0)")
        a <<

      li(@click="goTo(currentPage - 1)")
        a <

      li(v-for="page in pages", :class="{active: page - 1 === currentPage}", @click="goTo(page - 1)")
        a {{ page }}

      template(v-if="!pages.includes(totalPages - 1)")
        li
          a ...
        li(@click="goTo(totalPages - 1)")
          a {{ totalPages }}

      li(@click="goTo(currentPage + 1)")
        a >

      li(@click="goTo(totalPages - 1)")
        a >>

</template>

<script>
export default {
  name: 'custom-paginate',
  props: {
    currentPage: {required: true, type: Number},
    totalPages: {required: true, type: Number},
    numberItemsDisplay: {'default': 10, type: Number}
  },
  computed: {
    pages () {
      return pagenation(this.currentPage + 1, this.totalPages, this.numberItemsDisplay)
    }
  },
  methods: {
    goTo (number) {
      if (number >= 0 && number < this.totalPages) {
        this.$emit('goToPage', number)
      }
    }
  }
}

/* eslint-disable */
function pagenation (current, total, pageLimit){
  var list = [];
  var upperLimit, lowerLimit;
  var currentPage = lowerLimit = upperLimit = Math.min(current, total);

  for (var b = 1; b < pageLimit && b < total;) {
    if (lowerLimit > 1 ) {
      lowerLimit--; b++;
    }
    if (b < pageLimit && upperLimit < total) {
      upperLimit++; b++;
    }
  }

  for (var i = lowerLimit; i <= upperLimit; i++) {
    list.push(i);
  }
  return list;
}
/* eslint-enable */
</script>

<style scoped lang="scss">
div {
  ul {
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    list-style-type: none;
    display: grid;
    grid-auto-columns: min-content;
    grid-auto-flow: column;

    li {
      cursor: pointer;
      padding: 3px;
      margin: 0 2px 0 2px;
      width: min-content;
      user-select: none;

      a {
        color: darkgray;
        transition: background-color 0.1s linear;
      }

      transition: background-color 0.1s linear;

      &.active {
        font-weight: bold;
        background-color: transparent !important;
        a {
          color: var(--color-primary);
        }
      }
      &:hover:not(.active) {
        background-color: var(--color-primary);

        a {
          color: var(--color-primary-contrast);
        }
      }
    }
  }
}
</style>
