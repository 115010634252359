import { copiarObjetoProfundamente, congelarObjetoProfundamente } from 'src/utils/formatadoras/formatadoras'
import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'

const defaultState = () => ({
  paginationInfo: null,
  companiesSums: null
})

const __state = defaultState()

const getters = {
}

const mutations = {
  resetState (state) {
    resetarParaEstadoInicial(state, defaultState())
  },
  paginationInfo (state, paginationInfo) {
    state.paginationInfo = copiarObjetoProfundamente(congelarObjetoProfundamente(paginationInfo))
  },
  companiesSums (state, payload) {
    state.companiesSums = payload
  }
}

const actions = {
}

export default {
  namespaced: true,
  state: __state,
  getters,
  mutations,
  actions
}
