import moment from 'moment'

const state = {
  reportLoading: false,
  lastNotification: null
}

const mutations = {
  fireReportLoading: (state) => {
    state.reportLoading = !state.reportLoading
  },
  setLastNotificationNow: (state) => {
    state.lastNotification = moment().format('YYYY-MM-DD HH:mm')
  }
}

export default {
  namespaced: true,
  state,
  // getters,
  mutations
  // actions,
}
