var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-input-hour-minute" },
    [
      _c(
        "PTextField",
        _vm._g(
          _vm._b(
            {
              directives: [
                {
                  name: "mask",
                  rawName: "v-mask",
                  value: "##:##",
                  expression: "'##:##'",
                },
              ],
              ref: "input",
              attrs: {
                label: _vm.label,
                name: _vm.name,
                type: "text",
                disabled: _vm.disabled,
                error: _vm.errorMsg || _vm.error,
              },
              on: { focus: _vm.onFocus, input: _vm.onChange },
              model: {
                value: _vm.inputValue,
                callback: function ($$v) {
                  _vm.inputValue = $$v
                },
                expression: "inputValue",
              },
            },
            "PTextField",
            _vm.localAttributes,
            false
          ),
          _vm.localListeners
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }