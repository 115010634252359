import _Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './services'
import 'src/common/globalCommon'
import swal from 'sweetalert2'
import i18n from './i18n'
import { utilizarSentry } from './sentry.js'
import { FiltradorDeErrosPlugin } from 'src/erros'
import servicoLocalStorage from './typescript/servicos/localStorage/localStorage.servico'

// utils
import { modalDeSucesso, modalDeErro, modalDeInformacao, modalDeConfirmacao, modalDeAlerta } from 'src/utils'

// CSS
import '../static/css/general.css'
import '../static/css/transitions.css'
import '../static/css/animations.css'

import '../static/icons-antigo/style.css'

import '../static/icones/linha.css'
import '../static/icones/chapado.css'
import '../static/icones/utilitarios.css'

import '../static/css/onlyNecessaryBootstrap/bootstrap.css'
import '../node_modules/sweetalert2/dist/sweetalert2.css'
import '../static/css/variables.css'
import 'viewerjs/dist/viewer.css'

// Third party components
import * as uiv from 'uiv'
import VModal from 'vue-js-modal'
import vClickOutside from 'v-click-outside'
import VueTheMask from 'vue-the-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import Viewer from 'v-viewer'
import Donut from 'vue-css-donut-chart'
import 'vue-css-donut-chart/dist/vcdonut.css'

// Migration stuff
import localeMoment from '../static/js/pt-br'
import moment from 'moment'
import './directives'
import './filters'
import vueShortkey from 'vue-shortkey'
import Validations from 'vuelidate'
import { VNode } from 'vue/types/umd'

import Formbricks from './typescript/formbricks/formbricks'

// import { ServicoHttpCondominio } from '@/typescript/servicos/http/http.servico'

_Vue.use(Validations)

_Vue.use(vueShortkey, { prevent: ['input', 'textarea'] })
/* eslint-disable  @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    jQuery: any;
  }
}
/* eslint-enable  @typescript-eslint/no-explicit-any */
window.jQuery = require('jquery')
moment.updateLocale('pt-br', localeMoment)

_Vue.use(uiv, { prefix: 'uiv' })
_Vue.use(VModal)

_Vue.use(VueTheMask)

_Vue.use(vClickOutside)

_Vue.use(Donut)

_Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAlUctfv8CKCv29n339XstjTu8gvmXd8lw',
    libraries: 'places'
  }
})

export const formbricksGlobal = new Formbricks()

/* eslint-disable  @typescript-eslint/no-explicit-any */
const swalPlugin = {
  install (Vue: typeof _Vue, options?: any): void {
    Vue.prototype.$swal = swal
    Vue.prototype.$modalDeSucesso = modalDeSucesso
    Vue.prototype.$modalDeErro = modalDeErro
    Vue.prototype.$modalDeInformacao = modalDeInformacao
    Vue.prototype.$modalDeConfirmacao = modalDeConfirmacao
    Vue.prototype.$modalDeAlerta = modalDeAlerta
    Vue.prototype.$servicoLocalStorage = servicoLocalStorage
  }
}
/* eslint-enable  @typescript-eslint/no-explicit-any */
_Vue.use(swalPlugin)

_Vue.use(Viewer)

_Vue.config.productionTip = false

_Vue.use(FiltradorDeErrosPlugin)

utilizarSentry(_Vue, router)

new _Vue({
  router,
  i18n,
  store,
  render (h): VNode {
    return h(App)
  }
}).$mount('#app')

// export const servicoHttpCondominio = new ServicoHttpCondominio(store)
