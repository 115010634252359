import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'
import {
  vuexMapDefaultsMutations
} from 'src/utils.store'

import { API } from 'src/services'

function defaultState () {
  return {
    limits: [],
    apontamentos: [],
    aprovadores: []
  }
}

const getters = {}

const mutations = {
  ...vuexMapDefaultsMutations(defaultState()),
  resetState (state) {
    resetarParaEstadoInicial(state, defaultState())
  },
  atualizaRegraDeApontamento (state, {indice, apontamento, comparacao, min, max}) {
    if (apontamento) state.limits[indice].apontamento = apontamento
    if (comparacao) state.limits[indice].comparacao = comparacao
    if (min) state.limits[indice].min = min
    if (max) state.limits[indice].max = max
  }
}

const actions = {
  async carregaNomeDosApontamentos ({ commit }, { idRegraDeCalculo }) {
    const resultado = await API.customRules.get({id: idRegraDeCalculo})
    const nomeDosApontamentos = resultado.body.success[0].titles
    const apontamentosFormatados = Object.keys(nomeDosApontamentos).map(key => {
      return {
        id: key.replace('show', ''),
        name: nomeDosApontamentos[key]['text']
      }
    })
    commit('apontamentos', apontamentosFormatados)
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  mutations,
  actions,
  getters
}
