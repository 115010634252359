var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "checkbox-with-input" }, [
    _c(
      "label",
      _vm._g(
        { staticClass: "p-checkbox p-checkbox__label" },
        _vm.localListeners
      ),
      [
        _c(
          "input",
          _vm._b(
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.state.check,
                  expression: "state.check",
                },
              ],
              staticClass: "p-checkbox__input",
              class: _vm.classInput,
              attrs: {
                type: "checkbox",
                disabled: _vm.disabled || _vm.loading || _vm.readonly,
                readonly: "",
              },
              domProps: {
                checked: Array.isArray(_vm.state.check)
                  ? _vm._i(_vm.state.check, null) > -1
                  : _vm.state.check,
              },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  _vm.state = !_vm.state
                },
                change: function ($event) {
                  var $$a = _vm.state.check,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(_vm.state, "check", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.state,
                          "check",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.state, "check", $$c)
                  }
                },
              },
            },
            "input",
            _vm.localAttributes,
            false
          )
        ),
        _c("div", { staticClass: "p-checkbox__indicator" }),
        _c("span", { class: _vm.classLabel }, [
          _vm._v(_vm._s(_vm.labelPrimeiraParte)),
        ]),
      ]
    ),
    _c("div", { staticClass: "text-input" }, [
      _c(
        "div",
        {
          staticClass: "p-textfield",
          class: { "icon-slot": _vm.$slots.icon && _vm.$slots.icon.length },
        },
        [
          _c(
            "input",
            _vm._g(
              _vm._b(
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.state.limit,
                      expression: "state.limit",
                      modifiers: { number: true },
                    },
                  ],
                  ref: "input",
                  staticClass: "p-textfield__input",
                  class: { error: _vm.error },
                  style: _vm.hideLabel ? { paddingTop: "0" } : {},
                  attrs: {
                    disabled: _vm.disabled || _vm.loading || !_vm.state.check,
                    type: _vm.type,
                    autocomplete: _vm.autocomplete,
                    spellcheck: "false",
                  },
                  domProps: { value: _vm.state.limit },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.state, "limit", _vm._n($event.target.value))
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                },
                "input",
                _vm.localAttributes,
                false
              ),
              _vm.localListeners
            )
          ),
          _c("label", {
            staticClass: "p-textfield__title",
            class: { error: _vm.error },
            attrs: { for: "textfield" },
          }),
          _vm.$slots.icon && _vm.$slots.icon.length
            ? _c(
                "span",
                { staticClass: "p-textfield__icon--span" },
                [_vm._t("icon")],
                2
              )
            : _vm._e(),
          _vm.copyicon
            ? _c(
                "span",
                {
                  staticClass: "p-textfield__icon--span-copy",
                  on: { click: _vm.copyToClipBoard },
                },
                [
                  _c(
                    "tooltip",
                    {
                      attrs: { text: "copiado", trigger: "manual" },
                      model: {
                        value: _vm.showTooltip,
                        callback: function ($$v) {
                          _vm.showTooltip = $$v
                        },
                        expression: "showTooltip",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "p-textfield__icon",
                        attrs: {
                          src: require("../../../static/images/copy_content.svg"),
                        },
                      }),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]),
    _c(
      "label",
      _vm._g(
        { staticClass: "p-checkbox p-checkbox__label" },
        _vm.localListeners
      ),
      [
        _c("span", { class: _vm.classLabel }, [
          _vm._v(_vm._s(_vm.labelSegundaParte)),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }