import { Store } from 'vuex'

export abstract class ServicoAbstrato<Repositorio, State = null> {
  protected repositorio: Repositorio
  store?: Store<State>

  constructor (repositorio: Repositorio, store?: Store<State>) {
    this.repositorio = repositorio
    this.store = store
  }
}
