<template lang="pug">
.estado-vazio
  slot(v-if="$slots.default")
  .estado-vazio-padrao(v-else)
    img(src="../../../static/icones/caixaVazia.svg")
    p oops... nenhum resultado encontrado
</template>

<script>
export default {
  name: 'EstadoVazio'
}
</script>

<style lang="scss" scoped>
.estado-vazio {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);

  &-padrao {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    img {
      width: 60px;
    }

    p {
      color:  #2C3E50;
      margin: 0;
      font-size: 1rem;
      font-weight: 600;
    }
  }
}
</style>
