import simpleChildrenWrapper from 'src/router/wrapper'

const ListagemDeColetores = () => import('./Telas/ListagemDeColetores.vue')
const CriacaoEdicaoDePerfil = () => import('./Componentes/Perfil/CriarOuEditarPerfil.vue')

export default [
  {
    path: '/coletores',
    name: 'coletores',
    component: simpleChildrenWrapper,
    children: [
      {
        path: 'listar',
        name: 'coletores.listar',
        component: ListagemDeColetores,
        meta: {title: 'Coletores'}
      },
      {
        path: 'perfil',
        name: 'perfil',
        component: simpleChildrenWrapper,
        children: [
          {
            path: 'criar',
            name: 'perfil.criar',
            component: CriacaoEdicaoDePerfil,
            meta: {title: 'Novo perfil'}
          },
          {
            path: ':id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})',
            name: 'perfil.editar',
            props: true,
            component: CriacaoEdicaoDePerfil,
            meta: {title: 'Editar perfil'}
          }
        ]
      }
    ]
  }
]
