/* import ErrosDeNavegador */
import {
  ErroNavegadorNaoSuportaNotificacoes,
  ErroNavegadorNaoSuportaGeolocalizacao
} from 'src/tipos/erros/navegador'
/* import ErrosDeServidor */
/* import ErrosDeUsuario */
import {
  ErroUsuarioNaoPermitiuNotificacoes
} from 'src/tipos/erros/usuario'

export {
  ErroNavegadorNaoSuportaNotificacoes,
  ErroNavegadorNaoSuportaGeolocalizacao,
  ErroUsuarioNaoPermitiuNotificacoes
}
