var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass: "fs-13 color-danger error-msg",
      staticStyle: { height: "13px", width: "100%" },
    },
    [_vm._v(_vm._s(_vm.props.error))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }