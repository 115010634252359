var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-select" },
    [
      _c(
        "multiselect",
        _vm._b(
          {
            ref: "multiselect",
            class: { error: _vm.error, required: _vm.required },
            attrs: {
              options: _vm.customOptions,
              placeholder: _vm.title,
              "preserve-search": true,
              "close-on-select": false,
              "clear-on-select": false,
              optionsLimit: _vm.limit,
              "track-by": _vm.trackBy,
              label: _vm.labelItem,
              loading: _vm.objectLoading || _vm.commonLoading,
              openDirection: _vm.openDirection,
              selectLabel: "",
              deselectLabel: "",
              selectedLabel: "",
              closeOnSelect: true,
              disabled: _vm.disabled,
              internalSearch: false,
              id: "multiselect",
            },
            on: {
              select: (option, id) => _vm.$emit("select", option, id),
              "search-change": _vm.changeSearch,
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function ({ values, search, isOpen }) {
                  return [
                    !isOpen
                      ? _c("div", [
                          values.length > 1
                            ? _c(
                                "span",
                                { staticClass: "multiselect__single" },
                                [
                                  _vm._v(
                                    _vm._s(values.length) +
                                      " itens selecionados"
                                  ),
                                ]
                              )
                            : values.length === 1
                            ? _c(
                                "span",
                                { staticClass: "multiselect__single" },
                                [_vm._v(_vm._s(values[0][_vm.labelItem]))]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "tag",
                fn: function ({ option, remove }) {
                  return [
                    _c("span", {
                      staticClass: "custom__tag",
                      staticStyle: { display: "none" },
                    }),
                  ]
                },
              },
              {
                key: "option",
                fn: function (props) {
                  return [
                    _c(
                      "span",
                      {
                        attrs: {
                          title: props.option[_vm.labelItem],
                          "aria-label": props.option[_vm.labelItem],
                        },
                      },
                      [_vm._v(_vm._s(props.option[_vm.labelItem]))]
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.parsedState,
              callback: function ($$v) {
                _vm.parsedState = $$v
              },
              expression: "parsedState",
            },
          },
          "multiselect",
          _vm.$attrs,
          false
        ),
        [
          _c("template", { slot: "logoIcon" }, [_vm._t("logoIcon")], 2),
          _vm.mostrarBotaoCarregarMais
            ? [
                _c(
                  "PButton",
                  {
                    attrs: {
                      slot: "opcaoEspecial",
                      color: "primary",
                      size: "sm",
                    },
                    on: { click: _vm.fetchItemsComPaginacao },
                    slot: "opcaoEspecial",
                  },
                  [_vm._v("carregar mais")]
                ),
              ]
            : _vm._e(),
          _c(
            "span",
            {
              staticClass: "multiselect__no-result",
              attrs: { slot: "noResult" },
              slot: "noResult",
            },
            [_vm._v("nada encontrado")]
          ),
          _c(
            "span",
            {
              staticClass: "multiselect__no-options",
              attrs: { slot: "noOptions" },
              slot: "noOptions",
            },
            [_vm._v("nenhuma opção")]
          ),
        ],
        2
      ),
      _vm.error || !_vm.mensagemDeInformacao
        ? _c("ErrorMsg", { attrs: { error: _vm.error } })
        : _vm._e(),
      _vm.mensagemDeInformacao
        ? _c("InfoMsg", { attrs: { info: _vm.mensagemDeInformacao } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }