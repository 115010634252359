import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'
import { API } from 'src/services'
import { converteObjetoDoBackParaVuex } from './converteObjetoDoBackParaVuex'
import { formataObjetoParaEnviar } from './formataObjetoParaEnviar'
import {
  OPCOES_DE_APONTAMENTOS,
  VALOR_PADRAO_LIMITE_HORAS_TRABALHADAS,
  VALOR_PADRAO_JORNADA_BASE
} from './limiteDeAcumuloPorHorasTrabalhadas'

import {
  vuexMapDefaultsMutations,
  vuexMapDefaultsActions,
  vuexMapDefaultsGetters
} from 'src/utils.store'

function defaultState () {
  return {
    id: null,
    carregandoRegra: false,
    // Cabeçalho
    nome: '',
    codigo: '',
    dataInicioDoBanco: null,
    limiteDeAcumuloDoBanco: 90,
    tipoDeContagemDeTempo: {
      selected: {id: 'fixo', name: 'a partir da data de início do banco ou da última baixa (fixo)'},
      loaded: [
        {id: 'fixo', name: 'a partir da data de início do banco ou da última baixa (fixo)'},
        {id: 'movel', name: 'a partir do primeiro evento de banco do empregado (móvel)'}
      ]
    },
    modalidadeDoBanco: {
      selected: {id: 'geral', name: 'compensação geral'},
      loaded: [
        {id: 'geral', name: 'compensação geral'},
        {id: 'janela', name: 'compensação por janela'}
      ]
    },
    tipoDoCicloDeAutoCompensacao: {
      loaded: [
        {id: 'month', name: 'mensal'},
        {id: 'week', name: 'semanal'},
        {id: 'days', name: 'diária'}
      ],
      selected: {id: 'month', name: 'mensal'}
    },
    quantosCiclosParaVencimento: 6,
    permitirDescontoEmSaldosVencidos: false,
    // FatorDeTransformacaoDeHoras
    fatorDeTransformacaoDeHoras: Array(10).fill(1.0),
    // RazaoDeAcumuloECompensacao
    usaRazaoDeAcumuloECompensacao: false,
    razaoDeAcumuloECompensacao: Array(10).fill(100),
    // LimitesDiarios
    usaLimiteDiario: false,
    limiteDeAcumuloECompensacaoDiario: Array(10).fill(null),
    limiteBaseDeAcumuloECompensacaoDiario: [0, 0, 0, -1, 0, 0, 0, 0, -1, -1],
    limiteDeAcumuloPorHorasTrabalhadas: {
      seletor: {
        loaded: OPCOES_DE_APONTAMENTOS,
        selected: []
      },
      valor: VALOR_PADRAO_LIMITE_HORAS_TRABALHADAS,
      jornadaBase: VALOR_PADRAO_JORNADA_BASE
    },
    // LimitesMensais
    usaLimiteMensal: false,
    limiteDeAcumuloECompensacaoMensal: Array(10).fill(null),
    limiteBaseDeAcumuloECompensacaoMensal: [0, 0, 0, -1, 0, 0, 0, 0, -1, -1],
    // LimitesGerais
    usaLimiteGeral: false,
    limiteDeAcumuloECompensacaoGeral: Array(10).fill(null),
    limiteBaseDeAcumuloECompensacaoGeral: [0, 0, 0, -1, 0, 0, 0, 0, -1, -1],
    // OutrasConfiguracoes
    acumularHorasAutomaticamente: true,
    compensarHorasAutomaticamente: true,
    permitirBalancoNegativo: true,
    aprovacaoAutomatica: true,
    separarCompensacaoEAcumulo: false,
    considerarAcumuloParcialDeHoras: false,
    considerarBaixaComTmsComoCompensacao: false,
    compensateOnLock: true,
    completarVisualizacaoDoAcumuladoComJanelasFalsas: true,
    mostrarHistoricoDosSaldosNoAcumuladoDeBancoDeHoras: false,
    mudaNomenclaturaParaCompensacaoNaFolha: false,
    // ConfiguracoesAvancadas
    usarGatilhoDeSaldoGeral: false,
    gatilhoLimiteGeral: 100,
    usarGatilhoDeSaldoMensal: false,
    gatilhoBaseMensal: 50,
    camposDesconsideradosDoGatilhoMensal: []
  }
}

const getters = {
  ...vuexMapDefaultsGetters(defaultState())
}

const mutations = {
  ...vuexMapDefaultsMutations(defaultState()),
  resetState (state) {
    resetarParaEstadoInicial(state, defaultState())
  },
  setFromState (state, {...company}) {
    Object.assign(state, company)
  },
  carregandoRegra (state, payload) {
    state.carregandoRegra = payload
  }
}

const actions = {
  ...vuexMapDefaultsActions(defaultState()),
  async loadWhbRules ({ commit }, whbRuleId) {
    commit('carregandoRegra', true)

    const api = await API.whbRules.get({id: whbRuleId})

    commit('setFromState', {
      ...converteObjetoDoBackParaVuex(api.data.success[0])
    })

    commit('carregandoRegra', false)
  },
  async save ({ state }) {
    const info = formataObjetoParaEnviar(state)
    if (state.id) {
      await API.whbRules.save({id: state.id}, info)
    } else {
      await API.whbRules.save(info)
    }
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  getters,
  mutations,
  actions
}
