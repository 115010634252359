import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

const TAXA_DE_AMOSTRAS_EM_PRODUCAO = 0.000001

export function utilizarSentry (app, router) {
  const ambienteNode = process.env.NODE_ENV
  const ehAmbienteDeProducao = ambienteNode === 'production'

  const definirTaxaDeAmostras = () => {
    const taxaDeAmostrasEmProducao = TAXA_DE_AMOSTRAS_EM_PRODUCAO
    const taxaDeAmostrasEmDesenvolvimento = 0

    if (ehAmbienteDeProducao) {
      return taxaDeAmostrasEmProducao
    } else {
      return taxaDeAmostrasEmDesenvolvimento
    }
  }

  const porcentagemDeAmostras = definirTaxaDeAmostras()

  if (ehAmbienteDeProducao) {
    Sentry.init({
      app,
      dsn: 'https://b1e34487e00040cfb5d327aa229ac224@sentry.pontotel.com.br/11',
      /**
       * Defina logErros para exibir ou não os erros no console do navegador
       * Para produção recomendamos ocultar
       */
      logErros: !ehAmbienteDeProducao,
      integrations: [
        new BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracePropagationTargets: ['localhost', 'my-site-url.com', /^\//]
        })
      ],
      /**
       * Defina tracesSampleRate como 1.0 para capturar 100%
       * de transações para monitoramento de desempenho.
       * Recomendamos ajustar este valor na produção
       */
      tracesSampleRate: porcentagemDeAmostras
    })
  }
}
