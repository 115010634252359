import { API } from 'src/services'
import { formatarDataParaEnvio } from 'src/utils/formatadoras/formatadoras'

export const defaultState = () => ({
  totalGraph: {data: null, loading: false},
  companiesGraph: {data: null, loading: false},
  employeesGraph: {data: null, loading: false},
  displayedSelected: {
    // this have the selected item to show in header of each type
    companiesGraph: null,
    employeesGraph: null,
    useType: null // the apontamento, witch graphs must use
  }
})

const __state = defaultState()

const getters = {}

const mutations = {
  totalGraph (state, payload) {
    state.totalGraph = payload
  },
  companiesGraph (state, payload) {
    state.companiesGraph = payload
  },
  employeesGraph (state, payload) {
    state.employeesGraph = payload
  },
  displayedSelected (state, payload) {
    state.displayedSelected = {...state.displayedSelected, ...payload}
  }
}

const actions = {
  async loadGraphs ({ rootState, dispatch }, payload) {
    if (rootState.dashboards.typeView === 'extraTime') {
      dispatch('loadGraphsExtra', payload)
    } else if (rootState.dashboards.typeView === 'special') {
      dispatch('loadSpecialGraphs', payload)
    } else {
      dispatch('loadWhbGraphs', payload)
    }
  },

  async loadWhbGraphs (
    { state, dispatch, commit, rootState },
    { view, companies = null, employees = null, types, chartType = 'pie' }
  ) {
    const {startDate, endDate, orderBy, sumType, groupPerLineCompany} = rootState.dashboards

    const params = {
      types,
      startDate: formatarDataParaEnvio(startDate),
      endDate: formatarDataParaEnvio(endDate),
      chartType,
      orderBy: orderBy,
      groupPerLineCompany,
      companies,
      employees,
      sumType,
      view
    }

    const commitPerView = {
      total: 'totalGraph',
      companies: 'companiesGraph',
      employees: 'employeesGraph'
    }

    commit(commitPerView[view], {data: null, loading: true})

    try {
      const result = await API.dashboardWHB.save(params)
      commit(commitPerView[view], {data: result.body.success, loading: false})
    } catch (erro) {
      commit(commitPerView[view], {data: null, loading: false})
    }
  },

  async loadGraphsExtra (
    { state, dispatch, commit, rootState },
    { view, companies = null, employees = null, types, chartType = 'pie' }
  ) {
    const {startDate, endDate, orderBy, sumType, groupPerLineCompany} = rootState.dashboards

    const params = {
      types,
      startDate: formatarDataParaEnvio(startDate),
      endDate: formatarDataParaEnvio(endDate),
      chartType,
      orderBy: orderBy,
      groupPerLineCompany,
      companies,
      employees,
      sumType,
      view
    }

    const commitPerView = {
      total: 'totalGraph',
      companies: 'companiesGraph',
      employees: 'employeesGraph'
    }

    commit(commitPerView[view], {data: null, loading: true})

    try {
      const result = await API.dashboardExtraTime.save(params)
      commit(commitPerView[view], {data: result.body.success, loading: false})
    } catch (erro) {
      commit(commitPerView[view], {data: null, loading: false})
    }
  },

  async loadSpecialGraphs (
    { state, dispatch, commit, rootState },
    { view, companies = null, employees = null, types, chartType = 'pie' }
  ) {
    const {startDate, endDate, sumType} = rootState.dashboards

    const params = {
      types,
      startDate: formatarDataParaEnvio(startDate),
      endDate: formatarDataParaEnvio(endDate),
      chartType,
      companies,
      employees,
      sumType,
      view
    }

    const commitPerView = {
      total: 'totalGraph',
      companies: 'companiesGraph',
      employees: 'employeesGraph'
    }

    commit(commitPerView[view], {data: null, loading: true})

    try {
      const result = await API.dashboardSpecial.save(params)
      commit(commitPerView[view], {data: result.body.success, loading: false})
    } catch (erro) {
      commit(commitPerView[view], {data: null, loading: false})
    }
  }
}

export default {
  namespaced: true,
  state: __state,
  getters,
  mutations,
  actions
}
