import { API, retryRequest } from 'src/services'
import { congelarObjetosDaLista } from 'src/utils/formatadoras/formatadoras'

export default {
  namespaced: true,
  state: {
    items: [],
    loading: false
  },
  getters: {
  },
  mutations: {
    saveItems (state, payload) {
      state.items = payload
      state.loading = false
    },
    resetItems (state) {
      state.items = []
    },
    loading (state, payload) {
      state.loading = Boolean(payload)
    }
  },
  actions: {
    async fetchItems ({ commit }, payload) {
      const { fullReload } = payload || {}

      if (fullReload) {
        commit('resetItems')
      }

      commit('loading', true)
      const result = await retryRequest(API.regrasDeAprovacao.get)
      commit('saveItems', congelarObjetosDaLista(result.body))
    }
  }
}
