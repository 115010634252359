import { API } from 'src/services'
import { congelarObjetosDaLista } from 'src/utils/formatadoras/formatadoras'

export default {
  namespaced: true,
  state: {
    items: [],
    loading: false
  },
  getters: {
    onlyIdRepp (state) {
      return state.items.filter(empregador => empregador.id_repp !== '')
    },
    todosEmpregadores (state) {
      return state.items
    },
    apenasVisiveis (state) {
      const empregadoresVisiveis = state.items.filter(empregador => empregador.visivel)
      return empregadoresVisiveis
    },
    visiveisComIdRepp (state) {
      const empregadoresVisiveis = state.items.filter(empregador => empregador.visivel)
      const empregadoresVisiveisComIdRepp = empregadoresVisiveis.filter(empregador => empregador.id_repp !== '')
      return empregadoresVisiveisComIdRepp
    }
  },
  mutations: {
    saveItems (state, payload) {
      state.items = payload
      state.loading = false
    },
    loading (state, payload) {
      state.loading = Boolean(payload)
    }
  },
  actions: {
    async fetchItems ({ commit }, payload) {
      commit('loading', true)

      const { additionalFields = [] } = payload || {}

      const result = await API.employer.get({
        fields: JSON.stringify(['id', 'name', 'code', 'trading_name', 'cnpj', 'cpf', 'cno_caepf', 'id_repp', 'visivel', 'total_of_employees', ...additionalFields])
      })
      commit('saveItems', congelarObjetosDaLista(result.body.success))
    }
  }
}
