var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        name: _vm.name,
        classes: "v--modal v--modal-box p-modal",
        width: _vm.width,
        height: _vm.height,
      },
      on: {
        "before-open": (event) => _vm.$emit("beforeOpen", event),
        closed: function ($event) {
          return _vm.$emit("closed")
        },
      },
    },
    [
      _c("div", { staticClass: "p-modal__content" }, [_vm._t("default")], 2),
      _c(
        "div",
        { staticClass: "p-modal__close", on: { click: _vm.fecharModal } },
        [_vm._v("X")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }