<template lang="pug">
  div.p-select
    multiselect(
      v-model="parsedState"
      :options="customOptions"
      :placeholder="title"
      :preserve-search="true"
      :close-on-select="false"
      :clear-on-select="false"
      ref="multiselect"
      :optionsLimit="limit"
      :track-by="trackBy"
      :label="labelItem"
      :loading="objectLoading || commonLoading"
      :openDirection="openDirection"
      selectLabel=""
      deselectLabel=""
      selectedLabel=""
      :closeOnSelect="true"
      v-bind="$attrs"
      :disabled="disabled"
      @select="(option, id) => $emit('select', option, id)"
      :class="{'error': error, 'required': required}"
      :internalSearch="false"
      @search-change="changeSearch"
      id="multiselect"
    )
      template(slot="logoIcon")
        slot(name="logoIcon")

      template(v-if="mostrarBotaoCarregarMais")
        PButton(
          slot="opcaoEspecial"
          @click="fetchItemsComPaginacao"
          color="primary"
          size="sm"
        ) carregar mais

      template(
        slot="selection"
        slot-scope="{ values, search, isOpen }"
      )
        div(v-if="!isOpen")
          span.multiselect__single(v-if="values.length > 1")
            | {{ values.length }} itens selecionados

          span.multiselect__single(v-else-if="values.length === 1")
            | {{ values[0][labelItem] }}

      template(
        slot="tag",
        slot-scope="{ option, remove }"
      )
        span.custom__tag(style="display: none;")

      span(slot="noResult").multiselect__no-result
        | nada encontrado

      span(slot="noOptions").multiselect__no-options
        | nenhuma opção

      template(
        slot="option"
        slot-scope="props"
      )
        span(:title="props.option[labelItem]", :aria-label="props.option[labelItem]")
          | {{ props.option[labelItem] }}

    ErrorMsg(v-if="error || !mensagemDeInformacao" :error="error")
    InfoMsg(v-if="mensagemDeInformacao" :info="mensagemDeInformacao")

</template>

<script>
import canUseCommonState from 'src/common/mixins/canUseCommonState'
import Multiselect from './_MultiselectWrapper'
import fetchItems from 'src/common/mixins/fetchItems'
import ErrorMsg from 'src/common/inputs/ErrorMsg'
import InfoMsg from '@/common/inputs/InfoMsg.vue'
import { includesSearch } from 'src/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'PSelect',
  mixins: [canUseCommonState(false), fetchItems()],
  components: {
    Multiselect, ErrorMsg, InfoMsg
  },
  props: {
    usaPaginacao: Boolean,
    disabled: Boolean,
    tamanhoMaximoCaracteresInseridos: {
      type: Number,
      'default': 200
    },
    labelItem: { type: String, required: true },
    title: { type: String },
    trackBy: { type: String, 'default': 'id' },
    customLabel: { type: Function },
    noSelectedOption: {type: String}, // show a option in the begin for not selected (null)
    loading: { type: Boolean },
    required: { type: Boolean, 'default': false },
    limit: { type: Number, 'default': 300 },
    openDirection: {type: String, 'default': 'below'},
    options: {type: Array, 'default': null},
    // if options is giver this component will not use the items from loaded
    // its usefull to make this component work like:
    //            input(type="select" v-model="state")
    //              option a
    //              option b
    error: String,
    sortAlphabetically: { type: Boolean, 'default': false },
    loadDataFunction: { type: Function },
    mensagemDeInformacao: { type: String, default: '' }
  },
  data () {
    return {
      search: ''
    }
  },
  watch: {
    loadDataFunction: {
      immediate: true,
      handler (loadDataFunction) {
        if (loadDataFunction) { this.loadDataFunction() }
      }
    }
  },
  computed: {
    ...mapGetters('common/users', ['aindaFaltaMostrarPaginas']),
    mostrarBotaoCarregarMais () {
      const {usaPaginacao} = this
      if (!usaPaginacao) return
      return this.aindaFaltaMostrarPaginas || false
    },
    customOptions: {
      get () {
        let ops = this.options ? this.options : this.state.loaded
        if (!ops) return []

        ops = ops.filter(option => {
          if (!option[this.labelItem]) return false
          return includesSearch(option[this.labelItem], this.search)
        })

        if (this.sortAlphabetically) {
          ops = ops.sort((a, b) => {
            const key = this.labelItem
            if (a[key] < b[key]) return -1
            if (a[key] > b[key]) return 1
            return 0
          })
        }

        if (this.noSelectedOption) {
          ops = [{[this.labelItem]: this.noSelectedOption, id: null}, ...ops]
        }
        return ops
      }
    },
    parsedState: {
      set (value) {
        // Ao dar duplo click, por algum motivo esse value chega como array, e não é isso que queremos aqui.
        if (Array.isArray(value)) {
          value = value[0]
        }
        if (this.options) {
          if (!value) {
            this.state = null
          } else {
            this.state = value[this.trackBy]
          }
        } else {
          this.state = { ...this.state, selected: value }
        }
      },
      get () {
        if (this.options) {
          return this.options.find(i => i[this.trackBy] === this.state)
        } else {
          return this.state.selected
        }
      }
    },
    objectLoading: {
      get () {
        return !this.options && this.state.loading
      }
    }
  },
  methods: {
    async fetchItemsComPaginacao (append = true) {
      let {useCommon, search} = this
      if (useCommon.includes('/')) {
        useCommon = useCommon.split('/')[0]
      }
      this.$store.dispatch(`common/${useCommon}/fetchItemsComPaginacao`, { append, search })
    },
    open () {
      this.$refs.multiselect.activate()
    },
    focus () {
      return this.open()
    },
    verificaTamanhoCaracteres () {
      const motivo = this.$refs.multiselect._data.search
      if (motivo.length > this.tamanhoMaximoCaracteresInseridos) {
        this.$refs.multiselect._data.search = motivo.substring(0, this.tamanhoMaximoCaracteresInseridos)
      }
    },
    changeSearch (query) {
      const querySemEspacos = query.trim()

      this.$emit('asyncSearch', query)
      this.search = querySemEspacos
      this.verificaTamanhoCaracteres()
      if (this.usaPaginacao) {
        // false é importante pois quando mudar a string de pesquisa queremos iniciar uma nova e não adicionar ao final
        this.fetchItemsComPaginacao(false)
      }
    },
    limparCampoDePesquisa () {
      this.$refs.multiselect._data.search = ''
    }
  }
}
</script>

<style>
.p-select__error--message {
  display: block;
  font-size: .75rem;
  padding-top: 2px;
  padding-bottom: 2px;
  color: var(--color-danger);
}

.campoBloqueadoSankhya input:disabled + .p-textfield__title .p-textfield__title-text {
  color: #C4C4C4;
}

/* Logo label style */
.p-textfield__logoicon {
  margin: 0px 3px 0px 5px;
}
</style>
