import { LOGIN_PATH } from './auth'
import store from 'src/store'
import { unregisterSSOLogin } from './index'
import Cookies from 'js-cookie'

import {
  SAMLLoginUrl, SolarSSOLogin, signUpUserHasEmailSaved, clearSavedEmailForAutoSignUp, startRefreshTokenWorker
} from 'src/components/Cognito'
import { possuiParametrosAutenticacaoKeycloak } from 'src/typescript/servicos/keycloak/utils'
import { keycloakSSOLogin } from 'src/typescript/servicos/keycloak/keycloak.servico'

export async function realizeSSOLogin (...rota) {
  const [from, to, next] = rota
  // login Cognito SAML

  // unregister this before each to ensure that is called only once
  unregisterSSOLogin()

  const rotasDeLoginComSSO = {
    // Todos os clientes que utilizam SSO, são redirecionados para nosso sistema com o user_pool_id
    BV: window.location.href.includes('?user_pool_id='),
    SOLAR: window.location.href.includes('/login?token='),
    KEYCLOAK: possuiParametrosAutenticacaoKeycloak()
  }

  const utilizaLoginComSSO = [...Object.values(rotasDeLoginComSSO)].some(Boolean) && (from.name == null || to.path !== LOGIN_PATH)

  if (utilizaLoginComSSO) {
    // DO NOT DISPATCH 'logout' INSIDE THIS IF
    if (rotasDeLoginComSSO.SOLAR) {
      await SolarSSOLogin(...rota)
    } else if (rotasDeLoginComSSO.BV) {
      await SAMLLoginUrl()
    } else if (rotasDeLoginComSSO.KEYCLOAK) {
      await keycloakSSOLogin()
    }
    try {
      await store.dispatch('getUserInfo', true)
      Cookies.set('acesso-login', true)
      // reload the frontend
      window.location.href = window.location.origin + window.location.pathname
      // next('/')
    } catch (erro) {
      this.$filtradorDeErros.capturarErro(erro)
      await store.dispatch('logout', true)
    }
    return null
  } else if (to.path === LOGIN_PATH) {
    try {
      await signUpUserHasEmailSaved()
    } catch (erro) {}
  } else if (to.name === 'cognito' && to.params.type === 'logout') {
    await store.dispatch('logout', false)
    clearSavedEmailForAutoSignUp()
    next(LOGIN_PATH)
    return null
  }
  next()
}

export async function startRefreshTokensWorkerForSSO () {
  if (store.state.auth.tokenAPI) {
    startRefreshTokenWorker()
  }
}
