import { API, retryRequest } from 'src/services'
import { congelarObjetosDaLista } from 'src/utils/formatadoras/formatadoras'

export default {
  namespaced: true,
  state: {
    items: [],
    loading: false
  },
  getters: {
    onlyNotRh (state) {
      return state.items.filter(i => !i.isRh)
    },
    notRhNorEmployee (state) {
      return state.items.filter(i => !i.isRh && i.kind !== 'mine')
    },
    todosOsPerfis (state) {
      return state.items
    },
    notEmployee (state) {
      return state.items.filter(i => i.kind !== 'mine')
    },
    todasPermissoes (state) {
      return state.items
    },
    somentePermissoesDeFuncionario (state) {
      return state.items.filter(i => i.kind === 'mine')
    },
    somentePermissoesDeGestor (state) {
      return state.items.filter(i => i.kind === 'group')
    },
    somentePermissoesDeGestorERh (state) {
      return state.items.filter(i => i.kind === 'group' || i.kind === 'all')
    },
    somentePermissoesDeRh (state) {
      return state.items.filter(i => i.kind === 'all')
    },
    somentePermissoesComEhRh (state) {
      return state.items.filter(item => item.isRh)
    }
  },
  mutations: {
    saveItems (state, payload) {
      state.items = payload
      state.loading = false
    },
    resetItems (state) {
      state.items = []
    },
    loading (state, payload) {
      state.loading = Boolean(payload)
    }
  },
  actions: {
    async fetchItems ({ commit }, payload) {
      payload = payload || {}

      commit('loading', true)

      const { fullReload } = payload

      if (fullReload) {
        commit('resetItems')
      }

      const result = await retryRequest(API.roleGroup.get, {
        fields: JSON.stringify(['name', 'id', 'kind', 'description', 'is_rh'])
      })

      commit('saveItems', congelarObjetosDaLista(result.body.success))
    }
  }
}
