import moment from 'moment'
import { multiSelect } from 'src/common/selectors'
import { copiarObjetoProfundamente, obterDataValida } from 'src/utils/formatadoras/formatadoras'
import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'
import router from 'src/router'
import { API } from 'src/services'
import store from 'src/store'

const NOW = moment()

const defaultState = () => ({
  ...multiSelect.mapStates(['companies', 'employees', 'userGroups']),
  startDate: NOW.toDate(),
  endDate: NOW.toDate(),
  closedPeriod: null,
  type: null,
  typeLoadCompany: store?.state.userInfo.compMan.frontOptions.typeLoadCompany || 'perday',
  queueEmployees: [], // list of employee, e.g {name, id, closingDate}
  queueCurrentIndex: null, // index of what item we are showing of the queueEmployees
  typeEmployeeOrder: 'name'
})

const state = defaultState()

const getters = {
  startDate (state) {
    return moment(state.startDate)
  },
  endDate (state) {
    return moment(state.endDate)
  },
  currentQueueEmployee (state) {
    return state.queueEmployees.length ? state.queueEmployees[state.queueCurrentIndex] : null
  }
}

const mutations = {
  resetState: state => {
    const toBe = defaultState()
    delete toBe['companies']
    delete toBe['employees']
    delete toBe['userGroups']
    resetarParaEstadoInicial(state, toBe)
  },
  ...multiSelect.mapMutations(['companies', 'employees', 'userGroups']),
  typeLoadCompany: (state, payload) => { state.typeLoadCompany = payload },
  type: (state, payload) => {
    state.type = payload
  },
  startDate: (state, payload) => { state.startDate = obterDataValida(payload) },
  endDate: (state, payload) => { state.endDate = obterDataValida(payload) },
  datePeriod: (state, payload) => {
    state.startDate = obterDataValida(payload.startDate)
    state.endDate = obterDataValida(payload.endDate)
  },
  setOptions (state, payload) {
    state.type = payload.type
    state.startDate = obterDataValida(payload.startDate)
    state.endDate = obterDataValida(payload.endDate)
  },
  queueEmployees (state, payload) {
    state.queueCurrentIndex = 0
    state.queueEmployees = payload || []
  },
  typeEmployeeOrder (state, payload) {
    state.typeEmployeeOrder = payload
  },
  queueCurrentIndex (state, payload) {
    state.queueCurrentIndex = payload
  },
  _updateUrlAndHeaderSelectors (state, { company = null, userGroup = null }) {
    // Only states should call this function, actually, today only the populateLines call it
    let id = ''
    if (state.type === 'work-place' && state.companies.selected.length >= 1 && state.typeLoadCompany !== 'peremp') {
      id = company || state.companies.selected[0].id
    } else if (state.type === 'employee') {
      id = state.queueEmployees[state.queueCurrentIndex].id
    } else if (state.type === 'user-group') {
      id = userGroup || state.userGroups.selected[0].id
    }

    router.replace({ path: `/timesheet/${state.type}/${getters.startDate(state).format('YYYY-MM-DD')}/${
      getters.endDate(state).isValid() ? getters.endDate(state).format('YYYY-MM-DD') : 'null'}/${id}` })
  },
  closedPeriod (state, closedPeriod = null) {
    if (closedPeriod) {
      state.startDate = obterDataValida(moment(closedPeriod.startPeriod))
      state.endDate = obterDataValida(moment(closedPeriod.endPeriod))
      state.closedPeriod = copiarObjetoProfundamente(closedPeriod)
    } else {
      state.closedPeriod = null
    }
  }
}

const actions = {
  startDate: ({ commit }, payload) => { commit('startDate', payload) },
  endDate: ({ commit }, payload) => { commit('endDate', payload) },
  nextEmployee ({ state, commit, dispatch }, payload) {
    if (state.type === 'work-place') {
      commit('type', 'employee')
    }
    const index = payload && payload.fromStart ? 0 : state.queueCurrentIndex + 1
    commit('queueCurrentIndex', index)

    let date
    if (state.closedPeriod) {
      date = moment(new Date(state.closedPeriod.year, state.closedPeriod.month - 1, 1))
    } else {
      date = moment(state.endDate)
    }
    dispatch('calcPeriod', { baseDate: date })
  },
  prevEmployee ({ state, commit, dispatch }) {
    const index = state.queueCurrentIndex - 1
    commit('queueCurrentIndex', index)

    let date
    if (state.closedPeriod) {
      date = moment(new Date(state.closedPeriod.year, state.closedPeriod.month - 1, 1))
    } else {
      date = moment(state.endDate)
    }
    dispatch('calcPeriod', { baseDate: date })
  },
  async calcPeriod ({ state, commit, dispatch }, { type = null, baseDate = null, closingSheet = false }) {
    commit('sheet/loading', {lines: true}, {root: true})

    // closingSheet: set to true if is to calc employee timesheet date in current closing sheet
    if (state.type === 'employee') {
      const employee = state.queueEmployees[state.queueCurrentIndex]

      let usuarioAcessandoSuaFolhaAtual = false

      if (state.closedPeriod && !baseDate) {
        const { year, month } = state.closedPeriod
        baseDate = moment(new Date(year, month - 1, 1))
      } else {
        if (!baseDate) {
          usuarioAcessandoSuaFolhaAtual = true
        }
        baseDate = moment(baseDate || state.endDate)
      }

      // Gambiarra para arrumar o erro de pegar a folha seguinte quando usuário de funcionário entra na própria folha (user-story #3081)
      const dataCorteDoMesDiferenteDe1 = employee.closingDate !== 1
      const dataDeHojeEhMenorQueDataDeCorteDoMes = NOW.date() < employee.closingDate && NOW.month() < baseDate.month()
      if (usuarioAcessandoSuaFolhaAtual && dataCorteDoMesDiferenteDe1 && dataDeHojeEhMenorQueDataDeCorteDoMes) {
        type = 'sub'
      }

      if (type) {
        baseDate = type === 'add' ? baseDate.add(1, 'month') : baseDate.subtract(1, 'month')
      }

      if (closingSheet) {
        baseDate = moment()
      }

      const result = await API.closedPeriod.get({ id: employee.id, month: baseDate.month() + 1, year: baseDate.year() })
      commit('closedPeriod', result.data.success)
    } else {
      baseDate = baseDate || moment(state.endDate)

      if (type === 'add') {
        baseDate.add(1, 'day')
      } else if (type === 'subtract') {
        baseDate.subtract(1, 'day')
      }
      const period = { startDate: baseDate.toDate(), endDate: baseDate.toDate() }
      commit('datePeriod', period)
      commit('closedPeriod', null)
    }

    dispatch('timesheet/loadSheet', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
