import { ParametrosAutenticacao } from './keycloak.interface'

export const obterParametrosAutenticacaoKeycloak = (): ParametrosAutenticacao => {
  const urlParams = new URLSearchParams(window.location.search)

  const sessionState = urlParams.get('session_state')
  const code = urlParams.get('code')
  const realm = urlParams.get('realm')

  return {
    sessionState,
    code,
    realm
  }
}

export const possuiParametrosAutenticacaoKeycloak = (): boolean => {
  const parametros = obterParametrosAutenticacaoKeycloak()

  return Object.values(parametros).every(Boolean)
}

export const construirUrlDeRedirecionamentoKeycloak = (realm: string): string => {
  return `${window.location.origin}${window.location.pathname}?realm=${realm}`
}

export const construirUrlDeRenovacaoToken = (realm: string): string => {
  return `${process.env.VUE_APP_KEYCLOAK_BASE_URL}/realms/${realm}/protocol/openid-connect/token`
}
