<template lang="pug">
  a.p-ancora(
    :class="ancoraClasses"
    v-bind="$attrs"
  )
    .p-ancora__content(
      :class="ancoraConteudoClasses"
    )
      slot
</template>

<script>
export default {
  name: 'PAncora',
  props: {
    estado: { type: String, required: false, default: 'primary' },
    tamanho: { type: String, required: false, default: 'md' },
    bloco: { type: Boolean }
  },
  computed: {
    ancoraClasses () {
      return {
        'p-ancora--bloco': this.bloco
      }
    },
    ancoraConteudoClasses () {
      return {
        [`p-ancora--${this.estado}`]: true,
        [`p-ancora__content--tamanho-${this.tamanho}`]: true
      }
    }
  }
}
</script>

<style scoped lang="scss">

$border-bottom-height: 3px;

.p-ancora {
  background-color: transparent;
  display: inline-block;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  height: auto;
  padding-bottom: 3px;
  width: fit-content;

  &--bloco {
    display: flex;
    width: 100%;

    .p-ancora__content {
      width: 100%;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    border: 0;
    outline: 0;
    position: relative;
    padding: 0px 10px;
    flex: 0 1 auto;
    cursor: pointer;
    transition: all 0.12s ease-in-out;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    box-sizing: border-box;
    transition: background-color linear 0.2s;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 300;
  }
}

/* Tamanho do botão */
.p-ancora__content--tamanho-xm {
  font-size: 8px;
  min-height: 10px;
}

.p-ancora__content--tamanho-sm {
  font-size: 13px;
  min-height: 20px;
}

.p-ancora__content--tamanho-md {
  font-size: 16px;
  min-height: 29px;
}

.p-ancora__content--tamanho-lg {
  font-size: 21px;
  min-height: 40px;
}

/* Efeito de clique com mouse */
.p-ancora__content:active:not([disabled]) {
  box-shadow: 0 0 transparent;
  transform: translateY($border-bottom-height);
  outline: 0;
}

.p-ancora__content:after {
  /* IMPORTANT. this make the click events worken even if click too close to top of element
  Its a old problem with 3d as. As we need to add a margin-top when clicked, the click event dont fire
  because after styles render, the mouse is not above the a anymore
 */
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: -4px;
  left: 0;
  z-index: 1;
}

/* Efeito de clique com enter */
.p-ancora--active:not([disabled]) {
  box-shadow: 0 0 transparent;
  transform: translateY($border-bottom-height);
  outline: 0;
}

/* Botão desabilitado */
.p-ancora__content:disabled {
  box-shadow: 0 0 transparent;
  transform: translateY($border-bottom-height);
  cursor: default;
}

/* Retira estilo padrão de foco no mozilla */
::-moz-focus-inner {
  border:0;
}

/* Estilo de focado */

.p-ancora__content:focus {
  outline-width: 1px;
  outline-style: solid;
  outline-offset: -1px
}

$classes-de-estado: "default", "danger", "success", "info", "warning", "primary";

@each $estado in $classes-de-estado {

  $cor-de-contraste-por-estado: var(--color-#{$estado}-contrast);
  $cor-de-borda-por-estado: var(--color-#{$estado}-border);
  $cor-de-sobreposto-por-estado: var(--color-#{$estado}-hover);
  $cor-por-estado: var(--color-#{$estado});

  /* Estilo por classe */
  .p-ancora--#{$estado} {
    background-color: $cor-por-estado;
    box-shadow: 0 $border-bottom-height $cor-de-borda-por-estado;
    color: $cor-de-contraste-por-estado
  }

  /* Estilo Desabilitado */
  .p-ancora--#{$estado}:disabled {
    background-color: $cor-por-estado;
  }

  /* Estilo Focado */
  .p-ancora--#{$estado}:focus {
    outline-color: $cor-de-borda-por-estado;
  }

  /* Estilo Sobreposto */
  .p-ancora--#{$estado}:hover:not([disabled]) {
    background-color: $cor-de-sobreposto-por-estado;
  }
}

</style>
