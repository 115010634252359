import { API, retryRequest } from 'src/services'
import { congelarObjetosDaLista } from 'src/utils/formatadoras/formatadoras'
import { uniqBy } from 'lodash'

const defaultOnly = [
  'id', 'name', 'email', 'role_group'
]
const PER_PAGE = 30

let timer

export default {
  namespaced: true,
  state: {
    page: 0,
    items: [],
    loading: false,
    deleted: false,
    totalPages: 0
  },
  getters: {
    usuariosQueSaoEmpregados (state) {
      return state.items.filter(usuario => usuario.roleGroup.kind === 'mine')
    },
    usuariosQueSaoGestores (state) {
      return state.items.filter(usuario => usuario.roleGroup.kind === 'group')
    },
    usuariosQueTemFuncionario (state) {
      return state.items.filter(i => i.compemployee.id)
    },
    usuariosGestoresERh (state) {
      return state.items.filter(usuario => usuario.roleGroup.kind === 'group' || usuario.roleGroup.kind === 'all')
    },
    usuariosSomenteRh (state) {
      return state.items.filter(usuario => usuario.roleGroup.kind === 'all')
    },
    todosUsuarios (state) {
      return state.items
    },
    aindaFaltaMostrarPaginas (state) {
      const {page, totalPages, items} = state
      const saoNumeros = [page, totalPages].every(v => typeof v === 'number')
      const possuiMenosQuePerPage = items.length < PER_PAGE
      const maisPaginasAlemDaAtual = totalPages > page
      return saoNumeros && !possuiMenosQuePerPage && maisPaginasAlemDaAtual
    }
  },
  mutations: {
    saveItems (state, payload) {
      state.items = payload
      state.page = 0
      state.loading = false
    },
    appendItems (state, payload) {
      state.items = uniqBy([...state.items, ...payload], 'id')
      state.loading = false
    },
    setDeleted (state, payload) {
      state.deleted = payload
    },
    resetItems (state) {
      state.items = []
    },
    loading (state, payload) {
      state.loading = Boolean(payload)
    },
    setTotalPages (state, payload) {
      state.totalPages = payload
    },
    setPage (state, payload) {
      state.page = payload
    }
  },
  actions: {
    async fetchItems ({ commit }, payload) {
      payload = payload || {}

      commit('loading', true)

      const { withFields = [], fullReload, deleted } = payload

      if (fullReload) {
        commit('resetItems')
      }
      commit('setDeleted', deleted === 'true')
      const only = Array.from(new Set([...defaultOnly, ...withFields]))

      const result = await retryRequest(API.user.get, {
        fields: JSON.stringify(only),
        order_by: 'name',
        deleted: deleted
      })
      commit('saveItems', congelarObjetosDaLista(result.body.success))
    },
    async fetchItemsComPaginacao ({ state, commit }, payload = {}) {
      commit('loading', true)

      clearTimeout(timer)
      timer = setTimeout(async () => {
        const { withFields = [], fullReload, deleted, search, append = false } = payload
        const {page} = state

        if (fullReload) {
          commit('resetItems')
        }
        commit('setDeleted', deleted === 'true')
        const only = Array.from(new Set([...defaultOnly, ...withFields]))

        // append define se iremos adicionar no final (mais itens carregados)
        const paginaParaRequisicao = append ? (page + 1) : 0

        const result = await retryRequest(API.user.get, {
          fields: JSON.stringify(only.filter(f => f !== 'compemployee')),
          search,
          order_by: 'name',
          deleted: deleted,
          page: paginaParaRequisicao,
          per_page: PER_PAGE
        })

        if (result.status === 204) {
          result.body = {
            success: [],
            total: 0,
            totalPages: 0
          }
        }
        const {totalPages, success} = result.body

        commit('setTotalPages', totalPages)
        commit('setPage', paginaParaRequisicao)
        commit('appendItems', congelarObjetosDaLista(success))
      }, 500)
    }
  }
}
