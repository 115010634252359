<template lang="pug">
  .p-timefield
    PTextField.p-timefield__hour(
      type="text",
      :label="label"
      v-model="textInputComputed",
      autocomplete="disabled"
      v-mask="'##:##'",
      placeholder="00:00",
      :loading="loading",
      :disabled="disabled",
      v-on="localListeners",
      @focus="($event) => $event.target.select()"
      v-bind="localAttributes"
      :error="error"
    )

    PCheckbox(label="+1d", v-if="showPlusOneDay" v-model="plusOneDay", :disabled="disabled")

</template>

<script>
import { mask } from 'vue-the-mask'
import moment from 'moment'

import PTextField from './PTextField.vue'
import { handlerUseStateVmodel } from '../mixins/handlerUseStateVmodel'
import {
  formatString24Hours
} from 'src/utils'
import { verificarSeHorarioInputValido } from 'src/utils/validadoras/validadoras'
import { converterHorarioParaObjetoMoment } from 'src/utils/formatadoras/formatadoras'

export default {
  name: 'PTimeField',
  props: {
    label: {type: String, required: true},
    baseDate: {type: Date, required: true},
    showPlusOneDay: {type: Boolean, default: false},
    loading: Boolean,
    disabled: Boolean,
    error: {type: String, default: ''},
    format24Hours: {type: Boolean, default: false}
  },
  data () {
    return {
      textInput: '',
      localListeners: undefined,
      localAttributes: undefined,
      internalPlusOneDay: false
    }
  },
  created () {
    this.localListeners = Object.keys(this.$listeners)
      .filter(key => key !== 'input' && key !== 'change')
      // eslint-disable-next-line no-sequences
      .reduce((res, key) => (res[key] = this.$listeners[key], res), {}) // eslint-disable-line no-return-assign

    this.localAttributes = Object.keys(this.$attrs)
      .filter(key => key !== 'name' && key !== 'disabled')
      // eslint-disable-next-line no-sequences
      .reduce((res, key) => (res[key] = this.$attrs[key], res), {}) // eslint-disable-line no-return-assign

    if (moment(this.state).date() > moment(this.baseDate).date()) {
      this.internalPlusOneDay = true
    }

    this.textInput = moment(this.state).format('HH:mm')
  },
  computed: {
    plusOneDay: {
      set (newValue) {
        const baseDayOfMonth = moment(this.baseDate).date()
        const stateDayOfMonth = moment(this.state).date()
        this.internalPlusOneDay = newValue

        if (newValue) {
          this.state = baseDayOfMonth === stateDayOfMonth
            ? moment(this.state).add(1, 'days').toDate()
            : moment(this.state).toDate()
        } else {
          this.state = baseDayOfMonth === stateDayOfMonth
            ? moment(this.state).toDate()
            : moment(this.state).subtract(1, 'days').toDate()
        }
      },
      get () {
        return this.internalPlusOneDay
      }
    },
    textInputComputed: {
      get () {
        return this.textInput
      },
      set (val) {
        this.textInput = val
        if (this.valid) {
          const time = converterHorarioParaObjetoMoment(val)
          const mergedDates = moment(this.baseDate).set({hour: time.hours(), minute: time.minutes(), seconds: 0})
          this.state = (this.plusOneDay ? mergedDates.add(1, 'days') : mergedDates).toDate()
        } else {
          if (this.textInput.length < 5) this.state = null
          if (this.format24Hours && this.textInput.length === 5) {
            this.textInput = formatString24Hours(this.textInput)
          }
        }
      }
    },
    valid () {
      return verificarSeHorarioInputValido(this.textInput, true)
    }
  },
  watch: {
    baseDate: function (newValue, oldValue) {
      if (moment(newValue).diff(oldValue, 'hours') < 1) return
      if (this.valid) {
        const time = converterHorarioParaObjetoMoment(this.textInput)
        const mergedDates = moment(newValue).set({hour: time.hours(), minute: time.minutes()})
        this.state = (this.plusOneDay ? mergedDates.add(1, 'days') : mergedDates).toDate()
      }
    },
    state: function () {
      this.internalPlusOneDay = moment(this.state).date() > moment(this.baseDate).date()
      if (this.state) this.textInput = moment(this.state).format('HH:mm')
    }
  },
  mixins: [handlerUseStateVmodel()],
  directives: { mask },
  components: { PTextField }
}
</script>

<style scoped>
.p-timefield {
  display: flex;
  align-items: center;
}
</style>
