import { congelarObjetoProfundamente, obterDataComHoraInicial } from 'src/utils/formatadoras/formatadoras'
import moment from 'moment'

export const COLORS = congelarObjetoProfundamente({
  primary: {backgroundColor: '#2c3e50', color: 'white'},
  'default': {backgroundColor: '#f5f5f5', color: 'black'},
  danger: {backgroundColor: '#ff6955', color: 'white'},
  success: {backgroundColor: '#0c9b73', color: 'white'},
  info: {backgroundColor: '#00a2b5', color: 'white'},
  warning: {backgroundColor: '#ffcb55', color: 'black'},
  after: {color: '#333'},
  before: {color: 'darkgray'}
})

function toBorderBottom (x) { return `2px solid ${x}` }

export const INFOS_PER_TYPE = congelarObjetoProfundamente({
  after: {
    leave: {style: COLORS.warning, text: 'A'},
    vacation: {style: COLORS.danger, text: '*'},
    holiday: {style: {...COLORS.info}, text: 'F'},
    falta: {style: COLORS.after},
    folga: {style: COLORS.danger},
    folga_trabalhada: {style: COLORS.after},
    justify: {style: COLORS.warning, text: 'J'},
    invalid_punch: {style: COLORS.after},
    not_admitted: {style: COLORS.before, text: '--'},
    no_journey: {style: COLORS.before, text: 'SJ'},
    'default': {style: COLORS.after}
  },
  before: {
    leave: {style: COLORS.warning, text: 'A'},
    vacation: {style: COLORS.danger, text: '*'},
    holiday: {style: COLORS.info, text: 'F'},
    folga: {style: COLORS.before},
    'folga_trabalhada': {style: {borderBottom: toBorderBottom(COLORS.danger.backgroundColor), ...COLORS.after}},
    falta: {style: COLORS.danger, text: 'FA'},
    justify: {style: COLORS.warning, text: 'J'},
    invalid_punch: {style: {borderBottom: toBorderBottom(COLORS.info.backgroundColor), ...COLORS.after}},
    not_admitted: {style: COLORS.before, text: '--'},
    no_journey: {style: COLORS.before, text: 'SJ'},
    'default': {style: COLORS.before},
    worked: {style: {borderBottom: toBorderBottom(COLORS.before.color), ...COLORS.before}}
  }
})

export function genDayInfoToPreview (dayInfoAlreadyFormated, overManualChanges, journey, jrnsPerId) {
  /*
    Parse a dayInfo to a journey to preview the result in scheduler if the new routine be applyed
  */

  // here we remove cols from the list based on overManualChanges flag
  // We remove the day if it has manualChange and overManualChanges is false,

  const preventOverflowManualChange = !overManualChanges && dayInfoAlreadyFormated.manualChange

  if (!dayInfoAlreadyFormated.allowChange || preventOverflowManualChange) {
    return {
      ...dayInfoAlreadyFormated.infoDisplay,
      previewManualChange: dayInfoAlreadyFormated.manualChange
    }
  }

  const { infoDisplay } = formatDayInfo({
    ...dayInfoAlreadyFormated,
    journey: journey?.id,
    mandatoryPresence: Boolean(journey?.mandatoryPresence),
    displayInfo: journey?.mandatoryPresence ? 'default' : 'folga',
    // the next fields we put a default value because we can't know the real value in front
    // without a brutal amount of logic. Bven if we could, it's too much variables that affect
    folgaTrabalhada: false,
    isRightPunched: true,
    falta: false
  }, jrnsPerId)
  return {...infoDisplay, previewManualChange: false}
}

export function formatDayInfo (dayInfo, jrnsPerId) {
  let infoDisplay

  const today = obterDataComHoraInicial(moment())
  const date = moment(dayInfo.day)

  const styleBase = INFOS_PER_TYPE[date.isBefore(today, 'date') ? 'before' : 'after']

  if (styleBase[dayInfo.displayInfo] !== undefined) {
    infoDisplay = styleBase[dayInfo.displayInfo]
  } else {
    infoDisplay = styleBase.default
  }

  // Important to avoid change INFOS_PER_TYPE and COLORS conts
  infoDisplay = {...infoDisplay}

  if (dayInfo.locked) {
    infoDisplay = {
      ...infoDisplay,
      style: {
        ...infoDisplay.style,
        textDecoration: 'line-through',
        borderBottom: null,
        backgroundColor: null,
        ...COLORS.before
      }
    }
  }

  function parseInfoDisplayOfJourney (journeyId, jrnsPerId) {
    const journey = jrnsPerId.get(journeyId)

    let infoDisplay = {}

    if (journey) {
      if (journey.name === 'Folga') {
        infoDisplay.text = 'F'
      } else {
        infoDisplay.text = journey.refCode
      }
    } else {
      // It should not never get here
      // It means that back response with journeys dont contains this day journey
      infoDisplay = styleBase.noJourney
    }
    return infoDisplay
  }

  if (!infoDisplay.text) {
    infoDisplay = {...infoDisplay, ...parseInfoDisplayOfJourney(dayInfo.journey, jrnsPerId)}
  }

  return {...dayInfo, infoDisplay}
}
