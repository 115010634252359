import Amplify from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import { API } from 'src/services'
import store from 'src/store'
import Vue from 'vue'
import Cookies from 'js-cookie'
import swal from 'sweetalert2'
import { FiltradorDeErros } from 'src/erros'

const enviarMensagemWorkerAtivo = () => {
  const informacoesAuth = {...store.state.auth, verificarTempoToken: true}
  window.__cognitoRefreshTokenWorker.postMessage(JSON.stringify(
    informacoesAuth
  ))
}

export function startRefreshTokenWorker () {
  // Caso já possua um worker, só passamos a mensagem para verificar o tempo do token
  if (window.__cognitoRefreshTokenWorker) {
    enviarMensagemWorkerAtivo()
    return
  }
  try {
    setTimeout(() => {
      window.__cognitoRefreshTokenWorker = new Worker('/js/cognitoTokenRefresh.js')

      window.__cognitoRefreshTokenWorker.addEventListener('message', (evento) => {
        const data = JSON.parse(evento.data)

        if (data.error) {
          store.dispatch('logout')
          return
        }

        store.commit('setFreshToken', {
          accessToken: 'Bearer ' + data.id_token
        })

        store.commit('setDataCriacaoToken', new Date().getTime())
      })

      window.__cognitoRefreshTokenWorker.postMessage(JSON.stringify(
        store.state.auth
      ))
    }, 300)
  } catch (erro) {
    FiltradorDeErros.capturarErro(erro)
  }
}

export async function refreshToken () {
  if (store.state.auth.refreshToken) { return }

  let currentSessionTokens

  // This try catch exists to not break aplication if is asked to
  // refresh tokens but the login was not made using Amplify (e.g: SAML)
  try {
    currentSessionTokens = await Auth.currentSession()
  } catch (erro) {
    return
  }

  const newToken = 'Bearer ' + currentSessionTokens.idToken.jwtToken

  if (newToken && store.state.auth.accessToken !== newToken) {
    store.commit('cognitoTokens', {
      authorization: null,
      accessToken: newToken
    })
  }
}

export async function SolarSSOLogin (from, to, next) {
  try {
    const authToken = from.query.token
    store.commit('cognitoTokens', {
      authorization: `Bearer ${authToken}`,
      accessToken: null
    })
  } catch (erro) {
    await swal({type: 'info', text: erro.data.error || 'Usuário não encontrado'})
  }
}

export async function SAMLLoginUrl () {
  const samelInfos = (/\?user_pool_id(?:=|%3D)(.*)\?client_id(?:=|%3D)(.*)\?domain(?:=|%3D)(.*)&code(?:=|%3D)(.*)#\//).exec(window.location.href)

  const infos = {userPoolId: samelInfos[1], clientId: samelInfos[2], domain: samelInfos[3], code: samelInfos[4]}

  store.commit('cognitoTokens', {
    authorization: null,
    accessToken: null,
    refreshToken: null,
    userPoolId: null,
    userPoolWebClientId: null
  })

  const tokenAPI = `https://${infos.domain}.auth.us-east-1.amazoncognito.com/oauth2/token`

  function safePathName () {
    return window.location.pathname !== '/' ? window.location.pathname : ''
  }

  try {
    const tokens = await Vue.http.post(
      tokenAPI,
      {
        grant_type: 'authorization_code',
        client_id: infos.clientId,
        // redirect has to be the same as in cognito dashboard > App clients settings > callback urls
        redirect_uri: `${window.location.origin + safePathName()}?user_pool_id=${infos.userPoolId}?client_id=${infos.clientId}?domain=${infos.domain}`,
        code: infos.code
      },
      {emulateJSON: true}
    )

    store.commit('cognitoTokens', {
      authorization: null,
      accessToken: 'Bearer ' + tokens.body.id_token, // for SAML we need to use id_token
      refreshToken: tokens.body.refresh_token,
      userPoolId: infos.userPoolId,
      userPoolWebClientId: infos.clientId,
      tokenAPI: tokenAPI,
      definirDataCriacaoToken: true
    })
  } catch (erro) {
    FiltradorDeErros.capturarErro(erro)
  }
}

export async function executeAuthCognitoRequest (email) {
  // Function resposible to discover userPoolId and client of cognito
  // and redirect to SAML url if is necessary

  let userPoolWebClientId
  let userPoolId
  let useCognito

  try {
    const result = await API.getAuthUserPool.save({email})

    userPoolWebClientId = result.data.success.userPoolWebClientId
    userPoolId = result.data.success.userPoolId
    useCognito = result.data.success.useCognito
  } catch (erro) {
    userPoolWebClientId = null
    userPoolId = null
    useCognito = null
  }

  return { userPoolWebClientId, userPoolId, useCognito }
}

export async function signUpUserHasEmailSaved () {
  const samlEmail = Cookies.get('saml-email')
  if (samlEmail) {
    await executeAuthCognitoRequest(samlEmail)
  }
}
export function clearSavedEmailForAutoSignUp () {
  Cookies.remove('saml-email')
}

export function configureCongito ({ userPoolId, userPoolWebClientId }) {
  try {
    Amplify.configure({
      Auth: {
        userPoolId,
        region: 'us-east-1',
        identityPoolRegion: 'us-east-1',
        userPoolWebClientId,
        storage: window.localStorage,
        authenticationFlowType: 'USER_SRP_AUTH'
      }
    })
  } catch (erro) {
    this.$filtradorDeErros.capturarErro(erro)
    store.dispatch('logout')
  }
}
