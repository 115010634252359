<template lang="pug">

  uiv-dropdown(ref="dropdown-sino")
    a.to-click.main-header__button--category(@click="buscarNotificacoes" type="primary" class="dropdown-toggle" @keyup.enter="buscarNotificacoes" aria-label="Notificações" tabindex="0")
      .icon-ptt-icons-sino
      span.badge(v-if="notificacoesNaoLidas !== 0") {{notificacoesNaoLidas}}
      span.badge(v-if="notificacoesNaoLidas === 0 && !relatoriosDeTodasAsFilasEstaoProntos")
        .icon-ptt-icons-recarregar.icon-spin
      .arrow-img.icon-ptt-icons-dropdown

    template(slot="dropdown")
      li.lowercase(v-if="!totalDeRelatoriosEmAndamento")
        a(class="descricao") não existem relatórios sendo gerados no momento

      li.lowercase(v-if="totalDeRelatoriosEmAndamento > 0")
        a(class="descricao") relatórios em andamento ({{ totalDeRelatoriosEmAndamento }})

      li.lowercase(v-for="item in listaDeRelatoriosEmAndamento" class="item-da-lista-de-relatorios")
        a(style="display: flex; align-items: center; justify-content: space-between")
          .nome-relatorio
            span {{ item.name }} {{ gerarInformacoesDoRelatorio(item.percent, item.ttf, item.status) }}

          .icones(style="display: flex; justify-content: flex-end")
            .icon.fs-15.icone-linha-atualizar.icon-spin.color-info(v-if="item.status==='STARTED' || item.status==='NAO_INICIADO'")
            .icon.fs-15.icone-linha-atualizar.icon-spin.color-info(v-if="item.status==='PENDING' || item.status==='GERANDO'")
            .icon-ptt-icons-X.color-warning(v-if="item.status==='FAILURE' || item.status==='FALHA_CRITICA'")

            a(v-if="verificarSeRelatorioPodeSerCancelado(item)" href="#" @click="cancelarRelatorio(item.id)")
              .icon.fs-13.icone-linha-fechar.color-danger(style="margin-left: 8px")

      li(v-if="totalDeRelatoriosEmAndamento > 0")
        a.to-click.lowercase(role="button" @click="cleanQueueReports")
          | Limpar fila de relatórios

      li.lowercase(v-if="totalDeRelatoriosConcluidos > 0")
        a(class="descricao") relatórios concluídos

      li.lowercase(v-for="item in listaDeRelatoriosConcluidos" class="item-da-lista-de-relatorios")
        a(v-if="item.url" :href="item.url" target='_blank' style="display: flex; align-items: center; justify-content: space-between")
          .nome-relatorio
            span {{item.name}}
          .icon.fs-15.icone-linha-download.color-info(style="margin-left: 8px")

      li
        a.to-click.lowercase(v-if="showActivateNotification && notificationDisabled", role="button" @click="activateBrowserNotification")
          | Ativar notificações do navegador

</template>

<script>
import { differenceBy } from 'lodash'
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  props: {},
  data () {
    return {
      filaDeRelatoriosAntigos: [],
      notificacoesNaoLidas: 0,
      timer: null,
      primeiraVezQueConsultaFilaAntiga: true,
      estaBuscandoFilas: false,
      showActivateNotification: true,
      primeiraVezQueConsulta: true,
      relatorioFilaTravamentoEstaPronto: false,
      relatorioFilaAntigaEstaPronto: false,
      relatorioNovaFilaEstaPronto: false,
      relatorioAcompanhamentoIntegracaoEstaPronto: false,
      filaDeRelatorios: [],
      relatoriosConcluidos: [],
      relatoriosEmAndamento: [],
      relatoriosLidos: [],
      errosEInfosJaEnviadas: [],
      totalDeNovosRelatoriosEmAndamento: 0,
      relatoriosAntigosConcluidos: [],
      relatoriosAntigosEmAndamento: [],
      totalDeRelatoriosAntigosEmAndamento: 0,
      primeiraVezConsultaFilaTravamento: true,
      filaDeRelatoriosTravamento: [],
      relatoriosFilaTravamentoEmEndamento: [],
      relatorioFilaTravamentoConcluidos: [],
      totalDeRelatoriosFilaTravamentoEmEndamento: 0,
      primeiraVezQueConsultaAcompanhamentoDeIntegracao: true,
      filaDeRelatoriosAcompanhamentoDeIntegracao: [],
      relatoriosFilaAcompanhamentoDeIntegracaoEmEndamento: [],
      relatorioFilaAcompanhamentoDeIntegracaoConcluidos: [],
      totalDeRelatoriosFilaAcompanhamentoDeIntegracaoEmEndamento: 0
    }
  },
  computed: {
    ...mapState('notification', ['reportLoading', 'lastNotification']),
    notificationDisabled () {
      return Notification.permission !== 'granted'
    },
    listaDeRelatoriosConcluidos () {
      return [...this.relatoriosConcluidos, ...this.relatoriosAntigosConcluidos, ...this.relatorioFilaTravamentoConcluidos, ...this.relatorioFilaAcompanhamentoDeIntegracaoConcluidos]
    },
    totalDeRelatoriosConcluidos () {
      return Number(this.listaDeRelatoriosConcluidos.length)
    },
    listaDeRelatoriosEmAndamento () {
      return [...this.relatoriosEmAndamento, ...this.relatoriosAntigosEmAndamento, ...this.relatoriosFilaTravamentoEmEndamento, ...this.relatoriosFilaAcompanhamentoDeIntegracaoEmEndamento]
    },
    totalDeRelatoriosEmAndamento () {
      return Number(this.listaDeRelatoriosEmAndamento.length)
    },
    possuiPermissaoDeVerRelatorio () {
      return this.$hasPermission({ module: 'exportfiles', action: 'get' })
    },
    relatoriosDeTodasAsFilasEstaoProntos () {
      return this.relatorioFilaTravamentoEstaPronto &&
      this.relatorioFilaAntigaEstaPronto &&
      this.relatorioNovaFilaEstaPronto &&
      this.relatorioAcompanhamentoIntegracaoEstaPronto
    },
    possuiFlagIntegracaoAtiva () {
      return this.$store.state.userInfo.compMan.possuiIntegracaoAutomatica
    },
    modalEstaAberto () {
      return this?.$refs['dropdown-sino']?.show
    },
    intervaloDeTimeout () {
      // se o sininho estiver aberto, o intervalo de polling é de 1 segundo
      // se não, o intervalo é de 5 segundos
      const modalEstaAberto = this.modalEstaAberto
      const intervaloEmMilissegundos = modalEstaAberto ? 1000 : 5000

      return intervaloEmMilissegundos
    }

  },
  mounted () {
    this.polling()
    // watch for events
    this.$root.$on('Notifications__checkUserFiles', this.buscarNotificacoes)
  },
  methods: {
    verificarSeRelatorioPodeSerCancelado (relatorio) {
      return relatorio.id && ['NAO_INICIADO', 'GERANDO'].includes(relatorio.status)
    },
    gerarInformacoesDoRelatorio (porcentagem, tempoRestante, status) {
      if (status === 'NAO_INICIADO') {
        return '- iniciará em breve'
      }

      if (!isNaN(tempoRestante)) {
        return ''
      }

      porcentagem = porcentagem / 10
      if (porcentagem > 100 || porcentagem < 0) {
        return ''
      }

      const [h, m] = tempoRestante.split(':')
      const horas = Number(h)
      const minutos = Number(m)

      if (horas > 0) {
        return `- ${porcentagem}% - ${horas} horas e ${minutos} minutos restantes`
      } else if (minutos > 0) {
        return `- ${porcentagem}% - ${minutos} minutos restantes`
      } else {
        return `- ${porcentagem}% - em instantes`
      }
    },
    async cleanQueueReports () {
      const { compMan, id } = this.$store.state.userInfo

      try {
        await this.$API.clearUserFile.get({ compManId: compMan.id, userId: id })

        let relatoriosDaNovaFilaParaCancelar = this.obterIdsDeRelatoriosQueEstaoSendoGerados()

        if (relatoriosDaNovaFilaParaCancelar.length > 0) {
          await this.$API.cancelarGeracaoDeRelatorio.save({ ids_relatorios: relatoriosDaNovaFilaParaCancelar })

          this.$swal({
            type: 'success',
            title: 'A fila de relatórios foi limpa com sucesso.'
          })
        }
      } catch (erro) {
        this.$filtradorDeErros.capturarErro(erro)
      }
    },
    activateBrowserNotification () {
      Notification.requestPermission()
      this.showActivateNotification = false
    },
    async polling () {
      if (!this.estaBuscandoFilas && (!this.relatoriosDeTodasAsFilasEstaoProntos || this.totalDeRelatoriosEmAndamento > 0)) {
        this.estaBuscandoFilas = true
        const resultado = await Promise.allSettled([
          this.buscarFilaDeRelatoriosAntiga(),
          this.buscarFilaDeRelatorioTravamento(),
          this.buscarNovaFilaDeRelatorios(),
          this.buscarFilaDeAcompanhamentoIntegracao()
        ])
        this.estaBuscandoFilas = false

        this.lidarComResultadoDasFilas(resultado)

        if (this.totalDeRelatoriosEmAndamento > 0) {
          this.timer = setTimeout(this.polling, this.intervaloDeTimeout) // chamada recursiva com timeout
        }
      } else {
        clearTimeout(this.timer)
      }
    },
    buscarNotificacoes () {
      this.notificacoesNaoLidas = 0
      clearTimeout(this.timer)
      this.alterarStatusDeAndamentoDeTodosOsRelatorios(false)
      this.polling()
    },
    async buscarFilaDeRelatoriosAntiga () {
      return new Promise((resolve, reject) => {
        this.$API.reports.get()
          .then(resposta => {
            resolve(resposta)
          }).catch(erro => {
            reject(erro)
          })
      })
    },
    async buscarFilaDeRelatorioTravamento () {
      return new Promise((resolve, reject) => {
        if (!this.possuiPermissaoDeVerRelatorio) {
          this.$API.filaRelatorioTravamento.get()
            .then(resposta => {
              resolve(resposta)
            })
            .catch(erro => {
              reject(erro)
            })
        } else {
          this.relatorioFilaTravamentoEstaPronto = true
          resolve()
        }
      })
    },
    async buscarNovaFilaDeRelatorios () {
      const valoresParaConsulta = {
        intervalo_de_horas: 6,
        status_para_retornar: ['NAO_INICIADO', 'GERANDO', 'CONCLUIDO']
      }

      return new Promise((resolve, reject) => {
        this.$API.consultarFilaDeRelatorios.get(valoresParaConsulta)
          .then(resposta => {
            resolve(resposta)
          })
          .catch(erro => {
            reject(erro)
          })
      })
    },
    async buscarFilaDeAcompanhamentoIntegracao () {
      const valoresIntegracao = {
        intervalo: 6
      }
      return new Promise((resolve, reject) => {
        if (this.possuiFlagIntegracaoAtiva) {
          this.$API.acompanhamentoIntegracao.get(valoresIntegracao)
            .then(resposta => {
              resolve(resposta)
            })
            .catch(erro => {
              reject(erro)
            })
        } else {
          this.relatorioAcompanhamentoIntegracaoEstaPronto = true
          resolve()
        }
      })
    },
    lidarComResultadoDasFilas (resultados) {
      // cada posição do array abaixo representa a ordem das requisições
      // feitas no allSettled na função de polling
      resultados.forEach((resultado) => {
        const { status, value: resultadoDeSucesso, reason: erro } = resultado

        switch (status) {
          case 'fulfilled':
            if (resultado === resultados[0]) {
              this.tratarRespostaFilaAntiga(resultadoDeSucesso)
            } else if (resultado === resultados[1]) {
              this.tratarRespostaFilaTravamento(resultadoDeSucesso)
            } else if (resultado === resultados[2]) {
              this.tratarRespostaDaNovaFila(resultadoDeSucesso)
            } else if (resultado === resultados[3]) {
              this.tratarRespostaDoAcompanhamentoDeIntegracao(resultadoDeSucesso)
            }
            break
          case 'rejected':
            if (resultado === resultados[0]) {
              this.tratarErroDaFilaGenerico(erro, 'relatorioFilaAntigaEstaPronto')
            } else if (resultado === resultados[1]) {
              this.tratarErroDaFilaGenerico(erro, 'relatorioFilaTravamentoEstaPronto')
            } else if (resultado === resultados[2]) {
              this.tratarErroDaFilaGenerico(erro, 'relatorioNovaFilaEstaPronto')
            } else if (resultado === resultados[3]) {
              this.tratarErroDaFilaGenerico(erro, 'relatorioAcompanhamentoIntegracaoEstaPronto')
            }
            break
        }
      })
    },
    tratarErroDaFilaGenerico (erro, variavelDeStatus) {
      // caso o erro seja 403, significa que o usuário não possui permissão para ver a fila
      // sendo assim, a fila é considerada pronta
      const ehErro403 = erro?.status === 403
      if (ehErro403 || this.totalDeRelatoriosEmAndamento === 0) {
        this[variavelDeStatus] = true
      }
    },
    tratarRespostaFilaTravamento (response) {
      // Fila apenas para relatório de travamentos
      // Essa rota é livre de permissoes, sendo assim, só é chamanda quando
      // o usuário não possui as permissoes gerais de relatórios

      if (this.possuiPermissaoDeVerRelatorio) {
        return
      }

      const { data, ready, error, info } = response.body.success

      this.relatorioFilaTravamentoEstaPronto = ready

      this.relatoriosFilaTravamentoEmEndamento = this.obterRelatoriosEmAndamento(data)
      this.totalDeRelatoriosFilaTravamentoEmEndamento = this.relatoriosFilaTravamentoEmEndamento.length

      this.relatorioFilaTravamentoConcluidos = this.obterRelatoriosConcluidos(data)
      if (!this.primeiraVezConsultaFilaTravamento) this.verificarRelatoriosDaFila(this.relatorioFilaTravamentoConcluidos, this.filaDeRelatoriosTravamento)

      this.lidarComErrosEInfos(error, info)

      this.filaDeRelatoriosTravamento = data
      this.primeiraVezConsultaFilaTravamento = false
    },
    tratarRespostaFilaAntiga (response) {
      const { data, ready, error, info } = response.body.success
      this.relatorioFilaAntigaEstaPronto = ready

      this.relatoriosAntigosEmAndamento = this.obterRelatoriosEmAndamento(data)
      this.totalDeRelatoriosAntigosEmAndamento = this.relatoriosAntigosEmAndamento.length

      this.relatoriosAntigosConcluidos = this.obterRelatoriosConcluidos(data)

      if (!this.primeiraVezQueConsultaFilaAntiga) this.verificarRelatoriosDaFila(this.relatoriosAntigosConcluidos, this.filaDeRelatoriosAntigos)

      this.lidarComErrosEInfos(error, info)

      this.filaDeRelatoriosAntigos = data
      this.primeiraVezQueConsultaFilaAntiga = false
    },
    tratarRespostaDaNovaFila (response) {
      const { data, ready, error, info } = response.body.success

      this.relatorioNovaFilaEstaPronto = ready
      // separa relatorios por status
      this.relatoriosEmAndamento = this.obterRelatoriosEmAndamento(data)
      this.totalDeNovosRelatoriosEmAndamento = this.relatoriosEmAndamento.length

      this.relatoriosConcluidos = this.obterRelatoriosConcluidos(data)

      if (!this.primeiraVezQueConsulta) this.verificarRelatoriosDaFila(this.relatoriosConcluidos, this.filaDeRelatorios)

      this.lidarComErrosEInfos(error, info)

      this.filaDeRelatorios = data
      this.primeiraVezQueConsulta = false
    },
    tratarRespostaDoAcompanhamentoDeIntegracao (response) {
      if (!this.possuiFlagIntegracaoAtiva) {
        return
      }

      const { data, ready, error, info } = response.body.success

      this.relatorioAcompanhamentoIntegracaoEstaPronto = ready
      // separa relatorios por status
      this.relatoriosFilaAcompanhamentoDeIntegracaoEmEndamento = this.obterRelatoriosEmAndamento(data)
      this.totalDeRelatoriosFilaAcompanhamentoDeIntegracaoEmEndamento = this.relatoriosFilaAcompanhamentoDeIntegracaoEmEndamento.length

      this.relatorioFilaAcompanhamentoDeIntegracaoConcluidos = this.obterRelatoriosConcluidos(data)

      if (!this.primeiraVezQueConsultaAcompanhamentoDeIntegracao) this.verificarRelatoriosDaFila(this.relatorioFilaAcompanhamentoDeIntegracaoConcluidos, this.filaDeRelatoriosAcompanhamentoDeIntegracao)

      this.lidarComErrosEInfos(error, info)

      this.filaDeRelatoriosAcompanhamentoDeIntegracao = data
      this.primeiraVezQueConsultaAcompanhamentoDeIntegracao = false
    },
    verificarRelatoriosDaFila (dados, dadosAntigos) {
      const novosRelatorios = differenceBy(dados, dadosAntigos, 'url')
      const possuiMaisDeUmRelatorioNovo = novosRelatorios.filter(relatorio => relatorio.url).length > 1

      if (possuiMaisDeUmRelatorioNovo) {
        this.$uiv_notify({
          type: 'success',
          title: 'Vários relatórios foram concluidos com sucesso.',
          content: 'Verifique a aba de notificações.'
        })
      }

      novosRelatorios.forEach(relatorio => {
        if (!relatorio.url) return

        if (!possuiMaisDeUmRelatorioNovo) {
          this.enviarNotificacoes(relatorio.name, relatorio.url)
        }

        // guarda a url do relatório para não notificar novamente
        this.relatoriosLidos.push(relatorio.url)

        let allowDownload = true

        const now = moment().format('YYYY-MM-DD HH:mm')
        // so faz o download automatico se o ultimo download foi a mais de 1 minuto
        if (this.lastNotification) {
          const lastMoment = moment(this.lastNotification)
          const nowMoment = moment(now)
          const diff = nowMoment.diff(lastMoment)
          const d = moment(diff).format('mm')

          if (d > 1) {
            allowDownload = true
          } else {
            allowDownload = false
          }
        }

        if (relatorio === novosRelatorios[0]) {
          this.$store.commit('notification/setLastNotificationNow')
        }

        if (allowDownload) {
          const link = document.createElement('a')
          link.download = relatorio.name
          link.href = relatorio.url
          link.click()
        }
      })
    },
    enviarNotificacoes (nomeRelatorio, urlRelatorio) {
      const relatorioFoiLido = this.relatoriosLidos.includes(urlRelatorio)
      if (relatorioFoiLido) return

      if (Notification.permission !== 'granted') {
        Notification.requestPermission()
      } else {
        const notification = new Notification('Relatório Pontotel', {
          notificationId: 'avoidmultiple',
          tag: 1,
          icon: 'https://storage.googleapis.com/pontotel-publico/LOGO%20120X120.png',
          body: "O relatório '" + nomeRelatorio + "' terminou de ser gerado."
        })

        notification.onclick = function () {
          window.open(urlRelatorio)
        }
      }

      this.notificacoesNaoLidas += 1

      this.$uiv_notify({
        type: 'success',
        title: nomeRelatorio
      })
    },
    async cancelarRelatorio (relatorioId) {
      try {
        await this.$API.cancelarGeracaoDeRelatorio.save({ ids_relatorios: [relatorioId] })

        this.$swal({
          type: 'success',
          title: 'Relatório cancelado com sucesso.'
        })
      } catch (erro) {
        this.$filtradorDeErros.capturarErro(erro)
      }
    },
    obterIdsDeRelatoriosQueEstaoSendoGerados () {
      let relatoriosDaNovaFilaParaCancelar = []

      this.listaDeRelatoriosEmAndamento.map(relatorio => {
        if (relatorio.status === 'GERANDO') {
          relatoriosDaNovaFilaParaCancelar.push(relatorio.id)
        }
      })

      return relatoriosDaNovaFilaParaCancelar
    },
    obterRelatoriosConcluidos (relatorios) {
      let relatoriosConcluidos = []

      relatorios.map(relatorio => {
        if (relatorio.status === 'CONCLUIDO' || relatorio.status === 'SUCCESS') {
          relatoriosConcluidos.push(relatorio)
        } else if (relatorio.url) {
          relatoriosConcluidos.push(relatorio)
        }
      })

      return relatoriosConcluidos
    },
    obterRelatoriosEmAndamento (relatorios) {
      let relatoriosEmAndamento = []

      relatorios.map(relatorio => {
        let relatorioComStatusEmAndamento = relatorio.status === 'NAO_INICIADO' || relatorio.status === 'GERANDO' || relatorio.status === 'PENDING' || relatorio.status === 'STARTED'
        let relatorioPossuiUrl = relatorio.url

        if (relatorioComStatusEmAndamento && !relatorioPossuiUrl) {
          relatoriosEmAndamento.push(relatorio)
        }
      })

      return relatoriosEmAndamento
    },
    lidarComErrosEInfos (erros, info) {
      if (erros) {
        erros.forEach(erro => {
          const notificacaoJaEnviada = this.errosEInfosJaEnviadas.includes(erro?.name || erro?.error)
          if (notificacaoJaEnviada) return

          this.$filtradorDeErros.capturarErro(erro)

          const mensagemDeErro = erro.error
            ? `${erro.error}\nEntre em contato suporte@pontotel.com.br`
            : 'Erro ao gerar Relatório, entre em contato: suporte@pontotel.com.br'

          this.$uiv_notify({
            type: 'danger',
            title: erro.name,
            content: mensagemDeErro
          })
          // identificador para não notificar o erro novamente enquanto ocorrer o polling
          // foi usado o "name" pois o id do relatório com erro pode alterar toda vez que é feita uma chamada na api
          // uma solução é adicionar um id fixo para o relatório no back e depois mudar aqui
          this.errosEInfosJaEnviadas.push(erro?.name || erro?.error)
        })
      }

      if (info) {
        const notificaoJaEnviada = this.errosEInfosJaEnviadas.includes(info)
        if (notificaoJaEnviada) return
        this.$uiv_notify({
          type: 'info',
          content: info
        })

        this.errosEInfosJaEnviadas.push(info)
      }
    },
    alterarStatusDeAndamentoDeTodosOsRelatorios (status) {
      this.relatorioFilaTravamentoEstaPronto = Boolean(status)
      this.relatorioFilaAntigaEstaPronto = Boolean(status)
      this.relatorioNovaFilaEstaPronto = Boolean(status)
      this.relatorioAcompanhamentoIntegracaoEstaPronto = Boolean(status)
    }
  },
  beforeDestroy () {
    // Cancel pending timeouts
    clearTimeout(this.timer)
  }
}
</script>

<style scoped>
.main-header__button--category {
  color: var(--front-color-font-header);
  text-overflow: ellipsis;
  text-shadow: none;
  white-space: nowrap;
  font-weight: 300;
  font-size: 1em;
  padding: 0 .5rem;
  display: flex;
}
.arrow-img {
  position: relative;
  top: 0px;
  left: 2px;
}
.badge {
  display: inline-block;
  min-width: 10px;
  padding: 1px 7px;
  font-size: 12px;
  font-weight: normal;
  color: var(--front-color-header);
  line-height: 1;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  background-color: var(--front-color-font-header);
  border-radius: 10px;
  margin-left:5px;
}

.icon-spin {
  animation-name: spin;
  animation-duration: 800ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
    color: #00A2B5;
    text-decoration: none;
    background-color: #D9F1F4;
    border-left: solid #00A2B5;
    border-width: .5px;
    border-right: none;
}

.dropdown-menu > li > .descricao:hover, .dropdown-menu > li > :focus {
  border: none;
  color: #333333;
  background-color: #FFFFFF;
}

.descricao {
  font-weight: 900;
}

.item-da-lista-de-relatorios {
  width: 580px;
}

.nome-relatorio {
  white-space: normal;
  word-break: break-word;
}

.icones {
  margin-left: 4px;
}
</style>
