import Vue from 'vue'

export default {
  bind (el, binding) {
    if (binding.value) {
      const getElementAttrs = (element) => {
        return [].slice.call(element.attributes).map(attr => attr.name).filter(attr => attr.includes('data-'))[0]
      }

      Vue.nextTick(() => {
        el.setAttribute('disabled', 'true')
        const cont = document.createElement('div')
        const copyClasses = []

        el.classList.forEach(item => {
          if (item !== 'form-control') {
            copyClasses.push(item)
          }
        })
        cont.classList = copyClasses
        el.parentNode.insertBefore(cont, el.nextSibling)
        cont.appendChild(el)

        const scopedStyle = getElementAttrs(el)
        if (scopedStyle) { cont.setAttribute(scopedStyle, '') }

        const loading = document.createElement('div')
        loading.classList.add('input-loading')
        const bar = document.createElement('div')
        bar.classList.add('input-loading-bar')
        loading.appendChild(bar)
        el.parentNode.insertBefore(loading, el.nextSibling)
      })
    }
  },
  update (el, binding) {
    if (binding.value === false) {
      Vue.nextTick(() => {
        el.removeAttribute('disabled')
        el.nextSibling.remove()
      })
    }
  }
}
