var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "estado-inicial" },
    [
      _vm.$slots.default
        ? _vm._t("default")
        : _c("div", { staticClass: "estado-inicial-padrao" }, [
            _c("img", {
              attrs: { src: require("../../../static/icones/lupa.svg") },
            }),
            _c("p", [_vm._v("utilize os filtros acima para fazer a busca")]),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }