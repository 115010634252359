import swal from 'sweetalert2'
import router from 'src/router'
import { API } from 'src/services'
import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'

import {
  vuexMapDefaultsMutations
} from 'src/utils.store'

const retonarTipoDeInfoAfetada = tipoDeInfoAfetada => {
  const tiposDeInfo = {
    'empregado': {id: 'empregado', name: 'os empregados'},
    'regra_de_calculo': {id: 'regra_de_calculo', name: 'as regras de cálculo'},
    'local_de_trabalho': {id: 'local_de_trabalho', name: 'os locais'}
  }

  return tiposDeInfo[tipoDeInfoAfetada]
}

const retornarTipoDeGatilho = tipoDeGatilho => {
  const tiposDeGatilho = {
    'dias_corridos': {id: 'dias_corridos', name: 'do dia'},
    'evento_travamento': {id: 'evento_travamento', name: 'do travamento iniciado'}
  }

  return tiposDeGatilho[tipoDeGatilho]
}

const retornarMensagemDeErro = (erro, mensagemPadrao) => {
  if (erro?.data?.error) {
    return erro.data.error
  }

  if (!mensagemPadrao) {
    return 'Ocorreu um erro ao tentar salvar a regra de travamento.'
  }

  return mensagemPadrao
}

const formatarListasParaRequisicao = listaDeObjetos => {
  let selecionados = listaDeObjetos.selected

  if (!selecionados) {
    return null
  }

  return listaDeObjetos.selected.map(obj => obj.id)
}

const formatarObjetoParaRequisicao = state => {
  let tipoDeGatilho = state.tipoDeGatilho.selected.id
  let diaDoFechamento = state.diaDoFechamento

  if (tipoDeGatilho === 'evento_travamento') {
    diaDoFechamento = null
  }

  let dicionario = {
    nome: state.nome,
    codigo: state.codigo,
    ativo: state.ativo,
    tipo_de_info_afetada: state.tipoDeInfoAfetada.selected.id,
    ids_afetados: formatarListasParaRequisicao(state.idsAfetados),
    tipo_de_gatilho: tipoDeGatilho,
    dias_de_tolerancia: state.diasDeTolerancia,
    tem_prazo_de_travamento: state.temPrazoDeTravamento,
    confirmar_automaticamente: state.confirmarAutomaticamente,
    dia_do_fechamento: diaDoFechamento,
    perfis_responsaveis: formatarListasParaRequisicao(state.perfisResponsaveis),
    tipo_de_evento_de_travamento: state.tipoDeEventoDeTravamento
  }
  return dicionario
}

const formatarObjetoParaGuardarNoState = regraDeTravamento => {
  return {
    id: regraDeTravamento.id,
    nome: regraDeTravamento.nome,
    ativo: regraDeTravamento.ativo,
    codigo: regraDeTravamento.codigo,
    tipoDeGatilho: regraDeTravamento.tipo_de_gatilho,
    tipoDeInfoAfetada: regraDeTravamento.configuracao_de_aplicacao_da_regra.tipo_de_info_afetada,
    idsAfetados: regraDeTravamento.configuracao_de_aplicacao_da_regra.ids_afetados,
    diasDeTolerancia: regraDeTravamento.configuracao_de_gatilho.dias_de_tolerancia,
    temPrazoDeTravamento: regraDeTravamento.configuracao_de_gatilho.tem_prazo_de_travamento,
    confirmarAutomaticamente: regraDeTravamento.configuracao_de_gatilho.confirmar_automaticamente,
    diaDoFechamento: regraDeTravamento.configuracao_de_gatilho.dia_do_fechamento,
    perfisResponsaveis: regraDeTravamento.configuracao_de_gatilho.perfis_responsaveis,
    tipoDeEventoDeTravamento: regraDeTravamento.configuracao_de_gatilho.tipo_de_evento_de_travamento
  }
}

const defaultState = () => {
  return {
    id: null,
    ativo: true,
    nome: '',
    codigo: '',
    tipoDeInfoAfetada: {
      selected: {id: 'regra_de_calculo', name: 'as regras de cálculo'},
      loaded: [
        {id: 'empregado', name: 'os empregados'},
        {id: 'regra_de_calculo', name: 'as regras de cálculo'},
        {id: 'local_de_trabalho', name: 'os locais'}
      ]
    },
    idsAfetados: { selected: [] },
    tipoDeGatilho: {
      selected: {id: 'evento_travamento', name: 'do travamento iniciado'},
      loaded: [
        {id: 'dias_corridos', name: 'do dia'},
        {id: 'evento_travamento', name: 'do travamento iniciado'}
      ]
    },
    diasDeTolerancia: 0,
    temPrazoDeTravamento: true,
    confirmarAutomaticamente: true,
    diaDoFechamento: 1,
    perfisResponsaveis: { selected: [] },
    carregando: false,
    listaDeRegras: []
  }
}

const mutations = {
  ...vuexMapDefaultsMutations(defaultState()),
  resetState (state) {
    resetarParaEstadoInicial(state, defaultState())
  },
  carregando (state, payload) {
    state.carregando = payload
  },
  resetAfetados (state) {
    state.idsAfetados = { selected: [] }
  },
  setarRegraDeTravamento (state, {...regraDeTravamento}) {
    state.id = regraDeTravamento.id
    state.nome = regraDeTravamento.nome
    state.ativo = regraDeTravamento.ativo
    state.codigo = regraDeTravamento.codigo
    state.tipoDeGatilho.selected = retornarTipoDeGatilho(regraDeTravamento.tipo_de_gatilho)
    state.tipoDeInfoAfetada.selected = retonarTipoDeInfoAfetada(regraDeTravamento.configuracao_de_aplicacao_da_regra.tipo_de_info_afetada)
    state.idsAfetados.selected = regraDeTravamento.configuracao_de_aplicacao_da_regra.ids_afetados
    state.diasDeTolerancia = regraDeTravamento.configuracao_de_gatilho.dias_de_tolerancia
    state.temPrazoDeTravamento = regraDeTravamento.configuracao_de_gatilho.tem_prazo_de_travamento
    state.confirmarAutomaticamente = regraDeTravamento.configuracao_de_gatilho.confirmar_automaticamente
    state.diaDoFechamento = regraDeTravamento.configuracao_de_gatilho.dia_do_fechamento
    state.perfisResponsaveis.selected = regraDeTravamento.configuracao_de_gatilho.perfis_responsaveis
    state.tipoDeEventoDeTravamento = regraDeTravamento.configuracao_de_gatilho.tipo_de_evento_de_travamento

    // Object.assign(state, regraDeTravamento)
  },
  setarListaDeRegrasDeTravamento (state, listaDeRegras) {
    let novaListaDeRegras = listaDeRegras.map(regra => formatarObjetoParaGuardarNoState(regra))
    state.listaDeRegras = novaListaDeRegras
  }
}

const actions = {
  async carregarListaDeRegrasDeTravamento ({ commit }, listaDeRegras) {
    commit('setarListaDeRegrasDeTravamento', listaDeRegras)
  },
  async consultarRegraDeTravamento ({ commit }, regraId) {
    commit('carregando', true)

    const api = await API.regraDeTravamento.get({id: regraId})
    const infoRegraDeTravamento = api.data.success[0]

    commit('setarRegraDeTravamento', { id: regraId, ...infoRegraDeTravamento })
    commit('carregando', false)
  },
  async criarOuAtualizarRegraDeTravamento ({ state }) {
    let regraId = state.id
    let regraDeTravamento = formatarObjetoParaRequisicao(state)

    if (regraId) {
      try {
        const resultado = await API.regraDeTravamento.update({ id: regraId }, regraDeTravamento)

        swal({
          type: 'success',
          title: resultado.data.success || 'Regra de travamento atualizada com sucesso.',
          text: resultado.data.obs || null
        })

        router.push('/regras-de-travamento/list')
      } catch (erro) {
        swal({
          type: 'error',
          title: 'Não foi possível salvar a regra',
          confirmButtonText: 'voltar e editar',
          text: retornarMensagemDeErro(erro)
        })
      }
    } else {
      try {
        const resultado = await API.regraDeTravamento.save(regraDeTravamento)

        swal({
          type: 'success',
          title: resultado.data.success || 'Regra de travamento criada com sucesso.',
          text: resultado.data.obs || null
        })

        router.push('/regras-de-travamento/list')
      } catch (erro) {
        swal({
          type: 'error',
          title: 'Não foi possível salvar a regra',
          confirmButtonText: 'voltar e editar',
          text: retornarMensagemDeErro(erro)
        })
      }
    }
  },
  async ativarOuDesativarRegraDeTravamento ({ commit }, payload) {
    let acao = payload['acao']
    let regraId = payload['regraId']

    try {
      const resultado = await API.atualizarRegraDeTravamento.patch({ id: regraId, acao: acao })
      const dicionario = {
        'ativar': 'ativada',
        'desativar': 'desativada'
      }

      swal({
        type: 'success',
        title: resultado.data.success || `Regra de travamento ${dicionario[acao]} com sucesso.`,
        text: resultado.data.obs || null
      })
    } catch (erro) {
      let mensagemPadrao = `Ocorreu um erro ao tentar ${acao} a regra de travamento`

      swal({
        type: 'error',
        title: `Não foi possível ${acao} a regra`,
        text: retornarMensagemDeErro(erro, mensagemPadrao)
      })
    }
  },
  async deletarRegraDeTravamento ({ commit }, payload) {
    let regraId = payload['regraId']

    try {
      const resultado = await API.regraDeTravamento.delete({ id: regraId })

      swal({
        type: 'success',
        title: resultado.data.success || 'Regra de travamento excluída com sucesso.',
        text: resultado.data.obs || null
      })
    } catch (erro) {
      swal({
        type: 'error',
        title: 'Não foi possível excluir a regra',
        text: 'Ocorreu um erro ao tentar excluir a regra de travamento'
      })
    }
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  mutations,
  actions
}
