<template lang="pug">
  label.p-radio.p-radio__label
    input.p-radio__input(
      type="radio",
      v-model="state",
      :value="option ? option : label",
      v-on="localListeners",
      v-bind="localAttributes",
      :disabled="disabled || loading",
      :class="classInput"
      ref="radio"
    )
    div.p-radio__indicator
    span(:class="classLabel" :style="estilizacaoCustomizavel") {{ label }}
</template>

<script>
import { handlerUseStateVmodel } from '@/common/mixins/handlerUseStateVmodel'

export default {
  name: 'PRadio',
  mixins: [handlerUseStateVmodel()],
  data () {
    return {
      localListeners: undefined,
      localAttributes: undefined
    }
  },
  props: {
    label: { type: String, required: true },
    disabled: Boolean,
    loading: Boolean,
    option: { type: String },
    labelFontSize: { type: String, default: '14px' }
  },
  created () {
    this.localListeners = Object.keys(this.$listeners)
      .filter(key => key !== 'input')
      // eslint-disable-next-line no-sequences
      .reduce((res, key) => (res[key] = this.$listeners[key], res), {}) // eslint-disable-line no-return-assign

    this.localAttributes = Object.keys(this.$attrs)
      .filter(key => key !== 'type' && key !== 'disabled' && key !== 'value')
      // eslint-disable-next-line no-sequences
      .reduce((res, key) => (res[key] = this.$attrs[key], res), {}) // eslint-disable-line no-return-assign
  },
  updated () {
  },
  computed: {
    estilizacaoCustomizavel () {
      return `
        font-size: ${this.labelFontSize};
      `
    },
    classLabel () {
      return {
        'p-radio__label--loading': this.loading,
        'p-radio__label--disabled': this.disabled
      }
    },
    classInput () {
      return {
        'p-radio__input--loading': this.loading,
        'p-radio__input--disabled': this.disabled
      }
    }
  }
}
</script>

<style scoped>

.p-radio {
  margin-bottom: 5px;
  padding-top: 2px;
  font-size: 14px;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: grid;
  align-items: center;
  grid-template-columns: 25px 1fr;
  grid-template-rows: auto;
  align-items: center;
  grid-template-areas:
    "indicator label";
}

.p-radio span {
  grid-area: label;
}

.p-radio .p-radio__input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.p-radio__indicator {
  grid-area: indicator;
  position: relative;
  height: 12px;
  width: 12px;
  background: #fff;
  border: 1px solid var(--color-default);
  cursor: pointer;
  margin-right: 5px;
}

.p-radio__label .p-radio__indicator {
  border-radius: 50%;
}

.p-radio:hover .p-radio__input:not([disabled]) ~ .p-radio__indicator {
  background: #fff;
  opacity: .8;
}
.p-radio:hover .p-radio__input:not([disabled]) ~ span,
.p-radio__input:focus ~ span  {
  color: var(--color-primary);
  font-weight: bold;
}

.p-radio:hover .p-radio__input ~ .p-radio__indicator,
.p-radio .p-radio__input:focus ~ .p-radio__indicator{
  background: #fff;
}

.p-radio .p-radio__input:checked ~ .p-radio__indicator {
  background: #fff;
}

.p-radio:hover .p-radio__input:not([disabled]):checked ~ .p-radio__indicator,
.p-radio .p-radio__input:checked:focus ~ .p-radio__indicator {
  background: #fff;
}

.p-radio .p-radio__input:disabled ~ .p-radio__indicator {
  background: #fff;
  opacity: 0.6;
  pointer-events: none;
}

.p-radio__indicator:after {
  box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}

.p-radio .p-radio__input:checked ~ .p-radio__indicator:after {
  display: block;
}

.p-radio__label .p-radio__indicator:after {
  top: 2px;
  left: 2px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  animation: anim-radio-check 0.2s ease;
  animation-fill-mode: forwards;
}

.p-radio__label .p-radio__input:disabled ~ .p-radio__indicator:after {
  border-color: var(--color-default-disabled);
}

.p-radio__label .p-radio__input:disabled ~ .p-radio__indicator {
  border-color: var(--color-default-disabled);
}

.p-radio__label--disabled {
  color: var(--color-default-disabled);
}

.p-radio__label--loading {
  color: transparent;
  background-color: var(--color-default-disabled);
}

@keyframes anim-radio-check {
  0% {
    background: none;
    transform: scale(0);
  }
  80% {
    background: var(--color-primary);
    transform: scale(1.2);
  }
  100% {
    background: var(--color-primary);
    transform: scale(1);
  }
}

</style>
