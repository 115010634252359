import moment from 'moment'
import store from 'src/store'
import { multiSelect } from 'src/common/selectors'
import { obterDataValida } from 'src/utils/formatadoras/formatadoras'

function defaultState () {
  return {
    ...multiSelect.mapStates(['companies', 'employees', 'userGroups', 'perfis']),
    startDate: moment().startOf('month').toDate(),
    endDate: moment().endOf('month').toDate(),
    allApprovals: [],
    buscarDadosTelaInicial: false
  }
}

const mutations = {
  ...multiSelect.mapMutations(['companies', 'employees', 'userGroups', 'perfis']),
  startDate: (state, payload) => { state.startDate = obterDataValida(payload) },
  endDate: (state, payload) => { state.endDate = obterDataValida(payload) },
  allApprovals (state, payload) {
    state.allApprovals = payload
  },
  atualizarApenarUmaSolicitacao (state, {solicitacao, indice}) {
    state.allApprovals.splice(indice, 1, solicitacao)
  },
  buscarDadosTelaInicial (state, payload) {
    state.buscarDadosTelaInicial = payload
  }
}

const getters = {
  dataDeMigracaoParaNovoModuloDeSolicitacoes () {
    if (!store.state.userInfo?.compMan?.dataDeMigracaoParaNovoModuloDeAprovacoes) {
      return null
    }
    return moment.utc(store.state.userInfo.compMan.dataDeMigracaoParaNovoModuloDeAprovacoes)
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  getters,
  mutations
}
