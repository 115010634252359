import Vue from 'vue'
import { handlerUseStateVmodel } from '../mixins/handlerUseStateVmodel'
import { includesSearch } from 'src/utils'
import { montarItemsDaListaEmBlocos } from 'src/utils/formatadoras/formatadoras'
import { obterComNotacaoPonto } from 'src/utils/outro/outro'
import { debounce } from 'lodash'

export default {
  mixins: [handlerUseStateVmodel(true)],
  data () {
    return {
      realCurrentPage: 0,
      search: {},
      lastSearchedField: null,
      currentFieldSort: {field: null, order: 'asc'},
      resetPagesAndReloadDebouncedFunc: null
    }
  },
  created () {
    this.realCurrentPage = this.mergedConfig.startPage
    this.setDataSearchFieldsToBeWatchable()
    this.resetPagesAndReloadDebouncedFunc = debounce(this.resetPagesAndReload, 1000)
  },
  watch: {
    headerItems (newValue) {
      this.setDataSearchFieldsToBeWatchable()
    }
  },
  computed: {
    items () {
      let items = this.state.loaded.map((i, realIndex) => ({...i, realIndex}))
      // 1º Apply filters

      const getField = (item, field) => obterComNotacaoPonto(item, field)

      this.searchingFields.forEach(({field, filterFunction}) => {
        items = items.filter(item => {
          if (!filterFunction && !getField(item, field)) return false

          return filterFunction
            ? filterFunction({search: this.search[field], item})
            : includesSearch(getField(item, field), this.search[field])
        })
      })

      // 2º Sort items

      // we only apply order if are not bulk loading, otherwise the items will flash cuz they has no specific order

      if (!this.isBulkLoading && this.currentFieldSort.field) {
        const fieldToSort = this.currentFieldSort.field
        const order = this.currentFieldSort.order
        const header = this.headerItems.find(i => i.field === fieldToSort)

        items.sort((a, b) => {
          const valA = getField(a, fieldToSort)
          const valB = getField(b, fieldToSort)

          if (header.sortFunction) {
            // this function must return -1, 0 or 1, otherwise sort must not end as expected
            return header.sortFunction({field: fieldToSort, order, itemA: a, itemB: b})
          }

          if (valA === valB) return 0
          if (valA > valB) return order === 'asc' ? 1 : -1
          if (valA < valB) return order === 'desc' ? 1 : -1
        })
      }

      return items
    },
    searchingFields () {
      return this.headerItems.filter(i => this.search[i.field])
    },
    toDisplay () {
      return this.isServerSidePaginated
        ? this.serverSidePagination.loadedPages[this.currentPage]
        : montarItemsDaListaEmBlocos(this.items, this.mergedConfig.perPage)[this.currentPage]
    },
    totalPages () {
      if (this.isServerSidePaginated) return this.serverSidePagination.totalPages

      if (this.internalLoad.totalItems && !this.searchingFields.length) {
        return Math.ceil(this.internalLoad.totalItems / this.mergedConfig.perPage)
      }

      return montarItemsDaListaEmBlocos(this.items, this.mergedConfig.perPage).length
    },
    currentPage: {
      set (val) {
        this.realCurrentPage = val
      },
      get () {
        // the number of pages will decrease depending on filters that user are doing
        // this prevents to crash if the number of pages be less than the total of pages filtered
        return Math.max(Math.min(this.realCurrentPage, this.totalPages - 1), 0)
      }
    }
  },
  methods: {
    async changePage (number) {
      this.closeDetailsBox()
      this.currentPage = number
      if (this.isServerSidePaginated) await this.loadPageDataUsingGivenFunc()
    },
    buildHeaderEvents (item) {
      return {
        search: (field, newValue) => {
          this.search[field] = newValue
          if (newValue) {
            this.lastSearchedField = field
          }
          this.resetPagesAndReloadDebouncedFunc()
        },
        order: (field, order) => {
          this.currentFieldSort = {field, order}
          this.resetPagesAndReload()
        },
        focusFirstRow: () => this.focusRow(0)
      }
    },
    buildBinds (type, item, index) {
      const activeDetails = this.isServerSidePaginated
        ? this.detailsBox.itemId === item.id
        : this.detailsBox.index === item.realIndex

      const search = this.search[item.field]

      const options = {
        header: () => ({...item, index, search, currentFieldSort: this.currentFieldSort}),
        body: () => (
          {
            index,
            item,
            header: this.header,
            onClick: this.rowClick,
            activeDetails,
            onKeydown: this.keyboardEventRow
          }
        )
      }

      return options[type]()
    },
    setDataSearchFieldsToBeWatchable () {
      this.headerItems.forEach(i => {
        // as this fields are dynamic based on where the component is being used
        // we need to use Vue.set to make things reactive
        if (!this.isServerSidePaginated && this.mergedConfig.defaultSortField) {
          this.currentFieldSort.field = this.mergedConfig.defaultSortField
        }

        const value = this.isServerSidePaginated
          ? this.search[i.field] || ''
          : ''

        Vue.set(this.search, i.field, value)
      })
    }
  }
}
