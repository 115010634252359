import { API, retryRequest } from 'src/services'
import { congelarObjetosDaLista } from 'src/utils/formatadoras/formatadoras'

const defaultOnly = [
  'id', 'name', 'email', 'compemployee', 'role_group'
]
const PER_PAGE = 30

let timer

export default {
  namespaced: true,
  state: {
    page: 0,
    items: [],
    loading: false,
    deleted: false,
    totalPages: 0,
    perfilSelecionado: 'empregado'
  },
  getters: {
    pegarUsuariosDoPerfil (state) {
      return state.items
    },
    pegarPerfilSelecionado (state) {
      return state.perfilSelecionado
    },
    aindaFaltaMostrarPaginas (state) {
      const {page, totalPages, items} = state
      const saoNumeros = [page, totalPages].every(v => typeof v === 'number')
      const possuiMenosQuePerPage = items.length < PER_PAGE
      const maisPaginasAlemDaAtual = totalPages > page
      return saoNumeros && !possuiMenosQuePerPage && maisPaginasAlemDaAtual
    }
  },
  mutations: {
    saveItems (state, payload) {
      state.items = payload
      state.page = 0
      state.loading = false
    },
    appendItems (state, payload) {
      state.items = [...state.items, ...payload]
      state.page = state.page + 1
      state.loading = false
    },
    setDeleted (state, payload) {
      state.deleted = payload
    },
    reset (state) {
      state.items = []
      state.page = 1
    },
    loading (state, payload) {
      state.loading = Boolean(payload)
    },
    setTotalPages (state, payload) {
      state.totalPages = payload
    },
    setPage (state, payload) {
      state.page = payload
    },
    setPerfilSelecionado (state, payload) {
      state.perfilSelecionado = payload
    }
  },
  actions: {
    mudarPerfilSelecionado ({ commit }, { perfilSelecionado }) {
      commit('setPerfilSelecionado', perfilSelecionado)
    },
    async fetchItems ({ state, commit }, payload) {
      payload = payload || {}

      commit('loading', true)

      const { withFields = [], fullReload, deleted } = payload

      if (fullReload) {
        commit('resetItems')
      }
      commit('setDeleted', deleted === 'true')
      const only = Array.from(new Set([...defaultOnly, ...withFields]))

      const result = await retryRequest(API.userPerfil.get, {
        perfil: state.perfilSelecionado,
        fields: JSON.stringify(only),
        order_by: 'name',
        deleted: deleted
      })
      commit('saveItems', congelarObjetosDaLista(result.body.success))
    },
    async fetchItemsComPaginacao ({ state, commit }, payload = {}) {
      commit('loading', true)

      clearTimeout(timer)
      timer = setTimeout(async () => {
        const { withFields = [], fullReload, deleted, search, append = false } = payload
        const {page} = state

        if (fullReload) {
          commit('resetItems')
        }
        commit('setDeleted', deleted === 'true')
        const only = Array.from(new Set([...defaultOnly, ...withFields]))

        // append define se iremos adicionar no final (mais itens carregados)
        const paginaParaRequisicao = append ? (page + 1) : 0

        const result = await retryRequest(API.userPerfil.get, {
          perfil: state.perfilSelecionado,
          fields: JSON.stringify(only.filter(f => f !== 'compemployee')),
          search,
          order_by: 'name',
          deleted: deleted,
          page: paginaParaRequisicao,
          per_page: PER_PAGE
        })

        if (result.status === 204) {
          result.body = {
            success: [],
            total: 0,
            totalPages: 0
          }
        }

        const {totalPages, success} = result.body

        commit('setTotalPages', totalPages)
        commit('setPage', paginaParaRequisicao)

        if (append) {
          commit('appendItems', congelarObjetosDaLista(success))
        } else {
          commit('saveItems', congelarObjetosDaLista(success))
        }
      }, 500)
    }
  }
}
