import Vue from 'vue'
import {range} from 'src/utils'
import { copiarObjetoProfundamente } from 'src/utils/formatadoras/formatadoras'

const defaultState = () => ({
  // object with two layers deep, first is row and second is col
  // e.g: {0: {5: true, 4: false}}
  selectedDays: null,
  selectionBox: {
    first: null, // {rowNumber, colNumber} of the first selected item with shift
    last: null
  }
})

const __state = defaultState()

const getters = {
  allSelectedDaysWithInfos (state, getters, rootState) {
    const selectedDaysInfos = []
    if (!state.periodData || !state.selectedDays || rootState.workSchedule.populatingScheduler) {
      return []
    }

    const selectedDays = getters.selectedDays

    state.periodData.forEach((ignore, rowNumber) => {
      const indexDays = range(0, state.periodData[0].period.length - 1)
      indexDays.forEach(colNumber => {
        if (selectedDays[rowNumber][colNumber] === true) {
          selectedDaysInfos.push({
            rowNumber,
            colNumber,
            employee: state.periodData[rowNumber].employee,
            day: state.periodData[rowNumber].period[colNumber].day
          })
        }
      })
    })
    return selectedDaysInfos
  },
  selectedDays (state) {
    if (!state.periodData || !state.selectedDays) {
      return {}
    }

    const finalSelected = copiarObjetoProfundamente(state.selectedDays)

    // SelectionBox logic block
    if (state.selectionBox.last) {
      const {first, last} = state.selectionBox

      const toSelect = calcRowColsSelectedInBox(first, last)

      toSelect.forEach(({rowNumber, colNumber}) => {
        if (state.periodData[rowNumber].period[colNumber].allowChange) {
          finalSelected[rowNumber][colNumber] = true
        }
      })
    }

    return finalSelected
  }
}

export function buildDeepObjectKeys (state) {
  // generate a deep dict where is two deep
  // first layer is the rows number
  // the values of first layer is another object with col values. Values are booleans
  // eg , rows=2, cols=2 returns {0: {0: false, 1:false}, 1: {0: false, 1:false}}

  if (!state.periodData.length) {
    return {}
  }

  const rows = state.periodData.length
  const cols = state.periodData[0].period.length

  const final = {}
  for (let i = 0; i < rows; i++) {
    const colData = {}
    for (let c = 0; c < cols; c++) {
      colData[c] = false
    }
    final[i] = colData
  }
  return final
}

function calcRowColsSelectedInBox (first, last) {
  if (!first || !last) {
    throw new Error('calcRowColsSelectedInBox function missing arguments')
  }

  const toSelect = []

  const min = Math.min.apply(this, [first.rowNumber, last.rowNumber])
  const max = Math.max.apply(this, [first.rowNumber, last.rowNumber])

  for (let r = min; r <= max; r++) {
    const min = Math.min.apply(this, [first.colNumber, last.colNumber])
    const max = Math.max.apply(this, [first.colNumber, last.colNumber])

    for (let c = min; c <= max; c++) {
      toSelect.push({rowNumber: r, colNumber: c})
    }
  }
  return toSelect
}

const mutations = {
  resetSelectionBox (state) {
    state.selectionBox = {first: null, last: null}
  },
  resetSelectedDays (state) {
    Vue.set(state, 'selectedDays', buildDeepObjectKeys(state))
    state.selectionBox = {first: null, last: null}
  },
  selectItem (state, {rowNumber, colNumber}) {
    if (state.periodData[rowNumber].period[colNumber].allowChange) {
      state.selectedDays[rowNumber][colNumber] = true
    }
  },
  unSelectItem (state, {rowNumber, colNumber}) {
    state.selectedDays[rowNumber][colNumber] = false
  },
  unSelectDays (state, listRowsCols) {
    listRowsCols.forEach(({rowNumber, colNumber}) => {
      state.selectedDays[rowNumber][colNumber] = false
    })
  },
  selectionBox (state, {rowNumber, colNumber, type, apply}) {
    if (!['first', 'last'].includes(type)) {
      throw new Error('selectionBox mutation received an invalid type')
    }

    state.selectionBox[type] = {rowNumber, colNumber}

    if (apply && state.selectionBox.first && state.selectionBox.last) {
      const toSelect = calcRowColsSelectedInBox(
        state.selectionBox.first, state.selectionBox.last
      )

      toSelect.forEach(({rowNumber, colNumber}) => {
        if (state.periodData[rowNumber].period[colNumber].allowChange) {
          state.selectedDays[rowNumber][colNumber] = true
        }
      })

      state.selectionBox = {first: null, last: null}
    }
  }
}

const actions = {
}

export default {
  namespaced: false,
  state: __state,
  getters,
  mutations,
  actions
}
