var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "ul",
      [
        _c(
          "li",
          {
            on: {
              click: function ($event) {
                return _vm.goTo(0)
              },
            },
          },
          [_c("a", [_vm._v("<<")])]
        ),
        _c(
          "li",
          {
            on: {
              click: function ($event) {
                return _vm.goTo(_vm.currentPage - 1)
              },
            },
          },
          [_c("a", [_vm._v("<")])]
        ),
        _vm._l(_vm.pages, function (page) {
          return _c(
            "li",
            {
              class: { active: page - 1 === _vm.currentPage },
              on: {
                click: function ($event) {
                  return _vm.goTo(page - 1)
                },
              },
            },
            [_c("a", [_vm._v(_vm._s(page))])]
          )
        }),
        !_vm.pages.includes(_vm.totalPages - 1)
          ? [
              _vm._m(0),
              _c(
                "li",
                {
                  on: {
                    click: function ($event) {
                      return _vm.goTo(_vm.totalPages - 1)
                    },
                  },
                },
                [_c("a", [_vm._v(_vm._s(_vm.totalPages))])]
              ),
            ]
          : _vm._e(),
        _c(
          "li",
          {
            on: {
              click: function ($event) {
                return _vm.goTo(_vm.currentPage + 1)
              },
            },
          },
          [_c("a", [_vm._v(">")])]
        ),
        _c(
          "li",
          {
            on: {
              click: function ($event) {
                return _vm.goTo(_vm.totalPages - 1)
              },
            },
          },
          [_c("a", [_vm._v(">>")])]
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("a", [_vm._v("...")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }