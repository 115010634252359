export function elementFocusable (refElementToFocus = 'defaultFocus') {
  // Use this mixin to create a method in the component called focus
  // that already find the element in $refs that must be focused
  // to it works you need a element in component with the ref as the param to this mixin
  return {
    methods: {
      focus () {
        if (this.$refs[refElementToFocus]) {
          this.$refs[refElementToFocus].focus()
        }
      }
    }
  }
}
