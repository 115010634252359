import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'

import {
  vuexMapDefaultsMutations,
  vuexMapDefaultsActions
} from 'src/utils.store'

import { multiSelect } from 'src/common/selectors'
// import moment from 'moment'
// import { API } from 'src/services'

function defaultState () {
  return {
    ...multiSelect.mapStates(['companies', 'employees']),
    id: null,
    type: null,
    startDate: null,
    endDate: null,
    activities: [],
    loading: false
    // currentMonth: {name: 'Janeiro', number: 1},
    // showSendActivity: false,
    // showActivitySums: false,
    // kind: 'all',
  }
}

const mutations = {
  ...multiSelect.mapMutations(['companies', 'employees']),
  ...vuexMapDefaultsMutations(defaultState()),
  resetState (state) {
    const toBe = defaultState()
    delete toBe['companies']
    delete toBe['employees']
    resetarParaEstadoInicial(state, toBe)
    state.companies.selected = null
    state.employees.selected = null
  },
  setFromState (state, {...company}) {
    Object.assign(state, company)
  },
  loading (state, payload) {
    state.loading = payload
  }
}

const actions = {
  ...vuexMapDefaultsActions(defaultState())
}

export default {
  namespaced: true,
  state: defaultState(),
  mutations,
  actions
}
