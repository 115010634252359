export default {
  bind: function (el, binding) {
    el.addEventListener('scroll', () => {
      if (binding.value.condition !== false) {
        if (el.offsetHeight + el.scrollTop >= el.scrollHeight) {
          binding.value.callback()
        }
      }
    })
  }
}
