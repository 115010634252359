export function localListenersAndAttributes () {
  return {
    data () {
      return {
        localListeners: undefined,
        localAttributes: undefined
      }
    },
    created () {
      this.localListeners = Object.keys(this.$listeners)
        .filter(key => key !== 'input')
        // eslint-disable-next-line no-sequences
        .reduce((res, key) => (res[key] = this.$listeners[key], res), {}) // eslint-disable-line no-return-assign

      this.localAttributes = Object.keys(this.$attrs)
        .filter(key => key !== 'disabled')
        // eslint-disable-next-line no-sequences
        .reduce((res, key) => (res[key] = this.$attrs[key], res), {}) // eslint-disable-line no-return-assign
    }
  }
}
