var render = function render(_c, _vm) {
  return _c(
    "div",
    { staticClass: "details-box-label" },
    [
      _c(
        "label",
        [
          _vm._t("title", function () {
            return [_vm._v(_vm._s(_vm.props.title))]
          }),
          _vm.props.tooltipComDica
            ? _c("span", {
                directives: [
                  {
                    name: "uiv-popover",
                    rawName: "v-uiv-popover.bottom.hover",
                    value: { content: _vm.props.tooltipComDica },
                    expression: "{content: props.tooltipComDica}",
                    modifiers: { bottom: true, hover: true },
                  },
                ],
                staticClass: "icone-chapado-info",
              })
            : _vm._e(),
        ],
        2
      ),
      !_vm.props.loading && !_vm.slots.cleanBody
        ? _c(
            "b",
            [
              _vm._t("body", function () {
                return [_vm._v(_vm._s(_vm.props.body))]
              }),
            ],
            2
          )
        : _c("div", { staticClass: "loading" }),
      !_vm.props.loading ? _vm._t("cleanBody") : _vm._e(),
      _vm.props.copyicon
        ? _c("img", {
            staticClass: "copyicon",
            attrs: { src: require("../../../static/images/copy_content.svg") },
            on: {
              click: function ($event) {
                return _vm.$options.methods.copy(_vm.props.body)
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }