import store from 'src/store'
import { FiltradorDeErros } from 'src/erros'
import { CorpoDaMensagemWorker, RetornoErroAPIRenovacaoTokenKeycloak, RetornoSucessoAPIRenovacaoTokenKeycloak } from './keycloak.interface'

interface WindowComWorker extends Window {
  __keycloakRefreshTokenWorker?: Worker;
}

declare let window: WindowComWorker

/**
 * obs: O client_id é o id da aplicação que está
 * acessando o keycloak, e não a compman_id
 */
const CLIENT_ID = 'pontotel'
const CAMINHO_WORKER = '/js/keycloakRefreshToken.js'

/**
 * @description função chamada pelo router.afterEach para iniciar o worker de renovação de token do keycloak
 * @throws em caso de erro desloga o usuário e envia para o sentry
 */
export const inicializarKeycloakRefreshTokenWorker = (): void => {
  if (!store.state.auth.renovacaoTokenKeycloakAPI) {
    return
  }

  try {
    if (!window) {
      throw new Error('O objeto window não foi encontrado.')
    }

    if (window.__keycloakRefreshTokenWorker) {
      enviarMensagemParaWorker(true)
      return
    }

    setTimeout(() => {
      criarWorker()
      adicionarEventoDeEscuta()
      enviarMensagemParaWorker()
    }, 300)
  } catch (erro) {
    store.dispatch('logout')
    FiltradorDeErros.capturarErro(erro as Error)
  }
}

const criarWorker = (): void => {
  const keycloakRefreshTokenWorker = new Worker(CAMINHO_WORKER)

  window.__keycloakRefreshTokenWorker = keycloakRefreshTokenWorker
}

/**
 * @description Adiciona um evento de escuta para lidar
 * com a resposta do worker de renovação de token do keycloak
 */
const adicionarEventoDeEscuta = (): void => {
  if (!window.__keycloakRefreshTokenWorker) {
    throw new Error('O worker não foi encontrado no objeto window.')
  }

  // TODO - Se recebermos um retorno de erro e o usuário não estiver autenticado, poderia interromper o worker
  window.__keycloakRefreshTokenWorker.addEventListener('message', (evento) => {
    try {
      const resposta = JSON.parse(evento.data) as RetornoSucessoAPIRenovacaoTokenKeycloak

      if ('error' in resposta) {
        throw new Error((resposta as unknown as RetornoErroAPIRenovacaoTokenKeycloak).error_description)
      }

      store.commit('cognitoTokens', {
        authorization: `Bearer ${resposta.access_token}`,
        accessToken: null,
        refreshToken: resposta.refresh_token,
        definirDataCriacaoToken: true
      })
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro as Error)
    }
  })
}

/**
 * Envia uma mensagem para o worker para atualizar o token.
 * @param verificarTempoToken - Caso true, o worker irá verificar se o token está próximo de expirar e renová-lo sem aguardar o setInterval.
 * ou somente registrar o worker com a função de renovação sendo executada nos próximos minutos(minutos definidos dentro do worker).
 * @throws {Error} - Se o worker não for encontrado no objeto window.
 */
const enviarMensagemParaWorker = (verificarTempoToken = false): void => {
  if (!window.__keycloakRefreshTokenWorker) {
    throw new Error('O worker não foi encontrado no objeto window.')
  }

  const { refreshToken, renovacaoTokenKeycloakAPI, dataCriacaoToken } = store.state.auth

  const corpoDaMensagem: CorpoDaMensagemWorker = {
    renovacaoTokenKeycloakAPI: renovacaoTokenKeycloakAPI,
    refreshToken: refreshToken,
    clientId: CLIENT_ID,
    dataCriacaoToken: dataCriacaoToken,
    verificarTempoToken
  }

  const corpoDaMensagemString = JSON.stringify(corpoDaMensagem)

  window.__keycloakRefreshTokenWorker.postMessage(corpoDaMensagemString)
}

/**
 * Desliga o worker de renovação de token do keycloak.
 * @returns {void}
 */
export const desativarWorker = (): void => {
  if (!window.__keycloakRefreshTokenWorker) {
    return
  }

  window.__keycloakRefreshTokenWorker.terminate()
  window.__keycloakRefreshTokenWorker = undefined
}
