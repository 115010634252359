import { IRegraDeNotificacao, IResposeRegraDeNotificacao } from '../dominio/regraNotificacao.interface'
import { IRegraDeNotificacaoRepositorio } from '../dominio/regraNotificacaoRepositorio.interface'
import { API } from 'src/services'

export class RegraDeNotificacaoRepositorio implements IRegraDeNotificacaoRepositorio {
  async obterRegrasDeNotificacoes (parametrosDeBusca: unknown): Promise<IResposeRegraDeNotificacao> {
    return API.regrasDeNotificacao.get(parametrosDeBusca)
  }
  async removerRegraDeNotificacao (idRegraNotificacao: Pick<IRegraDeNotificacao, 'id'>): Promise<unknown> {
    return API.regrasDeNotificacao.delete(idRegraNotificacao)
  }
  async obterRegraDeNotificacao (idRegraNotificacao: Pick<IRegraDeNotificacao, 'id'>): Promise<IResposeRegraDeNotificacao> {
    return API.regrasDeNotificacao.get({id: idRegraNotificacao})
  }
  async criarRegraDeNotificacao (regrasDeNotificacoes: IRegraDeNotificacao[]): Promise<unknown> {
    return API.regrasDeNotificacao.save({regrasDeNotificacao: regrasDeNotificacoes})
  }
  async atualizarRegraDeNotificacao (idRegraNotificacao: Pick<IRegraDeNotificacao, 'id'>, camposAlterados: Partial<IRegraDeNotificacao>): Promise<unknown> {
    if (camposAlterados.usuariosNotificados && camposAlterados.usuariosNotificados?.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      camposAlterados.usuariosNotificados = [camposAlterados.usuariosNotificados[0].id] as any
    }
    return API.editarRegrasDeNotificacao.patch({id: idRegraNotificacao}, {...camposAlterados})
  }
}
