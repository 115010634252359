import auth from './auth'
import userInfo from './userInfo'
import visual from './visual'
import frontOptions from './frontOptions'

export default {
  getters: { ...auth.getters, ...userInfo.getters, ...visual.getters, ...frontOptions.getters },
  mutations: { ...auth.mutations, ...userInfo.mutations, ...visual.mutations },
  actions: { ...auth.actions, ...userInfo.actions, ...visual.actions }
}
