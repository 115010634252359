import simpleChildrenWrapper from './wrapper'

import {wp, ws} from 'src/services'

import moduloCustomizacoesDeCalculo from 'src/components/CustomizacoesDeCalculo'

import moduloDeColetores from 'src/components/Coletores'

import moduloParametrizadorE02 from 'src/components/ParametrizadorE02'

const Cognito = () => import('src/components/Cognito/MainScreens/Cognito.vue')
const ConfirmSignUp = () => import('src/components/Cognito/MainScreens/ConfirmSignUp.vue')
const Home = () => import('@/components/Home/Home.vue')
const Example = () => import('src/common/Example')
const Timesheet = () => import('src/components/Sheets/Timesheet/Timesheet.vue')
const WhbManagement = () => import('src/components/Whb/WhbManagement.vue')
const ExtraTimeDash = () => import('src/components/DashBoards/ExtraTimeDash')
const PainelDePontos = () => import('src/components/PainelDePontos/PainelDePontos')
const VerifyTimesheet = () => import('src/components/Sheets/VerifyTimesheet/VerifyTimesheet')
const GestaoDeSolicitacoes = () => import('src/components/Approvals/GestaoDeSolicitacoes')
const REP = () => import('src/components/REP/repList.vue')
const RepDetails = () => import('src/components/REP/repDetails/RepDetails')
const PegaPontoDetails = () => import('src/components/REP/pegaPontoDetails/PegaPontoDetails')
// const DisciplineRulesList = () => import('src/components/DisciplineRules/list/DisciplineRulesList.vue')
// const DisciplineRulesDetails = () => import('src/components/DisciplineRules/details/DisciplineRulesDetails.vue')
const ApprovalsRulesList = () => import('src/components/ApprovalsRules/list/ApprovalsRulesList.vue')
const LockerRulesList = () => import('src/components/LockerRules/list/LockerRulesList.vue')
const LockerRulesDetails = () => import('src/components/LockerRules/details/LockerRulesDetails.vue')
const ApprovalsRulesDetails = () => import('src/components/ApprovalsRules/details/ApprovalsRulesDetails')
const WorkSchedule = () => import('src/components/WorkSchedule/WorkSchedule.vue')
const CompanyList = () => import('src/components/Company/list/CompanyList.vue')
const CompanyDetails = () => import('src/components/Company/details/CompanyDetails.vue')
const EmployeeList = () => import('src/components/Employee/list/EmployeeList.vue')
const EmployeeDetails = () => import('src/components/Employee/details/EmployeeDetails.vue')
const MultipleChanges = () => import('src/components/Employee/multipleChanges/MultipleChanges.vue')
const SupervisorDetails = () => import('src/components/Supervisor/details/SupervisorDetails.vue')
const SupervisorList = () => import('src/components/Supervisor/list/SupervisorList.vue')
const EmployerList = () => import('src/components/Employer/list/EmployerList.vue')
const EmployerDetails = () => import('src/components/Employer/details/EmployerDetails.vue')
const ReasonList = () => import('src/components/Reason/list/ReasonList.vue')
// const ReasonDetails = () => import('src/components/Reason/details/ReasonDetails.vue')
const GroupsList = () => import('src/components/Groups/GroupsList.vue')
const UserList = () => import('src/components/User/list/UserList.vue')
const DetalhesDadosDaEmpresa = () => import('src/components/CompMan/DetalhesDadosDaEmpresa.vue')
const MassChanges = () => import('src/components/MassChanges/MassChanges.vue')
const JourneyList = () => import('src/components/Journey/JourneyList.vue')
const RoutineDetails = () => import('src/components/Routine/details/RoutineDetails.vue')
const RoutineList = () => import('src/components/Routine/list/RoutineList.vue')
const HolidayList = () => import('src/components/Holiday/list/HolidayList.vue')
const LockerManagement = () => import('src/components/Locker/LockerManagement.vue')
const MonitorWorkplace = () => import('src/components/Monitors/Workplace/MonitorWorkplace.vue')
const MonitorEntrance = () => import('src/components/Monitors/Entrance/MonitorEntrance.vue')
const ListagemDasRegrasDeBancoDeHoras = () => import('src/components/HourBankRules/list/ListagemDasRegrasDeBancoDeHoras.vue')
const DetalhesDeRegraDeBancoDeHoras = () => import('src/components/HourBankRules/details/DetalhesDeRegraDeBancoDeHoras.vue')
const ActivityType = () => import('src/components/Activities/ActivityType')
const CostCenter = () => import('src/components/Activities/CostCenter')
const ActivityManagement = () => import('src/components/Activities/ActivityManagement/ActivityManagement')
const Activity = () => import('src/components/Activities/Activity')
const FeriasEAfastamentos = () => import('src/components/FeriasEAfastamentos/FeriasEAfastamentos.vue')
const FaceBiometric = () => import('src/components/FaceBiometric/FaceBiometricScreen.vue')
const ListagemDePermissoes = () => import('src/components/Permissions/list/ListagemDePermissoes.vue')
const CriarOuEditarPermissao = () => import('src/components/Permissions/criacaoOuEdicao/CriarOuEditarPermissao.vue')
const DevicesList = () => import('src/components/Devices/DevicesList.vue')
const Reports = () => import('src/components/Reports/Reports.vue')
const RegrasDeNotificacao = () => import('src/typescript/modulos/acompanhamento/RegraDeNotificacao/apresentacao/RegraDeNotificacao.vue')
const RegrasDeNotificacaoListagem = () => import('src/typescript/modulos/acompanhamento/RegraDeNotificacao/apresentacao/listagem/RegraDeNotificacaoListagem.vue')
const CriarEditarRegraDeNotificacao = () => import('src/typescript/modulos/acompanhamento/RegraDeNotificacao/apresentacao/criarEditar/CriarEditarRegraDeNotificacao.vue')
const FileManagementList = () => import('src/components/FileManagement/list/FileManagementList.vue')
const FileManagementApproval = () => import('src/components/FileManagement/approval/FileManagementApproval.vue')
const AcessoNegado = () => import('src/components/AcessoNegado/AcessoNegado.vue')
const ListagemDeLogDeOperacoes = () => import('src/components/LogDeOperacoes/ListagemDeLogDeOperacoes.vue')
const AcompanhamentoTempoReal = () => import('src/components/AcompanhamentoTempoReal/AcompanhamentoTempoReal.vue')
const Auditor = () => import('src/components/Auditor/Auditor.vue')
const SetupDeIntegracoes = () => import('src/components/Sankhya/SetupDeIntegracoes.vue')
const GerenciamentoDeFerias = () => import('src/typescript/modulos/calendario-de-trabalho/GerenciamentoDeFerias/apresentacao/GerenciamentoDeFerias.vue')

const UUIDPathUrl = ':id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})'

export default [
  ...moduloCustomizacoesDeCalculo.rotas,
  ...moduloDeColetores.rotas,
  ...moduloParametrizadorE02.rotas,

  { path: '/', name: 'home', component: Home },

  {path: '/acesso-negado', name: 'AcessoNegado', component: AcessoNegado, meta: { dontNeedAuth: true }},

  { path: '/components', name: 'components', component: Example, meta: { dontNeedAuth: true } },

  { path: '/deviceManage/list', name: 'devices-management', component: DevicesList, meta: {title: 'Dispositivos'} },

  { path: '/gerenciamento-banco', name: 'whb-management', component: WhbManagement, meta: {title: 'Gestão de BH'} },

  { path: '/fast', name: 'mass-changes', component: MassChanges, meta: {title: 'Alterações rápidas'} },

  { path: '/fechamentos', name: 'reports', component: Reports, meta: {title: 'Relatórios e registros'} },
  {
    path: '/employee',
    name: 'employee',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'employee.list', component: EmployeeList, meta: {title: wp('employee')} },
      { path: 'list/:filter/:fieldId([0-9abcdef]{15,})/:fieldName', name: 'employee.list.filter', component: EmployeeList, props: true, meta: {title: 'Funcionários'} },
      { path: 'new', name: 'employee.new', component: EmployeeDetails, meta: {title: `Novo ${ws('employee')}`} },
      { path: 'all', name: 'employee.multipleChanges', component: MultipleChanges, meta: {title: 'Alterações múltiplas'} },
      { path: ':id([0-9abcdef]{15,})', name: 'employee.edit', component: EmployeeDetails, props: true, meta: {title: `Cadastro ${ws('employee')}`} }
    ]
  },

  {
    path: '/timesheet/:type/:startDate/:endDate?/:id([0-9abcdef]{15,})?',
    name: 'timesheet',
    component: Timesheet,
    meta: { dontNeedAuth: false, title: 'Folhas' },
    props: true
  },

  {
    path: '/extra-time-dashboard',
    name: 'extraTimeDashboard',
    component: ExtraTimeDash,
    meta: {dontNeedAuth: false, title: 'Painel de acompanhamento'}
  },
  {
    path: '/painel-pontos',
    name: 'painelDePontos',
    component: PainelDePontos,
    meta: {dontNeedAuth: false, title: 'Painel de Pontos'}
  },
  {path: '/verify-timesheet', name: 'verifyTimesheet', component: VerifyTimesheet, meta: {title: 'Verificar folhas'}},

  {path: '/work-schedule', name: 'workSchedule', component: WorkSchedule, props: true, meta: {title: 'Escalas de trabalho'}},

  {path: '/approvals', name: 'approvals', component: GestaoDeSolicitacoes, meta: {title: 'Gestão de Solicitações'}},

  {path: '/rep', name: 'rep', component: REP, meta: {title: 'Gestão de Reps'}},

  {
    path: '/rep',
    name: 'rep',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'rep.list', component: REP, meta: {title: 'Gestão de REPs'} },
      { path: 'new-rep', name: 'rep.new', component: RepDetails, meta: {title: 'Novo REP'} },
      { path: 'edit-rep/:id', name: 'rep.edit', component: RepDetails, props: true, meta: {title: 'Editar REP'} },
      { path: 'new-pega-ponto', name: 'pega-ponto.new', component: PegaPontoDetails, meta: {title: 'Novo Pega Ponto'} },
      { path: 'edit-pega-ponto/:id', name: 'pega-ponto.edit', component: PegaPontoDetails, props: true, meta: {title: 'Editar Pega Ponto'} }
    ]
  },

  {
    path: '/supervisor',
    name: 'supervisor',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'supervisor.list', component: SupervisorList, meta: {title: 'Responsáveis'} },
      { path: 'new', name: 'supervisor.new', component: SupervisorDetails, meta: {title: 'Novo responsável'} },
      { path: ':id([0-9abcdef]{15,})', name: 'supervisor.edit', component: SupervisorDetails, props: true, meta: {title: 'Editar responsável'} }
    ]
  },

  {
    path: '/company',
    name: 'company',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'company.list', component: CompanyList, meta: {title: 'Locais de Trabalho'} },
      { path: 'new', name: 'company.new', component: CompanyDetails, meta: {title: 'Novo Local de Trabalho'} },
      { path: ':id([0-9abcdef]{15,})', name: 'company.edit', component: CompanyDetails, props: true, meta: {title: 'Editar Local de Trabalho'} }
    ]
  },

  {
    path: '/employer',
    name: 'employer',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'employer.list', component: EmployerList, meta: {title: 'Empregadores'} },
      { path: 'new', name: 'employer.new', component: EmployerDetails, meta: {title: 'Novo empregador'} },
      { path: ':id([0-9abcdef]{15,})', name: 'employer.edit', component: EmployerDetails, props: true, meta: {title: 'Editar empregadores'} }
    ]
  },

  {
    path: '/reason',
    name: 'reason',
    component: simpleChildrenWrapper,
    children: [
      // { path: 'new', name: 'reason.new', component: ReasonDetails, meta: {title: 'Novo motivo padrão'} },
      { path: 'list', name: 'reason.list', component: ReasonList, meta: {title: 'Motivos padrões'} }
    ]
  },

  {path: '/dados', name: 'dados', component: DetalhesDadosDaEmpresa, meta: {title: 'Editar dados da empresa'}},

  {
    path: '/user-group',
    name: 'user-group',
    component: GroupsList,
    meta: {title: 'Grupos'}
  },

  {
    path: '/holidays',
    name: 'holidays',
    component: HolidayList,
    meta: {title: 'Feriados'}
  },

  {
    path: '/user',
    name: 'user',
    component: simpleChildrenWrapper,
    children: [
      { path: ':id([0-9abcdef]{15,})?', name: 'user.edit', component: UserList, props: true, meta: {title: 'Usuários'} },
      { path: 'new/:employeeId([0-9abcdef]{15,})', name: 'user.new', component: UserList, props: true, meta: {title: 'Usuários'} }
    ]
  },

  {
    path: '/routine',
    name: 'routine',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'routine.list', component: RoutineList, meta: {title: 'Escalas'} },
      { path: 'new', name: 'routine.new', component: RoutineDetails, meta: {title: 'Nova escala'} },
      { path: ':id([0-9abcdef]{15,})', name: 'routine.edit', component: RoutineDetails, props: true, meta: {title: 'Editar escala'} }
    ]
  },

  {path: '/monitor-workplace/*', name: 'monitor-workplace', component: MonitorWorkplace, props: true, meta: {title: 'Acompanhamento por posto'}},

  {path: '/monitor-entrance/*', name: 'monitor-entrance', component: MonitorEntrance, props: true, meta: {title: 'Acompanhamento de entrada'}},

  {path: '/workjourney', name: 'workjourney', component: JourneyList, props: true, meta: {title: 'Jornadas'}},

  {
    path: '/fechar-folha-em-conjunto',
    name: 'locker-management',
    component: LockerManagement,
    props: true,
    meta: {title: 'gestão de travamentos'}
  },

  {
    path: '/approvals-rules',
    name: 'approvalsRules',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'approvalRules.list', component: ApprovalsRulesList, meta: {title: 'Regras de aprovação'} },
      { path: 'new', name: 'approvalRules.new', component: ApprovalsRulesDetails, meta: {title: 'Nova regra de aprovação'} },
      { path: ':id([0-9abcdef]{15,})', name: 'approvalRules.edit', component: ApprovalsRulesDetails, props: true, meta: {title: 'Editar Regra'} },
      { path: UUIDPathUrl, name: 'approvalRules.edit', component: ApprovalsRulesDetails, props: true, meta: {title: 'Editar Regra'} }
    ]
  },

  {
    path: '/regras-de-travamento',
    name: 'lockerRules',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'lockerRules.list', component: LockerRulesList, meta: {title: 'Regras de travamento'} },
      { path: 'criacao', name: 'lockerRules.criacao', component: LockerRulesDetails, meta: {title: 'Nova regra de travamento'} },
      { path: UUIDPathUrl, name: 'lockerRules.edit', component: LockerRulesDetails, props: true, meta: {title: 'Editar Regra'} }
    ]
  },

  // {
  //   path: '/discipline-rules',
  //   name: 'disciplineRUles',
  //   component: simpleChildrenWrapper,
  //   children: [
  //     { path: 'list', name: 'disciplineRules.list', component: DisciplineRulesList, meta: {title: 'Regra de disciplinação'} },
  //     { path: 'new', name: 'disciplineRules.new', component: DisciplineRulesDetails, meta: {title: 'Regra de disciplinação'} }
  //   ]
  // },

  {
    path: '/timeoff',
    name: 'timeoff',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'timeoff.list', component: ListagemDasRegrasDeBancoDeHoras, meta: {title: 'Regras de banco de Horas'} },
      { path: 'new', name: 'timeoff.new', component: DetalhesDeRegraDeBancoDeHoras, meta: {title: 'Nova Regra'} },
      { path: ':id([0-9abcdef]{15,})', name: 'timeoff.edit', component: DetalhesDeRegraDeBancoDeHoras, props: true, meta: {title: 'Editar Regra'} }
    ]
  },

  {path: '/activity-type', name: 'activity-type', component: ActivityType, meta: {title: 'Tipo de atividade'}},

  {path: '/activity-cost-center', name: 'activity-cost-center', component: CostCenter, meta: {title: 'Centro de custo'}},

  {path: '/activity', name: 'activity', component: Activity, meta: {title: 'Atividade'}},

  {path: '/activity-manager/:type/:startDate/:endDate/:id', name: 'activity-manager', component: ActivityManagement, meta: {title: 'Gestão de atividades'}, props: true},

  { path: '/afast/:id([0-9abcdef]{15,})', name: 'afast', component: FeriasEAfastamentos, meta: {title: 'Afastamentos e Férias'}, props: true },

  { path: '/face-biometric', name: 'face-biometric', component: FaceBiometric, meta: {title: 'Gerenciamento de biometria'} },

  {
    path: '/permissions',
    name: 'permissions',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'permissions.list', component: ListagemDePermissoes, meta: {title: 'Permissões'} },
      { path: 'new', name: 'permissions.new', component: CriarOuEditarPermissao, meta: {title: 'Nova Permissão'} },
      { path: ':id([0-9abcdef]{15,})', name: 'permissions.edit', component: CriarOuEditarPermissao, props: true, meta: {title: 'Editar Permissão'} }
    ]
  },

  {
    path: '/regras-de-notificacao',
    name: 'regrasDeNotificacao',
    component: RegrasDeNotificacao,
    children: [
      { path: '', name: 'regrasDeNotificacao.listagem', component: RegrasDeNotificacaoListagem, meta: {titulo: 'regra de notificações'} },
      { path: 'new', name: 'regrasDeNotificacao.criacao', component: CriarEditarRegraDeNotificacao, meta: {titulo: 'nova regra de notificações'} },
      { path: ':id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})', name: 'regrasDeNotificacao.edicao', component: CriarEditarRegraDeNotificacao, props: true, meta: {titulo: 'editar regra de notificações'} }
    ]
  },

  {
    path: '/gerenciamento-de-arquivos',
    name: 'fileManagement',
    component: simpleChildrenWrapper,
    children: [
      { path: 'list', name: 'fileManagement.list', component: FileManagementList, meta: {title: 'Gerenciamento de Arquivos'} },
      { path: 'aprovacao/:id', name: 'fileManagement.edit', component: FileManagementApproval, props: true, meta: {title: 'Aprovação de Arquivo'} }
    ]
  },

  {
    path: '/log',
    name: 'LogDeOperacoes',
    component: ListagemDeLogDeOperacoes,
    meta: {title: 'Log de Operações'}
  },

  {
    path: '/auditor',
    name: 'Auditor',
    component: Auditor,
    meta: {title: 'Auditorias de Integrações'}
  },

  {
    path: '/setup',
    name: 'SetupDeIntegracoes',
    component: SetupDeIntegracoes,
    meta: {title: 'Configuração de Integrações'}
  },

  {
    path: '/cognito/confirmSignUp',
    name: 'cognito.confirmSignUp',
    component: ConfirmSignUp,
    props: function (route) {
      return {
        email: route.query.email,
        tempPassword: route.query.code
      }
    },
    meta: {dontNeedAuth: true}
  },

  {
    path: '/cognito/applyForgotPassword',
    name: 'cognito.applyForgotPassword',
    component: Cognito,
    props: function (route) {
      return {
        type: 'applyForgotPassword',
        forgotPasswordData: route.query
      }
    },
    meta: {dontNeedAuth: true}
  },

  { path: '/cognito/:type', name: 'cognito', component: Cognito, props: true, meta: { dontNeedAuth: true } },

  { path: '*', redirect: {name: 'home'} },

  { path: '/acompanhamento-tempo-real', name: 'acompanhamentoTempoReal', component: AcompanhamentoTempoReal, meta: {title: 'Pontotel - Acompanhamento em tempo real'} },

  {
    path: '/gerenciamento-de-ferias',
    name: 'gerenciamentoDeFerias',
    component: GerenciamentoDeFerias,
    meta: {titulo: 'gerenciamento de férias'}
  }
]
