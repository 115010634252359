import { ErroNoNavegadorPontotel } from 'src/tipos/erros/classes'

export class ErroNavegadorNaoSuportaNotificacoes extends ErroNoNavegadorPontotel {
  constructor () {
    super('O navagador não suporta notificações.')
  }
}

export class ErroNavegadorNaoSuportaGeolocalizacao extends ErroNoNavegadorPontotel {
  constructor () {
    super('O navagador não suporta geolocalização.')
  }
}
