import Vue from 'vue'
import { copiarObjetoProfundamente, congelarObjetoProfundamente, formatarDataParaEnvio } from 'src/utils/formatadoras/formatadoras'
import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'
import { API } from 'src/services'
import selectionStates, { buildDeepObjectKeys } from './selection'
import moment from 'moment'
import { formatDayInfo } from 'src/components/WorkSchedule/utils'
import _ from 'lodash'
import { FiltradorDeErros } from 'src/erros'

const defaultState = () => ({
  ...selectionStates.state,
  periodData: null,
  loadingDays: {},
  modalDaysInfos: null,
  paginationInfo: null
})

const __state = defaultState()

const getters = {
  ...selectionStates.getters,
  filterRowsColsCanBeEdited (state) {
    return (rowsColsList) => {
      return rowsColsList
        .filter(({rowNumber, colNumber}) => state.periodData[rowNumber].period[colNumber].allowChange)
    }
  },
  isDaysAllowedToChange (state) {
    // returns false if any day is bloqued to change
    return (listRowsAndCols) => !(
      listRowsAndCols.find(i => !state.periodData[i.rowNumber].period[i.colNumber].allowChange)
    )
  },
  filterAllowedDaysToChange (state) {
    return (listRowsAndCols) => listRowsAndCols
      .filter(i => state.periodData[i.rowNumber].period[i.colNumber].allowChange)
  },
  selectionGroup (state) {
    return function selectionGroupGetter (rowNumber, colNumber) {
      if (!state.periodData.length) {
        return []
      }

      const numberRows = state.periodData.length
      const numberCols = state.periodData[0].period.length
      const selectedDays = state.selectedDays

      function invalidRange (row, col) {
        return col === -1 || row === -1 || col >= numberCols || row >= numberRows
      }

      // That was fun to code
      // If current day is selected we want to get all neighborhoods days
      function reduceAllSelectedBlock (row, col, finalList, first = false) {
        // order -> up, left, right and right
        if (invalidRange(row, col)) {
          return finalList
        }
        let currentList = [...finalList]

        if (currentList.find(i => i[0] === row && i[1] === col)) {
          return currentList
        }
        const types = [
          [-1, 0], // up
          [0, -1], // left
          [1, 0], // down
          [0, 1] // right
        ]

        if ((!invalidRange(row, col) && selectedDays[row][col] === true &&
          !currentList.find(i => i[0] === row && i[1] === col)) || first) {
          currentList.push([row, col])
        }

        types.forEach(item => {
          const nextRow = row + item[0]
          const nextCol = col + item[1]

          if (!invalidRange(nextRow, nextCol) && selectedDays[nextRow][nextCol] === true &&
            !currentList.find(i => i[0] === nextRow && i[1] === nextCol)) {
            currentList = reduceAllSelectedBlock(row + item[0], col + item[1], currentList)
          }
        })
        return currentList
      }
      return reduceAllSelectedBlock(rowNumber, colNumber, [], true)
        .map(i => ({rowNumber: i[0], colNumber: i[1]}))
    }
  }
}

const mutations = {
  ...selectionStates.mutations,
  resetState (state) {
    resetarParaEstadoInicial(state, defaultState())
  },
  scheduleData (state, periodData) {
    state.periodData = periodData
    state.selectionBox = {first: null, last: null}
    state.loadingDays = buildDeepObjectKeys(state)
    state.modalDaysInfos = buildDeepObjectKeys(state, null)
  },
  setLoading (state, {listRowsCols, to}) {
    listRowsCols.forEach(({rowNumber, colNumber}) => {
      state.loadingDays[rowNumber][colNumber] = Boolean(to)
    })
  },
  paginationInfo (state, paginationInfo) {
    state.paginationInfo = copiarObjetoProfundamente(congelarObjetoProfundamente(paginationInfo))
  },
  currentPage (state, page) {
    state.currentPage = page
  },
  changeDayInfo (state, {rowNumber, colNumber, dayInfo}) {
    Vue.set(state.periodData[rowNumber].period, colNumber, dayInfo)
  },
  changeSelectedDays (state, dayInfo) {
    selectionStates.getters.allSelectedDaysWithInfos(state).forEach(({rowNumber, colNumber}) => {
      Vue.set(state.periodData[rowNumber].period, colNumber, copiarObjetoProfundamente(dayInfo))
    })
  },
  updateMultipleDays (state, {empPeriodsData, journeysPerId}) {
    // merge the current day with

    const startDate = moment(state.periodData[0].startDate)
    const firstDateToComplete = moment(empPeriodsData[0].startDate)

    const diffDatesFromStart = Math.floor(firstDateToComplete.diff(startDate, 'days'))

    empPeriodsData.forEach(newEmpInfo => {
      const stateEmpInfo = state.periodData.find(i => i.employee.id === newEmpInfo.employee.id)

      newEmpInfo.period.forEach((dayInfo, index) => {
        Vue.set(stateEmpInfo.period, index + diffDatesFromStart, formatDayInfo(dayInfo, journeysPerId))
      })
    })
  },
  setModalDayInfo (state, {rowNumber, colNumber, dayInfo}) {
    state.modalDaysInfos[rowNumber][colNumber] = dayInfo
  }
}

const actions = {
  ...selectionStates.actions,
  swapDays ({ commit, state }, {fromDayInfo, toDayInfo}) {
    const initialFrom = copiarObjetoProfundamente(state.periodData[fromDayInfo.rowNumber].period[fromDayInfo.colNumber])
    const initialTo = copiarObjetoProfundamente(state.periodData[toDayInfo.rowNumber].period[toDayInfo.colNumber])
    commit('changeDayInfo', {...fromDayInfo, dayInfo: initialTo})
    commit('changeDayInfo', {...toDayInfo, dayInfo: initialFrom})
  },
  async syncData ({ commit, state, rootGetters }, rowsColsToUpdate) {
    // rowsColsToUpdate a list of cols and rows. e.g: [{rowNumber: 40, colNumber: 10}]

    const allMinDates = []
    const allMaxDates = []

    const employees = []
    const daysPerEmployee = {}

    rowsColsToUpdate.forEach(({rowNumber, colNumber}) => {
      const emp = state.periodData[rowNumber].employee
      if (!daysPerEmployee[emp.id]) {
        daysPerEmployee[emp.id] = []
      }
      daysPerEmployee[emp.id].push(state.periodData[rowNumber].period[colNumber].day)
      employees.push(emp)
    })

    employees.forEach(emp => {
      const daysDoNeedUpdate = daysPerEmployee[emp.id].map(i => moment(i))
      allMinDates.push(moment.min(daysDoNeedUpdate))
      allMaxDates.push(moment.max(daysDoNeedUpdate))
    })

    const startDate = moment.min(allMinDates)

    const reqData = {
      employees: employees.map(i => i.id),
      startDate: formatarDataParaEnvio(startDate),
      endDate: formatarDataParaEnvio(moment.max(allMaxDates))
    }
    const result = await API.workSchedule.save({type: 'base'}, reqData)

    commit('updateMultipleDays', {
      empPeriodsData: result.data.success.periodData,
      journeysPerId: rootGetters['workSchedule/journeysPerId']
    })
  },
  async applyJourneyToGroup ({ dispatch, state, getters }, {rowNumber, colNumber, journey}) {
    dispatch('applyJourneyToDays', {
      listRowsCols: getters.selectionGroup(rowNumber, colNumber),
      journey
    })
  },
  async applyJourneyToDays ({ dispatch, state, commit, getters }, {journey, listRowsCols}) {
    listRowsCols = getters.filterAllowedDaysToChange(listRowsCols)

    if (!listRowsCols.length) { return }

    commit('setLoading', {listRowsCols: listRowsCols, to: true})

    const data = {
      newDjJourneys: [
        {
          groups: listRowsCols.map(({rowNumber, colNumber}) => (
            {
              employee: state.periodData[rowNumber].employee.id,
              day: state.periodData[rowNumber].period[colNumber].day
            }
          )),
          journey
        }
      ]
    }

    await API.changeMultipleDayjourney.save(data)

    commit('workSchedule/appendChangeToHistory', {listRowsCols, undoType: 'dates'}, {root: true})

    commit('unSelectDays', listRowsCols)
    await dispatch('syncData', listRowsCols)
    commit('setLoading', {listRowsCols: listRowsCols, to: false})
  },
  async swapJourneys ({ commit, dispatch, state, getters }, {fromDayInfo, toDayInfo}) {
    const fromEmployee = state.periodData[fromDayInfo.rowNumber]
    const toEmployee = state.periodData[toDayInfo.rowNumber]

    if (!getters.isDaysAllowedToChange([fromDayInfo, toDayInfo])) { return }

    function format (info, colNumber) {
      return {
        employee: info.employee.id,
        day: info.period[colNumber].day
      }
    }

    commit('setLoading', {listRowsCols: [fromDayInfo, toDayInfo], to: true})

    try {
      await API.workSchedule.save({type: 'swap-day-journeys'}, {
        firstEmployee: format(fromEmployee, fromDayInfo.colNumber),
        secondEmployee: format(toEmployee, toDayInfo.colNumber)
      })

      commit('workSchedule/appendChangeToHistory', {
        listRowsCols: [{...fromDayInfo}, {...toDayInfo}], undoType: 'dates'
      }, {root: true})

      dispatch('syncData', [
        {rowNumber: fromDayInfo.rowNumber, colNumber: fromDayInfo.colNumber},
        {rowNumber: toDayInfo.rowNumber, colNumber: toDayInfo.colNumber}
      ])
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
    }

    commit('setLoading', {listRowsCols: [fromDayInfo, toDayInfo], to: false})
  },

  async applyNewRoutine ({ commit, dispatch }, payload) {
    const {
      rowNumber,
      affectedCols,
      startDate,
      employee,
      routine,
      phaseShift,
      overManualChanges,
      endDate
    } = payload

    const rowsColsOperationRolling = affectedCols.map(i => ({rowNumber, colNumber: i}))

    if (!rowsColsOperationRolling.length) { return }

    if (overManualChanges && !endDate) {
      throw new Error('Tried to apply a new endless routine with overManualChange=True')
    }

    commit('resetSelectedDays')
    commit('setLoading', {listRowsCols: rowsColsOperationRolling, to: true})

    try {
      await API.workSchedule.save({type: 'new-routine'}, {
        employee: employee.id,
        startDate,
        endDate,
        routine: routine.id,
        overManualChanges,
        phaseShift
      })

      await dispatch('syncData', rowsColsOperationRolling)
      commit('workSchedule/appendChangeToHistory', {
        listRowsCols: rowsColsOperationRolling,
        undoType: endDate === null ? 'djCreators' : 'dates'
      }, {root: true})
    } catch (erro) {
      FiltradorDeErros.capturarErro(erro)
    }

    commit('setLoading', {listRowsCols: rowsColsOperationRolling, to: false})
  },

  getInfoOfDay: _.debounce(async function ({ state, commit }, {rowNumber, colNumber}) {
    const employee = state.periodData[rowNumber].employee
    const day = state.periodData[rowNumber].period[colNumber].day

    const params = {
      startDate: formatarDataParaEnvio(day),
      endDate: formatarDataParaEnvio(day),
      employees: [employee.id],
      toLoad: ['djs', 'justifies', 'pts']
    }

    const firstChunk = await API.timesheetGeneric.save(params)
    commit('setModalDayInfo', {
      rowNumber,
      colNumber,
      dayInfo: firstChunk.data.success.lines[0]
    })
  }, 100)
}

export default {
  namespaced: true,
  state: __state,
  getters,
  mutations,
  actions
}
