import { API, retryRequest } from 'src/services'
import { congelarObjetosDaLista } from 'src/utils/formatadoras/formatadoras'

export default {
  namespaced: true,
  state: {
    items: [],
    loading: false
  },
  getters: {
    onlyForJustify (state) {
      return state.items.filter(i => i.kind === 'justify')
    },
    onlyForFakePts (state) {
      return state.items.filter(i => i.kind === 'faketimelog_reason')
    },
    justificativaEPontosFake (state) {
      return state.items.filter(i => ['justify', 'faketimelog_reason'].includes(i.kind))
    },
    apenasParaGerenciamentoDeArquivo (state) {
      return state.items.filter(i => i.kind === 'aprovacao_de_arquivo')
    }
  },
  mutations: {
    saveItems (state, payload) {
      state.items = payload
      state.loading = false
    },
    resetItems (state) {
      state.items = []
    },
    loading (state, payload) {
      state.loading = Boolean(payload)
    }
  },
  actions: {
    async fetchItems ({ commit }, payload) {
      const { fullReload, active } = payload || {}

      if (fullReload) {
        commit('resetItems')
      }

      commit('loading', true)
      const result = await retryRequest(API.reason.get, {active})
      commit('saveItems', congelarObjetosDaLista(result.body.success))
    }
  }
}
