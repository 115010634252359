import { DadosUsuarioFormbricks } from './usuario.formbricks'
export interface FormbricksInterface {
  registrarEmailUsuario: (emailUsuario: string) => void
  registrarAtributo: (nomeAtributo: string, valorAtributo: unknown) => void
  registrarGatilho: (nomeGatilho: string) => void
  registrarMudancaDeRota (): void
  deslogarFormbricks: () => void
}

/* O Formbricks é iniciado via script no head do nosso HTML(index.html)
  Quando ele é iniciado, ele cria uma variável global chamada window.formbricks
  que contém os métodos que podemos utilizar para interagir com o Formbricks
*/

export default class Formbricks implements FormbricksInterface {
  private definirAmbiente (): string {
    const ambienteNode = process.env.NODE_ENV
    const ehaAmbientek8s = process.env.VUE_APP_K8s
    return ehaAmbientek8s ? 'development' : ambienteNode as string
  }

  public verificarSeUsuarioPermitido (emailUsuario: string): boolean {
    const usuarioEhLar21 = emailUsuario.includes('lar21')
    const usuararioEhPontotel = emailUsuario.includes('@pontotel')
    if (usuarioEhLar21 || usuararioEhPontotel) return false
    return true
  }

  public registrarEmailUsuario (emailUsuario: string): void {
    window.formbricks.setEmail(emailUsuario)
  }

  public registrarAtributo (nomeAtributo: string, valorAtributo: unknown): void {
    window.formbricks.setAttribute(nomeAtributo, valorAtributo)
  }

  public registrarGatilho (nomeGatilho: string): void{
    window.formbricks.track(nomeGatilho)
  }

  public registrarMudancaDeRota (): void {
    window.formbricks.registerRouteChange()
  }

  public deslogarFormbricks (): void {
    window.formbricks.logout()
  }

  public iniciarFormbricks (dadosDoUsuarioFormbricks: DadosUsuarioFormbricks): void {
    const ambiente = this.definirAmbiente()
    const { idUsuario, ...atributos } = dadosDoUsuarioFormbricks
    window.formbricks.init({
      environmentId: process.env.VUE_APP_FORMBRICKS_KEY,
      apiHost: process.env.VUE_APP_FORMBRICKS_URL,
      userId: idUsuario,
      attributes: {
        email: atributos.emailUsuario,
        nomeUsuario: atributos.nomeUsuario,
        papelUsuario: atributos.papelUsuario,
        idEmpresaUsuario: atributos.idEmpresaUsuario,
        nomeEmpresaUsuario: atributos.nomeEmpresaUsuario,
        tamanhoEmpresaUsuario: atributos.tamanhoEmpresaUsuario,
        canal: atributos.canal,
        dataCriacaoUsuario: atributos.dataCriacaoUsuario,
        dataCriacaoEmpresa: atributos.dataCriacaoEmpresa,
        semanasUsoUsuario: String(atributos.semanasUsoUsuario),
        mesesUsoUsuario: String(atributos.mesesUsoUsuario),
        semanasUsoEmpresa: String(atributos.semanasUsoEmpresa),
        mesesUsoEmpresa: String(atributos.mesesUsoEmpresa)
      },
      debug: ambiente === 'development'
    })
  }

  public verificarUsuarioEmSessaoAtiva (): boolean {
    const dadosFormbricks = localStorage.getItem('formbricks-js')
    if (!dadosFormbricks) {
      return false
    }
    try {
      const dadosParseados = JSON.parse(dadosFormbricks)
      return dadosParseados && dadosParseados?.status !== 'error'
    } catch (e) {
      return false
    }
  }

  public verificarFormbricksEstaAtivo (): boolean {
    return Boolean(window.formbricks)
  }
}
