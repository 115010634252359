import moment from 'moment'
import {
  formatarApontamentosParaLimitePorHorasTrabalhadas,
  formatarValorParaLimitePorHorasTrabalhadas,
  formatarJornadaBaseParaLimitePorHorasTrabalhadas
} from './limiteDeAcumuloPorHorasTrabalhadas'

function formataDataInicioDoBanco (state) {
  return moment(state.dataInicioDoBanco).toISOString()
}

function formataRazaoDeAcumuloECompensacao (state) {
  if (!state.usaRazaoDeAcumuloECompensacao) {
    return Array(10).fill(0)
  }
  return state.razaoDeAcumuloECompensacao.map(valor => {
    if (valor !== 0 && !valor) return 0
    return 1 - (valor / 100)
  })
}

function formataLimites (state, flagDeUsoDoLimite, nomeDoLimite) {
  const formataValoresDoLimite = (valor, indice) => {
    if (state[nomeDoLimite][indice] !== 0 && !state[nomeDoLimite][indice]) {
      return -1
    }
    return valor
  }

  const valorSemLimite = -1
  const flagDoLimiteNaoFoiSelecionada = state[flagDeUsoDoLimite]

  if (!flagDoLimiteNaoFoiSelecionada) return Array(10).fill(valorSemLimite)
  return state[nomeDoLimite].map(formataValoresDoLimite)
}

function converteValorDeFatorDeTransformacao (valor) {
  if (valor !== 0 && !valor) return 1
  return valor
}

function formatarCompensateOnLock (state) {
  const modalidadeDeBancoEhJanela = state.modalidadeDoBanco.selected.id === 'janela'

  return modalidadeDeBancoEhJanela
    ? state.compensateOnLock
    : true
}

export function formataObjetoParaEnviar (state) {
  const request = {
    // Cabeçalho
    name: state.nome,
    code: state.codigo,
    dateOfBancoStart: formataDataInicioDoBanco(state),
    numberOfDaysToCompensation: state.limiteDeAcumuloDoBanco,
    rollingWorkHoursBank: state.tipoDeContagemDeTempo.selected.id === 'movel',
    autoCompensate: state.modalidadeDoBanco.selected.id === 'janela',
    windowType: state.tipoDoCicloDeAutoCompensacao.selected.id,
    windowExpirationCycle: state.quantosCiclosParaVencimento,
    permitirDescontoEmSaldosVencidos: state.permitirDescontoEmSaldosVencidos,
    // FatorDeTransformacaoDeHoras
    compensationPerHourKind: state.fatorDeTransformacaoDeHoras.map(converteValorDeFatorDeTransformacao),
    // RazaoDeAcumuloECompensacao
    splitPerKindOfHour: formataRazaoDeAcumuloECompensacao(state),
    // LimitesDiarios
    dailyLimitPerHourKind: formataLimites(
      state, 'usaLimiteDiario', 'limiteDeAcumuloECompensacaoDiario'
    ),
    ValorMinimoDiario: formataLimites(
      state, 'usaLimiteDiario', 'limiteBaseDeAcumuloECompensacaoDiario'
    ),
    apontLimiteDeAcumuloHorasTrabDiaria: formatarApontamentosParaLimitePorHorasTrabalhadas(
      state.limiteDeAcumuloPorHorasTrabalhadas
    ),
    valorLimiteDeAcumuloHorasTrabDiaria: formatarValorParaLimitePorHorasTrabalhadas(
      state.limiteDeAcumuloPorHorasTrabalhadas
    ),
    jornadaBaseLimiteDeAcumuloHorasTrabDiaria: formatarJornadaBaseParaLimitePorHorasTrabalhadas(
      state.limiteDeAcumuloPorHorasTrabalhadas
    ),
    // LimitesMensais
    monthlyLimitPerKindOfHora: formataLimites(
      state, 'usaLimiteMensal', 'limiteDeAcumuloECompensacaoMensal'
    ),
    ValorMinimoMensal: formataLimites(
      state, 'usaLimiteMensal', 'limiteBaseDeAcumuloECompensacaoMensal'
    ),
    // LimitesGerais
    generalLimitPerKindOfHora: formataLimites(
      state, 'usaLimiteGeral', 'limiteDeAcumuloECompensacaoGeral'
    ),
    ValorMinimoGeral: formataLimites(
      state, 'usaLimiteGeral', 'limiteBaseDeAcumuloECompensacaoGeral'
    ),
    // Aprovação automática
    isApproved: state.aprovacaoAutomatica,
    separarCompensacaoEAcumulo: state.separarCompensacaoEAcumulo,
    // OutrasConfiguracoes
    autoAccTm: state.acumularHorasAutomaticamente,
    autoCompStm: state.compensarHorasAutomaticamente,
    negativeBalance: state.permitirBalancoNegativo,
    partialAcc: state.considerarAcumuloParcialDeHoras,
    considerarIsTmsAsCompensation: state.considerarBaixaComTmsComoCompensacao,
    compensateOnLock: formatarCompensateOnLock(state),
    completarVisualizacaoDoAcumuladoComJanelasFalsas: state.completarVisualizacaoDoAcumuladoComJanelasFalsas,
    manterSaldoMensal: state.mostrarHistoricoDosSaldosNoAcumuladoDeBancoDeHoras,
    mudaNomenclaturaParaCompensacaoNaFolha: state.mudaNomenclaturaParaCompensacaoNaFolha,
    // ConfiguracoesAvancadas
    GatilhoLimiteGeral: state.usarGatilhoDeSaldoGeral ? state.gatilhoLimiteGeral : null,
    GatilhoBaseMensal: state.usarGatilhoDeSaldoMensal ? state.gatilhoBaseMensal : null,
    CamposDesconsideradosGatilhoMensal: state.camposDesconsideradosDoGatilhoMensal.map(campo => campo.id),
    // ?
    allHoursConvertible: true,
    dateOfLastBancoReset: false
  }

  if (state.id) request.id = state.id

  return request
}
