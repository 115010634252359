import { API } from 'src/services'
import router from 'src/router'
import Cookies from 'js-cookie'
import { configureCongito } from 'src/components/Cognito'
import Auth from '@aws-amplify/auth'
import { formbricksGlobal } from 'src/main'
import { FiltradorDeErros } from 'src/erros'
import { removerCookiesQueIncluemChaveEspefica } from 'src/utils/executoras/executoras'
import servicoLocalStorage from 'src/typescript/servicos/localStorage/localStorage.servico'
import { desativarWorker } from 'src/typescript/servicos/keycloak/renovarToken.servico'

export default {
  getters: {
    usuarioEstaAutenticado (state) {
      const autenticadoPeloAuthorization = !!state.auth.authorization || !!Cookies.get('Authorization')
      const autenticadoPeloAccessToken = !!state.auth.accessToken || !!Cookies.get('accessToken')
      return autenticadoPeloAuthorization || autenticadoPeloAccessToken
    }
  },
  mutations: {
    setFreshToken (state, {accessToken}) {
      state.auth.accessToken = accessToken
      Cookies.set('accessToken', accessToken)
    },
    setAPIRenovacaoTokenKeycloak (state, renovacaoTokenKeycloakAPI) {
      state.auth.renovacaoTokenKeycloakAPI = renovacaoTokenKeycloakAPI
    },
    desativarWorkerDeRenovacaoTokenKeycloak (state) {
      state.auth.renovacaoTokenKeycloakAPI = null
      desativarWorker()
    },
    cognitoTokens (
      state,
      { authorization, accessToken, refreshToken, userPoolId = null, userPoolWebClientId = null, tokenAPI = null, definirDataCriacaoToken = null }
    ) {
      // validate inputs
      authorization = authorization === 'null' ? null : authorization
      accessToken = accessToken === 'null' ? null : accessToken
      userPoolId = userPoolId === 'null' ? null : userPoolId
      userPoolWebClientId = userPoolWebClientId === 'null' ? null : userPoolWebClientId

      state.auth.authorization = authorization
      state.auth.accessToken = accessToken
      state.auth.refreshToken = refreshToken
      state.auth.userPoolId = userPoolId
      state.auth.userPoolWebClientId = userPoolWebClientId
      state.auth.tokenAPI = tokenAPI

      if (definirDataCriacaoToken) {
        state.auth.dataCriacaoToken = new Date().getTime()
      }

      // set cookies to angular has access
      if (authorization || accessToken) {
        Cookies.set('userPoolId', userPoolId)
        Cookies.set('userPoolWebClientId', userPoolWebClientId)
      }

      if (authorization) {
        Cookies.set('Authorization', authorization)
        Cookies.remove('accessToken')
      } else if (accessToken) {
        Cookies.remove('Authorization')
        Cookies.set('accessToken', accessToken)
        Cookies.set('userPoolId', userPoolId)
        Cookies.set('refreshToken', refreshToken)
        Cookies.set('tokenAPI', tokenAPI)
        Cookies.set('userPoolWebClientId', userPoolWebClientId)
        configureCongito({ userPoolId, userPoolWebClientId })
      } else {
        Cookies.remove('Authorization')
        Cookies.remove('accessToken')
        Cookies.remove('userPoolId')
        Cookies.remove('refreshToken')
        Cookies.remove('tokenAPI')
        Cookies.remove('userPoolWebClientId')
      }
    },
    setDataCriacaoToken (state, dataCriacaoToken) {
      state.auth.dataCriacaoToken = dataCriacaoToken
    }
  },
  actions: {
    async logout ({ state, commit }, goToLoginPage = true) {
      if (goToLoginPage) {
        router.push('/cognito/login')
      }

      try {
        // add accesstoke to blacklist- otherwise it still valid for next hour
        if (state.auth.authorization) {
          await API.legacyLogout.get()
        } else {
          await API.logout.get()
        }
        // revoke refresh and session tokens
        await Auth.signOut()

        if (formbricksGlobal.verificarFormbricksEstaAtivo() && formbricksGlobal.verificarUsuarioEmSessaoAtiva()) {
          formbricksGlobal.deslogarFormbricks()
        }
      } catch (erro) {
        FiltradorDeErros.capturarErro(erro)
      }

      const compManId = state.userInfo?.compMan?.id

      commit('cognitoTokens', {
        authorization: null, accessToken: null, calledBy: 'logout action from store'
      })
      commit('desativarWorkerDeRenovacaoTokenKeycloak')
      commit('removeUserInfo')

      commit('common/companies/resetItems', {}, {root: true})
      commit('home/gestor/resetState', {}, {root: true})
      commit('home/empregado/resetState', {}, {root: true})
      commit('acompanhamentoEmTempoReal/resetState', {}, {root: true})
      Cookies.remove('CompMan')
      Cookies.remove('comp_man')
      Cookies.remove('UserId')
      Cookies.remove('Authorization')
      Cookies.remove('accessToken')
      Cookies.remove('userPoolId')
      Cookies.remove('userPoolWebClientId')
      removerCookiesQueIncluemChaveEspefica('local-trabalho-tela-inicial')
      removerCookiesQueIncluemChaveEspefica('acompanhamento-filtro')

      function unicodeUnEscape (string) {
        return string.replace(/%u([\dA-Z]{4})|%([\dA-Z]{2})/g, function (_, m1, m2) {
          return String.fromCharCode(parseInt('0x' + (m1 || m2)))
        })
      }
      if (compManId) {
        try {
          // this api informs if we need to redirect to some routeon logout
          const result = await API.redirectSSORoute.get({compManId})
          servicoLocalStorage.removerDadosExcetoDaEmpresaCorrente()
          if (result.body && result.body.success && result.body.success.redirectApp) {
            window.location.href = unicodeUnEscape(result.body.success.redirectApp)
          }
        } catch (erro) {
          FiltradorDeErros.capturarErro(erro)
        }
      }
    }
  }
}
