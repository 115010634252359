<template lang="pug">
  .p-input-hour-minute
    PTextField(
      :label="label",
      :name="name",
      ref="input"
      type="text",
      v-model="inputValue",
      :disabled="disabled",
      v-mask="'##:##'"
      v-on="localListeners",
      v-bind="localAttributes"
      @focus="onFocus",
      @input="onChange",
      :error="errorMsg || error"
    )
</template>

<script>
import { inputTimeToFloat } from 'src/utils'
import { obterHorasEMinutosAPartirDeUmFloat, obterHorasEMinutosEmStringAPartirDeUmFloat } from 'src/utils/formatadoras/formatadoras'
import { handlerUseStateVmodel } from 'src/common/mixins/handlerUseStateVmodel'
import { localListenersAndAttributes } from 'src/common/mixins/localListenersAndAttributes'
import { mask } from 'vue-the-mask'

import PTextField from './PTextField.vue'

export default {
  name: 'PInputHourMinute',
  props: {
    name: {type: String, required: true},
    label: {type: String, required: true},
    disabled: {type: Boolean},
    permitirSomenteHorarioDiaCompleto: {type: Boolean},
    error: {type: String}
  },
  created () {
    this.inputValue = obterHorasEMinutosEmStringAPartirDeUmFloat(this.state.float)
  },
  data () {
    return {
      touch: false,
      inputValue: ''
    }
  },
  computed: {
    errorMsg () {
      const horarioInputEmMinutos = this.obterMinutosDoInput()
      const vinteTresHorasECinquentaNoveMinutosEmMinutos = 1439
      if (this.permitirSomenteHorarioDiaCompleto) {
        if (horarioInputEmMinutos > vinteTresHorasECinquentaNoveMinutosEmMinutos) {
          return 'valor inválido'
        }
      }
      if (this.inputValue.length !== 5 || parseInt(this.inputValue.slice(3, 5)) >= 60) {
        return 'valor inválido'
      }
      return ''
    }
  },
  methods: {
    onChange (value) {
      this.touch = true
      if (!this.errorMsg) {
        const float = inputTimeToFloat(value)
        this.__state = obterHorasEMinutosAPartirDeUmFloat(float)
      }
    },
    onFocus () {
      this.touch = false
      this.$refs.input.select()
    },
    updateValueWithFloat (float) {
      this.inputValue = obterHorasEMinutosEmStringAPartirDeUmFloat(float)
    },
    obterMinutosDoInput () {
      const [horas, minutos] = this.inputValue.split(':').map(Number)
      return horas * 60 + minutos
    }
  },
  watch: {
    'state.float': {
      handler (novoValor) {
        if (novoValor) {
          this.inputValue = obterHorasEMinutosEmStringAPartirDeUmFloat(novoValor)
        }
      }
    }
  },
  mixins: [handlerUseStateVmodel(), localListenersAndAttributes()],
  directives: {mask},
  components: {PTextField}
}
</script>

<style lang="scss" scoped>
.p-input-hour-minute {
  width: fit-content;
}
</style>
