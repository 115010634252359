import { API, retryRequest } from 'src/services'
import { congelarObjetosDaLista } from 'src/utils/formatadoras/formatadoras'

export default {
  namespaced: true,
  state: {
    items: [],
    loading: false
  },
  getters: {
    workLeaveJourneys (state) {
      const folgaJourneys = state.items.filter(i => i.kind === -1)
      const afastamentoJourneys = folgaJourneys.filter(i => {
        return !['Férias', 'Folga', 'Feriado', 'Dispensa'].includes(i.name)
      })
      return afastamentoJourneys
    },
    jornadasSemEspeciais (state) {
      const resultadoSemJornadasEspeciais = state.items.filter(jornada => {
        return jornada.justify === false && jornada.leaveJourney === false && jornada.vacationJourney === false
      })
      return resultadoSemJornadasEspeciais
    }
  },
  mutations: {
    saveItems (state, payload) {
      state.items = payload
      state.loading = false
    },
    loading (state, payload) {
      state.loading = Boolean(payload)
    }
  },
  actions: {
    async fetchItems ({ commit }) {
      commit('loading', true)

      const result = await retryRequest(API.journey.get)
      commit('saveItems', congelarObjetosDaLista(result.body.success))
    }
  }
}
