import { groupBy } from 'src/utils'
import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'

const CONFIGS_DEFAULTS = {
  'showPontoReason': true,
  'showUserGroupFilters': false,
  'showFaceRecoInfo': false,
  'ptsByCompany': false,
  'showIconAddMultiplePtKind': true,
  'showTypeEmployeeOrder': 'name',
  'showTypeLoadCompany': 'perday',
  'mostrarRegraDeCalculoDosDias': false,
  'modoAcessibilidadeParaDaltonismo': false,
  'navegacaoAcessibilidade': false
}

const CONFIGS = Object.keys(CONFIGS_DEFAULTS)

function parseLocalStorageValue (val) {
  // this function is necessary because some browsers store booleans as strings
  try {
    if (JSON.parse(val) === true) {
      val = true
    } else if (JSON.parse(val) === false) {
      val = false
    }
  } catch (erro) {
    // value is not a boolean or a json 'complex' value. Probably is a string, lets use it as it is.
  }
  return val
}

function loadConfigFromLocalStorage () {
  return CONFIGS.reduce(function (obj, x) {
    obj[x] = parseLocalStorageValue(localStorage.getItem(x) || CONFIGS_DEFAULTS[x] || false)
    return obj
  }, {})
}

function saveConfigsInLocal (configs) {
  CONFIGS.forEach(c => {
    localStorage.setItem(c, configs[c])
  })
}

export const defaultState = () => ({
  toolboxOption: null, // changeMultipleDjs | multipleDayJustify | joinLines

  configs: loadConfigFromLocalStorage(),

  // states to changeDayJourneys option
  cdjJourneyPerRow: {}, // key: int, value: Journey object
  cdjJourneyStep: null // Journey object,
})

const __state = defaultState()

const getters = {
  massChangeDjTempJourney (state, getters, rootState) {
    return (rowNumber) => {
      if (state.toolboxOption !== 'changeMultipleDjs') {
        return null
      }

      if (rootState.sheet.selectedLines.includes(rowNumber)) {
        return state.cdjJourneyStep
      } else if (state.cdjJourneyPerRow[rowNumber]) {
        return state.cdjJourneyPerRow[rowNumber]
      }
      return null
    }
  },
  inLockedSelectMode (state) {
    // It will block any change in timesheet
    // basically, it put the sheet as was in locker state without lock icons
    const toolBoxOptionsNeedLock = ['changeMultipleDjs', 'joinLines', 'processPts']
    return toolBoxOptionsNeedLock.includes(state.toolboxOption)
  }
}

const mutations = {
  toolboxOption (state, option) {
    if (!['changeMultipleDjs', 'multipleDayJustify', 'joinLines', 'processPts', null].includes(option)) {
      throw new Error('toolboxOption mutation received an invalid option')
    }
    state.toolboxOption = option
    if (!option) {
      resetarParaEstadoInicial(state, defaultState())
    }
  },
  setConfig (state, payload) {
    state.configs = { ...state.configs, ...payload }
    saveConfigsInLocal(state.configs)
  },
  cdjJourneyStep (state, journey) {
    state.cdjJourneyStep = journey
  },
  applyStepJourney (state, { selectedRows }) {
    const newJourneysPerRow = {}
    selectedRows.forEach(row => {
      newJourneysPerRow[row] = { ...state.cdjJourneyStep }
    })
    state.cdjJourneyPerRow = { ...state.cdjJourneyPerRow, ...newJourneysPerRow }
    state.cdjJourneyStep = null
  }
}

const actions = {
  applyStepJourney ({ state, commit, rootState }) {
    commit('applyStepJourney', { selectedRows: rootState.sheet.selectedLines })
  },
  async apply ({ state, dispatch }) {
    const actions = {
      changeMultipleDjs: 'applyMassJourneys',
      joinLines: 'joinLines',
      processPts: 'processPts'
    }
    dispatch(actions[state.toolboxOption])
    resetarParaEstadoInicial(state, defaultState())
  },
  async applyMassJourneys ({ state, rootState, dispatch }) {
    dispatch('applyStepJourney')

    const dataToPrepare = []

    rootState.sheet.lines.forEach((line, rowNumber) => {
      if (state.cdjJourneyPerRow[rowNumber]) {
        dataToPrepare.push({
          journey: state.cdjJourneyPerRow[rowNumber],
          rowNumber
        })
      }
    })

    const grouped = groupBy(dataToPrepare, 'journey.id').map(i => i[1])
    const actionDatas = grouped.map(sameJourney => {
      return { journey: sameJourney[0].journey, linesIndexes: sameJourney.map(i => i.rowNumber) }
    })

    const allDispatchs = actionDatas.map(info => {
      return dispatch('sheet/saveDayJourneys', info, { root: true })
    })

    return Promise.all(allDispatchs)
  },
  async joinLines ({ dispatch, commit }) {
    await dispatch('sheet/mergeSelectedLines', null, { root: true })
    commit('sheet/selectedLines', [], { root: true })
  },

  setConfigsToCompManFrontOptions ({ rootGetters, commit }) {
    const respectiveNamesInFrontOptions = {
      showPontoReason: 'alwaysShowFaketimelogReason'
    }

    const HANDLER_FOR_FRONT_OPTIONS = {
      showPontoReason: () => {
        const value = rootGetters['frontOptions'][respectiveNamesInFrontOptions['showPontoReason']]

        if (value === null) {
          return localStorage.getItem('showPontoReason') || CONFIGS_DEFAULTS['showPontoReason'] || false
        }
        return Boolean(value)
      },
      showFaceRecoInfo: () => {
        const value = rootGetters['frontOptions'][respectiveNamesInFrontOptions['showFaceRecoInfo']]

        if (value === null) {
          return CONFIGS_DEFAULTS['showFaceRecoInfo']
        }
        return Boolean(value)
      },
      showPtsByCompany: () => {
        const value = rootGetters['frontOptions'][respectiveNamesInFrontOptions['timesheetDisplayByTlPunched']]

        if (value === null) {
          return CONFIGS_DEFAULTS['timesheetDisplayByTlPunched']
        }
        return Boolean(value)
      }
    }

    Object.keys(HANDLER_FOR_FRONT_OPTIONS).forEach(op => {
      commit('setConfig', {
        [op]: HANDLER_FOR_FRONT_OPTIONS[op]()
      })
    })
  },

  async processPts ({ dispatch, state, rootState }) {
    await dispatch(
      'sheet/reprocessLinePts',
      rootState.sheet.selectedLines,
      { root: true }
    )
  }
}

export default {
  namespaced: true,
  getters,
  state: __state,
  actions,
  mutations
}
