<template lang="pug" functional>
  div.fs-13.color-primary.info-msg(style="height: 13px; width: 100%; line-height: 1.42857143; font-family: var(--front-font);")
    | {{ props.info }}

</template>

<script>
export default {
  name: 'InfoMsg',
  functional: true,
  props: {
    info: {type: String, 'default': ''}
  }
}
</script>

<style scoped>

</style>
