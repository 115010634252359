import { API, retryRequest } from 'src/services'
import { congelarObjetosDaLista } from 'src/utils/formatadoras/formatadoras'

const defaultOnly = [
  'id', 'code', 'name', 'active', 'has_on_call', 'color', 'priority', 'visivel', 'total_of_employees', 'empregadores'
]

export default {
  namespaced: true,
  state: {
    items: [],
    loading: false
  },
  getters: {
    onlyActive (state) {
      return state.items.filter(i => i.active)
    },
    notActive (state) {
      return state.items.filter(i => !i.active)
    },
    apenasVisiveis (state) {
      const locaisVisiveis = state.items.filter(local => local.visivel)
      return locaisVisiveis
    },
    apenasAtivosEVisiveis (state) {
      const locaisAtivos = state.items.filter(local => local.active)
      const locaisVisiveis = locaisAtivos.filter(local => local.visivel)
      return locaisVisiveis
    }
  },
  mutations: {
    saveItems (state, payload) {
      state.items = payload
      state.loading = false
    },
    resetItems (state) {
      state.items = []
    },
    loading (state, payload) {
      state.loading = Boolean(payload)
    }
  },
  actions: {
    async fetchItems ({ commit }, payload) {
      payload = payload || {}

      commit('loading', true)

      const { withFields = [], fullReload } = payload

      if (fullReload) {
        commit('resetItems')
      }

      const only = Array.from(new Set([...defaultOnly, ...withFields]))

      const result = await retryRequest(API.company.get, {
        fields: JSON.stringify(only),
        order_by: 'name'
      })
      commit('saveItems', congelarObjetosDaLista(result.body.success))
    }
  }
}
