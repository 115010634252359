var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "app" } }, [
    _c(
      "div",
      { attrs: { id: "header-space" } },
      [
        _vm.userInfo && !_vm.estaNaRotaDeAcessoNegado
          ? _c("MainHeader")
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      { attrs: { id: "view-space" } },
      [
        _c(
          "transition",
          { attrs: { name: "fade", mode: "out-in" } },
          [_c("router-view", { staticClass: "view" })],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }