import Vue from 'vue'
import { obterComNotacaoPonto } from 'src/utils/outro/outro'

export default {
  bind: function (el, binding) {
    Vue.nextTick(() => {
      const refs = binding.value.refs
      const subEl = binding.value.subEl || null

      const types = []

      const genValidator = (key, el, shift = false, ctrl = false) => {
        types.push([(e) => e.key === key && e.shiftKey === shift && e.ctrlKey === ctrl, el.$el || el])
      }
      // TODO obvious what is todo right?
      if (binding.value.left) {
        genValidator('ArrowLeft', binding.value.left instanceof Function ? binding.value.left : obterComNotacaoPonto(refs, binding.value.left))
      }
      if (binding.value.right) {
        genValidator('ArrowRight', binding.value.right instanceof Function ? binding.value.right : obterComNotacaoPonto(refs, binding.value.right))
      }
      if (binding.value.down) {
        genValidator('ArrowDown', binding.value.down instanceof Function ? binding.value.down : obterComNotacaoPonto(refs, binding.value.down))
      }
      if (binding.value.up) {
        genValidator('ArrowUp', binding.value.up instanceof Function ? binding.value.up : obterComNotacaoPonto(refs, binding.value.up))
      }
      if (binding.value.esc) {
        genValidator('Escape', binding.value.esc instanceof Function ? binding.value.esc : obterComNotacaoPonto(refs, binding.value.esc))
      }
      if (binding.value.shiftTab) {
        genValidator('Tab', binding.value.shiftTab instanceof Function ? binding.value.shiftTab : obterComNotacaoPonto(refs, binding.value.shiftTab), true)
      }
      if (binding.value.tab) {
        genValidator('Tab', binding.value.tab instanceof Function ? binding.value.tab : obterComNotacaoPonto(refs, binding.value.tab))
      }

      if (subEl) {
        el = obterComNotacaoPonto(el.__vue__, subEl)
      }
      el.addEventListener('keydown', (e) => {
        for (const item in types) {
          const func = types[item][0]
          const toFocus = types[item][1]
          if (func(e)) {
            if (toFocus instanceof Function) {
              toFocus()
              return
            }
            if (e.key === 'Tab') {
              e.preventDefault()
            }
            if (toFocus.tagName === 'INPUT') {
              toFocus.select()
              toFocus.focus()
            } else {
              toFocus.focus()
            }
            return
          }
        }
      })
    })
  }
}
