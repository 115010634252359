import { multiSelect } from 'src/common/selectors'
import { obterDataValida } from 'src/utils/formatadoras/formatadoras'
import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'
import moment from 'moment'

const NOW = moment()

const defaultState = () => ({
  ...multiSelect.mapStates(['companies']),
  rangeDates: {
    start: NOW.clone().startOf('month').toDate(),
    end: NOW.clone().endOf('month').toDate()
  },
  typeView: 'scheduler' // scheduler or sums
})

const __state = defaultState()

const getters = {
}

const mutations = {
  resetState (state) {
    resetarParaEstadoInicial(state, defaultState())
  },
  ...multiSelect.mapMutations(['companies']),
  typeView (state, typeView) {
    state.typeView = typeView
  },
  rangeDates (state, {start, end}) {
    state.rangeDates.start = obterDataValida(start)
    state.rangeDates.end = obterDataValida(end)
  }
}

const actions = {
}

export default {
  namespaced: true,
  state: __state,
  getters,
  mutations,
  actions
}
