<template lang="pug" functional>
  div.fs-13.color-danger.error-msg(style="height: 13px; width: 100%")
    | {{ props.error }}

</template>

<script>
export default {
  name: 'ErrorMsg',
  functional: true,
  props: {
    error: {type: String, 'default': ''}
  }
}
</script>

<style scoped>

</style>
