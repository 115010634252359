import { ErroValidacaoRegraDeNotificacao } from '../erros/regraNotificacao.erros'
import { EmpregadoId, GrupoId, LocalDeTrabalhoId } from 'src/tipos'
import {
  NomeRegraDeNotificacao,
  PerfilPermissaoId,
  IRegraDeNotificacao,
  CategoriaRegraNotificacao,
  EventoObservavelUsuariosEmpregados,
  EventoObservavelUsuariosGestor,
  EventoObservavel,
  TipoNotificado,
  TipoObservado,
  TipoDeNotificacao,
  UsuarioNotificado
} from './regraNotificacao.interface'

const EventoObservavelUsuariosEmpregados: Record<EventoObservavelUsuariosEmpregados, EventoObservavelUsuariosEmpregados> = {
  HorarioDeEntradaProximo: 'HorarioDeEntradaProximo',
  ChegouHorarioDeEntrada: 'ChegouHorarioDeEntrada',
  ChegouHorarioDeSaida: 'ChegouHorarioDeSaida',
  IniciouHoraExtra: 'IniciouHoraExtra',
  ChegouHorarioDeRetornoDaPausa: 'ChegouHorarioDeRetornoDaPausa',
  HoraExtraPertoDoLimiteDefinido: 'HoraExtraPertoDoLimiteDefinido',
  HoraExtraRealizadaAcimaDoLimite: 'HoraExtraRealizadaAcimaDoLimite'
}

const EventoObservavelUsuariosGestor: Record<EventoObservavelUsuariosGestor, EventoObservavelUsuariosGestor> = {
  EntradaAdiantada: 'EntradaAdiantada',
  IniciouAtraso: 'IniciouAtraso',
  EntradaNoHorario: 'EntradaNoHorario',
  EntradaAtrasada: 'EntradaAtrasada',
  SaidaAdiantada: 'SaidaAdiantada',
  SaidaNoHorario: 'SaidaNoHorario',
  SaidaAtrasadaComHoraExtra: 'SaidaAtrasadaComHoraExtra',
  IniciouHoraExtra: 'IniciouHoraExtra',
  PausaIniciada: 'PausaIniciada',
  RetornoAdiantado: 'RetornoAdiantado',
  RetornoNoHorario: 'RetornoNoHorario',
  RetornoAtrasado: 'RetornoAtrasado',
  IniciouAtrasoDoRetornoDaPausa: 'IniciouAtrasoDoRetornoDaPausa',
  HoraExtraPertoDoLimiteDefinido: 'HoraExtraPertoDoLimiteDefinido',
  HoraExtraRealizadaAcimaDoLimite: 'HoraExtraRealizadaAcimaDoLimite',
  RealizouInterjornada: 'RealizouInterjornada'
}

export class RegraDeNotificacao implements IRegraDeNotificacao {
    id?: string | undefined
    ativa = true
    categoria: CategoriaRegraNotificacao = 'gestor'
    eventosObservados: EventoObservavel[] = []
    funcionariosObservados: EmpregadoId[] = []
    gruposDeUsuarioObservados: GrupoId[] = []
    locaisDeTrabalhoObservados: LocalDeTrabalhoId[] = []
    nome: NomeRegraDeNotificacao = ''
    perfisNotificados: PerfilPermissaoId[] = []
    tipoNotificado: TipoNotificado = 'usuarios'
    tipoObservado: TipoObservado = 'funcionarios'
    tiposDeNotificacao: TipoDeNotificacao[] = []
    usuariosNotificados: UsuarioNotificado[] = []
    ehObservadoPorLiderancaDireta = false
    ehObservadoPorLiderancaIndireta = false

    constructor (inicializador?: IRegraDeNotificacao) {
      if (inicializador) {
        /*
            Inicicializador é utilizado quando vamos instanciar a classe para criação ou edição
            Quando ele não é utilizado, a classe é criado com os valores default
         */
        this.id = inicializador.id
        this.ativa = inicializador.ativa
        this.nome = this.validarNome(inicializador.nome)
        this.categoria = this.validarCategoria(inicializador.categoria)
        this.eventosObservados = this.validarEventosObservados(inicializador.categoria, inicializador.eventosObservados)
        this.funcionariosObservados = this.validarFuncionariosObservados(inicializador.funcionariosObservados)
        this.gruposDeUsuarioObservados = this.validarGrupoDeUsuarioObservados(inicializador.gruposDeUsuarioObservados)
        this.locaisDeTrabalhoObservados = this.validarLocaisDeTrabalhoObservados(inicializador.locaisDeTrabalhoObservados)
        this.perfisNotificados = this.validarPerfisNotificados(inicializador.tipoNotificado, inicializador.perfisNotificados)
        this.tipoNotificado = this.validarTipoNotificado(inicializador.tipoNotificado)
        this.tipoObservado = this.validarTipoObservado(inicializador.tipoObservado)
        this.tiposDeNotificacao = this.validarTiposDeNotificacao(inicializador.tiposDeNotificacao)
        this.usuariosNotificados = this.validarUsuariosNotificados(inicializador.tipoNotificado, inicializador.usuariosNotificados)
        this.ehObservadoPorLiderancaDireta = this.validarEhObservadoPorLiderancaDireta(inicializador.categoria, inicializador.ehObservadoPorLiderancaDireta, inicializador.ehObservadoPorLiderancaIndireta)
        this.ehObservadoPorLiderancaIndireta = this.validarEhObservadoPorLiderancaIndireta(inicializador.categoria, inicializador.ehObservadoPorLiderancaIndireta, inicializador.ehObservadoPorLiderancaDireta)
      }
    }

    private validarNome (nome: NomeRegraDeNotificacao): NomeRegraDeNotificacao {
      if (nome.length < 1) throw new ErroValidacaoRegraDeNotificacao('O nome da regra de notificação não pode ser vazio')
      if (nome.length < 3) throw new ErroValidacaoRegraDeNotificacao('O nome da regra de notificação deve ter no mínimo 3 caracteres')
      return nome
    }
    private validarCategoria (categoria: CategoriaRegraNotificacao): CategoriaRegraNotificacao {
      if (categoria !== 'gestor' && categoria !== 'empregado') throw new ErroValidacaoRegraDeNotificacao('A categoria de permissão não é válida')
      return categoria
    }
    private validarEventosObservados (categoria: CategoriaRegraNotificacao, eventosObservados: EventoObservavel[]): EventoObservavelUsuariosEmpregados[] | EventoObservavelUsuariosGestor[] {
      if (eventosObservados.length < 1) throw new ErroValidacaoRegraDeNotificacao('Deve ser informado pelo menos um evento a ser observado observado')
      if (categoria === 'empregado') {
        const eventosValidos = Object.values(EventoObservavelUsuariosEmpregados) as EventoObservavel[]
        if (eventosObservados.some(evento => !eventosValidos.includes(evento))) throw new ErroValidacaoRegraDeNotificacao('Eventos observados não são validos para a categoria empregado')
        return eventosObservados as EventoObservavelUsuariosEmpregados[]
      }
      if (categoria === 'gestor') {
        const eventosValidos = Object.values(EventoObservavelUsuariosGestor) as EventoObservavel[]
        if (eventosObservados.some(evento => !eventosValidos.includes(evento))) throw new ErroValidacaoRegraDeNotificacao('Eventos observados não são validos para a categoria gestor')
        return eventosObservados as EventoObservavelUsuariosGestor[]
      }
      throw new ErroValidacaoRegraDeNotificacao('Categoria não é válida')
    }
    private validarFuncionariosObservados (funcionariosObservados: EmpregadoId[]): EmpregadoId[] {
      return funcionariosObservados
    }
    private validarGrupoDeUsuarioObservados (grupoDeUsuarioObservados: GrupoId[]): GrupoId[] {
      return grupoDeUsuarioObservados
    }
    private validarLocaisDeTrabalhoObservados (locaisDeTrabalhoObservados: LocalDeTrabalhoId[]): LocalDeTrabalhoId[] {
      return locaisDeTrabalhoObservados
    }
    private validarPerfisNotificados (tipoNotificado: TipoNotificado, perfisNotificados: PerfilPermissaoId[]): PerfilPermissaoId[] {
      if (tipoNotificado !== 'perfil') return []
      if (perfisNotificados.length < 1) throw new ErroValidacaoRegraDeNotificacao('Deve ser informado pelo menos um perfil a ser notificado')
      return perfisNotificados
    }
    private validarTipoNotificado (tipoNotificado: TipoNotificado): TipoNotificado {
      const tiposNotificadosValidos = ['usuarios', 'perfil']
      if (!tiposNotificadosValidos.includes(tipoNotificado)) throw new ErroValidacaoRegraDeNotificacao('Tipo notificado não é válido')
      return tipoNotificado
    }
    private validarTipoObservado (tipoObservado: TipoObservado): TipoObservado {
      const tiposObservadosValidos = ['funcionarios', 'locais_de_trabalho', 'grupos']
      if (!tiposObservadosValidos.includes(tipoObservado)) throw new ErroValidacaoRegraDeNotificacao('Tipo observado não é válido')
      return tipoObservado
    }
    private validarTiposDeNotificacao (tiposDeNotificacao: TipoDeNotificacao[]): TipoDeNotificacao[] {
      const tiposDeNotificacaoValidos = ['desktop', 'mobile']
      if (tiposDeNotificacao.length < 1) throw new ErroValidacaoRegraDeNotificacao('Deve ser informado pelo menos um tipo de notificação')
      if (tiposDeNotificacao.some(tipo => !tiposDeNotificacaoValidos.includes(tipo))) throw new ErroValidacaoRegraDeNotificacao('Tipo de notificação não é válido')
      return tiposDeNotificacao
    }
    private validarUsuariosNotificados (tipoNotificado: TipoNotificado, usuariosNotificados: UsuarioNotificado[]): UsuarioNotificado[] {
      if (tipoNotificado !== 'usuarios') return []
      if (usuariosNotificados.length < 1) throw new ErroValidacaoRegraDeNotificacao('Deve ser informado pelo menos um usuário a ser notificado')
      return usuariosNotificados
    }
    private validarEhObservadoPorLiderancaDireta (categoria:CategoriaRegraNotificacao, ehObservadoPorLiderancaDireta: boolean, ehObservadoPorLiderancaIndireta: boolean): boolean {
      if (ehObservadoPorLiderancaDireta && categoria !== 'gestor') throw new ErroValidacaoRegraDeNotificacao('Apenas regras de notificação de gestores podem ser observadas por liderança')
      if (ehObservadoPorLiderancaIndireta && ehObservadoPorLiderancaDireta) throw new ErroValidacaoRegraDeNotificacao('Não é possível observar por liderança direta e indireta ao mesmo tempo')
      return ehObservadoPorLiderancaDireta
    }
    private validarEhObservadoPorLiderancaIndireta (categoria:CategoriaRegraNotificacao, ehObservadoPorLiderancaIndireta: boolean, ehObservadoPorLiderancaDireta: boolean): boolean {
      if (ehObservadoPorLiderancaIndireta && categoria !== 'gestor') throw new ErroValidacaoRegraDeNotificacao('Apenas regras de notificação de gestores podem ser observadas por liderança')
      if (ehObservadoPorLiderancaDireta && ehObservadoPorLiderancaIndireta) throw new ErroValidacaoRegraDeNotificacao('Não é possível observar por liderança indireta e direta ao mesmo tempo')
      return ehObservadoPorLiderancaIndireta
    }
}
