import moment from 'moment'
import Vue from 'vue'
import { uniq } from 'lodash'

import { API, retryRequest } from 'src/services'
import { obterDataComHoraInicial } from 'src/utils/formatadoras/formatadoras'
import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'
import store from 'src/store/index'

import { defaultState as toolBoxDefault } from './toolBox'
import { ApontamentoDiarioRepoConsulta } from 'src/components/Sheets/Repositorio/RepoConsulta/Apontamento'
import { FiltradorDeErros } from 'src/erros'

import { hasPermission } from '@/utils'

import { ServicoHttpCondominio } from '@/typescript/servicos/http/http.servico'
import { FolhaRepositorio } from '@/typescript/modulos/estrutura-de-pontos-da-empresa/Folha/repositorio/folha.repositorio'
import { FolhaServico } from '@/typescript/modulos/estrutura-de-pontos-da-empresa/Folha/dominio/folha'

// All the functions in this file are destined to only be called by the state itself
// Be careful changing things here cuz all logic that control the basic state of sheet is here.

Vue.http.interceptors.push(function (request, next) {
  if (request.url.includes('timesheet-partial')) {
    store.commit('sheet/adicionarRequisicao', request)
  }
  next()
})

Vue.http.interceptors.push(function (request, next) {
  if (request.url.includes('sumtime')) {
    const oldSumtimesRequests = store.state.sheet.requisicoes.filter(i => i?.url?.includes('sumtime'))
    oldSumtimesRequests.forEach(r => {
      r.abort()
    })
    store.commit('sheet/adicionarRequisicao', request)
  }
  next()
})

const montarFolhaServico = (store) => {
  const http = new ServicoHttpCondominio(store.rootState)
  const repositorio = new FolhaRepositorio(http)
  return new FolhaServico(repositorio, store)
}

function __state () {
  return {
    controladoresDeCancelamento: [],
    requisicoes: [],
    processing: {
      lines: [], // index of lines
      pts: [], // index of pts. Will not be empty when there's lines with unprocessed pts
      djs: [], // index of djs
      justifies: [], // index of justifies
      tms: [],
      whbs: [],
      approvals: []
    },
    requestsLines: {
      lines: {}, // key: index of Line, value: list of requisicoes
      djs: {},
      pts: {},
      justifies: {},
      whbs: {},
      approvals: {}
    },
    loading: {
      lines: false,
      pts: false,
      djs: false,
      tms: false,
      justifies: false,
      whbs: false,
      approvals: false
    },
    apontamentosDiariosPorJornadaRealizada: {}
  }
}

const mutations = {
  resetLines (state) {
    state.lines = []
  },
  state (state, payload) {
    state.populating = true
    const employeesPerId = {}
    payload.employees.forEach(i => {
      employeesPerId[i.id] = i
    })

    resetarParaEstadoInicial(state.toolBox, toolBoxDefault())

    payload.lines.forEach((line, index) => {
      line.employee = employeesPerId[line.employee]

      // check if is double line
      const lastLine = index > 0 ? payload.lines[index - 1] : null
      let isDoubleLine = false

      if (lastLine && lastLine.employee.id === line.employee.id &&
        moment(lastLine.data).toDate().toDateString() === moment(line.data).toDate().toDateString() && line.id) {
        isDoubleLine = true
      }

      line.isDoubleLine = isDoubleLine
    })

    state.lines = payload.lines.map(l => {
      return {
        timelogs: null,
        dayJourney: null,
        timeMeasurements: null,
        justify: null,
        comments: null,
        workHoursBank: null,
        regraDeAprovacao: null,
        ...l
      }
    })
    state.selectedLines = []
    state.processing = __state().processing
    state.employees = payload.employees
    state.employeesPerId = employeesPerId
    state.populating = false
  },
  loading (state, payload) {
    state.loading = { ...state.loading, ...payload }
  },
  processing (state, { types, add = false, remove = false }) {
    if (!add && !remove) {
      throw new Error(`the 'processing' action of sheet dont received add or remove params`)
    } else if ((add && add.length === 0) || (remove && remove.length === 0)) {
      return
    }
    types.forEach(type => {
      if (add) {
        Vue.set(state.processing, type, Array.from(new Set([...state.processing[type], ...add])))
      } else {
        Vue.set(state.processing, type, state.processing[type].filter(el => remove.indexOf(el) < 0))
      }
    })
  },
  _populateLines (state, { loaded, lines, calcLinesMap = false, mergeTms = false }) {
    // mergeTms = pass true if you want to merge current line info in vuex
    // with the infos of the lines in the payload

    state.populating = true

    const fieldsToUpdatePerLoadType = {
      pts: ['timelogs'],
      djs: ['dayJourney'],
      tms: ['timeMeasurements'],
      justifies: ['justify', 'comments'],
      whbs: ['workHoursBank'],
      approvals: ['approvals', 'regraDeAprovacao']
    }
    const fieldsToUpdade = fieldsToUpdatePerLoadType[loaded]

    if (lines.find(l => !l.infoToRefetch.hashFront)) {
      throw new Error('PopulateLines received lines without infoToRefetch')
    }

    const onlyHashs = lines.map(l => l.infoToRefetch.hashFront)
    if (Array.from(new Set(onlyHashs)).length !== onlyHashs.length) {
      throw new Error('PopulateLines received lines with duplicated hashs')
    }

    const realIndexesPerHash = {}
    state.lines.forEach((l, index) => {
      realIndexesPerHash[l.infoToRefetch.hashFront] = index
    })

    lines.forEach(line => {
      delete line.employee

      const realIndex = realIndexesPerHash[line.infoToRefetch.hashFront]
      const realLine = state.lines[realIndex]

      if (!realLine) return

      state.lines[realIndex].lockRequest = (realLine && realLine.lockRequest) || (line && line.lockRequest) || null

      fieldsToUpdade.forEach(f => {
        state.lines[realIndex][f] = Object.freeze(line[f])
      })

      if (mergeTms && line.timeMeasurements) {
        state.lines[realIndex].timeMeasurements.apontamentos = {
          ...(realLine.timeMeasurements?.apontamentos || {}),
          ...(line.timeMeasurements?.apontamentos || {})
        }
      }
    })

    if (calcLinesMap) {
      const linesMap = []
      state.lines.forEach(l => {
        let row = []
        if (!l.timelogs || !l.timelogs.length) {
          row = [[], [], [], []]
        } else {
          l.timelogs.forEach(kind => {
            row.push(kind.map(tl => tl.id))
          })
        }
        linesMap.push(row)
      })
      state.linesMap = linesMap
    }
    state.populating = false
  },
  preencherApontamentosDiarios (state, novosApontamentosDiarios) {
    const atualizarApontamentosDiariosExistentes = (state, novosApontamentosDiarios) => {
      Object.entries(novosApontamentosDiarios).forEach(([key, apontamento]) => {
        const apontamentoExistente = state.apontamentosDiariosPorJornadaRealizada[key]

        Vue.set(state.apontamentosDiariosPorJornadaRealizada, key,
          apontamentoExistente
            ? { ...apontamentoExistente, ...apontamento }
            : apontamento
        )
      })
    }

    function obterTamanhoObjeto (obj) {
      return Object.keys(obj).length
    }

    if (obterTamanhoObjeto(state.apontamentosDiariosPorJornadaRealizada) > 0) {
      atualizarApontamentosDiariosExistentes(
        state, novosApontamentosDiarios
      )
    } else {
      state.apontamentosDiariosPorJornadaRealizada = novosApontamentosDiarios
    }
  },
  updateLine (state, payload) {
    state.populating = true
    delete payload.updatedLine.employee
    let newLineMap = []
    if (payload.updatedLine.timelogs) {
      payload.updatedLine.timelogs.forEach(kind => {
        newLineMap.push(kind.map(tl => tl.id))
      })
    } else {
      newLineMap = [[], [], [], []]
    }
    Vue.set(state.linesMap, payload.index, newLineMap)
    Vue.set(state.lines, payload.index, { ...state.lines[payload.index], ...payload.updatedLine })
    state.populating = false
  },
  updateMultipleLines (state, linesListUpdated) {
    // We use the attr hashFront here that back generated to know witch one line we need to update
    state.populating = true

    linesListUpdated = linesListUpdated.map(i => ({ ...i, employee: state.employeesPerId[i.employee] }))

    const linesIdsThatWereUpdated = []

    state.lines.forEach((l, rindex) => {
      for (const upindex in linesListUpdated) {
        const lup = { ...linesListUpdated[upindex] }

        delete lup['employee']

        if (lup.infoToRefetch.hashFront === l.infoToRefetch.hashFront) {
          Vue.set(state.lines, rindex, { ...l, ...lup })
          linesIdsThatWereUpdated.push(l.id)
        }
      }
    })

    // remove lines with id and no timelog, they were deleted in backend
    // we need to sync they here
    state.lines = state.lines.filter(l => {
      return !l.id || !l.timelogs || l.timelogs.reduce((a, b) => [...a, ...b]).length > 0
    })

    // add lines that back returned and are not in the state
    const receivedLinesThatAreNotInState = linesListUpdated
      .filter(l => !linesIdsThatWereUpdated.includes(l.id))
      .map(l => ({
        ...l,
        employee: state.lines.find(i => i.employee.id === l.employee).employee
      }))

    if (receivedLinesThatAreNotInState.length) {
      state.lines = [...state.lines, ...receivedLinesThatAreNotInState].sort((a, b) =>
        moment(a.data).unix() - moment(b.data).unix()
      )
    }

    // remove lines that are duplicated but are not in linesListUpdated
    // We can assume that these lines were deleted in backend cuz back always return the
    // duplicated lines of the days that we ask
    state.lines = state.lines
      .filter(l => {
        if (l.infoToRefetch.numberDuplicated !== null) {
          linesListUpdated
            .filter(i => i.employee.id === l.employee.id)
            .map(i => obterDataComHoraInicial(moment(i.data)))
            .includes(obterDataComHoraInicial(moment(l.data)))
        }

        return true
      })

    // finally, remove the lines with duplicated hashFront or ids
    state.lines =
      state.lines
        .filter(function (item, index) {
          return state.lines.findIndex(l => l.infoToRefetch.hashFront === item.infoToRefetch.hashFront) >= index
        })

    state.populating = false
  },
  addLineRequests (state, { type, request, indexes }) {
    if (!Array.isArray(type)) {
      type = [type]
    }

    type.forEach(t => {
      t = t === 'tms' ? 'lines' : t // request of tms is of line

      indexes.forEach(index => {
        if (state.requestsLines[t][index]) {
          state.requestsLines[t][index].push(request)
        } else {
          state.requestsLines[t][index] = [request]
        }
      })
    })
  },
  abortLineRequests (state, { types, indexes }) {
    types.forEach(t => {
      t = t === 'tms' ? 'lines' : t // request of tms is of line

      indexes.forEach(index => {
        if (state.requestsLines[t][index]) {
          state.requestsLines[t][index].forEach(r => {
            r.abort()
          })
        }
        state.requestsLines[t][index] = []
      })
    })
  },
  popularLinhasComInformacaoDeAcompanhamento (state, limitesAPI) {
    state.lines = state.lines.map(linhaEstado => {
      const limiteDaLinhaEncontrado = limitesAPI.find(linhaApi => {
        return linhaApi.employee === linhaEstado.employee.id &&
          moment(linhaApi.data).isSame(linhaEstado.data, 'day')
      })

      return limiteDaLinhaEncontrado ? { ...limiteDaLinhaEncontrado, ...linhaEstado } : linhaEstado
    })
  },
  adicionarRequisicao (state, payload) {
    state.requisicoes = [...state.requisicoes, payload]
  },
  adicionarControladorDeCancelamentoDeRequisicao (state, controller) {
    state.controladoresDeCancelamento.push(controller)
  },
  cancelarTodasAsRequisicoesPendentes (state) {
    state.controladoresDeCancelamento.forEach(controller => controller.abort())
    state.controladoresDeCancelamento = []
  }
}

const actions = {
  async loadSheet (store, { startDate, endDate, companies, employees, userGroups }) {
    const ptsByCompany = store.rootState.sheet.toolBox.configs.ptsByCompany && store.rootState.timesheet.header.type === 'work-place'
    await montarFolhaServico(store).carregarFolha(
      startDate,
      endDate,
      ptsByCompany,
      companies,
      employees,
      userGroups
    )
  },
  async populateLines ({ dispatch, commit, state, rootState, rootGetters }, { startDate, endDate }) {
    if (!state.lines.length) {
      return
    }
    const batch = 100

    // Start async load of things, the actions returns promises
    // that we will wait to only do the request of whbSums and sumtime
    // when the entire sheet is loaded to not fuck with the Back

    dispatch('carregarLotesDaFolha',
      { load: 'pts', batchEmployees: batch, startDate, endDate }
    )

    dispatch('carregarLotesDaFolha',
      {
        load: 'djs',
        batchEmployees: batch,
        startDate,
        endDate,
        mergeTms: true,
        nested: [
          // justifies calculate on the fly the delay time
          // merge tms will merge current tms of line with the response
          { load: 'justifies', mergeTms: true }
        ]
      }
    ).then(() => {
      dispatch('_verifyMissingDayJourneysAndDaysToRecalc')
    })

    dispatch('carregarLotesDaFolha',
      { load: 'whbs', batchEmployees: null, startDate, endDate }
    )

    const hasApprovals = rootState.userInfo.compMan.hasApprovals

    if (hasApprovals) {
      dispatch('carregarLotesDaFolha',
        { load: 'approvals', batchEmployees: null, startDate, endDate }
      )
    }

    const hasCalculoPassivo = rootState.userInfo.compMan.hasCalculoPassivo
    const temMostrarRubricasEGrupoDeRubricas = rootState.userInfo.compMan.frontOptions.mostrarRubricasEGrupoDeRubricas

    if (hasCalculoPassivo || temMostrarRubricasEGrupoDeRubricas) {
      dispatch('carregarApontamentosDiariosDeEmpregados', {
        dataInicio: startDate,
        dataFim: endDate
      })
    }

    const possuiPermissaoEditarAcompamanhamentoDiario = hasPermission({ module: 'acompanhamentodiario', action: 'get' })
    const possuiFeatureFlagDeLimiteDeHoraExtra = rootState.userInfo.compMan.possuiLimiteHoraExtra

    if (possuiFeatureFlagDeLimiteDeHoraExtra && possuiPermissaoEditarAcompamanhamentoDiario) {
      const empregados = state.lines.map(linha => linha.employee.id)
      dispatch('carregarLimitesDeHoraExtraDasLinhas', {
        dataInicio: startDate,
        dataFim: endDate,
        empregados
      })
    }

    await Promise.all(state.requisicoes)
  },
  async carregarLotesDaFolha (store, { load, startDate, endDate, batchEmployees, nested = null, mergeTms = false }) {
    await montarFolhaServico(store).carregarLoteDaFolha(
      load,
      nested,
      batchEmployees,
      startDate,
      endDate,
      mergeTms
    )
  },
  updateLine ({ commit, dispatch, rootState, state }, payload) {
    // payload: {type: ['pts', 'lines', 'djs', 'tms', 'justify], index: rowNumber of line}
    // Start process of update line populating it again
    const line = state.lines[payload.index]

    payload.type = payload.type === 'tms' ? 'lines' : payload.type

    // let's avoid abort any line request if has any

    if (payload.type !== 'acompanhamentos-diarios') {
      commit('abortLineRequests', { indexes: [payload.index], types: [payload.type] })
    }
    const TYPES_RECALC_LINE = ['pts', 'djs']

    const params = {
      startDate: line.data,
      endDate: line.data,
      employees: [line.employee.id],
      djs: line.dayJourney ? [line.dayJourney.id] : null
    }

    if (line.id) {
      params.lines = [line.id]
    }

    return retryRequest(API.timesheet.save, { load: payload.type }, params)
      .then(response => {
        commit('updateLine', { updatedLine: response.body.success.lines[0], index: payload.index })

        if (TYPES_RECALC_LINE.includes(payload.type) && !payload.blockRecalculate) {
          dispatch('recalcLines', [payload.index])
        }
      })
  },
  async updateMultipleLines ({ commit, dispatch, rootState, state }, { toLoad, indexes, recalculate = false }) {
    // payload: {toLoad: ['pts', 'lines', 'djs', 'justifies'], indexes: list of indexes of lines}

    Object.freeze(toLoad)

    if (!indexes.length || !state.lines.length) { return }
    commit('processing', {
      add: indexes,
      // dont show processing pts or djs when is asked to recalculate
      types: toLoad.filter(i => recalculate ? !['pts', 'djs'].includes(i) : true)
    })
    const lines = indexes.map(item => state.lines[item])

    commit('abortLineRequests', { indexes, types: toLoad })

    // ------------ Prepare data of request --------------------------
    const params = {
      infoToRefetchList: lines.filter(l => Boolean(l)).map(l => l.infoToRefetch),
      toLoad,
      recalculate
    }
    if (toLoad.includes('djs') || toLoad.includes('justifies')) {
      params.djs = lines.filter(l => l.dayJourney && l.dayJourney.id).map(l => l.dayJourney.id)
    }

    // --------- Request and update state with responses ------------
    try {
      const response = await retryRequest(API.timesheetSync.save, undefined, params)
      commit('updateMultipleLines', response.body.success.lines)
    } catch (erro) {
      if (recalculate === true) {
        dispatch('updateMultipleLines', { toLoad, indexes, recalculate: false })
        return
      }
    }

    if (rootState.userInfo.compMan.frontOptions.mostrarRubricasEGrupoDeRubricas) {
      const primeiraData = lines[0].data
      const ultimaData = lines[lines.length - 1].data
      dispatch('carregarApontamentosDiariosDeEmpregados', {
        dataInicio: primeiraData,
        dataFim: ultimaData
      })
    }

    commit('processing', { remove: indexes, types: toLoad })
  },
  async updateMultipleLinesByDates ({ dispatch }, { dates, ...otherArgs }) {
    const indexes = getLineIndexesByDates(dates)

    return dispatch('updateMultipleLines', { indexes, ...otherArgs })
  },
  async recalcLines ({ dispatch, rootState, state }, indexes) {
    // indexes = indexes of lines that is to be recalc

    indexes = indexes
      .filter(index => state.lines[index].dayJourney && !state.lines[index].dayJourney.locked)

    if (!indexes.length) { return }

    // verify if there's any pt that need to be processed
    // if true, we add 'pts' in the load list, that back will do the trick
    const anyPtToRecalc = indexes.find(index => {
      const timelogs = state.lines[index].timelogs.reduce((a, b) => [...a, ...b])
      return timelogs.filter(i => i.kind === 'unprocessed').length > 0
    })

    // recalculate auto fill pause pts if need
    // here we check if there's any employee that use autoFill and has pause missing
    const anyEmpNeedFillPause = indexes.find(index => {
      const useAutoFillPause = state.lines[index].employee.autoPauseFill === true

      if (useAutoFillPause) {
        const { timelogs } = state.lines[index]

        // has a missing pause pt and the line has Entrada and Saída pts
        const startEndOk = timelogs[0].length >= 1 && timelogs[3].length >= 1
        return (timelogs[1].length === 0 || timelogs[2].length === 0) && startEndOk
      }
    })

    const loadPtsToo = (anyPtToRecalc !== undefined) || (anyEmpNeedFillPause !== undefined)

    // We dont need to add 'lines' nor 'djs', with recalculate = true back will consider they
    // if we put they will appear loading

    const toLoadList = ['tms', 'whbs', 'djs']

    if (loadPtsToo) {
      toLoadList.push('pts')
    }

    if (rootState.userInfo.compMan.hasApprovals) {
      toLoadList.push('approvals')
    }

    const params = {
      toLoad: toLoadList,
      indexes,
      recalculate: true
    }

    dispatch('updateMultipleLines', params)
  },
  async _verifyMissingDayJourneysAndDaysToRecalc ({ commit, dispatch, state }) {
    // this action is called when the sheet is loaded
    // the goal here is to keep the sheet clean of toBeRecalculated and not generated djs

    const maxToVerify = moment().add(2, 'months')

    const linesWithIndex = state.lines
      .map((line, index) => ({ line, index }))
      .filter(({ line }) => !line.dayJourney.locked)

    // 1º GET THE PROBLEMS

    const needGenDj = linesWithIndex
      .filter(item => !item.line.dayJourney?.id && moment(item.line.data).isBefore(maxToVerify))
      .map(item => ({ employee: item.line.employee.id, data: item.line.data, index: item.index }))

    const needRecalc = linesWithIndex
      .filter(({ line }) => line.toBeRecalculated || line.dayJourney.toBeRecalculated)
      .map(i => i.index)

    commit('sheet/processing', { add: needRecalc, types: ['tms'] }, { root: true })

    // 2º GENERATE DAY JOURNEYS

    const genDjsIndexes = needGenDj.map(i => i.index)

    if (genDjsIndexes.length) {
      const dates = needGenDj.map(i => moment(i.data))
      const startDate = moment.min(dates)
      const endDate = moment.max(dates)

      commit('sheet/processing', { add: genDjsIndexes, types: ['djs'] }, { root: true })

      try {
        await API.genEmpsDjs.save({
          employees: needGenDj.map(i => i.employee),
          startDate,
          endDate
        })
      } catch (erro) {
        FiltradorDeErros.capturarErro(erro)
      }

      commit('sheet/processing', { remove: genDjsIndexes, types: ['djs'] }, { root: true })
    }

    // 3º RECALCULATE DAYS WITH TO_BE_RECALC AND THE DAYS WITH GENERATED DJS

    dispatch(
      'sheet/updateMultipleLines',
      { toLoad: ['djs', 'whbs', 'tms'], indexes: [...genDjsIndexes, ...needRecalc], recalculate: true },
      { root: true }, { root: true }
    )
  },
  async carregarApontamentosDiariosDeEmpregados ({ state, dispatch, commit }, { dataInicio, dataFim }) {
    const idsDosEmpregados = uniq(state.lines.map(i => i.employee.id))
    const idsDasLinhas = uniq(state.lines.filter(l => l.id !== null).map(l => l.id))
    const repoConsulta = new ApontamentoDiarioRepoConsulta({ api: API.apontamentosDiarios })

    const resultadoApontamentoDiarioApi = await repoConsulta
      .consultarApontamentosDiariosDeEmpregadoPorPeriodo({
        empregados: idsDosEmpregados,
        linhas: idsDasLinhas,
        dataInicio: dataInicio,
        dataFim: dataFim
      })

    if (!resultadoApontamentoDiarioApi) {
      return
    }

    commit('preencherApontamentosDiarios', resultadoApontamentoDiarioApi.apontamentos)
  },
  async carregarLimitesDeHoraExtraDasLinhas ({ state, dispatch, commit }, { dataInicio, dataFim, empregados }) {
    try {
      const parametros = {
        startDate: dataInicio,
        endDate: dataFim,
        employees: empregados
      }
      const resultadoAPI = await retryRequest(API.timesheet.save, { load: 'acompanhamentos-diarios' }, parametros)
      commit('popularLinhasComInformacaoDeAcompanhamento', resultadoAPI.body.success.lines)
    } catch (erro) { }
  }
}

export function getLineIndexesByDates (dates) {
  // returns the indexes of lines that date is in given list of dates

  const datesString = dates.map(i => moment(i).format('YYYY-MM-DD'))

  return store.state.sheet.lines
    .map((l, index) => {
      if (datesString.includes(moment(l.data).format('YYYY-MM-DD'))) {
        return index
      }
      return null
    })
    .filter(i => i !== null)
}

export default {
  state: __state,
  actions,
  mutations
}
