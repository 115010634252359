<template lang="pug">
  span
    label.switch.noselect(v-if="!threeStates")
      input(type="checkbox" v-model="state")
      span.slider.round

    label.switch.noselect.three(v-else :class="{['position-' + state]: true}")
      span.slider.round(:class="{['position-' + state]: true}")
      div(@click="switchState(0)")
      div(@click="switchState(1)")
      div(@click="switchState(2)")

    label.to-click(v-if="threeStates" @click="switchState") &nbsp;
      slot

</template>

<script>
import { handlerUseStateVmodel } from 'src/common/mixins/handlerUseStateVmodel'

export default {
  name: 'Slider',
  mixins: [handlerUseStateVmodel()],
  props: {
    threeStates: {type: Boolean, 'default': false}
  },
  methods: {
    switchState (to) {
      if (this.threeStates) {
        this.state = to
      } else {
        this.state = !this.state
      }
      this.$emit('change', this.state)
    }
  }
}
</script>

<style scoped lang="scss">
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;

  &.three {
    width: 60px;

    div {
      width: calc(60px/3);
      height: 20px;
      cursor: pointer;
      margin: 0;
      z-index: 10;
      display: inline-block;
      background-color: white;
      border-width: 10px;
      border-color: var(--color-success);
    }

    .slider {
      pointer-events:none;
      z-index: 1;
    }

    &.position-0 {
      .slider {
        &:before {
          transform: translateX(0px);
        }
      }
    }
    &.position-1 {
      .slider {
        &:before {
          transform: translateX(18px);
        }
      }
    }
    &.position-2 {
      .slider {
        &:before {
          transform: translateX(38px);
        }
      }
    }
  }

  &:not(.three) {
    input {
      &:checked + .slider {
        border-color: var(--color-info);
        border-style: solid;
        border-width: 2px;
      }

      &:checked + .slider:before {
        transform: translateX(18px);
      }
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    border-color: #d3d3d3;
    border-style: solid;
    border-width: 2px;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 2px;
      background-color: var(--color-info);
      -webkit-transition: .4s;
      transition: .4s;
    }
    input {
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

</style>
