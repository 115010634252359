import Vue from 'vue'

export default {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    Vue.nextTick(() => {
      if (el.__vue__ && el.__vue__.focus) {
        el.__vue__.focus()
      } else {
        el.focus()
      }
    })
  }
}
