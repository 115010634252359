var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "p-time-count-field" }, [
    _vm.decimal
      ? _c(
          "div",
          { staticClass: "campos" },
          [
            _c(
              "PTextField",
              _vm._g(
                _vm._b(
                  {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: ["#.##", "##.##", "###.##"],
                        expression: "['#.##', '##.##', '###.##']",
                      },
                    ],
                    staticClass: "input-decimal",
                    attrs: {
                      label: _vm.label,
                      type: "text",
                      loading: _vm.loading,
                      disabled: _vm.disabled,
                      error: _vm.error,
                      placeholder: "00.00",
                    },
                    model: {
                      value: _vm.float,
                      callback: function ($$v) {
                        _vm.float = $$v
                      },
                      expression: "float",
                    },
                  },
                  "PTextField",
                  _vm.localAttributes,
                  false
                ),
                _vm.localListeners
              )
            ),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "campos" },
          [
            !_vm.justMinutes
              ? _c(
                  "PTextField",
                  _vm._g(
                    _vm._b(
                      {
                        directives: [
                          {
                            name: "mask",
                            rawName: "v-mask",
                            value: ["#", "##", "###"],
                            expression: "['#', '##', '###']",
                          },
                        ],
                        staticClass: "input-horas",
                        attrs: {
                          label: `${_vm.notShowLabel ? "" : _vm.label}`,
                          type: "text",
                          loading: _vm.loading,
                          disabled: _vm.disabled,
                          error: _vm.error,
                        },
                        model: {
                          value: _vm.hours,
                          callback: function ($$v) {
                            _vm.hours = $$v
                          },
                          expression: "hours",
                        },
                      },
                      "PTextField",
                      _vm.localAttributes,
                      false
                    ),
                    _vm.localListeners
                  )
                )
              : _vm._e(),
            _c(
              "PTextField",
              _vm._g(
                _vm._b(
                  {
                    directives: [
                      {
                        name: "mask",
                        rawName: "v-mask",
                        value: _vm.withoutLimitMinutes
                          ? ["#", "##", "###"]
                          : _vm.mascaraDoInput,
                        expression:
                          "withoutLimitMinutes ? ['#', '##', '###'] : mascaraDoInput",
                      },
                    ],
                    staticClass: "input-minutos",
                    attrs: {
                      label: `${_vm.notShowLabel ? "" : _vm.labelMinutos}`,
                      type: "text",
                      loading: _vm.loading,
                      disabled: _vm.disabled,
                      error: _vm.error,
                    },
                    model: {
                      value: _vm.minutes,
                      callback: function ($$v) {
                        _vm.minutes = $$v
                      },
                      expression: "minutes",
                    },
                  },
                  "PTextField",
                  _vm.localAttributes,
                  false
                ),
                _vm.localListeners
              )
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }