<template lang="pug">
  .checkbox-with-input
    label.p-checkbox.p-checkbox__label(v-on="localListeners")
      input.p-checkbox__input(
        type="checkbox",
        v-model="state.check"
        v-bind="localAttributes",
        :disabled="disabled || loading || readonly",
        :class="classInput"
        @keyup.enter="state = !state"
        readonly
      )
      div.p-checkbox__indicator
      span(:class="classLabel") {{ labelPrimeiraParte }}
    .text-input
      div.p-textfield(:class="{'icon-slot': $slots.icon && $slots.icon.length}")
        input.p-textfield__input(
          v-model.number="state.limit",
          ref="input",
          :disabled="disabled || loading || !state.check",
          :type="type",
          :autocomplete="autocomplete"
          v-on="localListeners",
          spellcheck="false"
          v-bind="localAttributes",
          :style="hideLabel ? {paddingTop: '0'} : {}"
          :class="{error: error}",
        )

        label.p-textfield__title(for="textfield" :class="{error: error}")

        span.p-textfield__icon--span(v-if="$slots.icon && $slots.icon.length")
          slot(name="icon")

        span.p-textfield__icon--span-copy(v-if="copyicon", @click="copyToClipBoard")
          tooltip(
            text="copiado",
            trigger="manual",
            v-model="showTooltip"
          )
            img.p-textfield__icon(src="../../../static/images/copy_content.svg")

        //- ErrorMsg(:error="error")
    label.p-checkbox.p-checkbox__label(v-on="localListeners")
      span(:class="classLabel") {{ labelSegundaParte }}
</template>

<script>
import { handlerUseStateVmodel } from '../mixins/handlerUseStateVmodel'
import { elementFocusable } from '../mixins/elementFocusable'
import { Tooltip } from 'uiv'
import ErrorMsg from 'src/common/inputs/ErrorMsg'

export default {
  name: 'PCheckboxTextField',
  mixins: [handlerUseStateVmodel(), elementFocusable('input')],
  components: {
    Tooltip, ErrorMsg
  },
  data () {
    return {
      localListeners: undefined,
      localAttributes: undefined,
      showTooltip: false
    }
  },
  props: {
    labelPrimeiraParte: { type: String, required: true },
    labelSegundaParte: { type: String, required: true },
    labelTextInput: { type: String, required: false, defaul: '' },
    disabled: Boolean,
    loading: Boolean,
    readonly: Boolean,
    hideLabel: Boolean,
    error: String,
    copyicon: Boolean,
    type: { default: 'text' },
    autocomplete: { default: 'off' }
  },
  created () {
    this.localListeners = Object.keys(this.$listeners)
      .filter(key => key !== 'input')
      // eslint-disable-next-line no-sequences
      .reduce((res, key) => (res[key] = this.$listeners[key], res), {}) // eslint-disable-line no-return-assign

    this.localAttributes = Object.keys(this.$attrs)
      .filter(key => key !== 'type' && key !== 'disabled')
      // eslint-disable-next-line no-sequences
      .reduce((res, key) => (res[key] = this.$attrs[key], res), {}) // eslint-disable-line no-return-assign
  },
  computed: {
    classLabel () {
      return {
        'p-checkbox__label--loading': this.loading,
        'p-checkbox__label--disabled': this.disabled,
        'p-checkbox__label--readonly': this.readonly
      }
    },
    classInput () {
      return {
        'p-checkbox__input--loading': this.loading,
        'p-checkbox__input--disabled': this.disabled,
        'p-textfield__error--input': this.error
      }
    }
  },
  methods: {
    copyToClipBoard () {
      this.$refs.input.select()
      document.execCommand('copy')

      if (window.getSelection) {
        window.getSelection().removeAllRanges()
      } else if (document.selection) {
        document.selection.empty()
      }

      this.showTooltip = true
      setTimeout(() => {
        this.showTooltip = false
      }, 1000)
    },
    select () {
      // to outside use, dont remove, we want this component to act like a real input
      this.$refs.input.select()
    }
  }
}
</script>

<style scoped>
.checkbox-with-input {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  font-size: 14px;
}

.text-input {
  width: 10%;
  margin: 0 0;
  padding: 0 1%;
  align-content: center;
  justify-content: center;
}
</style>

<style scoped>

.p-checkbox {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.p-checkbox span {
  flex: auto;
}

.p-checkbox .p-checkbox__input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.p-checkbox:hover .p-checkbox__input:not([disabled]) ~ .p-checkbox__indicator {
  background: #fff;
  opacity: .8;
}
.p-checkbox:hover .p-checkbox__input:not([disabled]) ~ span,
.p-checkbox__input:focus ~ span  {
  color: var(--color-primary);
  font-weight: bold;
}

.p-checkbox .p-checkbox__input:disabled ~ .p-checkbox__indicator {
  background: #fff;
  opacity: .4;
  pointer-events: none;
}

.p-checkbox__label .p-checkbox__input:disabled ~ .p-checkbox__indicator:after {
  border-color: var(--color-default-disabled);
}

.p-checkbox__label .p-checkbox__input:disabled ~ .p-checkbox__indicator {
  border-color: var(--color-default-disabled);
}

.p-checkbox__label--disabled {
  cursor: auto;
  color: #6a6a6a;
}

.p-checkbox__label--readonly {
  cursor: auto;
  color: var(--color-default);
}

.p-checkbox__label--loading {
  color: transparent;
  background-color: var(--color-default-disabled);
}

.p-checkbox__indicator {
  position: relative;
  display: flex;
  width: 11px;
  height: 11px;
  margin-right: 5px;
  border: 1px solid #ccc;
  border-radius: 2px;
  cursor: pointer;
}
.p-checkbox__indicator:after, .p-checkbox__indicator:before  {
  position: absolute;
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  left: 1px;
  top: 5px;
  -webkit-transform: rotate(225deg) rotateY(180deg);
          transform: rotate(225deg) rotateY(180deg);
  -webkit-transform-origin: 0% 0%;
          transform-origin: 0% 0%;
}
.p-checkbox__label .p-checkbox__indicator:before {
  width: 5px;
  box-shadow: inset -2px 0 0 0 var(--color-primary);
  transition: height .15s ease;
}
.p-checkbox__label .p-checkbox__indicator:after {
  height: 8px;
  box-shadow: inset 0 2px 0 0 var(--color-primary);
  transition: width .15s ease .15s;
}
.p-checkbox .p-checkbox__input:checked + .p-checkbox__indicator:before {
  height: 17px;
  transition: height .15s ease .15s;
}
.p-checkbox .p-checkbox__input:checked + .p-checkbox__indicator:after {
  width: 5px;
  transition: width .15s ease;
}

</style>

<style scoped lang="scss">
.p-textfield {
  display: flex;
  position: relative;
  flex: auto;
  width: auto;
  box-sizing: border-box;
  overflow: hidden;
  line-height: 1;

  input.p-textfield__input {
    display: flex;
    width: 100%;
    font-family: var(--front-font);
    text-align: center;
    border: none;
    outline: none;
    color: black;
    border-bottom-width: 0.7px;
    border-bottom-style: solid;
    border-bottom-color: var(--color-primary);
    transition: all .3s ease;

    &::-moz-selection { /* Code for Firefox */
      color: white;
      background: var(--color-primary);
    }

    &::selection {
      color: white;
      background: var(--color-primary);
    }

    &:focus {
      border-bottom-color: var(--color-info);
    }

    /* Efeito de subir o texto*/
    &:not(:placeholder-shown) + .p-textfield__title .p-textfield__title-text{
      transform: translateY(0);
      font-size: .75rem;
      color: var(--color-default);
    }

    &:focus + .p-textfield__title .p-textfield__title-text{
      transform: translateY(0);
      font-size: .75rem;
      color: var(--color-primary);
    }

    /* Disabled style */
    &:disabled {
      border-bottom-color: var(--color-default-disabled);
      border-bottom-style: solid;
      color: #6a6a6a;
      background-color: transparent;
    }

    &:disabled + .p-textfield__title {
      color: #6a6a6a;
    }

    &.error {
      border-bottom-color: var(--color-danger);

      :focus + .p-textfield__title .p-textfield__title-text{
        color: var(--color-danger);
      }
      &:focus + .p-textfield--required .p-textfield__title-text::after {
        color: var(--color-danger);
      }
    }

    /* this disables the default browser arrows in input number */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    &[type=number] {
      -moz-appearance:textfield; /* Firefox */
    }

  }

  label.p-textfield__title {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    font-size: 90%;
    font-family: var(--front-font);
    pointer-events: none;

    /* Error style */
    &.error {
      color: var(--color-danger);

      &:focus {
        border-bottom-color: var(--color-danger);
      }
    }
  }
}

</style>

<style scoped>

/* Cria efeito de linha mudar de cor quando selecionado */

.p-textfield__error--message {
  display: block;
  font-size: .75rem;
  padding-top: 2px;
  padding-bottom: 2px;
  color: var(--color-danger);
}

.p-textfield__error--message {
  display: block;
  font-size: .75rem;
  padding-top: 2px;
  padding-bottom: 2px;
  color: var(--color-danger);
}

.p-textfield__error--placeholder {
  color: transparent;
  font-size: .75rem;
  padding-top: 2px;
  padding-bottom: 2px;
  grid-row: 2;
  display: block;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Copy Icon style */
.p-textfield__icon {
  margin-right: 6px;
  position: relative;
  cursor: pointer;
  width: 15px;
}

.p-textfield__icon--span {
  display: grid;
  align-self: end;
}

.p-textfield__icon--span-copy {
  position: absolute;
  display: grid;
  right: 0;
  height: 100%;
  align-items: center;
  visibility: hidden;
}

.p-textfield:hover .p-textfield__icon--span-copy {
  visibility: visible !important;
}

.p-textfield__input:not(:focus)::-webkit-input-placeholder { color:transparent; }
.p-textfield__input:not(:focus):-moz-placeholder { color:transparent; } /* FF 4-18 */
.p-textfield__input:not(:focus)::-moz-placeholder { color:transparent; } /* FF 19+ */
.p-textfield__input:not(:focus):-ms-input-placeholder { color:transparent; } /* IE 10+ */

.error {
  grid-row: 2;
}
</style>
