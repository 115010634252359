export const OPCOES_DE_APONTAMENTOS = [
  {id: 'horas_extras', name: 'horas extras'},
  {id: 'horas_extras_extraordinarias', name: 'horas extras extraordinárias'},
  {id: 'horas_extras_especial', name: 'horas extras especial'}
]

export const VALOR_PADRAO_LIMITE_HORAS_TRABALHADAS = null
export const VALOR_PADRAO_JORNADA_BASE = null

export function formatarApontamentosParaLimitePorHorasTrabalhadas (limiteDeAcumuloPorHorasTrabalhadas) {
  return limiteDeAcumuloPorHorasTrabalhadas.seletor.selected.map(apontamento => apontamento.id)
}

export function formatarValorParaLimitePorHorasTrabalhadas (limiteDeAcumuloPorHorasTrabalhadas) {
  if (!limiteDeAcumuloPorHorasTrabalhadas.valor) {
    return VALOR_PADRAO_LIMITE_HORAS_TRABALHADAS
  }

  return limiteDeAcumuloPorHorasTrabalhadas.valor
}

export function formatarJornadaBaseParaLimitePorHorasTrabalhadas (limiteDeAcumuloPorHorasTrabalhadas) {
  if (!limiteDeAcumuloPorHorasTrabalhadas.jornadaBase) {
    return null
  }

  return limiteDeAcumuloPorHorasTrabalhadas.jornadaBase
}

export function obterApontamentosSelecionadosLimiteHorasTrabalhadas (apontamentos) {
  if (!apontamentos) {
    return []
  }

  return OPCOES_DE_APONTAMENTOS.filter(opcao => {
    return apontamentos.includes(opcao.id)
  })
}

export function obterValorLimiteHorasTrabalhadas (valor) {
  if (!valor) {
    return VALOR_PADRAO_LIMITE_HORAS_TRABALHADAS
  }

  return valor
}

export function obterJornadaBaseLimiteHorasTrabalhadas (valor) {
  if (!valor) {
    return null
  }

  return valor
}
