var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "datepicker" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.fecharSeletorData,
            expression: "fecharSeletorData",
          },
        ],
        staticClass: "p-datepicker",
      },
      [
        _c(
          "p-text-field",
          {
            directives: [
              {
                name: "mask",
                rawName: "v-mask",
                value: "##/##/####",
                expression: "'##/##/####'",
              },
            ],
            staticClass: "p-datepicker__textfield",
            attrs: {
              label: _vm.label ? _vm.label : " ",
              name: _vm.name,
              type: "text",
              autocomplete: "off",
              required: _vm.$attrs.hasOwnProperty("required"),
              loading: _vm.loading,
              disabled: _vm.disabled,
              error: _vm.error,
              sinalizarErroSemMensagem: _vm.sinalizarErroSemMensagem,
              readonly: _vm.inputDesabilitadoParaDigitacao,
              mensagemDeInformacao: _vm.mensagemDeInformacao,
            },
            model: {
              value: _vm.dataRenderizadaNoInput,
              callback: function ($$v) {
                _vm.dataRenderizadaNoInput = $$v
              },
              expression: "dataRenderizadaNoInput",
            },
          },
          [
            _c("template", { slot: "logoIcon" }, [_vm._t("logoIcon")], 2),
            _c(
              "template",
              { slot: "icon" },
              [
                _c(
                  "PButton",
                  {
                    attrs: {
                      size: _vm.buttonSize,
                      color: "primary",
                      disabled: _vm.disabled,
                    },
                    on: { click: _vm.abrirSeletorData },
                  },
                  [
                    _c("span", {
                      directives: [
                        {
                          name: "uiv-tooltip",
                          rawName: "v-uiv-tooltip",
                          value: "Mostrar calendário",
                          expression: "'Mostrar calendário'",
                        },
                      ],
                      staticClass:
                        "icon-ptt-icons-calendario color-primary-contrast",
                      attrs: { disabled: _vm.disabled },
                    }),
                  ]
                ),
              ],
              1
            ),
          ],
          2
        ),
        _c("transition", { attrs: { name: "bounce" } }, [
          _vm.mostrarSeletorDeData
            ? _c(
                "div",
                { staticClass: "p-datepicker__seletor" },
                [
                  _c("DatePicker", {
                    attrs: {
                      lang: _vm.configuracaoDatePicker,
                      inline: true,
                      range: _vm.range,
                      "disabled-date": _vm.datasDesabilitadas,
                      type: _vm.tipoDoSeletor,
                    },
                    model: {
                      value: _vm.valorDataFormatada,
                      callback: function ($$v) {
                        _vm.valorDataFormatada = $$v
                      },
                      expression: "valorDataFormatada",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }