var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("label", { staticClass: "p-radio p-radio__label" }, [
    _c(
      "input",
      _vm._g(
        _vm._b(
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.state,
                expression: "state",
              },
            ],
            ref: "radio",
            staticClass: "p-radio__input",
            class: _vm.classInput,
            attrs: { type: "radio", disabled: _vm.disabled || _vm.loading },
            domProps: {
              value: _vm.option ? _vm.option : _vm.label,
              checked: _vm._q(_vm.state, _vm.option ? _vm.option : _vm.label),
            },
            on: {
              change: function ($event) {
                _vm.state = _vm.option ? _vm.option : _vm.label
              },
            },
          },
          "input",
          _vm.localAttributes,
          false
        ),
        _vm.localListeners
      )
    ),
    _c("div", { staticClass: "p-radio__indicator" }),
    _c("span", { class: _vm.classLabel, style: _vm.estilizacaoCustomizavel }, [
      _vm._v(_vm._s(_vm.label)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }