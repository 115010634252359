var render = function render(_c, _vm) {
  return _vm.props.totalItems > _vm.props.perPage
    ? _c("span", [
        _vm._v(
          _vm._s(
            _vm.props.perPage * (_vm.props.currentPage + 1) -
              _vm.props.perPage +
              1
          ) +
            " -\n" +
            _vm._s(
              Math.min(
                _vm.props.totalItems,
                _vm.props.perPage * (_vm.props.currentPage + 1)
              )
            ) +
            "\nde " +
            _vm._s(_vm.props.totalItems)
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }