import ServicoHttpBase from './http.axios'
import config from '@/config'
import Cookies from 'js-cookie'

export type RespostaHttpAxios<T> = {
  data: T,
  status: number
}

export interface IStateAutenticacao {
  auth: {
    authorization: string | null;
    accessToken: string | null;
    userPoolId: string | null;
    userPoolWebClientId: string | null;
  }
}

export class ServicoHttpCondominio extends ServicoHttpBase {
  autenticacao: IStateAutenticacao;

  constructor (autenticacao: IStateAutenticacao) {
    super(config.baseUrl + '/api')
    this.autenticacao = autenticacao
  }

  protected adicionarInterceptadorDeRequisicao (): void {
    this.httpClient.interceptors.request.use((config) => {
      if (!config.url?.endsWith('oauth2/token')) {
        if (this.autenticacao.auth.authorization) {
          config.headers.set('Authorization', this.autenticacao.auth.authorization)
        } else {
          config.headers.set('Authorization', Cookies.get('Authorization'))
        }

        if (this.autenticacao.auth.accessToken || Cookies.get('accessToken')) {
          config.headers.set('X-PontoTel-Authorization', this.autenticacao.auth.accessToken || Cookies.get('accessToken'))
          config.headers.set('X-PontoTel-UP', this.autenticacao.auth.userPoolId || Cookies.get('userPoolId'))
          config.headers.set('X-PontoTel-DI', this.autenticacao.auth.userPoolWebClientId || Cookies.get('userPoolWebClientId'))
        }
      }
      return config
    }, (error) => {
      return Promise.reject(error)
    })
  }

  protected adicionarInterceptadorDeResposta (): void {
    this.httpClient.interceptors.response.use((response) => {
      return response
    }, (error) => {
      return Promise.reject(error)
    })
  }
}
