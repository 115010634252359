import { handlerUseStateVmodel } from './handlerUseStateVmodel'
import { uniqBy } from 'lodash'

export default function canUseCommonState (mergeOldWithNew = true) {
  return {
    mixins: [handlerUseStateVmodel(mergeOldWithNew)],
    props: {
      useCommon: { type: String, default: null },
      withFields: {type: Array, default: () => []},
      withMinimalInformation: { type: Boolean, default: false },
      withParams: {type: Object, default: () => {}}
    },
    computed: {
      commonLoading () {
        if (!this.useCommon) return false
        else return this.$store.state.common[this.useCommon.split('/')[0]].loading
      },
      state: {
        get () {
          if (!this.useCommon) {
            return this.__state
          }
          const commonState = this.obterEstadoDoCommon()
          return {
            ...this.__state,
            loaded: commonState
          }
        },
        set (value) {
          this.__state = value
        }
      }
    },
    created () {
      this.reloadCommonItems()
    },
    methods: {
      reloadCommonItems () {
        if (!this.useCommon) { return }
        const usaPaginacao = Boolean(this.$options.propsData.usaPaginacao)

        const payload = {
          withFields: this.withFields,
          withMinimalInformation: this.withMinimalInformation,
          withParams: this.withParams
        }

        if (usaPaginacao) {
          this.$store.dispatch(`common/${this.useCommon.split('/')[0]}/fetchItemsComPaginacao`, payload)
        } else {
          this.$store.dispatch(`common/${this.useCommon.split('/')[0]}/fetchItems`, payload)
        }
      },
      obterEstadoDoCommon () {
        const caminhoParaGetterNaStore = this.useCommon.includes('/')
        const estadoLoadedPassadoNoVmodel = this.__state.loaded || []

        if (caminhoParaGetterNaStore) {
          return this.obterCommonAPartirDoGetter(estadoLoadedPassadoNoVmodel)
        }
        return this.obterCommonAPartirDoEstado(estadoLoadedPassadoNoVmodel)
      },
      obterCommonAPartirDoGetter (estadoLoadedPassadoNoVmodel) {
        const estadoGetter = this.$store.getters[`common/${this.useCommon}`] || []
        return uniqBy([...estadoGetter, ...estadoLoadedPassadoNoVmodel], 'id')
      },
      obterCommonAPartirDoEstado (estadoLoadedPassadoNoVmodel) {
        const estadoState = this.$store.state.common[this.useCommon]?.items || []
        return uniqBy([...estadoState, ...estadoLoadedPassadoNoVmodel], 'id')
      }
    },
    watch: {
      useCommon: function (newValue) {
        this.reloadCommonItems()
      }
    }
  }
}
