import _ from 'lodash'

export function vuexMapDefaultsGetters (stateObject) {
  const result = {}
  _.forIn(stateObject, (value, key) => {
    result[key] = (state) => state[key]
  })
  return result
}

export function vuexMapDefaultsMutations (stateObject) {
  const result = {}
  _.forIn(stateObject, (value, key) => {
    result[key] = (state, payload) => {
      state[key] = payload
    }
  })
  return result
}

export function vuexMapDefaultsActions (stateObject) {
  const result = {}
  _.forIn(stateObject, (value, key) => {
    result[key] = ({ commit }, payload) => {
      commit(key, payload)
    }
  })
  return result
}
