import Vue from 'vue'
import { API } from '../../services'
import { toBase64 } from '../../utils'
import { multiSelect } from 'src/common/selectors'

import {
  vuexMapDefaultsMutations,
  vuexMapDefaultsGetters
} from 'src/utils.store'

const defaultState = () => {
  return {
    ...multiSelect.mapStates(['companies', 'employees']),
    carregandoBiometriasFaciais: false,
    identificadorDaRequisicao: null,
    listaDeDicionariosDeBiometriasFaciaisPorEmpregado: []
  }
}

const getters = {
  ...vuexMapDefaultsGetters(defaultState())
}

const mutations = {
  ...vuexMapDefaultsMutations(defaultState()),
  ...multiSelect.mapMutations(['companies', 'employees']),
  listaDeDicionariosDeBiometriasFaciaisPorEmpregado: (state, payload) => {
    Vue.set(state, 'listaDeDicionariosDeBiometriasFaciaisPorEmpregado', payload)
  },
  resetarListaDeDicionariosDeBiometriasFaciaisPorEmpregado: (state) => {
    Vue.set(state, 'listaDeDicionariosDeBiometriasFaciaisPorEmpregado', [])
  },
  carregandoBiometriasFaciais (state, payload) {
    Vue.set(state, 'carregandoBiometriasFaciais', payload)
  },
  atualizarFotosBiometriaFacialAprovadas: (state, { id, imagem }) => {
    Vue.set(state, 'listaDeDicionariosDeBiometriasFaciaisPorEmpregado',
      state.listaDeDicionariosDeBiometriasFaciaisPorEmpregado.map(employee => {
        return {
          ...employee,
          biometrics: employee.biometrics.map(biometric => {
            if (biometric.id === id) {
              return { ...biometric, imageUrl: imagem }
            }
            return { ...biometric }
          })
        }
      }))
  },
  adicionarBiometriaAprovadaLocalmente: (state, { id, approved, createdWhen, employee, imageUrl }) => {
    Vue.set(state, 'listaDeDicionariosDeBiometriasFaciaisPorEmpregado', state.listaDeDicionariosDeBiometriasFaciaisPorEmpregado.map(empregado => {
      if (empregado.employee.id === employee) {
        return {
          ...empregado,
          biometrics: [
            ...empregado.biometrics,
            {
              id,
              approved,
              createdWhen,
              imageUrl
            }
          ]
        }
      }
      return empregado
    }))
  },
  /**
   * Mutation para deletar uma biometria localmente do estado Vuex.
   *
   * @param {Object} state O estado Vuex onde a biometria será deletada.
   * @param {string} payload.id O ID da biometria a ser deletada.
   *
   * @returns {void} Esta mutation não retorna nada explicitamente.
   */
  deletarBiometriaLocalmente: (state, { id, empregadoId }) => {
    Vue.set(state, 'listaDeDicionariosDeBiometriasFaciaisPorEmpregado', state.listaDeDicionariosDeBiometriasFaciaisPorEmpregado.map(empregado => {
      if (empregado.employee.id === empregadoId) {
        return {
          ...empregado,
          biometrics: empregado.biometrics.filter(biometria => biometria.id !== id)
        }
      }
      return empregado
    }))
  }
}

const actions = {
  async carregarFotosRecentes (context, { empregadoId }) {
    try {
      const resultado = await API.biometriaFacialFotosRecentes.get({
        empregado_id: empregadoId
      })
      if (resultado.status === 200) {
        return resultado.body.success
      }
      return []
    } catch (erro) {
      return erro
    }
  },
  async carregarBiometriasFaciais ({ commit }, { identificadorDaRequisicao, parametros }) {
    commit('identificadorDaRequisicao', identificadorDaRequisicao)
    commit('carregandoBiometriasFaciais', true)

    try {
      const resultado = await API.biometric.save({
        entidadeEscolhida: parametros.entidade_escolhida,
        idsEntidades: parametros.ids_entidades
      })

      if (resultado.status === 200) {
        commit('listaDeDicionariosDeBiometriasFaciaisPorEmpregado', resultado.body.success)
      } else if (resultado.status === 204) {
        commit('resetarListaDeDicionariosDeBiometriasFaciaisPorEmpregado')
      }
      return resultado
    } catch (erro) {
      return erro
    } finally {
      commit('carregandoBiometriasFaciais', false)
    }
  },
  async uploadImageFromBiometric ({ commit }, { employeeId, image }) {
    const formData = new FormData()
    formData.append('arquivos', image)

    const response = await API.biometricImport.save({ employee_id: employeeId }, formData)
    const resultado = response.data.arquivos[0]

    commit('adicionarBiometriaAprovadaLocalmente', {
      id: resultado.id,
      approved: resultado.approved,
      createdWhen: resultado.createdWhen,
      employee: resultado.employee,
      imageUrl: await toBase64(image)
    })
  },
  async obterFotosDeBiometriaAprovadas ({ commit }, { biometrics }) {
    const idsBiometriasAprovadas = biometrics.filter(biometria => biometria.approved)
      .map(biometria => biometria.id)
    const requisicaoBiometrias = idsBiometriasAprovadas.map(async biometriaId => {
      try {
        const response = await API.safeGetImages.get({ model: 'biometrics', id: biometriaId })
        const imagem = response.data.success['img_b64']
        commit('atualizarFotosBiometriaFacialAprovadas', { id: biometriaId, imagem })
      } catch (erro) {
        throw erro
      }
    })

    Promise.allSettled(requisicaoBiometrias)
  }
}

export default {
  namespaced: true,
  state: defaultState(),
  getters,
  mutations,
  actions
}
