var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass: "fs-13 color-primary info-msg",
      staticStyle: {
        height: "13px",
        width: "100%",
        "line-height": "1.42857143",
        "font-family": "var(--front-font)",
      },
    },
    [_vm._v(_vm._s(_vm.props.info))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }