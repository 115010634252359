import { HttpOptions, HttpResponse } from 'vue-resource/types/vue_resource'
import router from 'src/router'
import store from 'src/store'
import { FiltradorDeErros } from 'src/erros'

let estaDeslogando = false
const rotaDeLogin = '/cognito/login'
const enviarParaRotaDeLogin = true

type Resposta = (response: HttpResponse) => void
type FuncaoDeResposta = (response: Resposta) => void

/**
 * Função que desloga o usuário quando o token de autenticação expirar
 * O usuário é deslogado caso: aconteça algum retorno 401 da API e ele não esteja na rota de login ou já não esteja sendo deslogado
 * @param {HttpOptions} _ - Opções da requisição (não utilizado)
 * @param {FuncaoDeResposta} obterResposta - Função que recebe a resposta da requisição
 * @returns {void}
 */
const deslogarQuandoTokenExpirar = (_: HttpOptions, obterResposta: FuncaoDeResposta): void => {
  try {
    obterResposta(({ status }: HttpResponse): void => {
      const tokenExpirou = status === 401
      const estaNaRotaDeLogin = router.currentRoute.path === rotaDeLogin
      if (tokenExpirou && !estaNaRotaDeLogin && !estaDeslogando) {
        estaDeslogando = true
        store.dispatch('logout', enviarParaRotaDeLogin).then(() => {
          estaDeslogando = false
        })
      }
    })
  } catch (erro) {
    FiltradorDeErros.capturarErro(erro as unknown as Error)
  }
}

export default deslogarQuandoTokenExpirar
