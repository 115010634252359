var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "uiv-dropdown",
    { ref: "dropdown-sino" },
    [
      _c(
        "a",
        {
          staticClass: "to-click main-header__button--category dropdown-toggle",
          attrs: {
            type: "primary",
            "aria-label": "Notificações",
            tabindex: "0",
          },
          on: {
            click: _vm.buscarNotificacoes,
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.buscarNotificacoes.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "icon-ptt-icons-sino" }),
          _vm.notificacoesNaoLidas !== 0
            ? _c("span", { staticClass: "badge" }, [
                _vm._v(_vm._s(_vm.notificacoesNaoLidas)),
              ])
            : _vm._e(),
          _vm.notificacoesNaoLidas === 0 &&
          !_vm.relatoriosDeTodasAsFilasEstaoProntos
            ? _c("span", { staticClass: "badge" }, [
                _c("div", {
                  staticClass: "icon-ptt-icons-recarregar icon-spin",
                }),
              ])
            : _vm._e(),
          _c("div", { staticClass: "arrow-img icon-ptt-icons-dropdown" }),
        ]
      ),
      _c(
        "template",
        { slot: "dropdown" },
        [
          !_vm.totalDeRelatoriosEmAndamento
            ? _c("li", { staticClass: "lowercase" }, [
                _c("a", { staticClass: "descricao" }, [
                  _vm._v("não existem relatórios sendo gerados no momento"),
                ]),
              ])
            : _vm._e(),
          _vm.totalDeRelatoriosEmAndamento > 0
            ? _c("li", { staticClass: "lowercase" }, [
                _c("a", { staticClass: "descricao" }, [
                  _vm._v(
                    "relatórios em andamento (" +
                      _vm._s(_vm.totalDeRelatoriosEmAndamento) +
                      ")"
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._l(_vm.listaDeRelatoriosEmAndamento, function (item) {
            return _c(
              "li",
              { staticClass: "lowercase item-da-lista-de-relatorios" },
              [
                _c(
                  "a",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "space-between",
                    },
                  },
                  [
                    _c("div", { staticClass: "nome-relatorio" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(item.name) +
                            " " +
                            _vm._s(
                              _vm.gerarInformacoesDoRelatorio(
                                item.percent,
                                item.ttf,
                                item.status
                              )
                            )
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "icones",
                        staticStyle: {
                          display: "flex",
                          "justify-content": "flex-end",
                        },
                      },
                      [
                        item.status === "STARTED" ||
                        item.status === "NAO_INICIADO"
                          ? _c("div", {
                              staticClass:
                                "icon fs-15 icone-linha-atualizar icon-spin color-info",
                            })
                          : _vm._e(),
                        item.status === "PENDING" || item.status === "GERANDO"
                          ? _c("div", {
                              staticClass:
                                "icon fs-15 icone-linha-atualizar icon-spin color-info",
                            })
                          : _vm._e(),
                        item.status === "FAILURE" ||
                        item.status === "FALHA_CRITICA"
                          ? _c("div", {
                              staticClass: "icon-ptt-icons-X color-warning",
                            })
                          : _vm._e(),
                        _vm.verificarSeRelatorioPodeSerCancelado(item)
                          ? _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    return _vm.cancelarRelatorio(item.id)
                                  },
                                },
                              },
                              [
                                _c("div", {
                                  staticClass:
                                    "icon fs-13 icone-linha-fechar color-danger",
                                  staticStyle: { "margin-left": "8px" },
                                }),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
              ]
            )
          }),
          _vm.totalDeRelatoriosEmAndamento > 0
            ? _c("li", [
                _c(
                  "a",
                  {
                    staticClass: "to-click lowercase",
                    attrs: { role: "button" },
                    on: { click: _vm.cleanQueueReports },
                  },
                  [_vm._v("Limpar fila de relatórios")]
                ),
              ])
            : _vm._e(),
          _vm.totalDeRelatoriosConcluidos > 0
            ? _c("li", { staticClass: "lowercase" }, [
                _c("a", { staticClass: "descricao" }, [
                  _vm._v("relatórios concluídos"),
                ]),
              ])
            : _vm._e(),
          _vm._l(_vm.listaDeRelatoriosConcluidos, function (item) {
            return _c(
              "li",
              { staticClass: "lowercase item-da-lista-de-relatorios" },
              [
                item.url
                  ? _c(
                      "a",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "space-between",
                        },
                        attrs: { href: item.url, target: "_blank" },
                      },
                      [
                        _c("div", { staticClass: "nome-relatorio" }, [
                          _c("span", [_vm._v(_vm._s(item.name))]),
                        ]),
                        _c("div", {
                          staticClass:
                            "icon fs-15 icone-linha-download color-info",
                          staticStyle: { "margin-left": "8px" },
                        }),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          }),
          _c("li", [
            _vm.showActivateNotification && _vm.notificationDisabled
              ? _c(
                  "a",
                  {
                    staticClass: "to-click lowercase",
                    attrs: { role: "button" },
                    on: { click: _vm.activateBrowserNotification },
                  },
                  [_vm._v("Ativar notificações do navegador")]
                )
              : _vm._e(),
          ]),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }