import moment from 'moment'

export function verificarSeHorarioInputValido (horario, doisPontos = false) {
  if (doisPontos) {
    if (horario.length !== 5) return false

    const regexHorarioComDoisPontos = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/
    // HH:MM
    return regexHorarioComDoisPontos.test(horario)
  } else {
    if (horario.length !== 4) return false

    const regexHorarioSemDoisPontos = /^([01]?[0-9]|2[0-3])[0-5][0-9]$/
    // HHMM
    return regexHorarioSemDoisPontos.test(horario)
  }
}

/**
 * Verifica se a data fornecida está dentro de um intervalo de dias antes e depois de hoje.
 *
 * @param {Date} data - A data a ser verificada.
 * @param {number} diasAntesHoje - A quantidade de dias antes de hoje.
 * @param {number} diasDepoisHoje - A quantidade de dias depois de hoje.
 * @returns {boolean} - Retorna true se a data estiver dentro do intervalo, caso contrário retorna false.
 */
export const verificarSeDataEstaNoIntervaloDado = (data, diasAntesHoje, diasDepoisHoje) => {
  const dataInicial = moment().subtract(diasAntesHoje, 'days').startOf('day')
  const dataFinal = moment().add(diasDepoisHoje, 'days').endOf('day')
  const dataComparadaSemHoras = moment(data).startOf('day')

  return dataComparadaSemHoras.isBetween(dataInicial, dataFinal, null, '[]')
}
