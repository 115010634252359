import { ErroDoUsuarioPontotel } from 'src/tipos/erros/classes'

export class ErroUsuarioNaoPermitiuNotificacoes extends ErroDoUsuarioPontotel {
  constructor () {
    super('O usuário recusou notificações.')
  }
}

export class ErroPeriodoJornadaSobreavisoMaiorQue24Horas extends ErroDoUsuarioPontotel {
  constructor () {
    super('Não é possível inserir um sobreaviso maior que 24 horas')
  }
}

export class ErroPeriodoJornadasSobreavisoEstaoEmConflito extends ErroDoUsuarioPontotel {
  constructor () {
    super('Não é possível inserir sobreavisos com horários conflitantes')
  }
}

export class ErroDataDeInicioDoBancoDeHorasObrigatoria extends ErroDoUsuarioPontotel {
  constructor () {
    super('A data de início do banco de horas é obrigatória')
  }
}
