// import securityEvents from './SecurityEvents/state'
// import blockedUsers from './BlockedUsers/state'

const defaultState = () => ({
})

const getters = {}

const mutations = {
}

const actions = {
}

export default {
  namespaced: true,
  modules: {
    // securityEvents,
    // blockedUsers
  },
  state: defaultState(),
  getters,
  mutations,
  actions
}
