export function classificarEstiloComBaseNoTipoDeApontamento (apontamento) {
  if (!apontamento) { return null }

  const ehApontamentoExtra = apontamento.includes('100') || apontamento.includes('B') || apontamento.includes('_b')
  const ehApontamentoEspecial = apontamento.includes('C') || apontamento.includes('Spec') || apontamento.includes('_c')

  if (ehApontamentoExtra) {
    return 'warning'
  } else if (ehApontamentoEspecial) {
    return 'info'
  }
  return 'default'
}

/**
 * Acesse os atributos de objetos com notação de ponto usando uma string como guia.
 *
 * Por exemplo:
 * Para acessar o atributo 'b' do objeto '{a: {b: true}}', utilize: obterComNotacaoPonto(objeto, 'a.b')
 *
 * @param {object} objeto - O objeto no qual você deseja acessar os atributos.
 * @param {string} caminho - A string que descreve o caminho para o atributo desejado usando notação de ponto.
 * @param {string} separador - (Opcional) O caractere que separa os níveis da notação de ponto. O padrão é '.'.
 * @returns {*} O valor do atributo especificado pela notação de ponto na string caminho.
 */
export function obterComNotacaoPonto (objeto, caminho, separador = '.') {
  return caminho.split(separador).reduce((o, i) => {
    return o[i]
  }, objeto)
}
