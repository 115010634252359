import Vue from 'vue'
import PButton from './PButton.vue'

function getChildren () {
  const getButton = () => {
    const customListeners = {
      click: async (event) => {
        if (!await this.$modalDeConfirmacao({ text: this.text })) return
        this.$listeners.click(event)
      }
    }
    const buttonListeners = Object.assign({}, this.$listeners, customListeners)
    return this.$createElement(
      PButton,
      {
        attrs: { ...this.$attrs },
        domProps: { ...this.$attrs },
        on: buttonListeners
      },
      this.$slots.default
    )
  }

  return [
    getButton()
  ]
}

export default Vue.extend({
  name: 'PConfirmButton',
  props: {
    text: String
  },
  render (h) {
    return h('div', {
      staticClass: 'button'
    }, getChildren.call(this))
  }
})
