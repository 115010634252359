import Vue from 'vue'
import focus from './focus'
import hasPermission from './hasPermission'
import inputLoading from './inputLoading'
import whenScrollEnds from './whenScrollEnds'
import loadButton from './loadButton'
import focusEl from './focusEl'
import customPopover from './customPopover'
import {mask} from 'vue-the-mask'

Vue.directive('has-permission', hasPermission)
Vue.directive('focus-el', focusEl)
Vue.directive('focus', focus)
Vue.directive('when-scroll-ends', whenScrollEnds)
Vue.directive('input-loading', inputLoading)
Vue.directive('load-button', loadButton)
Vue.directive('custom-popover', customPopover)
Vue.directive('mask', mask)
