var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-header" },
    [
      !_vm.onlySheetView
        ? [
            _c(
              "router-link",
              { staticClass: "main-header__logo", attrs: { to: "/" } },
              [
                _c("img", {
                  staticClass: "to-click",
                  attrs: {
                    src: _vm.logo,
                    alt: "Logo Pontotel. Letra P com 2 pontos em amarelo parecendo uma carinha de sorrindo. Ao lado escrito Pontotel em branco",
                  },
                }),
              ]
            ),
          ]
        : [
            _c("a", { staticClass: "main-header__logo" }, [
              _c("img", {
                attrs: {
                  src: _vm.logo,
                  alt: "Logo Pontotel. Letra P com 2 pontos em amarelo parecendo uma carinha de sorrindo. Ao lado escrito Pontotel em branco",
                },
              }),
            ]),
          ],
      _c(
        "ul",
        { staticClass: "main-header__links" },
        [
          !_vm.onlySheetView
            ? [
                _c("Notification"),
                _vm._l(_vm.groups, function (group) {
                  return group.show && group.values.find((i) => i.show)
                    ? _c(
                        "uiv-dropdown",
                        {
                          key: group.category,
                          ref: `dropdown-${group.category}`,
                          refInFor: true,
                          staticClass: "category-dropdown",
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass:
                                "to-click main-header__button--category noselect lowercase dropdown-toggle",
                              attrs: {
                                type: "primary",
                                tabindex: "0",
                                "aria-haspopup": "true",
                              },
                              on: {
                                keydown: function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  return (() =>
                                    _vm.$refs[
                                      `dropdown-${group.category}`
                                    ][0].toggle()).apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c("span", [_vm._v(_vm._s(group.category))]),
                              _c("div", {
                                staticClass: "category-icon",
                                class: `icon-ptt-icons-${group.icon}`,
                              }),
                              _c("div", {
                                staticClass:
                                  "arrow-icon icon-ptt-icons-dropdown",
                              }),
                            ]
                          ),
                          _c("template", { slot: "dropdown" }, [
                            _c(
                              "li",
                              _vm._l(group.values, function (item, index) {
                                return item.show
                                  ? _c(
                                      "router-link",
                                      {
                                        key: index,
                                        staticClass:
                                          "to-click noselect lowercase",
                                        attrs: { tag: "a", to: item.link },
                                      },
                                      [
                                        item.adapted
                                          ? [
                                              _vm._v(
                                                _vm._s(_vm.$ws(item.adapted))
                                              ),
                                            ]
                                          : [_vm._v(_vm._s(item.name))],
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              }),
                              1
                            ),
                          ]),
                        ],
                        2
                      )
                    : _vm._e()
                }),
              ]
            : [
                _vm.possuiAlgumaPermissaoDeRelatorio
                  ? _c("Notification")
                  : _vm._e(),
                _c("label", { staticStyle: { "grid-column": "2/-2" } }, [
                  _vm._v(_vm._s(_vm.$store.state.userInfo.name)),
                ]),
              ],
          _c(
            "uiv-popover",
            { attrs: { trigger: "hover", title: "Acessando o sistema como" } },
            [
              _vm.showLogoutButton
                ? _c(
                    "ul",
                    {
                      staticClass: "to-click",
                      staticStyle: { padding: "0" },
                      attrs: { id: "botao-sair" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "to-click main-header__button--category",
                          attrs: { tabindex: "0" },
                          on: {
                            click: _vm.sair,
                            keydown: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.sair.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("span", [_vm._v("sair")]),
                          _c("div", {
                            staticClass: "category-icon icon-ptt-icons-sair",
                            staticStyle: { "font-size": "1.4em" },
                          }),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _c("template", { slot: "popover" }, [
                _vm.infoDoUsuario
                  ? _c(
                      "div",
                      { staticClass: "info-do-usuario" },
                      _vm._l(_vm.infoDoUsuario, function (item, key) {
                        return _c(
                          "div",
                          {
                            key: key,
                            class: { [`info-do-usuario__${key}`]: true },
                          },
                          [
                            item.conteudo
                              ? [
                                  _c("div", { staticClass: "legenda" }, [
                                    _vm._v(_vm._s(item.legenda)),
                                  ]),
                                  _c("div", { staticClass: "conteudo" }, [
                                    _vm._v(_vm._s(item.conteudo)),
                                  ]),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
            ],
            2
          ),
        ],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }