<template lang="pug">
  modal(
    :name="name"
    classes="v--modal v--modal-box p-modal"
    :width="width"
    :height="height"
    @before-open="event => $emit('beforeOpen', event)"
    @closed="$emit('closed')"
  )
    .p-modal__content
      slot
    .p-modal__close(@click="fecharModal") X
</template>

<script>
export default {
  name: 'PModal',
  props: {
    name: {type: String, required: true},
    width: {type: String, required: true},
    height: {type: String, required: true}
  },
  methods: {
    fecharModal () {
      this.$modal.hide(this.name)
      this.$emit('closed')
    }
  }
}
</script>

<style lang="scss">
.p-modal {
  padding: 20px;
}
.p-modal__close {
  position: absolute;
  top: 1%;
  left: 98.5%;
  z-index: 1200;
  // font-weight: 900;
  cursor: pointer;
  color: var(--color-info);
}
.p-modal__content {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 25px;
}
</style>
