import { RegraDeNotificacaoServico } from '../dominio/regraNotificacaoServico'
import { RegraDeNotificacaoRepositorio } from '../repositorio/regraNotificacaoRepositorio'

const regraDeNotificacaoRepositorio = new RegraDeNotificacaoRepositorio()
const regraDeNotificacaoServico = new RegraDeNotificacaoServico(regraDeNotificacaoRepositorio)

const moduloRegrasDeNotificacao = {
  namespaced: true,
  state: {
    carregandoRegrasDeNotificacoes: false,
    regrasDeNotificacoes: [],
    totalRegrasNotificacoes: 0
  },

  mutations: {
    definirCarregandoRegrasDeNotificacoes (state, carregandoRegrasDeNotificacoes) {
      state.carregandoRegrasDeNotificacoes = carregandoRegrasDeNotificacoes
    },
    definirRegrasDeNotificacoes (state, regrasDeNotificacoes) {
      state.regrasDeNotificacoes = regrasDeNotificacoes
    },
    definirTotalRegrasNotificacoes (state, totalRegrasNotificacoes) {
      state.totalRegrasNotificacoes = totalRegrasNotificacoes
    }
  },

  actions: {
    async listarRegrasDeNotificacao ({ commit }, parametrosDeBusca) {
      try {
        commit('definirCarregandoRegrasDeNotificacoes', true)
        const response = await regraDeNotificacaoServico.obterRegrasDeNotificacoes(parametrosDeBusca)
        commit('definirRegrasDeNotificacoes', response.data.regras_de_notificacao)
        commit('definirTotalRegrasNotificacoes', response.data.total)
        return {
          items: response.data.regras_de_notificacao,
          totalItems: response.data.total,
          totalPages: response.data.totalPages
        }
      } catch (error) {
        throw error
      } finally {
        commit('definirCarregandoRegrasDeNotificacoes', false)
      }
    },
    async removerRegraDeNotificacao (context, id) {
      try {
        await regraDeNotificacaoServico.removerRegraDeNotificacao({id})
      } catch (error) {
        throw error
      }
    },
    async obterRegraDeNotificacao (context, id) {
      try {
        const response = await regraDeNotificacaoServico.obterRegraDeNotificacao(id)
        return response.data.regras_de_notificacao[0]
      } catch (error) {
        throw error
      }
    },
    async criarRegraDeNotificacao (context, regraDeNotificacao) {
      try {
        await regraDeNotificacaoServico.criarRegraDeNotificacao(regraDeNotificacao)
      } catch (error) {
        throw error
      }
    },
    async atualizarRegraDeNotificacao (context, {idRegraNotificacao, camposAlterados}) {
      try {
        await regraDeNotificacaoServico.atualizarRegraDeNotificacao(idRegraNotificacao, camposAlterados)
      } catch (error) {
        throw error
      }
    },
    formatarRegrasDeNotificacaoParaEnvio (context, { regraDeNotificacao, listaDeUsuarios }) {
      try {
        return regraDeNotificacaoServico.formatarRegrasParaCriacao(regraDeNotificacao, listaDeUsuarios)
      } catch (error) {
        throw error
      }
    },
    obterCamposAlteradosNaRegraDeNotificacao (context, { regraDeNotificacaoAlterada, regraDeNotificacaoOriginal }) {
      try {
        return regraDeNotificacaoServico.obterCamposAlteradosNaRegraDeNotificacao(regraDeNotificacaoAlterada, regraDeNotificacaoOriginal)
      } catch (error) {
        throw error
      }
    }
  }
}

export default moduloRegrasDeNotificacao
