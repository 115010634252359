var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "panel",
      staticClass: "panel",
      class: { open: _vm.open, ["back-color-" + _vm.color]: true },
      style: _vm.computedStyle,
    },
    [
      _c(
        "div",
        {
          staticClass: "heading to-click",
          class: { "closer-button": _vm.closerButton },
          attrs: { tabindex: "0" },
          on: {
            click: _vm.switchOpen,
            keydown: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.switchOpen.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "heading-main noselect" },
            [_vm._t("heading")],
            2
          ),
          _c("div", { staticClass: "heading-button" }, [
            _c("div", {
              staticClass: "icon icon-ptt-icons-dropdown",
              class: { open: _vm.open },
            }),
          ]),
        ]
      ),
      _c("transition", { attrs: { name: "pop" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.open,
                expression: "open",
              },
            ],
            staticClass: "panel-collapse",
          },
          [_vm._t("default")],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }