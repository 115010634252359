import { API, retryRequest } from 'src/services'
import { congelarObjetosDaLista } from 'src/utils/formatadoras/formatadoras'

const defaultOnly = [
  'id', 'name', 'email'
]

export default {
  namespaced: true,
  state: {
    items: [],
    loading: false
  },
  getters: {
  },
  mutations: {
    saveItems (state, payload) {
      state.items = payload
      state.loading = false
    },
    loading (state, payload) {
      state.loading = Boolean(payload)
    }
  },
  actions: {
    async fetchItems ({ commit }, payload) {
      commit('loading', true)

      const withFields = payload?.withFields || []

      const only = Array.from(new Set([...defaultOnly, ...withFields]))

      const result = await retryRequest(API.supervisor.get, {
        fields: JSON.stringify(only),
        order_by: 'name'
      })
      commit('saveItems', congelarObjetosDaLista(result.body.success))
    }
  }
}
