var render = function render(_c, _vm) {
  return _c(
    "div",
    {
      staticClass: "p-btn",
      class: [
        {
          "p-btn--block": _vm.props.block,
          "fonte-customizada": _vm.props.fonteCustomizada,
        },
        _vm.data.class,
        _vm.data.staticClass,
      ],
      attrs: { title: _vm.props.title },
    },
    [
      !_vm.props.notShowButton
        ? _c(
            "button",
            _vm._g(
              {
                staticClass: "p-btn__content",
                class: `p-btn--${_vm.props.color} p-btn__main p-btn__content--size-${_vm.props.size}`,
                attrs: { disabled: _vm.props.disabled || _vm.props.loading },
              },
              { ..._vm.listeners }
            ),
            [
              _vm.props.loading
                ? _c("img", {
                    class: `p-btn__loading-spinner--size-${_vm.props.size}`,
                    attrs: {
                      src: require("../../../static/images/loading.svg"),
                    },
                  })
                : _c("span", [_vm._t("default")], 2),
            ]
          )
        : _vm._e(),
      _vm.props.showIcon
        ? _c(
            "button",
            {
              staticClass: "p-btn__content",
              class: `p-btn--${_vm.props.color} p-btn__icon p-btn__content--size-${_vm.props.size} p-btn__icon--${_vm.props.colorIcon}`,
              attrs: { disabled: _vm.props.disabled || _vm.props.loadingIcon },
              on: {
                click: (_) =>
                  _vm.listeners["click-icon"] && _vm.listeners["click-icon"](),
                keydown: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return ((e) =>
                    e.target.classList.toggle("p-btn--active")).apply(
                    null,
                    arguments
                  )
                },
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return ((e) =>
                    e.target.classList.toggle("p-btn--active")).apply(
                    null,
                    arguments
                  )
                },
              },
            },
            [
              _vm.props.loadingIcon
                ? _c("img", {
                    class: `p-btn__loading-spinner--size-${_vm.props.size}`,
                    attrs: {
                      src: require("../../../static/images/loading.svg"),
                    },
                  })
                : _vm.slots().icon
                ? _vm._t("icon")
                : _c("span", [_vm._v("X")]),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }