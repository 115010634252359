import { AxiosRequestConfig } from 'axios'
import { ServicoHttpCondominio } from '@/typescript/servicos/http/http.servico'
import {
  HelperFolhaResposta,
  IFolhaRepositorio,
  RotaFolhaParcial,
  SubRotaFolhaParcial
} from '@/typescript/modulos/estrutura-de-pontos-da-empresa/Folha/repositorio/folha.repositorio.interface'
import {
  IRequestFolhaParcialLoteDeLinhasEmpregado,
  IRequestFolhaParcialLoteDeLinhasGrupo,
  IRequestFolhaParcialLoteDeLinhasPorLocalDeTrabalho,
  IRequestFolhaParcialSubRotaBase,
  IRequestLoteDeJustificativasDasLinhas
} from '@/typescript/modulos/estrutura-de-pontos-da-empresa/Folha/repositorio/folha.respositorio.request'

export class FolhaRepositorio implements IFolhaRepositorio {
  http: ServicoHttpCondominio

  constructor (http: ServicoHttpCondominio) {
    this.http = http
  }

  private montarUrlFolhaParcial (rota: SubRotaFolhaParcial): string {
    return `${RotaFolhaParcial.TimesheetPartial}/${rota}`
  }

  async obterLoteDeLinhasPorLocalDeTrabalho (data: IRequestFolhaParcialLoteDeLinhasPorLocalDeTrabalho, config: AxiosRequestConfig): HelperFolhaResposta['ResponseFolhaParcialSubRotaLinhasSucesso'] {
    return this.http.post(this.montarUrlFolhaParcial(SubRotaFolhaParcial.Lines), data, config)
  }

  async obterLoteDeLinhasPorEmpregado (data: IRequestFolhaParcialLoteDeLinhasEmpregado, config: AxiosRequestConfig): HelperFolhaResposta['ResponseFolhaParcialSubRotaLinhasSucesso'] {
    return this.http.post(this.montarUrlFolhaParcial(SubRotaFolhaParcial.Lines), data, config)
  }

  async obterLoteDeLinhasPorGrupo (data: IRequestFolhaParcialLoteDeLinhasGrupo, config: AxiosRequestConfig): HelperFolhaResposta['ResponseFolhaParcialSubRotaLinhasSucesso'] {
    return this.http.post(this.montarUrlFolhaParcial(SubRotaFolhaParcial.Lines), data, config)
  }

  async obterLoteDeMarcacoesDasLinhas (data: IRequestFolhaParcialSubRotaBase, config: AxiosRequestConfig): HelperFolhaResposta['ResponseFolhaParcialSubRotaSucesso'] {
    return this.http.post(this.montarUrlFolhaParcial(SubRotaFolhaParcial.Pts), data, config)
  }

  async obterLoteDeJornadaDiariasDasLinhas (data: IRequestFolhaParcialSubRotaBase, config: AxiosRequestConfig): HelperFolhaResposta['ResponseFolhaParcialSubRotaSucesso'] {
    return this.http.post(this.montarUrlFolhaParcial(SubRotaFolhaParcial.Djs), data, config)
  }

  async obterLoteDeAprovacoesDasLinhas (data: IRequestFolhaParcialSubRotaBase, config: AxiosRequestConfig): HelperFolhaResposta['ResponseFolhaParcialSubRotaSucesso'] {
    return this.http.post(this.montarUrlFolhaParcial(SubRotaFolhaParcial.Approvals), data, config)
  }

  async obterLoteDeBancoDeHorasDasLinhas (data: IRequestFolhaParcialSubRotaBase, config: AxiosRequestConfig): HelperFolhaResposta['ResponseFolhaParcialSubRotaSucesso'] {
    return this.http.post(this.montarUrlFolhaParcial(SubRotaFolhaParcial.Whbs), data, config)
  }

  async obterLoteDeJustificativasDasLinhas (data: IRequestLoteDeJustificativasDasLinhas, config: AxiosRequestConfig): HelperFolhaResposta['ResponseFolhaParcialSubRotaSucesso'] {
    return this.http.post(this.montarUrlFolhaParcial(SubRotaFolhaParcial.Justifies), data, config)
  }
}
