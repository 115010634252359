export interface DadosUsuarioFormbricks {
  idUsuario: string;
  nomeUsuario: string;
  emailUsuario: string;
  papelUsuario: string;
  dataCriacaoUsuario: string;
  semanasUsoUsuario: number;
  mesesUsoUsuario: number;

  idEmpresaUsuario: string;
  nomeEmpresaUsuario: string;
  tamanhoEmpresaUsuario: string;
  canal: string;
  dataCriacaoEmpresa: string;
  semanasUsoEmpresa: number;
  mesesUsoEmpresa: number;
}

export interface DadosUsuarioAPIPersonalShort {
  idUsuario: string;
  nomeUsuario: string;
  emailUsuario: string;
  papelUsuario: string;
  idEmpresaUsuario: string;
  nomeEmpresaUsuario: string;
  tamanhoEmpresaUsuario: string;
  canal: string;
}

enum PapelUsuario {
  RH = 'Acesso geral (RH)',
  GESTOR = 'Acesso a grupos (Gestor/Supervisor)',
  FUNCIONARIO = 'Acesso a suas infos (Empregado)'
}

export default class UsuarioFormbricks {
  private readonly idUsuario: string;
  private readonly nomeUsuario: string;
  private readonly emailUsuario: string;
  private readonly papelUsuario: string;
  private readonly dataCriacaoUsuario: string;
  private readonly semanasUsoUsuario: number;
  private readonly mesesUsoUsuario: number;

  private readonly idEmpresaUsuario: string;
  private readonly nomeEmpresaUsuario: string;
  private readonly tamanhoEmpresaUsuario: string;
  private readonly canal: string;
  private readonly dataCriacaoEmpresa: string;
  private readonly semanasUsoEmpresa: number;
  private readonly mesesUsoEmpresa: number;

  constructor (dadosUsuario: DadosUsuarioAPIPersonalShort) {
    this.idUsuario = dadosUsuario.idUsuario
    this.nomeUsuario = dadosUsuario.nomeUsuario
    this.emailUsuario = dadosUsuario.emailUsuario
    this.papelUsuario = this.definirPapelDoUsuarioNaEmpresa(dadosUsuario.papelUsuario)
    this.idEmpresaUsuario = dadosUsuario.idEmpresaUsuario
    this.nomeEmpresaUsuario = dadosUsuario.nomeEmpresaUsuario
    this.tamanhoEmpresaUsuario = dadosUsuario.tamanhoEmpresaUsuario
    this.canal = dadosUsuario.canal
    this.dataCriacaoUsuario = this.definirDataCriacaoApartirObjectId(this.idUsuario)
    this.dataCriacaoEmpresa = this.definirDataCriacaoApartirObjectId(this.idEmpresaUsuario)
    this.semanasUsoUsuario = this.calcularQuantidadeDeSemanasDeUsoComBaseNaDataAtual(new Date(), this.dataCriacaoUsuario)
    this.mesesUsoUsuario = this.calcularQuantidadeDeMesesDeUsoComBaseNaDataAtual(new Date(), this.dataCriacaoUsuario)
    this.semanasUsoEmpresa = this.calcularQuantidadeDeSemanasDeUsoComBaseNaDataAtual(new Date(), this.dataCriacaoEmpresa)
    this.mesesUsoEmpresa = this.calcularQuantidadeDeMesesDeUsoComBaseNaDataAtual(new Date(), this.dataCriacaoEmpresa)
  }

  private definirPapelDoUsuarioNaEmpresa (papel: string): string {
    return {
      'all': PapelUsuario.RH,
      'group': PapelUsuario.GESTOR,
      'mine': PapelUsuario.FUNCIONARIO
    }[papel] || 'Não foi possivel identificar o papel do usuário'
  }

  private definirDataCriacaoApartirObjectId (objectId: string): string {
    const timestamp = parseInt(objectId.substring(0, 8), 16) * 1000
    return new Date(timestamp).toISOString()
  }

  public calcularQuantidadeDeSemanasDeUsoComBaseNaDataAtual (dataAtual: Date, dataReferenciaEmISOString: string): number {
    const dataReferencia = new Date(dataReferenciaEmISOString)
    const milisegundosPorDia = 24 * 60 * 60 * 1000
    const milisegundosPorSemana = milisegundosPorDia * 7
    const diferencaEmMilisegundos = dataAtual.getTime() - dataReferencia.getTime()
    const semanasDeUso = Math.floor(diferencaEmMilisegundos / milisegundosPorSemana) + 1

    return semanasDeUso
  }

  public calcularQuantidadeDeMesesDeUsoComBaseNaDataAtual (dataAtual: Date, dataReferenciaEmISOString: string): number {
    const dataReferencia = new Date(dataReferenciaEmISOString)
    const timestampAtual = dataAtual.getTime()
    const timestampReferencia = dataReferencia.getTime()

    const milisegundosPorMes = 30 * 24 * 60 * 60 * 1000 // Assumindo um mês de 30 dias
    const mesesDeUso = Math.floor((timestampAtual - timestampReferencia) / milisegundosPorMes) + 1

    return Math.max(mesesDeUso, 1)
  }

  public obterDadosDoUsuario (): DadosUsuarioFormbricks {
    return {
      idUsuario: this.idUsuario,
      nomeUsuario: this.nomeUsuario,
      emailUsuario: this.emailUsuario,
      papelUsuario: this.papelUsuario,
      idEmpresaUsuario: this.idEmpresaUsuario,
      nomeEmpresaUsuario: this.nomeEmpresaUsuario,
      tamanhoEmpresaUsuario: this.tamanhoEmpresaUsuario,
      canal: this.canal,
      dataCriacaoUsuario: this.dataCriacaoUsuario,
      dataCriacaoEmpresa: this.dataCriacaoEmpresa,
      semanasUsoUsuario: this.semanasUsoUsuario,
      mesesUsoUsuario: this.mesesUsoUsuario,
      semanasUsoEmpresa: this.semanasUsoEmpresa,
      mesesUsoEmpresa: this.mesesUsoEmpresa
    }
  }
}
