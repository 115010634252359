<template lang="pug">
  ul(:class="`wrapper-radio-slider-${theme}`")
    span(:style="positionBackground")

    li.to-click(
      v-for='(tab, index) in items',
      :class="{ 'is-active': index === current }",
      :key="index"
      tabindex="0"
      @click='selecionarAba(tab, index)'
      @keyup.enter='selecionarAba(tab, index)'
    )
      a.noselect(:class="{disabled: disabledModes.includes(tab.id), required: !!tab.required}")

        slot(:tab="tab")
          | {{ tab.name }}
        .icone-chapado-info.color-info(v-if="observacao[tab.id]" v-uiv-popover.down.hover="{content: observacao[tab.id]}")

</template>

<script>
export default {
  name: 'RadioSlider',
  props: {
    current: {type: Number, required: true},
    items: {type: Array, required: true},
    disabledModes: {type: Array, required: false, 'default': () => []},
    theme: {type: String, default: 'default'},
    observacao: {type: Object, required: false, default: () => ({})}
  },
  computed: {
    positionBackground () {
      return {left: `${this.current}00%`}
    }
  },
  methods: {
    selecionarAba (tab, index) {
      if (index !== this.current && !this.disabledModes.includes(this.items[index].id)) {
        this.$emit('selected', tab, index)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.wrapper-radio-slider-default {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(100px, 1fr) );
  margin: 0;
  padding: 0 3px;
  grid-auto-flow: column;
  position: relative;
  background-color: lightgray;

  span {
    position: absolute;
    width: 100%;
    height: 83%;
    transform: translateY(10%);
    grid-column: 1/1;
    background-color: var(--color-primary);
    z-index: 0;
    transition: left .2s ease-out;
  }

  li {
    z-index: 1;
    padding: 5px 12px 5px 12px;
    display: grid;

    a {
      color: var(--color-primary);
      font-weight: 600;
      text-align: center;
      transition: all 0.2s linear;

      &.disabled {
        color: darkgray;
        cursor: default;
      }

      &.required {
        content: " *";
        color: var(--color-danger);
        font-size: .70em;
        position: relative;
        bottom: 3px;
        right: 3px;
      }
    }
  }
  li:hover:not(.is-active) {
    // background-color: var(--color-default-hover) !important;
  }

  li.is-active {
    a {
      color: whitesmoke;
    }
  }
}

.wrapper-radio-slider-custom-rules {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(100px, 1fr) );
  margin: 0;
  padding: 0 3px;
  grid-auto-flow: column;
  position: relative;
    border-bottom: 1px solid lightgray;

  span {
    position: absolute;
    width: 100%;
    height: 5%;
    top: 95%;
    grid-column: 1/1;
    background-color: var(--color-primary);
    z-index: 0;
    transition: left .2s ease-out;
  }

  li {
    z-index: 1;
    padding: 5px 12px 5px 12px;
    display: grid;

    a {
      color: black;
      font-weight: 200;
      text-align: center;
      transition: all 0.2s linear;

      &.disabled {
        color: darkgray;
        cursor: default;
      }

      &.required::after {
        content: " *";
        color: var(--color-danger);
        font-size: .70em;
        position: relative;
        bottom: 3px;
        right: 3px;
      }
    }
  }
  li:hover:not(.is-active) {
    // background-color: var(--color-default-hover) !important;
  }

  li.is-active {
    a {
      color: var(--color-primary);
      font-weight: 600;
    }
  }
}
</style>
