import { RespostaHttpAxios, ServicoHttpCondominio } from '@/typescript/servicos/http/http.servico'
import { AxiosRequestConfig } from 'axios'
import {
  IRequestFolhaParcialLoteDeLinhasPorLocalDeTrabalho,
  IRequestFolhaParcialLoteDeLinhasEmpregado,
  IRequestFolhaParcialLoteDeLinhasGrupo,
  IRequestFolhaParcialSubRotaBase,
  IRequestLoteDeJustificativasDasLinhas
} from '@/typescript/modulos/estrutura-de-pontos-da-empresa/Folha/repositorio/folha.respositorio.request'
import {
  IResponseFolhaParcialSubRotaLinhasSucesso,
  IResponseFolhaParcialSubRotaSucesso,
  IResponseFolhaParcialSucessoBase
} from '@/typescript/modulos/estrutura-de-pontos-da-empresa/Folha/repositorio/folha.respositorio.response'

export enum RotaFolhaParcial {
  TimesheetPartial = 'timesheet-partial'
}

export type Resposta<T> = Promise<RespostaHttpAxios<IResponseFolhaParcialSucessoBase<T>>>

export enum SubRotaFolhaParcial {
  Lines = 'lines',
  Pts = 'pts',
  Djs = 'djs',
  Justifies = 'justifies',
  Whbs = 'whbs',
  Approvals = 'approvals'
}

export type HelperFolhaResposta = {
  ResponseFolhaParcialSubRotaLinhasSucesso: Resposta<IResponseFolhaParcialSubRotaLinhasSucesso>;
  ResponseFolhaParcialSubRotaSucesso: Resposta<IResponseFolhaParcialSubRotaSucesso>;
}

export interface IFolhaRepositorio {
  http: ServicoHttpCondominio
  obterLoteDeLinhasPorLocalDeTrabalho(data: IRequestFolhaParcialLoteDeLinhasPorLocalDeTrabalho, config: AxiosRequestConfig): HelperFolhaResposta['ResponseFolhaParcialSubRotaLinhasSucesso']
  obterLoteDeLinhasPorEmpregado(data: IRequestFolhaParcialLoteDeLinhasEmpregado, config: AxiosRequestConfig): HelperFolhaResposta['ResponseFolhaParcialSubRotaLinhasSucesso']
  obterLoteDeLinhasPorGrupo(data: IRequestFolhaParcialLoteDeLinhasGrupo, config: AxiosRequestConfig): HelperFolhaResposta['ResponseFolhaParcialSubRotaLinhasSucesso']

  obterLoteDeMarcacoesDasLinhas(data: IRequestFolhaParcialSubRotaBase, config: AxiosRequestConfig): HelperFolhaResposta['ResponseFolhaParcialSubRotaSucesso']
  obterLoteDeJornadaDiariasDasLinhas(data: IRequestFolhaParcialSubRotaBase, config: AxiosRequestConfig): HelperFolhaResposta['ResponseFolhaParcialSubRotaSucesso']
  obterLoteDeAprovacoesDasLinhas(data: IRequestFolhaParcialSubRotaBase, config: AxiosRequestConfig): HelperFolhaResposta['ResponseFolhaParcialSubRotaSucesso']
  obterLoteDeBancoDeHorasDasLinhas(data: IRequestFolhaParcialSubRotaBase, config: AxiosRequestConfig): HelperFolhaResposta['ResponseFolhaParcialSubRotaSucesso']
  obterLoteDeJustificativasDasLinhas(data: IRequestLoteDeJustificativasDasLinhas, config: AxiosRequestConfig): HelperFolhaResposta['ResponseFolhaParcialSubRotaSucesso']
}
