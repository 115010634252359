import { hasPermission } from 'src/utils'
import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'
import store from 'src/store'

export const defaultState = () => ({
  type: null,
  options: null,
  rowNumber: null,
  multipleDays: null,
  menuConfig: null,
  numeroLinhaAbrirInfo: null,
  elementosFocaveis: []
})

const __state = defaultState()

function _allowedActions () {
  const showAbsJustify = hasPermission({module: 'abstractjustify', action: 'post'})

  return {
    justify: {
      dispensa: showAbsJustify && hasPermission({module: 'justify', action: 'post'}),
      suspensao: showAbsJustify && hasPermission({module: 'suspension', action: 'post'}),
      observacao: showAbsJustify && hasPermission({module: 'absence', action: 'post'}),
      nota: hasPermission({module: 'groupscomment', action: 'post'}),
      sobreaviso: hasPermission({module: 'oncall', action: 'post'})
    },
    lineInfo: true,
    changeJourney: hasPermission({module: 'dayjourney', action: 'post'}),
    changeOnCall: hasPermission({module: 'oncall', action: 'post'}),
    conversaoApontamentoEmBh: true,
    transferirApontamentoEmBh: true,
    limiteDeApontamentos: hasPermission({ module: 'acompanhamentodiario', action: 'post' }),
    pedirHorasExtras: !!store.state.userInfo?.compMan?.possuiAprovacaoDeHorasExtras
  }
}

const getters = {
  line (state, getters, rootState) {
    if (state.rowNumber === -1) {
      const fakeLine = {
        data: null,
        justify: false,
        dayJourney: { journey: { kind: -1 } },
        employee: rootState.sheet.lines[0].employee
      }
      return fakeLine
    } else if (state.rowNumber !== null) {
      return state.rowNumber < rootState.sheet.lines.length ? rootState.sheet.lines[state.rowNumber] : null
    }
    return rootState.sheet.lines[state.rowNumber]
  },
  allowedActionsPerType () {
    return _allowedActions()
  },
  tipoDeJustificativa (state) {
    return state.options.tipoDeJustificativa
  },
  useOnCall (state, getters, rootState) {
    const { companies } = rootState.common
    const { employees } = rootState.sheet

    const employeesCompanies = employees.map((item) => {
      return item.company
    })

    const onlyCompaniesOnCall = companies.items.filter((item) => {
      if (item.hasOnCall) {
        return item.id
      }
    })

    const companiesOnCall = onlyCompaniesOnCall.map((item) => item.id)

    const onCallTotalCompanies = employeesCompanies.reduce((previous, item) => {
      let sum = 0
      if (companiesOnCall.indexOf(item) > -1) {
        sum = 1
      }
      return previous + sum
    }, 0)

    return onCallTotalCompanies > 0
  },
  numeroLinhaAbrirInfo (state) {
    return state.numeroLinhaAbrirInfo
  },
  possuiTrabalhoRemoto: (state, getters, rootState) => rootState.userInfo.compMan.possui_trabalho_remoto
}

const mutations = {
  abrirMenuDaLinha (state, {numeroDaLinha, posicoes}) {
    state.menuConfig = {
      ...state.menuConfig,
      numeroDaLinha,
      posicoes
    }
  },
  fecharMenuDaLinha (state) {
    state.menuConfig = null
  },
  activateLineActions (state, {rowNumber, type, options = null}) {
    if (!['justify', 'lineInfo', 'changeJourney', 'changeOnCall', 'conversaoApontamentoEmBh', 'transferirApontamentoEmBh', 'limiteDeApontamentos', 'pedirHorasExtras'].includes(type)) {
      throw new Error('currentLine received an invalid payload', type)
    }

    const allowedActions = _allowedActions()

    if (!allowedActions[type] || (type === 'justify' && !allowedActions[type][options.tipoDeJustificativa])) {
      return
    }

    state.type = type
    state.options = options
    state.rowNumber = rowNumber
  },
  rowNumber (state, rowNumber) {
    state.rowNumber = rowNumber
  },
  numeroLinhaAbrirInfo (state, numeroLinhaAbrirInfo) {
    state.numeroLinhaAbrirInfo = numeroLinhaAbrirInfo
  },
  options (state, payload) {
    state.options = {...state.options, ...payload}
  },
  close (state, manterLinhaAberta = false) {
    const linhaAMostrarInformacoes = state.numeroLinhaAbrirInfo
    resetarParaEstadoInicial(state, defaultState())
    if (manterLinhaAberta) {
      state.numeroLinhaAbrirInfo = linhaAMostrarInformacoes
    }
  },
  salvarElementosFocaveis (state, elementosFocaveis) {
    state.elementosFocaveis = elementosFocaveis
  }
}

const actions = {
  async abrirMenuDaLinha ({state, commit}, {numeroDaLinha, posicoes}) {
    if (state.menuConfig) commit('fecharMenuDaLinha')
    if (!typeof numeroDaLinha === 'number' && numeroDaLinha < 0) return
    await this._vm.$nextTick(() => {
      commit('abrirMenuDaLinha', {numeroDaLinha, posicoes})
    })
  },
  async fecharMenuDaLinha ({state, commit}) {
    if (state.menuConfig) commit('fecharMenuDaLinha')
  }
}

export default {
  namespaced: true,
  getters,
  state: __state,
  actions,
  mutations
}
