<template>
  <div id="app">
    <div id="header-space">
      <MainHeader v-if="userInfo && !estaNaRotaDeAcessoNegado"></MainHeader>
    </div>

    <div id="view-space">
      <transition
        name="fade"
        mode="out-in"
      >
        <router-view class="view"/>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import MainHeader from './components/MainHeader/MainHeader'

export default {
  name: 'App',
  async created () {
    this.$store.dispatch('common/loadMainCommons')
  },
  computed: {
    ...mapState(['userInfo']),
    estaNaRotaDeAcessoNegado () {
      return this.$route.name === 'AcessoNegado'
    }
  },
  components: {MainHeader}
}
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  height: 100%;
  max-height: 100%;
}

html {
  height: 100%;
  max-height: 100%;
  background-color: var(--front-background-color);
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 1.42857143;
  color: #333;
  background-color: var(--front-background-color);
  width: 100%;
  height: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: minmax(0, 0.1fr) minmax(0, 1fr) minmax(0, 0.1fr);
  grid-template-rows: min-content minmax(0, 100%);
  grid-template-areas:
    "header-space header-space header-space"
    ". view-space .";
}

#header-space { grid-area: header-space; }

#view-space {
  grid-area: view-space;
  width: 100%;
  height: 100%;
  max-height: 100%;
}
</style>
