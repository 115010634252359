<template functional lang="pug">
  span(v-if="props.totalItems > props.perPage")
    | {{ ((props.perPage * (props.currentPage + 1)) - props.perPage) + 1 }} -
    | {{ Math.min(props.totalItems, props.perPage * (props.currentPage + 1)) }}
    | de {{ props.totalItems }}
</template>

<script>
export default {
  name: 'PageInfo',
  functional: true,
  props: {
    totalItems: {required: true, type: Number},
    perPage: {required: true, type: Number},
    currentPage: {required: true, type: Number}
  }
}
</script>
