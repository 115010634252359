export default {
  getters: {
    getUserInfo (state) {
      return state.userInfo || {}
    },
    frontOptions (state, getters) {
      if (!getters.getUserInfo.compMan) {
        return {}
      }
      return getters.getUserInfo.compMan.frontOptions
    }
  }
}
