import Popper from 'popper.js'
import Vue from 'vue'
import _ from 'lodash'

export default {
  async inserted (el, binding) {
    // TODO, improve this directive. It must accept argumments, top, bottom, etc.
    // TODO turn the popover divs in a functional component that handles the top bottom etc elements and styles

    if (binding.disabled) { return }

    let popOverTarget = el
    let popOverElement

    await Vue.nextTick()

    if (binding.modifiers.putMeAsPopover) {
      popOverElement = el
      popOverTarget = binding.value.getElTarget()
    } else {
      popOverElement = binding.value.popOverEl()
      popOverElement = popOverElement.__vue__ ? popOverElement.$el : popOverElement
    }

    const beforeShow = binding.value.beforeShow ? _.debounce(binding.value.beforeShow, 500) : null

    function showPopover (recreatePopper = false) {
      beforeShow && beforeShow()

      popOverElement.style.visibility = 'visible'
      if (binding.value) {
        if (popOverTarget.popper && !recreatePopper) {
          popOverTarget.popper.enableEventListeners()
        } else {
          putPopoverOnElement(el, popOverElement)
        }
      }
    }
    function mouseOut () {
      popOverElement.style.visibility = 'hidden'
      popOverTarget.popper.disableEventListeners()
    }

    if (!popOverTarget.popperEventsAtached) {
      if (!binding.arg) {
        popOverTarget.addEventListener('mouseover', showPopover)
        popOverTarget.addEventListener('mouseout', mouseOut)
      } else if (binding.arg === 'contextmenu') {
        popOverTarget.addEventListener('contextmenu', (e) => {
          e.preventDefault()
          showPopover(true)
        })
      }
    }
    popOverTarget.popperEventsAtached = true
  }
}

export async function putPopoverOnElement (el, popOverElement, placement = 'bottom', modifiers = null) {
  modifiers = modifiers || {}

  const config = {
    placement,
    removeOnDestroy: true,
    modifiers: {
      keepTogether: { enabled: true },
      preventOverflow: { boundariesElement: 'viewport' },
      ...modifiers
    },
    onUpdate (info) {
      const el = info.instance.popper
      el.classList.remove('top')
      el.classList.remove('bottom')
      el.classList.remove('left')
      el.classList.remove('right')
      el.classList.add(info.placement)
    }
  }

  el.popper = new Popper(el, popOverElement, config)
  await Vue.nextTick()
  el.popper.scheduleUpdate()
  el.scrollIntoViewIfNeeded()
}
