var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "p-timefield" },
    [
      _c(
        "PTextField",
        _vm._g(
          _vm._b(
            {
              directives: [
                {
                  name: "mask",
                  rawName: "v-mask",
                  value: "##:##",
                  expression: "'##:##'",
                },
              ],
              staticClass: "p-timefield__hour",
              attrs: {
                type: "text",
                label: _vm.label,
                autocomplete: "disabled",
                placeholder: "00:00",
                loading: _vm.loading,
                disabled: _vm.disabled,
                error: _vm.error,
              },
              on: { focus: ($event) => $event.target.select() },
              model: {
                value: _vm.textInputComputed,
                callback: function ($$v) {
                  _vm.textInputComputed = $$v
                },
                expression: "textInputComputed",
              },
            },
            "PTextField",
            _vm.localAttributes,
            false
          ),
          _vm.localListeners
        )
      ),
      _vm.showPlusOneDay
        ? _c("PCheckbox", {
            attrs: { label: "+1d", disabled: _vm.disabled },
            model: {
              value: _vm.plusOneDay,
              callback: function ($$v) {
                _vm.plusOneDay = $$v
              },
              expression: "plusOneDay",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }