import { FiltradorDeErros } from '@/erros'

export function resetarParaEstadoInicial<T> (estado: T, estadoPadrao: T): void {
  Object.assign(estado as Record<string, unknown>, estadoPadrao)
}

export function removerCookiesQueIncluemChaveEspefica (chave: string): void {
  try {
    // Obtém todos os cookies e os transforma em um array
    const cookies = document.cookie.split(';')

    cookies.forEach(cookie => {
    // Obtém o nome do cookie
      const nomeCookie = cookie.split('=')[0].trim()
      if (nomeCookie.includes(chave)) {
      // Coloca a data de expiração do cookie para o passado para que ele seja deletado
        document.cookie = `${nomeCookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`
      }
    })
  } catch (erro) {
    FiltradorDeErros.capturarErro(erro as unknown as Error)
  }
}

interface ErroHTTP {
  status: number;
  mensagem: string;
}

/**
 * Função para encapsular uma requisição e caso ela falhe, tentar novamente até um número máximo de tentativas.
 * Caso ocorra erro 401 na primeira tentativa, as próximas tentativas não serão realizadas.
 * @param api Função de requisição a ser executada
 * @param parametros Parâmetros da requisição
 * @param payload Dados a serem enviados na requisição
 * @param maximoDeTentativas Número máximo de tentativas - padrão 3
 * @returns Promise com o resultado da requisição
 * @example requisitarComMultiplasTentativas(API.timesheet.post, {load: 'lines'}, {})
 */
export async function requisitarComMultiplasTentativas<T> (
  api: (parametros?: Record<string, unknown>, payload?: Record<string, unknown>) => Promise<T>,
  parametros?: Record<string, unknown>,
  payload?: Record<string, unknown>,
  maximoDeTentativas = 3
): Promise<T> {
  parametros = parametros ? { ...parametros } : undefined
  payload = payload ? { ...payload } : undefined

  return new Promise<T>(async function (resolve, reject) {
    let contadorDeTentativas = 0
    let ultimoErro: ErroHTTP | undefined

    while (contadorDeTentativas < maximoDeTentativas) {
      contadorDeTentativas++
      try {
        const resultado = await api(parametros, payload)
        resolve(resultado)
        return
      } catch (erro) {
        ultimoErro = erro as ErroHTTP
        FiltradorDeErros.capturarErro(erro as Error)
        if (ultimoErro.status === 401) {
          break
        }
      }
    }
    reject(ultimoErro)
  })
}
