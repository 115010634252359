import simpleChildrenWrapper from 'src/router/wrapper'

const ListagemDaCustomizacaoDeCalculo = () => import('./Telas/ListagemDaCustomizacaoDeCalculo')
const CriacaoEdicaoDaRegraDeCalculo = () => import('./Telas/CriacaoEdicaoRegraDeCalculo')
const CriacaoEdicaoGrupoDeRubricas = () => import('./Telas/CriacaoEdicaoGrupoDeRubricas')

export default [
  {
    path: '/customizacoes-de-calculo',
    name: 'customizacoesDeCalculo',
    component: simpleChildrenWrapper,
    children: [
      {
        path: 'listar',
        name: 'customizacoesDeCalculo.listar',
        component: ListagemDaCustomizacaoDeCalculo,
        meta: {title: 'Customizações de Cálculo'}
      },
      {
        path: 'regra-de-calculo',
        name: 'regraDeCalculo',
        component: simpleChildrenWrapper,
        children: [
          {
            path: 'criar',
            name: 'regrasDeCalculo.criar',
            component: CriacaoEdicaoDaRegraDeCalculo,
            meta: {title: 'Nova Regra'}
          },
          {
            path: ':id([0-9abcdef]{15,})',
            name: 'regrasDeCalculo.editar',
            component: CriacaoEdicaoDaRegraDeCalculo,
            props: true,
            meta: {title: 'Editar Regra'}
          }
        ]
      },
      {
        path: 'grupo-de-rubricas',
        name: 'grupoDeRubricas',
        component: simpleChildrenWrapper,
        children: [
          {
            path: 'criar',
            name: 'grupoDeRubricas.criar',
            component: CriacaoEdicaoGrupoDeRubricas,
            meta: {title: 'Novo grupo'}
          },
          {
            path: ':id([a-fA-F0-9]{8}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{4}-[a-fA-F0-9]{12})',
            name: 'grupoDeRubricas.editar',
            component: CriacaoEdicaoGrupoDeRubricas,
            props: true,
            meta: {title: 'Editar grupo'}
          }
        ]
      }
    ]
  }
]
