import { multiSelect } from 'src/common/selectors'
import barsState, {defaultState as barsDefState} from 'src/components/DashBoards/bars/state'
import pieState, {defaultState as pieDefState} from 'src/components/DashBoards/pie/state'
import { resetarParaEstadoInicial } from 'src/utils/executoras/executoras'

const defaultState = () => ({
  ...multiSelect.mapStates(['companies', 'types', 'typesSpecial', 'typesWhb']),
  typeView: 'extraTime',
  startDate: new Date(),
  endDate: new Date(),
  orderBy: 'daysHours',
  chartType: 'bars',
  sumType: 'total',
  groupPerLineCompany: false,
  acionadoPeloEventoEmHome: false,
  infoApontamentoIndexadoPeloCodigo: {}
})

const __state = defaultState()

const getters = {}

const mutations = {
  ...multiSelect.mapMutations(['companies', 'types', 'typesSpecial', 'typesWhb']),
  startDate (state, payload) {
    state.startDate = payload
  },
  groupPerLineCompany (state, payload) {
    state.groupPerLineCompany = payload
  },
  endDate (state, payload) {
    state.endDate = payload
  },
  typeView (state, payload) {
    state.typeView = payload
  },
  orderBy (state, payload) {
    state.orderBy = payload
  },
  chartType (state, payload) {
    state.chartType = payload
  },
  resetChartState (state) {
    state.pie = pieDefState()
    state.bars = barsDefState()
  },
  sumType (state, payload) {
    state.sumType = payload
  },
  acionadoPeloEventoEmHome (state, payload) {
    state.acionadoPeloEventoEmHome = payload
  },
  resetarParaEstadoInicial (state) {
    state.pie = pieDefState()
    state.bars = barsDefState()
    resetarParaEstadoInicial(state, defaultState())
  },
  infoApontamentoIndexadoPeloCodigo (state, payload) {
    state.infoApontamentoIndexadoPeloCodigo = payload
  }
}

const actions = {}

export default {
  namespaced: true,
  modules: {bars: barsState, pie: pieState},
  state: __state,
  getters,
  mutations,
  actions
}
