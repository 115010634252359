export const dadosIniciaisAcompanhamentoEmTempoReal = {
  estados: {
    antesDeTrabalhar: {
      total: 0,
      ids: []
    },
    aEntrarEmBreve: {
      total: 0,
      ids: []
    },
    emAtraso: {
      total: 0,
      ids: []
    },
    trabalhando: {
      total: 0,
      ids: []
    },
    emPausa: {
      total: 0,
      ids: []
    },
    aRetornarEmBreve: {
      total: 0,
      ids: []
    },
    retornoPausaEmAtraso: {
      total: 0,
      ids: []
    },
    aSairEmBreve: {
      total: 0,
      ids: []
    },
    emHoraExtra: {
      total: 0,
      ids: []
    },
    aEstourarLimiteDeHoraExtraEmBreve: {
      total: 0,
      ids: []
    },
    realizandoHoraExtraAcimaDoLimiteDefinido: {
      total: 0,
      ids: []
    },
    trabalhou: {
      total: 0,
      ids: []
    },
    faltou: {
      total: 0,
      ids: []
    },
    ausenciaTrabalhada: {
      total: 0,
      ids: []
    },
    emSobreavisoEmBreve: {
      total: 0,
      ids: []
    },
    emSobreaviso: {
      total: 0,
      ids: []
    },
    emHoraExtraDeSobreaviso: {
      total: 0,
      ids: []
    },
    emPausaDeSobreaviso: {
      total: 0,
      ids: []
    },
    completouSobreaviso: {
      total: 0,
      ids: []
    }
  },
  eventos: {
    ausencias: {
      afastamento: {
        total: 0,
        ids: []
      },
      dispensa: {
        total: 0,
        ids: []
      },
      ferias: {
        total: 0,
        ids: []
      },
      folga: {
        total: 0,
        ids: []
      },
      suspensao: {
        total: 0,
        ids: []
      },
      falta: {
        total: 0,
        ids: []
      }
    },
    entradaAdiantada: {
      total: 0,
      ids: []
    },
    entradaNoHorario: {
      total: 0,
      ids: []
    },
    entradaAtrasada: {
      total: 0,
      ids: []
    },
    iniciouPausa: {
      total: 0,
      ids: []
    },
    retornoAdiantado: {
      total: 0,
      ids: []
    },
    retornoNoHorario: {
      total: 0,
      ids: []
    },
    retornoAtrasado: {
      total: 0,
      ids: []
    },
    saidaAdiantada: {
      total: 0,
      ids: []
    },
    saidaNoHorario: {
      total: 0,
      ids: []
    },
    saidaAtrasada: {
      total: 0,
      ids: []
    }
  }
}

export const somarQuantidades = (objeto) => {
  let soma = 0
  for (const chave in objeto) {
    soma += objeto[chave].total
  }
  return soma
}

export const somarQuantidadesSelecionadas = (objeto, chaves) => {
  let soma = 0
  chaves.forEach(chave => {
    if (objeto[chave]) {
      soma += objeto[chave].total
    }
  })
  return soma
}

export const calcularPorcentagem = (parcial, total) => {
  return total === 0 ? 0 : (parcial / total) * 100
}

function converterSnakeCaseParaCamelCase (str) {
  return str.replace(/([-_]\w)/g, g => g[1].toUpperCase())
}

export function converterChavesDeObjeto (obj) {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  if (Array.isArray(obj)) {
    return obj.map(converterChavesDeObjeto)
  }

  return Object.keys(obj).reduce((acc, key) => {
    const camelCaseKey = converterSnakeCaseParaCamelCase(key)
    acc[camelCaseKey] = converterChavesDeObjeto(obj[key])
    return acc
  }, {})
}
