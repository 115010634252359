var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "wrapper-configs" },
    [
      _c("label", { staticClass: "fs-21" }, [
        _vm._v("Configurações da tabela"),
      ]),
      _c("h5", [_vm._v("Ordem dos campos")]),
      _c(
        "draggable",
        {
          staticClass: "grid",
          attrs: { direction: "horizontal", draggable: ".draggable" },
          model: {
            value: _vm.configs.headerItems,
            callback: function ($$v) {
              _vm.$set(_vm.configs, "headerItems", $$v)
            },
            expression: "configs.headerItems",
          },
        },
        _vm._l(_vm.headerItemsDisplay, function (item, index) {
          return _c(
            "div",
            {
              key: item.column,
              staticClass: "column",
              class: { draggable: item.movable },
            },
            [
              item.movable
                ? _c("img", {
                    attrs: {
                      src: "https://cdn.iconscout.com/icon/free/png-256/drag-indicator-1782445-1512354.png",
                    },
                  })
                : _vm._e(),
              item.hiddable
                ? [
                    _c("PCheckbox", {
                      attrs: { label: item.column },
                      model: {
                        value: _vm.configs.headerItems[index].isToShow,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.configs.headerItems[index],
                            "isToShow",
                            $$v
                          )
                        },
                        expression: "configs.headerItems[index].isToShow",
                      },
                    }),
                  ]
                : [_vm._v(_vm._s(item.column))],
            ],
            2
          )
        }),
        0
      ),
      _c(
        "PButton",
        {
          attrs: { color: "success", size: "md", disabled: !_vm.validToSave },
          on: { click: _vm.apply },
        },
        [_vm._v("salvar")]
      ),
      _c(
        "PButton",
        { attrs: { color: "danger", size: "md" }, on: { click: _vm.close } },
        [_vm._v("cancelar")]
      ),
      _c(
        "PButton",
        { attrs: { color: "info", size: "md" }, on: { click: _vm.reset } },
        [_vm._v("voltar padrão")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }