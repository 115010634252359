var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("span", [
    !_vm.threeStates
      ? _c("label", { staticClass: "switch noselect" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.state,
                expression: "state",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.state)
                ? _vm._i(_vm.state, null) > -1
                : _vm.state,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.state,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.state = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.state = $$a.slice(0, $$i).concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.state = $$c
                }
              },
            },
          }),
          _c("span", { staticClass: "slider round" }),
        ])
      : _c(
          "label",
          {
            staticClass: "switch noselect three",
            class: { ["position-" + _vm.state]: true },
          },
          [
            _c("span", {
              staticClass: "slider round",
              class: { ["position-" + _vm.state]: true },
            }),
            _c("div", {
              on: {
                click: function ($event) {
                  return _vm.switchState(0)
                },
              },
            }),
            _c("div", {
              on: {
                click: function ($event) {
                  return _vm.switchState(1)
                },
              },
            }),
            _c("div", {
              on: {
                click: function ($event) {
                  return _vm.switchState(2)
                },
              },
            }),
          ]
        ),
    _vm.threeStates
      ? _c(
          "label",
          { staticClass: "to-click", on: { click: _vm.switchState } },
          [_vm._v(" "), _vm._t("default")],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }