import simpleChildrenWrapper from '@/router/wrapper'

const ListagemDeVerbas = () => import('./Telas/ListagemDeVerbas.vue')

export default [
  {
    path: '/parametrizador',
    name: 'parametrizador',
    component: simpleChildrenWrapper,
    children: [
      {
        path: 'listar',
        name: 'parametrizador.listar',
        component: ListagemDeVerbas,
        meta: {title: 'Listagem de Verbas'}
      }
    ]
  }
]
