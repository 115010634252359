interface ErroPontotel {
  nome: string;
  mensagem: string;
  stack: string;
}

abstract class ErroPontotel extends Error implements ErroPontotel {
  constructor (mensagem: string) {
    super(mensagem)
    this.mensagem = mensagem
    this.stack = (new Error()).stack as string
    this.nome = this.constructor.name
  }
}

export abstract class ErroNoNavegadorPontotel extends ErroPontotel {}

export abstract class ErroDoServidorPontotel extends ErroPontotel {}

export abstract class ErroDoUsuarioPontotel extends ErroPontotel {}
